import PropTypes from "prop-types"
import React, { useEffect, useCallback, useRef, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link, useLocation } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"

//i18n
import { withTranslation } from "react-i18next"
//Decode
import jwtDecode from "jwt-decode"

import sm1 from "assets/images/services-icon/sm1.svg"
import sm2 from "assets/images/services-icon/sm2.svg"
import sm3 from "assets/images/services-icon/sm3.svg"
import sm4 from "assets/images/services-icon/sm4.svg"
import sm5 from "assets/images/services-icon/sm5.svg"
import sm6 from "assets/images/services-icon/sm6.svg"
import st1 from "assets/images/services-icon/st1.png"
import st2 from "assets/images/services-icon/st2.png"
import pen from "assets/images/download.png"

//axios

import axios from "axios"
import { SECURITY_KEY, BASE_URL } from "../../global";
const SidebarContent = props => {
  const location = useLocation()
  const ref = useRef()
  const path = location.pathname

  const [username, setusername] = useState("Admin")
  const [isEditing, setIsEditing] = useState(false)
  const [isSummaryEditing, setIsSummaryEditing] = useState(false)
  const [preferredName, setPreferredName] = useState("")
  const [flexidays, setflexidays] = useState('')
  const [summaryText, setSummaryText] = useState("")
  const [preferredNameLabel, setPreferredNameLabel] = useState("")
  //use for edit the preffered name

  //krishna -start

  const [ProfileData, setProfileData] = useState([])

  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  useEffect(() => {
    getmyprofile();
  }, [])


  function getmyprofile() {

    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL+"profile/getmyprofile")
        .then(responseProfile => {
          setProfileData(responseProfile.data.data[0])
          setflexidays(responseProfile.data.data[0].tut_flexi_days)
          setIsSummaryEditing(false);
        })
        .catch(error => {
          console.log(error.response.data.error)
        })
    } else {
     
      // navigate("/login");
    }

  }
  //krishna-end

  function decodeAccessToken(token) {
    try {
      const decoded = jwtDecode(token)
      return { success: true, data: decoded }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      //   const obj = JSON.parse(localStorage.getItem("authUser"));
      //   setusername(obj.displayName);
      // } else if (
      //   process.env.REACT_APP_DEFAULTAUTH === "fake" ||
      //   process.env.REACT_APP_DEFAULTAUTH === "jwt"
      // ) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const jwtdecode = decodeAccessToken(obj)

      setusername(jwtdecode.data)
      setPreferredNameLabel(jwtdecode.data.tut_pref_Name)
      //}
    }
  }, [props.success])

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = location.pathname
    const fullPath = pathName
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  //profile modal large
  const [modal_large, setmodal_large] = useState(false)

  const tog_large = () => {
    setmodal_large(!modal_large)
    // removeBodyCss()
  }

  //krishna -start

  const handleEditClick = () => {
    setIsEditing(true)
    setPreferredName(ProfileData.tut_pref_Name);
   
    
  }
  const handleEditSummaryClick = () => {
    setIsSummaryEditing(true)
    setSummaryText(ProfileData.tut_summary);
  }

  const handleInputChange = event => {
    setPreferredName(event.target.value)
  }
  const handleSummaryInputChange = event => {
    setSummaryText(event.target.value)
  }

  const handleInputFlexiChange = event => {
    console.log(event)
    setflexidays(event)
  }
  //updating for prefered name and summary

  const handleFileUpload = async (event) => {
    try {
      setIsEditing(false);

      const file = event.target.files[0]; // Get the first file from the selected files

      const formData = new FormData();
      formData.append('profileImage', file); // Append the file to form data

      const responseUpdate = await axios.put(
        BASE_URL+`profile/updateMyprofileImage`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (responseUpdate.status === 200) {
        toast.success('Profile image updated successfully', {
          autoClose: 3000,
        });
        getmyprofile(); // Refresh profile data after successful update
      } else {
        toast.error('Failed to update profile image', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error('Error updating profile image', {
        autoClose: 3000,
      });
    }
  };
  const handleSaveClick = async () => {
    try {
      setIsEditing(false)
      const responseUpdate = await axios.put(
        BASE_URL+`profile/updateMyProfile/${ProfileData.pk_tut_key}`,
        { tut_pref_Name: preferredName }
      )
      if (responseUpdate.status === 200) {
        toast.success(" Preferred name saved successfully", {
          autoClose: 3000, // 3 seconds in this example
        })

        //    alert('Prefered name successfully updated')
        // setUpdatedName(preferredName);
        getmyprofile();
        
      } else {
        toast.error("Failure! Failed to save preferred name", {
          autoClose: 3000, // 3 seconds in this example
        })
        //  alert('error updating')
      }
    } catch (error) {
      toast.error("Failure! Error saving preferred name", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }
  const handleSaveSummaryClick = async () => {
    try {
      setIsEditing(false)
      const responseUpdate = await axios.put(
        BASE_URL+`profile/updateMyProfile/${ProfileData.pk_tut_key}`,
        { tut_summary: summaryText }
      )
      if (responseUpdate.status === 200) {
        toast.success(" Summary saved successfully", {
          autoClose: 3000, // 3 seconds in this example
        })

        //    alert('Prefered name successfully updated')
        // setUpdatedName(summaryText)
        getmyprofile();
        
      } else {
        toast.error("Failure! Failed to save preferred name", {
          autoClose: 3000, // 3 seconds in this example
        })
        //  alert('error updating')
      }
    } catch (error) {
      toast.error("Failure! Error saving preferred name", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }




  //krishna -end


  //kathir start
 // State to hold the selected value
 const [selectedOption, setSelectedOption] = useState(ProfileData.tut_clock_is_active || 0);


 useEffect(() => {
  if (ProfileData && ProfileData.tut_clock_is_active !='') {
  
    setSelectedOption(parseInt(ProfileData.tut_clock_is_active));
    
  }else{
    setSelectedOption(0)
  }
}, [ProfileData])
 // Handler function to update the selected value
 const handleOptionChange = (event) => {
  handleClockActive(event.target.value)
  // setSelectedOption(event.target.value);
 };
 const handleClockActive = async (data) => {
  try {
    setIsEditing(false)
    const responseUpdate = await axios.put(
      BASE_URL+`profile/updateMyProfile/${ProfileData.pk_tut_key}`,
      { tut_clock_is_active: data }
    )
    if (responseUpdate.status === 200) {

      setSelectedOption(data);
      if(parseInt(data) === 1){
        toast.success("Clock Disabled successfully", {
          autoClose: 3000, // 3 seconds in this example
        })
      }else{
        toast.success("Clock Activated successfully", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
      


      //    alert('Prefered name successfully updated')
      // setUpdatedName(summaryText)
      //getmyprofile();
      // console.log(preferredName)
    } else {
      toast.error("Failure! Failed to save preferred name", {
        autoClose: 3000, // 3 seconds in this example
      })
      //  alert('error updating')
    }
  } catch (error) {
    toast.error("Failure! Error saving preferred name", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
}
const handleSaveFlexiClick = async () => {
  if(flexidays > 0 && flexidays <= 30){
  try {
    setIsEditing(false)
    const responseUpdate = await axios.put(
      BASE_URL+`profile/updateMyProfile/${ProfileData.pk_tut_key}`,
      { tut_flexi_days: flexidays }
    )
    if (responseUpdate.status === 200) {

      //setflexidays(data);
      // if(parseInt(data) === 1){
        toast.success("Flexi days updated successfully", {
          autoClose: 3000, // 3 seconds in this example
        })
      // }else{
      //   toast.success("Clock Activated successfully", {
      //     autoClose: 3000, // 3 seconds in this example
      //   })
      // }
      


      //    alert('Prefered name successfully updated')
      // setUpdatedName(summaryText)
      //getmyprofile();
      // console.log(preferredName)
    } else {
      toast.error("Failure! Failed to flexi days updated ", {
        autoClose: 3000, // 3 seconds in this example
      })
      //  alert('error updating')
    }
  } catch (error) {
    toast.error("Failure! Error saving preferred name", {
      autoClose: 3000, // 3 seconds in this example
    })
  }
}else{
  toast.error("Flexi no of days minimum 1 day and maximum 30 days.", {
    autoClose: 3000, // 3 seconds in this example
  })
}
}


  //kathir end
  return (
    <React.Fragment>
     
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
      
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled core1" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <img width={20} src={sm1} alt="" />

                <span>{props.t("Home")}</span>
              </Link>
            </li>

            <li>
              <Link to="/session" className="waves-effect">
                <img width={20} src={sm2} alt="" />
                <span>{props.t("Sessions")}</span>
              </Link>
            </li>
            <li>
              <Link to="/students" className="waves-effect">
                <img width={20} src={sm3} alt="" />

                <span>{props.t("Students")}</span>
              </Link>
            </li>

            <li>
              <Link to="/myLibrary" className="waves-effect">
                <img width={20} src={sm4} alt="" />
                <span>{props.t("My Library")}</span>
              </Link>
            </li>

            <li>
              <Link to="/timesheet" className="waves-effect">
                <img width={20} src={sm5} alt="" />
                <span>{props.t("Timesheet")}</span>
              </Link>
            </li>
          </ul>

          <ul
            className="metismenu list-unstyled custom-formatter"
            id="side-menu"
          > <ToastContainer autoClose={3000}/>
            <li onClick={tog_large} className="cust-pd pt-1">
              <Link to="#" className="waves-effect">
                <img width={24}  src={
                        ProfileData.tut_profile_pic
                          ? ProfileData.tut_profile_pic
                          : st1
                      } alt="" />
                

                <div>
                  <span className="font-size-18">
                    {username
                      ? username.tut_fname
                        ? username.tut_fname + " " + username.tut_surname
                        : ""
                      : ""}
                  </span>
                  <br></br>
                  <small className="font-size-15 fw-light">Edit profile</small>
                </div>
              </Link>
            </li>
            <li className="cust-pd mb-3">
              <Link to="/logout" className="waves-effect cp-pd-0">
                <img width={22} src={sm6} alt="" />

                <span className="luminous-wht font-size-15 fw-semibold">
                  {props.t(" Log out")}
                </span>
              </Link>
            </li>
          </ul>
        </div>

        {/* modal */}

        <Modal isOpen={modal_large} toggle={tog_large} size="lg">
          <div className="p-4">
            <ModalHeader className="mt-0" toggle={tog_large}>
              <div>
                <h6 class="profile_header">My Profile</h6>
                <p class="headerTimeline">Last modified February 2024</p>
              </div>
            </ModalHeader>
            <ModalBody style={{ padding: "12px" }}>
              <div className="pr-wrapper d-flex">
                <div className="cl-left me-22">
                  <div class="profileSection text-center position-relative">
                    <img
                      width={20}
                      src={
                        ProfileData.tut_profile_pic
                          ? ProfileData.tut_profile_pic
                          : st1
                      }
                      // src={ProfileData.tut_profile_pic}
                      //  src={st1}
                      alt=""
                      className="profileSection-img rounded-circle"
                    />
                    <div className="edit-float">
                    <form autoComplete="off" >

                      <label htmlFor="file-upload">
                        <input
                          id="file-upload"
                          accept="image/*"
                          multiple
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleFileUpload}
                        />
                        <img src={pen} alt="Upload Profile" />
                      </label>
                      </form>
                    </div>
                  </div>
                  <div class="Profile_title text-center mt-2 mb-4">
                    Tutor ID: {ProfileData.pk_tut_id}
                  </div>

                  <div className="d-flex text-center justify-content-between">
                    <div className="Profile_lable me-3">Preferred name</div>
                    <img src={pen} alt="" onClick={handleEditClick} />
                  </div>
                  <div class="profile_value mb-3">
                    {isEditing ? (
                      <div className="input-section">
                        <input
                          type="text"
                          value={preferredName}
                          onChange={handleInputChange}
                          onBlur={handleSaveClick}
                          className="form-control"
                        />
                      </div>
                    ) : (
                      <div className="profile_valuess mb-3">
                        {ProfileData.tut_pref_Name}{" "}
                      </div>
                    )}
                  </div>
                  <div className="d-flex text-center justify-content-between">
                    <div className="Profile_lable me-3">Summary</div>
                    <img src={pen} alt="" onClick={handleEditSummaryClick} />
                  </div>
                  <div class="profile_value mb-3">   {isSummaryEditing ? (
                    <div className="input-section">

                      <textarea
                        value={summaryText}
                        onChange={handleSummaryInputChange}
                        onBlur={handleSaveSummaryClick}
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <div className="profile_valuess mb-3">
                      {ProfileData.tut_summary}{" "}
                    </div>
                  )}</div>



                  <div className="d-flex text-center justify-content-between mt-4">
                    <div className="Profile_lable me-3">Setting</div>
                    {/* <img src={pen} alt="" onClick={handleEditSummaryClick} /> */}
                  </div>
                  <div class="profile_value mb-3">   
                     <div class="profile_detail_title mt-2 ms-2">
                       Clock
                      </div>
                    <div className="input-section mt-2 ms-4">
                    <div className="form-check mt-4">
                      <input
                        type="radio"
                        value={0}
                        className="form-check-input"
                        checked={parseInt(selectedOption) === 0}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label mb-0 fw-medium"
                        htmlFor="Active"
                      >
                        Active
                      </label>
                    </div>
                    <div className="form-check mt-4">
                      <input
                        type="radio"
                        value={1}
                        className="form-check-input"
                        checked={parseInt(selectedOption) === 1}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label mb-0 fw-medium"
                        htmlFor="Disable"
                      >
                        
                        Inactive
                      </label>
                    </div>
                    
                    
                    
                    </div>
                  </div>
                  <div class="profile_value mb-3">   
                     <div class="profile_detail_title mt-2 ms-2">
                       Flexi days 
                      </div>
                     
                    <div className="input-section mt-2 ms-2">
                    <div className="form-check mt-2">
                      <input
                        type="number"
                        value={flexidays
                          ? flexidays
                          : ''}
                        className="form-control"
                        onChange={(e) => { console.log(e.target.value); e.target.value <= 30 ? handleInputFlexiChange(e.target.value) : setflexidays(0)}}
                        onBlur={handleSaveFlexiClick}
                        min={1}
                        max={30}
                      />
                      
                    </div>
                    
                    
                    </div>
                  </div>
                </div>
                <div className="vertical-divider">
                </div>
                {/* <div class="MyProfile_deviederSection__atQsB"></div> */}

                <div className="cl-right ms-22">
                  <div className="profile-detail-wrap">
                    <div className="st-wrapper">
                      <div className="row">
                        <div className="col-5 Profile_subtitle me-5">
                          <h5 class="font-size-14 fw-700 text-dark mb-10">
                            Account
                          </h5>
                        </div>
                        <div className="col-4"></div>
                        <div className="col-5 Profile_subtitle me-5">
                          Username
                        </div>
                        <div className="col-4">
                          <h5 className="profile_value_acc me-3">
                            {" "}
                            {ProfileData.tut_fname} {ProfileData.tut_surname}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="profile_detail_title pd-12">
                      <h5 className="font-size-14 fw-700 text-dark mb-10">
                        Personal Information
                      </h5>

                      <div className="row mt-2">
                        <div className="col-5  me-5 mb-3">
                          <p className="Profile_subtitle">Salutation</p>
                          <p className="profile_info">-</p>
                        </div>

                        <div className="col-4  me-5 mb-3">
                          <p className="Profile_subtitle">First Name</p>
                          <p className="profile_info">
                            {ProfileData.tut_fname}
                          </p>
                        </div>
                        <div className="col-5  me-5">
                          <p className="Profile_subtitle">Last Name</p>
                          <p className="profile_info">
                            {ProfileData.tut_surname}
                          </p>
                        </div>

                        <div className="col-4  me-5">
                          <p className="Profile_subtitle">Birth date</p>
                          <p className="profile_info">{ProfileData.tut_dob}</p>
                        </div>
                      </div>
                    </div>

                    <div className="pd-12">
                      <div class="profile_detail_title">
                        Address Information
                      </div>
                      <div className="col-12 mt-2">
                        <p className="Profile_subtitle">Address</p>
                        <p className="profile_info text-dark mb-3">
                          {ProfileData.tut_address}
                        </p>
                      </div>

                      <div className="row mt-2">
                        <div className="col-5  me-5 mb-3">
                          <p className="Profile_subtitle">Country</p>
                          <p className="profile_info">
                            {ProfileData.tut_country}
                          </p>
                        </div>

                        <div className="col-4  me-5 mb-3">
                          <p className="Profile_subtitle">State</p>
                          <p className="profile_info">
                            {ProfileData.tut_state}
                          </p>
                        </div>
                        <div className="col-5  me-5">
                          <p className="Profile_subtitle">City</p>
                          <p className="profile_info">{ProfileData.tut_city}</p>
                        </div>

                        <div className="col-4  me-5">
                          <p className="Profile_subtitle">Postcode</p>
                          <p className="profile_info">754688</p>
                        </div>
                      </div>
                    </div>

                    <div className="pd-12">
                      <div class="profile_detail_title">
                        Contact Information
                      </div>

                      <div className="row">
                        <div className="col-5 me-5 mb-3 mt-2">
                          <p className="Profile_subtitle">Home Phone</p>
                          <p className="profile_info">
                            {ProfileData.tut_home_phone}
                          </p>
                        </div>

                        <div className="col-4  me-5 mb-3">
                          <p className="Profile_subtitle">Work Phone</p>
                          <p className="profile_info">
                            {ProfileData.tut_work_phone}
                          </p>
                        </div>
                        <div className="col-5  me-4">
                          <p className="Profile_subtitle">Mobile</p>
                          <p className="profile_info">
                            {ProfileData.tut_mobile == !null
                              ? ProfileData.tut_mobile
                              : "-"}
                          </p>
                        </div>

                        <div className="col-4  me-5">
                          <p className="Profile_subtitle">Email</p>
                          <p className="profile_info">
                            {ProfileData.tut_emailid}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pd-12">
                      <div class="profile_detail_title">About Tutor</div>
                      <div className="col-12  me-5 mt-2">
                        <p className="Profile_subtitle">
                          {ProfileData.tut_notes}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="status-wrapper mt-10">
                    <p className="d-flex align-items-center text-dark">
                      {" "}
                      <span className="cs-txt-danger">
                        <i className="fas fa-exclamation-circle font-size-20 me-2"></i>
                      </span>{" "}
                      Please notify your admin immediately if the information is
                      not accurate.
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
