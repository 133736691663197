import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import {
  Container,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  FormFeedback,
  Form,
  Progress,
  Row,
  Col,
} from "reactstrap"

import Switch from "react-switch"
import Select from "react-select"
import "chartist/dist/scss/chartist.scss"
import { Link, useParams, useNavigate, useLocation } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import chevleft from "assets/images/services-icon/chev-left.png"
//i18n
import { withTranslation } from "react-i18next"
import st1 from "../../assets/images/services-icon/st1.png"
import st2 from "../../assets/images/services-icon/st2.png"
import duration from "../../assets/images/services-icon/duration.png"
import evelaa from "../../assets/images/services-icon/evaluation.png"
import vid from "../../assets/images/services-icon/video.png"
import reel from "../../assets/images/services-icon/reel.png"

import awicon from "../../assets/images/services-icon/coin.png"
import error from "../../assets/images/services-icon/error.png"
import axios from "axios"
import { SECURITY_KEY, BASE_URL, STUDENT_BASE_URL } from "../../global"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import moment from "moment"

// Formik validation
import * as Yup from "yup"

import { useFormik } from "formik"

import Loader from "react-spinner-loader"
import TableComm from "../../dataTableCommon"

import * as HoverCard from "@radix-ui/react-hover-card"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const CreateLesson = props => {
  const [homeworkActivityArray, sethomeworkActivityArray] = useState([])
  const [lessonActivityArray, setLessonActivityArray] = useState([])

  const [modal_lesson_report, setmodal_lesson_report] = useState(false)
  const [modal_home_work_report, setmodal_home_work_report] = useState(false)

  const [switch2, setswitch2] = useState(false)

  //yogesh start

  const [newactivityhomework, setnewactivityhomework] = useState(false)

  const optionGroup3 = [
    {
      label: "Select Activity",
      options: [
        { label: "Video", value: "1" },
        { label: "Link", value: "3" },
        { label: "Task", value: "4" },
        { label: "Work sheet", value: "5" },
      ],
    },
  ]
  const handleInputChangeadd = event => {
    setSelectedOption(event.target.value)
  }
  const formRefadd = useRef(null)
  const [selectedOption3, setSelectedOption] = useState("")
  const [isSubmittingadd, setIsSubmittingadd] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const tog_large = () => {
    setmodal_large(!modal_large)
    formikadd.resetForm();
  }

  //add libary

  const basicSchema3 = Yup.object().shape({
    act_subject: Yup.string().required("Subject name is required."),
    act_type: Yup.string().required("Activity type is required."),
    act_name: Yup.string()
      .required("Activity name is required.")
      .min(2, "Minimum 2 characters required")
      .max(250, "Maximum 250 characters is required.")
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    act_source: Yup.string().required("Source is required."),
    act_duration: Yup.string().required("Duration is required."),
    act_link: Yup.string().when("act_type", {
      is: "3",
      then: schema =>
        schema
          .required("Link is required.")
          .matches(
            /^(https?:\/\/|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=%]*)?$/,
            "Enter a valid link"
          ),
      otherwise: schema => schema.notRequired(),
    }),
    act_video: Yup.string().when("act_type", {
      is: "1",
      then: schema =>
        schema
          .required("Video is required.")
          .matches(
            /^(https?:\/\/|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=%]*)?$/,
            "Enter a valid video link"
          ),
      otherwise: schema => schema.notRequired(),
    }),
    act_file: Yup.mixed().when("act_type", {
      is: "5",
      then: schema => schema.required("File is required."),
      otherwise: schema => schema.notRequired(),
    }),
  })

  const initialValues3 = {
    /*subjects*/

    act_subject: "",
    act_type: "",
    act_name: "",
    act_source: "",
    act_duration: "",
    act_file: null,
    act_link: "",
    act_video: "",
    act_description: "",
    tutor_comments: "",
  }
  const formikadd = useFormik({
    initialValues: initialValues3,
    validationSchema: basicSchema3,
    //  onSubmit,
    onSubmit: async formValues => {
      // setIsSubmitting1(true);

      try {
        if (authUser && authUser != "" && authUser != null) {
          // setLoader(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

          if (valuesadd.act_file && valuesadd.act_type == "5") {
            const formData = new FormData()
            formData.append("act_subject", valuesadd.act_subject)
            formData.append("act_type", valuesadd.act_type)
            formData.append("act_name", valuesadd.act_name)
            formData.append("act_source", valuesadd.act_source)
            formData.append("act_duration", valuesadd.act_duration)
            formData.append("act_file", valuesadd.act_file)
            formData.append("act_description", valuesadd.act_description)
            formData.append("tutor_comments", valuesadd.tutor_comments)
            formData.append("pk_student_key", student_key)
            formData.append("session_key", session_key)
            formData.append("is_homework", newactivityhomework ? 1 : 0)

            const response = await axios.post(
              BASE_URL + "mylibrary/createMyLibraryfromlesson1",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            if (response.data.status && response.data.status === true) {
              // Reset the form

              toast.success(" Your My Library was submitted", {
                autoClose: 3000, // 3 seconds in this example
              })
              setmodal_large(false)
              setnewactivityhomework(false)
              formikadd.setFieldValue("act_subject", "")
              formikadd.setFieldValue("act_type", "")
              formikadd.setFieldValue("act_name", "")
              formikadd.setFieldValue("act_source", "")
              formikadd.setFieldValue("act_duration", "")
              formikadd.setFieldValue("act_file", null)
              formikadd.setFieldValue("act_link", "")
              formikadd.setFieldValue("act_video", "")
              formikadd.setFieldValue("act_description", "")
              formikadd.setFieldValue("tutor_comments", "")
              formikadd.resetForm()

              // After 3 seconds, redirect to another page
              // setTimeout(function () {
              //   navigate("/Subject");
              // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
              fetchLessonData()
              fetchHomeWorkData()
              setIsSubmittingadd(false) // Re-enable the submit button
            } else {
              toast.error(
                "Failure! Unable to create my library. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmittingadd(false) // Re-enable the submit button
            }
          } else {
            const response = await axios.post(
              BASE_URL + "mylibrary/createMyLibraryfromlesson",
              {
                ...formValues,
                pk_student_key: student_key,
                session_key: session_key,
                is_homework: newactivityhomework ? 1 : 0,
              }
            )

            if (response.data.status && response.data.status === true) {
              toast.success(" Your My Library was submitted", {
                autoClose: 3000, // 3 seconds in this example
              })
              setmodal_large(false)
              setnewactivityhomework(false)
              formikadd.setFieldValue("act_subject", "")
              formikadd.setFieldValue("act_type", "")
              formikadd.setFieldValue("act_name", "")
              formikadd.setFieldValue("act_source", "")
              formikadd.setFieldValue("act_duration", "")
              formikadd.setFieldValue("act_file", null)
              formikadd.setFieldValue("act_link", "")
              formikadd.setFieldValue("act_video", "")
              formikadd.setFieldValue("act_description", "")
              formikadd.setFieldValue("tutor_comments", "")
              formikadd.resetForm()
              // After 3 seconds, redirect to another page
              // setTimeout(function () {
              //   navigate("/Subject");
              // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
              fetchLessonData()
              fetchHomeWorkData()
              setIsSubmittingadd(false) // Re-enable the submit button
            } else {
              toast.error(
                "Failure! Unable to create my library. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmittingadd(false) // Re-enable the submit button
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        // toast.error("Failure! Unable to create my library. Try again later", {
        //   autoClose: 3000, // 3 seconds in this example
        // })

        setIsSubmittingadd(false) // Re-enable the submit button
      } finally {
        setIsSubmittingadd(false) // Re-enable the submit button
      }
    },
  })

  const valuesadd = formikadd.values
  const handleBluradd = formikadd.handleBlur
  const touchedadd = formikadd.touched
  const handleChangeadd = formikadd.handleChange
  const errorsadd = formikadd.errors
  const handleSubmitadd = formikadd.handleSubmit
  const setTouchedadd = formikadd.setTouched
  const handleFileChangeadd = formikadd.handleChange //check here surya broo
  const setValuesadd = formikadd.setValues
  const handleResetadd = formikadd.handleReset
  //yogesh end
  //kathir start

  const [loader, setLoader] = useState(false)
  const [loaderHomework, setLoaderHomework] = useState(false)
  const [loaderLesson, setLoaderLesson] = useState(false)

  const [activity_key, setActivityKey] = useState([])
  //delete activity start
  const [modal_delete_activity, setmodal_delete_activity] = useState(false)

  const tog_delete_activity = () => {
    setmodal_delete_activity(!modal_delete_activity)
    // removeBodyCss();
  }
  const [isDASubmitting, setIsDASubmitting] = useState(false)

  const [isNotesSubmitting, setIsNotesSubmitting] = useState(false)
  const [lessonNote, setLessonNote] = useState("")

  function textareachange(event) {
    const value = event.target.value

    setLessonNote(value)
  }

  const [HomeWorkNote, setHomeWorkNote] = useState("")

  function textareachangeHome(event) {
    const value = event.target.value

    setHomeWorkNote(value)
  }
  //yogesh start

  const [modal_assessment, setAssessment_modal] = useState(false)
  const [modal_diagnostics, setDiagnostics_modal] = useState(false)
  const [subjectId, setSubjectId] = useState(null)
  const [subjectIdData, setSubjectIdData] = useState(1)
  const [activityIsHw, setActivityIsHW] = useState(0)
  const [pk_lesson_activity_key, setPkLessonActivityKey] = useState(null)
  const [assignActivityLevelData, setAssignActivityLevelData] = useState([])
  const [assignDiagnosticsActivity, setAssignDiagnosticsLevelData] = useState(
    []
  )
  const [currentDiagnosticslistActivity, setCurrentDiagnosticslistActivity] =
    useState([])
  const [InnerLoder, setInnerLoder] = useState(false)
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0)
  const loaderRef = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [existingData, setExistingValue] = useState(
    JSON.parse(localStorage.getItem("selectedData")) || []
  )

  const tog_Assessment = () => {
    setAssessment_modal(!modal_assessment)
    setExistingValue([])
    setActivityIsHW(0)
    localStorage.removeItem("selectedData")
  }
  const tog_Diagnostics = () => {
    setDiagnostics_modal(!modal_diagnostics)
    setExistingValue([])
    setActivityIsHW(0)
    localStorage.removeItem("selectedData")
  }
  const currentDiagnosticsActivity =
    assignDiagnosticsActivity.length > 0
      ? assignDiagnosticsActivity[0].activities[currentLevelIndex]
      : null

  useEffect(() => {
    if (assignDiagnosticsActivity && assignDiagnosticsActivity.length > 0) {
      //console.log(assignDiagnosticsActivity[0])

      if (assignDiagnosticsActivity[0].activities) {
        const activityNodeId =
          currentDiagnosticslistActivity?.topics?.[
            currentDiagnosticslistActivity?.topics.length - 1
          ]?.[0]?.activity_topic_id

        if (activityNodeId !== undefined) {
          const levelIndex = assignDiagnosticsActivity[0].activities.findIndex(
            activity => {
              return activity.topics.some(
                topic => topic.activity_topic_id === activityNodeId
              )
            }
          )

          if (levelIndex !== -1) {
            setCurrentLevelIndex(levelIndex)
          } else {
            console.warn("Activity with given activityNodeId not found")
          }
        } else {
          console.warn("activity_topic_id not found")
        }
      }
    }
  }, [assignDiagnosticsActivity, currentDiagnosticslistActivity])

  const goToNextLevel = () => {
    if (
      currentLevelIndex <
      assignDiagnosticsActivity[0].activities.length - 1
    ) {
      setCurrentLevelIndex(currentLevelIndex + 1)
    }
  }

  const goToPreviousLevel = () => {
    if (currentLevelIndex > 0) {
      setCurrentLevelIndex(currentLevelIndex - 1)
    }
  }

  const [currentTopicIndex, setCurrentTopicIndex] = useState(0)

  //kathir add next activity functionality
  const [CurrentActivityData, setCurrentActivityData] = useState(null)
  const [IsNextActivity, setNextActivity] = useState(false)

  useEffect(() => {
    if (assignActivityLevelData && assignActivityLevelData.activities) {
      const activityNodeId =
        assignActivityLevelData.activities[0].activity_node_id

      const topicIndex =
        assignActivityLevelData.activities[0].LevelTopics.findIndex(topic => {
          return topic.TopicActivities.some(
            activity => activity.activity_node_id === activityNodeId
          )
        })

      if (topicIndex !== -1) {
        setCurrentTopicIndex(topicIndex)
      }
    }
  }, [assignActivityLevelData])

  // const fetchAndProcessData = async () => {
  //   if (!assignActivityLevelData?.activities || !IsNextActivity) return;

  //   try {
  //     localStorage.removeItem("selectedData"); // This is synchronous, no need for `await`
  //     setExistingValue([])
  //     console.log('existing data remove')
  //     const activitiesData = assignActivityLevelData.activities[0];
  //     const activityNodeId = activitiesData.activity_node_id;

  //     const topicData = activitiesData.LevelTopics.find(topic =>
  //       topic.TopicActivities.some(activity => activity.activity_node_id === activityNodeId)
  //     );

  //     if (!topicData) return toast.error(
  //       "Sorry topic data not founded",
  //       {
  //         autoClose: 3000, // 3 seconds in this example
  //       }
  //     );

  //     const activities = topicData.TopicActivities || [];
  //     const currentIndex = activities.findIndex(activity => activity.activity_node_id === activityNodeId);
  //     const nextActivity =
  //       currentIndex >= 0 && currentIndex < activities.length - 1
  //         ? activities[currentIndex + 1]
  //         : null;

  //     if (!nextActivity) return toast.error(
  //       "This next actvity not avalable",
  //       {
  //         autoClose: 3000, // 3 seconds in this example
  //       }
  //     );

  //     const isActivityInArray = (array, id) =>
  //       array.some(data => data.activity_node_id === id);

  //     const isExistingLesson = CurrentActivityData?.marked_for_homework === 1
  //       ? isActivityInArray(homeworkActivityArray, nextActivity.activity_node_id)
  //       : isActivityInArray(lessonActivityArray, nextActivity.activity_node_id);

  //     const existingData = JSON.parse(localStorage.getItem("selectedData")) || [];
  //     const isExisting = existingData.some(data => data.activity_node_id === nextActivity.activity_node_id);
  //     if(existingData){
  //       toast.error(
  //         "This next actvity already assigned",
  //         {
  //           autoClose: 3000, // 3 seconds in this example
  //         }
  //       );
  //     }else if(isExisting){
  //       toast.error(
  //         "This next actvity already assigned",
  //         {
  //           autoClose: 3000, // 3 seconds in this example
  //         }
  //       );
  //     }

  //     if (!isExisting && !isExistingLesson) {
  //       await handleLocalStorageAssignClick(
  //         nextActivity,
  //         activities,
  //         activitiesData,
  //         0
  //       );
  //     }

  //     setAssignActivityLevelData([]);
  //     setNextActivity(false);

  //     console.log("Next Activity:", nextActivity);
  //   } catch (error) {
  //     console.error("Error processing activity data:", error);
  //   }
  // };

  // useEffect(() => {
  //   const executeEffect = async () => {
  //     if (IsNextActivity) {
  //       localStorage.removeItem("selectedData"); // No `await` needed
  //       await fetchAndProcessData();
  //       OnSubmitActivityData();
  //     }
  //   };

  //   executeEffect();
  // }, [assignActivityLevelData, IsNextActivity, homeworkActivityArray, lessonActivityArray, CurrentActivityData]);

  const fetchAndProcessData = async () => {
    if (!assignActivityLevelData?.activities || !IsNextActivity) return

    try {
      localStorage.removeItem("selectedData") // Clear previous selectedData
      setExistingValue([])
      console.log("Existing data removed")

      const activitiesData = assignActivityLevelData.activities[0]
      const activityNodeId = activitiesData.activity_node_id

      const topicData = activitiesData.LevelTopics.find(topic =>
        topic.TopicActivities.some(
          activity => activity.activity_node_id == activityNodeId
        )
      )

      if (!topicData) {
        return toast.error("Sorry, topic data not found", { autoClose: 3000 })
      }

      const activities = topicData.TopicActivities || []
      const currentIndex = activities.findIndex(
        activity => activity.activity_node_id == activityNodeId
      )
      const nextActivity =
        currentIndex >= 0 && currentIndex < activities.length - 1
          ? activities[currentIndex + 1]
          : null

      if (!nextActivity) {
        return toast.error("No next activity available", { autoClose: 3000 })
      }

      const isActivityInArray = (array, id) =>
        array.some(data => data.fk_activity_node_id == id)
      // console.log(lessonActivityArray);
      // console.log(homeworkActivityArray);
      const isExistingLesson =
        CurrentActivityData?.marked_for_homework == 1
          ? isActivityInArray(
              homeworkActivityArray,
              nextActivity.activity_node_id
            )
          : isActivityInArray(
              lessonActivityArray,
              nextActivity.activity_node_id
            )

      const existingData =
        JSON.parse(localStorage.getItem("selectedData")) || []
      const isExisting = existingData.some(
        data => data.activity_node_id == nextActivity.activity_node_id
      )

      if (isExisting || isExistingLesson) {
        return toast.error("This activity is already assigned", {
          autoClose: 3000,
        })
      }

      //console.log(nextActivity);
      // console.log(topicData);
      // console.log(activitiesData);

      // Proceed to handle the activity
      await handleLocalStorageAssignClick(
        nextActivity,
        topicData,
        activitiesData,
        0
      )
      //toast.success("submited", { autoClose: 3000 });
      OnSubmitActivityData()
      setAssignActivityLevelData([])
      setNextActivity(false)
      setExistingValue([])

      console.log("Next Activity:", nextActivity)
    } catch (error) {
      console.error("Error processing activity data:", error)
      toast.error("An error occurred while processing data", {
        autoClose: 3000,
      })
    }
  }

  useEffect(() => {
    const executeEffect = async () => {
      if (IsNextActivity) {
        localStorage.removeItem("selectedData") // Clear old data
        await fetchAndProcessData()
        //OnSubmitActivityData();
      }
    }

    executeEffect()
  }, [
    assignActivityLevelData,
    IsNextActivity,
    homeworkActivityArray,
    lessonActivityArray,
    CurrentActivityData,
  ])

  const currentTopic =
    assignActivityLevelData?.activities?.[0]?.LevelTopics?.[currentTopicIndex]
  const handlePreviousTopic = () => {
    if (currentTopicIndex > 0) {
      setCurrentTopicIndex(currentTopicIndex - 1)
    }
  }

  // Handle navigation to the next topic
  const handleNextTopic = () => {
    if (
      currentTopicIndex <
      assignActivityLevelData.activities[0].LevelTopics.length - 1
    ) {
      setCurrentTopicIndex(currentTopicIndex + 1)
    }
  }

  const fetchNextAssessmentActivityData = async (
    subjectId,
    pk_lesson_activity_key,
    marked_for_homework
  ) => {
    try {
      setAssignActivityLevelData([])
      setInnerLoder(true)
      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          const response1 = await axios
            .post(BASE_URL + "activity/getAssessmentActivitiesData", {
              is_homework: marked_for_homework,
              session_key: session_key,
              student_key: student_key,
              subject_id: subjectId,
              pk_lesson_activity_key: pk_lesson_activity_key,
            })

            .then(responseAssignActivitylevelData => {
              const activitieslevelData = responseAssignActivitylevelData.data

              setAssignActivityLevelData(activitieslevelData)
              setInnerLoder(false)
              setActivityIsHW(marked_for_homework)
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
            })
        }
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);

      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const fetchNextDiagnosticsActivityData = async activity => {
    try {
      console.log(activity)
      setAssignDiagnosticsLevelData([])
      setCurrentLevelIndex(0)
      setInnerLoder(true)
      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          const response1 = await axios
            .post(BASE_URL + "activity/getDiagnosticsActivities", {
              is_homework: activity.marked_for_homework,

              session_key: session_key,
              student_key: student_key,
              subject_id: activity.fk_sub_id,
            })

            .then(responseAssignActivitylevelData => {
              const activitieslevelData = responseAssignActivitylevelData.data

              setAssignDiagnosticsLevelData([activitieslevelData])
              setCurrentDiagnosticslistActivity(activity)
              setActivityIsHW(activity.marked_for_homework)
              setInnerLoder(false)
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
            })
        }
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);

      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const activityAttemptedStatus = AttemptData => {
    toast.info(
      "This activity is already assigned, and the date is " +
        moment(AttemptData.lastTwoWeekExistDate, moment.ISO_8601).format(
          "DD MMM YYYY (ddd)"
        ) +
        ".",
      {
        autoClose: 3000,
      }
    )
  }
  const fetchSelectedAssessData = async (subId, nodeId) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        setInnerLoder(true)
        setSubjectIdData(subId)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL + "activity/previewAssessmentDetails",
          {
            activity_node_id: nodeId,
            subject_id: subId,
            session_key: session_key,
            student_key: student_key,
          }
        )
        const activitiesmylibData = responseListLibrary.data.data
        setInnerLoder(false)
        return activitiesmylibData
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)
        setInnerLoder(false)
        return []
      }
    } else {
      return []
      setInnerLoder(false)
    }
  }
  const fetchSelectedDiagnosData = async (subId, nodeId) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL + "activity/previewDiagnosticsDetails",
          {
            activity_node_id: nodeId,
            subject_id: subId,
            session_key: session_key,
            student_key: student_key,
          }
        )
        // const activitiesmylibData = responseListLibrary.data.data;

        // return activitiesmylibData;
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)

        return []
      }
    } else {
      return []
    }
  }
  const fetchselectActivityData = async activity_node_id => {
    try {
      setInnerLoder(true)
      if (authUser && authUser != "" && authUser != null) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios
          .post(BASE_URL + "activity/selectActivity", {
            activity_node_id: activity_node_id,
            subject_id: subjectIdData,
          })
          .then(responseAssignActivity => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            const activitiesData = responseAssignActivity.data
            setInnerLoder(false)
            // setAssignActivity([activitiesData]);
          })
          .catch(error => {
            setInnerLoder(false)
            console.error("Error fetching options from API: ", error)
          })
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
      setInnerLoder(false)
    }
  }
  const handleLocalStorageAssignClick = async (
    topicTitle,
    topic,
    activity,
    acttype
  ) => {
    // console.log(topicTitle)
    // console.log(topic)
    // console.log(activity)
    let SelectedActivity = []
    if (acttype == 0) {
      SelectedActivity = await fetchSelectedAssessData(
        topic.subject_id,
        topicTitle.activity_node_id
      )
    } else if (acttype == 2) {
      SelectedActivity = await fetchSelectedDiagnosData(
        topic.subject_id,
        topicTitle.activity_master_topic.activity_topic_id
      )
    }

    let mergedData = {}
    // console.log("entered - 2")
    // console.log("SelectedActivity", SelectedActivity)
    if (acttype == 0) {
      /* Assessment */
      fetchselectActivityData(topicTitle.activity_node_id)

      const calculateCoin = async () => {
        if (
          SelectedActivity &&
          SelectedActivity.english_question_activities &&
          SelectedActivity.english_question_activities.length > 0
        ) {
          const firstCategoryId = parseInt(
            SelectedActivity.english_question_activities[0].english_question
              .english_question_new_category.english_question_category_id
          )

          if (firstCategoryId === 13 || firstCategoryId === 10) {
            return SelectedActivity.english_question_activities.reduce(
              (accumulatedScore, activity, popactivityindex) => {
                if (
                  activity.english_question &&
                  activity.english_question.english_question_new_category &&
                  (parseInt(
                    activity.english_question.english_question_new_category
                      .english_question_category_id
                  ) === 13 ||
                    parseInt(
                      activity.english_question.english_question_new_category
                        .english_question_category_id
                    ) === 10)
                ) {
                  const validParts =
                    activity.english_question.english_question_parts.filter(
                      part =>
                        part.english_question_answers.length > 0 &&
                        part.english_question_answers[0].english_answer_text
                    )

                  // If it's the last iteration, return the total score calculated
                  if (
                    popactivityindex ===
                    SelectedActivity.english_question_activities.length - 1
                  ) {
                    return accumulatedScore + validParts.length * 2
                  }
                }
                return accumulatedScore
              },
              0
            )
          }
        }

        return SelectedActivity && SelectedActivity.totalScore
          ? SelectedActivity.totalScore
          : 2
      }
      const calculateTime = async () => {
        let total_time = 0
        if (
          topic.subject_id == 1 &&
          SelectedActivity &&
          SelectedActivity.english_question_activities &&
          SelectedActivity.english_question_activities.length > 0
        ) {
          SelectedActivity.english_question_activities.map(
            (activity, popactivityindex) => {
              if (activity.english_question) {
                var total_part_time =
                  activity.english_question.english_question_time_needed ||
                  "Less than 2 minutes"

                if (total_part_time === "Less than 2 minutes") {
                  total_time += 2
                } else if (total_part_time === "2-5 minutes") {
                  total_time += 5
                } else if (total_part_time === "5-10 minutes") {
                  total_time += 10
                } else if (total_part_time === "10-15 minutes") {
                  total_time += 15
                } else if (total_part_time === "15-20 minutes") {
                  total_time += 20
                } else if (total_part_time === "20-30 minutes") {
                  total_time += 30
                } else if (total_part_time === "30-60 minutes") {
                  total_time += 60
                }
              }
            }
          )
        } else if (
          topic.subject_id == 2 &&
          SelectedActivity &&
          SelectedActivity.question_activities &&
          SelectedActivity.question_activities.length > 0
        ) {
          SelectedActivity.question_activities.map(
            (activity, popactivityindex) => {
              if (activity.question) {
                var total_part_time =
                  activity.question.question_time_needed ||
                  "Less than 2 minutes"

                if (total_part_time === "Less than 2 minutes") {
                  total_time += 2
                } else if (total_part_time === "2-5 minutes") {
                  total_time += 5
                } else if (total_part_time === "5-10 minutes") {
                  total_time += 10
                } else if (total_part_time === "10-15 minutes") {
                  total_time += 15
                } else if (total_part_time === "15-20 minutes") {
                  total_time += 20
                } else if (total_part_time === "20-30 minutes") {
                  total_time += 30
                } else if (total_part_time === "30-60 minutes") {
                  total_time += 60
                }
              }
            }
          )
        }

        return total_time && total_time > 0 ? total_time : 10
      }
      mergedData = {
        id: topicTitle.activity_node_id,
        name: topicTitle.node_name,
        subject_id: topic.subject_id,
        topicID: topic.activity_topic_id,
        coin: await calculateCoin(),
        duration: (await calculateTime()) + " mins",
        is_homework: 0,
        type: "Assessment",
        tutor_comments: "",
        activity_node_id: topicTitle.activity_node_id,
        activity_type: 0,
        level_id: activity.level_id,
        level_name: activity.level_name,
        topic_name: topic.topic_name,
      }
    } else if (acttype == 2) {
      /* Diagnostics */
      let uniqueTopicNodes = []

      const topicNodes =
        topicTitle.activity_master_topic.activity_level_has_topic_nodes
          .map(topicdata => {
            if (topic.subject_id == 1) {
              if (
                topicdata &&
                topicdata.activity_node &&
                topicdata.activity_node.english_diagnostic_question_activities
              ) {
                // Ensure topic and necessary properties exist before accessing them
                return topicdata.activity_node.english_diagnostic_question_activities.map(
                  node => node.question_id
                )
              } else {
                return [] // Return an empty array if any necessary properties are missing
              }
            } else if (topic.subject_id == 2) {
              if (
                topicdata &&
                topicdata.activity_node &&
                topicdata.activity_node.diagnostic_question_activities
              ) {
                // Ensure topic and necessary properties exist before accessing them
                return topicdata.activity_node.diagnostic_question_activities.map(
                  node => node.question_id
                )
              } else {
                return [] // Return an empty array if any necessary properties are missing
              }
            } else {
              return [] // Return an empty array if any necessary properties are missing
            }
          })
          .flat() // Flatten the array of arrays

      uniqueTopicNodes = [...new Set(topicNodes)].filter(nodeId => nodeId !== 0)

      const calculateTime = async () => {
        let total_time = 0
        if (
          topic.subject_id == 1 &&
          SelectedActivity &&
          SelectedActivity.questionData &&
          SelectedActivity.questionData.length > 0
        ) {
          SelectedActivity.questionData.map((activity, popactivityindex) => {
            if (activity.english_diagnostics) {
              var total_part_time =
                activity.english_diagnostics.diagnostic_time_needed ||
                "Less than 2 minutes"

              if (total_part_time === "Less than 2 minutes") {
                total_time += 2
              } else if (total_part_time === "2-5 minutes") {
                total_time += 5
              } else if (total_part_time === "5-10 minutes") {
                total_time += 10
              } else if (total_part_time === "10-15 minutes") {
                total_time += 15
              } else if (total_part_time === "15-20 minutes") {
                total_time += 20
              } else if (total_part_time === "20-30 minutes") {
                total_time += 30
              } else if (total_part_time === "30-60 minutes") {
                total_time += 60
              }
            }
          })
        } else if (
          topic.subject_id == 2 &&
          SelectedActivity &&
          SelectedActivity.questionData &&
          SelectedActivity.questionData.length > 0
        ) {
          SelectedActivity.questionData.map((activity, popactivityindex) => {
            if (activity.diagnostics) {
              var total_part_time =
                activity.diagnostics.diagnostic_time_needed ||
                "Less than 2 minutes"

              if (total_part_time === "Less than 2 minutes") {
                total_time += 2
              } else if (total_part_time === "2-5 minutes") {
                total_time += 5
              } else if (total_part_time === "5-10 minutes") {
                total_time += 10
              } else if (total_part_time === "10-15 minutes") {
                total_time += 15
              } else if (total_part_time === "15-20 minutes") {
                total_time += 20
              } else if (total_part_time === "20-30 minutes") {
                total_time += 30
              } else if (total_part_time === "30-60 minutes") {
                total_time += 60
              }
            }
          })
        }

        return total_time && total_time > 0 ? total_time : 10
      }
      mergedData = {
        id: uniqueTopicNodes,
        name: topicTitle.activity_master_topic.topic_name,
        subject_id: topic.subject_id,
        topicID: topicTitle.activity_master_topic.activity_topic_id,
        coin:
          SelectedActivity &&
          SelectedActivity.questionData &&
          SelectedActivity.questionData.length > 0
            ? SelectedActivity.questionData.length * 2
            : 2,
        duration: (await calculateTime()) + " mins",
        is_homework: 0,
        type: acttype && acttype == 2 ? "Diagnostics" : "",
        activity_node_id: uniqueTopicNodes,
        activity_type: 2,
        level_id: topic.activity_level_id,
        level_name: topic.level_name,
        topic_name: "",
      }
    } else {
      /* Else */
      mergedData = {
        id: "",
        name: "",
        subject_id: 0,
        topicID: 0,
        coin: 0,
        duration: "",
        is_homework: 0,
        type: "",
        activity_node_id: "",
        activity_type: "",
        tutor_comments: "",
        level_id: "",
        level_name: "",
        topic_name: "",
      }
    }

    const newValue = existingData.concat(mergedData)

    const jsonValue = JSON.stringify(newValue)

    localStorage.setItem("selectedData", jsonValue)
    setExistingValue(JSON.parse(localStorage.getItem("selectedData")) || [])
  }

  console.log(localStorage.getItem("selectedData"))
  const handleCommentChange = (id, value) => {
    // Update the comment for the given ID in the state
    setExistingValue(prevData => {
      const updatedData = prevData.map(item =>
        item.id === id ? { ...item, tutor_comments: value } : item
      )

      // Update localStorage with the updated data
      localStorage.setItem("selectedData", JSON.stringify(updatedData))

      return updatedData
    })
  }

  const OnSubmitActivityData = async () => {
    try {
      setExistingValue([])

      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

          const response = await axios
            .post(BASE_URL + "activity/createActivities", {
              activities:
                JSON.parse(localStorage.getItem("selectedData")) || [],
              is_homework: activityIsHw || 0,
              lesson_key:
                localStorage.getItem("lesson_key") &&
                localStorage.getItem("lesson_key") != null
                  ? localStorage.getItem("lesson_key")
                  : "",
              session_key: session_key || "",
              student_key: student_key || "",
            })
            .then(responseActivity => {
              const activitiesData = responseActivity.data

              if (activitiesData == 1) {
                toast.success(" Activities added successfully", {
                  autoClose: 3000,
                })
                setExistingValue([])
                localStorage.removeItem("selectedData")
                fetchLessonData()
                fetchHomeWorkData()
              } else {
                toast.success(" Activities added successfully", {
                  autoClose: 3000,
                })
                setExistingValue([])
                localStorage.removeItem("selectedData")
                fetchLessonData()
                fetchHomeWorkData()

                setIsSubmitting(false)
              }
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
            })
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      toast.error("Failure! Unable to create notes. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      })

      setIsSubmitting(false) // Re-enable the submit button
    } finally {
      setIsSubmitting(false) // Re-enable the submit button
    }
  }
  const removeLocalStorageAssignItem = id => {
    const updatedItems = existingData.filter(item => item.id !== id)
    //setItems(updatedItems);
    localStorage.setItem("selectedData", JSON.stringify(updatedItems))

    setExistingValue(JSON.parse(localStorage.getItem("selectedData")) || [])

    // fetchOPHData();
  }
  //yogesh end
  //preNext type
  const [PreNextType, setpreNextType] = useState(1)

  const [PreNextKey, setpreNextKey] = useState("")

  const [sessionStudentData, setSessionStudentData] = useState([])

  const [sessionLessonData, setSessionLessonData] = useState([])
  const [sessionHomeWorkData, setSessionHomeWorkData] = useState([])
  const [sessionPreNextData, setSessionPreNextData] = useState([])

  const [LessonReportData, setLessonReportData] = useState([])
  const [HomeworkReportData, setHomeworkReportData] = useState([])

  const [PreviousSessioncount, setPreviousSessioncount] = useState(0)
  const [CurrentPreviousSessioncount, setCurrectPreviousSessioncount] =
    useState(1)

  //Move activity start
  const [UpcomingSessionData, setUpcomingSessionData] = useState([])
  const [modal_move_activity, setmodal_move_activity] = useState(false)
  const [moveTosession, setmoveTosession] = useState(false)
  const tog_move_activity = () => {
    setmodal_move_activity(!modal_move_activity)
    // removeBodyCss();
  }
  const [move_activity, setMoveActivity] = useState([])
  function onMoveActivityPopup(getActivity) {
    setMoveActivity(getActivity)
    setmoveTosession(true)
  }

  const [moveingSessionKey, setmoveingSessionKey] = useState([])
  const [isMASubmitting, setIsMASubmitting] = useState(false)
  const [modal_moveing_conform, setmodal_moveing_conform] = useState(false)
  const tog_moveing_popup = () => {
    setmodal_moveing_conform(!modal_moveing_conform)
    // removeBodyCss();
  }

  function onMoveingActivityPopup() {
    // setmoveingSessionKey(sessionKey)

    if (moveingSessionKey.length === 0) {
      toast.error("Please select at least one session!", {
        autoClose: 3000, // 3 seconds in this example
      })
    } else {
      tog_moveing_popup()
    }
  }
  const handleCheckboxChange = (key, isChecked) => {
    const updatedSessions = UpcomingSessionData.map(session => {
      if (session.pk_ses_key === key) {
        return { ...session, checked: isChecked }
      }
      return session
    })

    setUpcomingSessionData(updatedSessions)
    if (isChecked) {
      // Add key to the selectedKeys array if checked
      setmoveingSessionKey(prev => [...prev, key])
    } else {
      // Remove key from the selectedKeys array if unchecked
      setmoveingSessionKey(prev => prev.filter(k => k !== key))
    }
  }

  //Roll over to homework start
  const [modal_move_home_activity, setmodal_move_home_activity] =
    useState(false)

  const tog_move_home_activity = () => {
    setmodal_move_home_activity(!modal_move_home_activity)
    // removeBodyCss();
  }
  const [isMAHSubmitting, setIsMAHSubmitting] = useState(false)
  const [isMAHmSubmitting, setIsMAHmSubmitting] = useState(false)
  function onMoveHomeworkActivityPopup(getActivity) {
    setMoveActivity(getActivity)
    setmoveTosession(false)
    tog_move_home_activity()
  }
  // Roll over to homework end
  useEffect(() => {
    if (moveTosession && move_activity && move_activity != "") {
      fetchUpcommingSessionData()
    }
  }, [moveTosession, move_activity])

  //Move activity end

  // student and session array start
  const [upcommingSessionArray, setUpcommingSessionArray] = useState([])
  const [studentArray, setStudentArray] = useState([])

  const [upcommingSessionArrayOption, setUpcommingSessionArrayOption] =
    useState([])
  const [studentArrayOption, setStudentArrayOption] = useState([])

  // console.log(upcommingSessionArrayOption)
  // console.log(studentArrayOption)
  // student and session array start
  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  const session_key = localStorage.getItem("session_key") || null

  const student_key = localStorage.getItem("student_key") || null

  //const subject_ID = localStorage.getItem("subject_ID") || null
  const [subject_ID, setSubject_ID] = useState(
    localStorage.getItem("subject_ID") || null
  )
  const [SwitchFlexi, setSwitchFlexi] = useState(false)

  const navigate = useNavigate()

  const location = useLocation()
  useEffect(() => {
    fetchData()
    fetchLessonData()
    fetchHomeWorkData()

    fetchPreNextData()
    fetchSessionStudentArray()
  }, [location])

  const fetchData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        // setLoader(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/SessionStudentDetails", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
          })
          .then(responseSession => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            if (
              responseSession.data.session != null &&
              responseSession.data.session != ""
            ) {
              //setLoader(false);
              setSessionStudentData(responseSession.data)

              const enrolments =
                responseSession?.data?.session?.tbl_session_time
                  ?.tbl_student_enrolment

              if (enrolments?.length > 0) {
                const subjects = enrolments[0]?.tbl_enrolment_subject || []
                const formattedOptions = subjects.map(subject => ({
                  value: subject.subject_id,
                  label: subject.subject_name,
                }))

                setSessionStudentSubject(formattedOptions)
              } else {
                setSessionStudentSubject([])
              }

              //   // Get unique subject names from the provided JSON data
              //   //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
            } else {
              //console.error("Home card details not get from database.");
              setSessionStudentData([])
              // setLoader(false);
            }
          })
          .catch(error => {
            // setLoader(false);
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        // setLoader(false);
        navigate("/session")
      }
    } else {
      // setLoader(false);
      navigate("/login")
    }
  }

  const fetchLessonData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        setLoaderLesson(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/SessionLessonActivityDetails", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
          })
          .then(responseLessonSession => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            setLoader(false)
            if (
              responseLessonSession.data != null &&
              responseLessonSession.data != ""
            ) {
              setLoaderLesson(false)
              
            
              setSessionLessonData({Lesson : {...responseLessonSession.data}})

              if (
                responseLessonSession.data &&
                responseLessonSession.data.description
              ) {
                setLessonNote(
                  responseLessonSession.data.description
                )
              } else {
                setLessonNote("")
              }
              //   // Get unique subject names from the provided JSON data
              //   //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
            } else {
              //console.error("Home card details not get from database.");
              setSessionLessonData([])
              setLoaderLesson(false)
              setLoader(false)
            }
          })
          .catch(error => {
            setLoaderLesson(false)
            setLoader(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setLoaderLesson(false)
        setLoader(false)
        navigate("/session")
      }
    } else {
      setLoaderLesson(false)
      setLoader(false)
      navigate("/login")
    }
  }

  const fetchHomeWorkData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        setLoaderHomework(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/HomeSessionActivityDetails", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
          })
          .then(responseHomeSession => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            setLoader(false)
            if (
              responseHomeSession.data != null &&
              responseHomeSession.data != ""
            ) {
              setLoaderHomework(false)
              setSessionHomeWorkData({Lesson : {...responseHomeSession.data}})
              //setSessionHomeWorkData(responseHomeSession.data)

              if (
                responseHomeSession.data &&
                responseHomeSession.data.homework_description
              ) {
                setHomeWorkNote(
                    responseHomeSession.data.homework_description
                  )
                } else {
                  setHomeWorkNote("")
                }
            } else {
              //console.error("Home card details not get from database.");
              setSessionHomeWorkData([])
              setLoaderHomework(false)
              setLoader(false)
            }
          })
          .catch(error => {
            setLoaderHomework(false)
            setLoader(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setLoaderHomework(false)
        setLoader(false)
        navigate("/session")
      }
    } else {
      setLoaderHomework(false)
      setLoader(false)
      navigate("/login")
    }
  }

  const fetchPreNextData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        setLoader(true)

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/SessionPreNextActivityDetails", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
            PreNextType: PreNextType,
            PreNextLimit: CurrentPreviousSessioncount,
            fk_sub_id: subject_ID,
          })
          .then(responsepreSession => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            //setLoader(false);

            if (
              responsepreSession.data.session != null &&
              responsepreSession.data.session != ""
            ) {
              setLoader(false)
              setSessionPreNextData(responsepreSession.data)
              setSwitchFlexi(
                responsepreSession.data?.session?.ses_is_flexi == 1
              )
              if (
                PreNextType === 1 &&
                responsepreSession.data &&
                responsepreSession.data.session &&
                responsepreSession.data.session.session_count
              ) {
                setPreviousSessioncount(
                  parseInt(responsepreSession.data.session.session_count) - 1
                )
              } else {
                setPreviousSessioncount(0)
              }

              // if (
              //   responseHomeSession.data &&
              //   responseHomeSession.data.session &&
              //   responseHomeSession.data.session.tbl_session_time &&
              //   responseHomeSession.data.session.tbl_session_time.tbl_student_enrolment &&
              //   responseHomeSession.data.session.tbl_session_time.tbl_student_enrolment[0].tbl_student &&
              //   responseHomeSession.data.session.tbl_session_time.tbl_student_enrolment[0].tbl_student.Lesson &&
              //   responseHomeSession.data.session.tbl_session_time.tbl_student_enrolment[0].tbl_student.Lesson.homework_description
              // ) {

              //   setHomeWorkNote(responseHomeSession.data.session.tbl_session_time.tbl_student_enrolment[0].tbl_student.Lesson.homework_description)

              // } else {
              //   setHomeWorkNote('')
              // }
            } else {
              //console.error("Home card details not get from database.");
              //setSessionHomeWorkData([]);
              setSessionPreNextData([])
              setSwitchFlexi(false)
              setLoader(false)
            }
          })
          .catch(error => {
            setLoader(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setLoader(false)
        navigate("/session")
      }
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  const fetchSessionStudentArray = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        // setLoader(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/SessionStudentArray", {
            pk_ses_key: session_key,
            tutor_days: localStorage.getItem("flexiDays"),
            current_time: moment()
              .tz(localStorage.getItem("timezone") || "Europe/London")
              .format("YYYY-MM-DD"),
          })
          .then(responseArray => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            if (
              responseArray.data.sessionUpcommingArray &&
              responseArray.data.sessionUpcommingArray.length > 0
            ) {
              setUpcommingSessionArray(responseArray.data.sessionUpcommingArray)
              const sessionArray = responseArray?.data?.sessionUpcommingArray

              if (sessionArray?.length > 0) {
                // const subjects = enrolments[0]?.tbl_enrolment_subject || [];
                const formattedOptions = sessionArray.map(session => ({
                  value: session.pk_ses_key,
                  label:
                    moment(session.ses_date, moment.ISO_8601).format(
                      "DD MMM YYYY"
                    ) +
                    " " +
                    moment(session.ses_start_time, "HH:mm:ss").format(
                      "hh:mm A"
                    ) +
                    " - " +
                    moment(session.ses_end_time, "HH:mm:ss").format("hh:mm A"),
                }))

                setUpcommingSessionArrayOption(formattedOptions)
              } else {
                setUpcommingSessionArrayOption([])
              }

              //   // Get unique subject names from the provided JSON data
              //   //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
            } else {
              setUpcommingSessionArrayOption([])
              setUpcommingSessionArray([])
            }
            if (
              responseArray.data.studentArray &&
              responseArray.data.studentArray.length > 0
            ) {
              setStudentArray(responseArray.data.studentArray)
              const studentArray = responseArray?.data?.studentArray

              if (studentArray?.length > 0) {
                // const subjects = enrolments[0]?.tbl_enrolment_subject || [];
                const formattedOptions = studentArray.map(student => ({
                  value: student.pk_student_key,
                  label: student.st_first_name + " " + student.st_surname,
                  subject_id: student.subject_id,
                  level_name: student.level_name,
                }))

                setStudentArrayOption(formattedOptions)
              } else {
                setStudentArrayOption([])
              }

              //   // Get unique subject names from the provided JSON data
              //   //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
            } else {
              setStudentArrayOption([])
              setStudentArray([])
            }
          })
          .catch(error => {
            // setLoader(false);
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        // setLoader(false);
        navigate("/session")
      }
    } else {
      // setLoader(false);
      navigate("/login")
    }
  }

  const fetchUpcommingSessionData = async () => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      setmoveTosession(false)
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseUpcomingList = await axios.post(
          BASE_URL + "session/getAllUpcommingSessionList",
          {
            pk_student_key: move_activity.fk_student_key,
            activity_type: move_activity.activity_type,
            fk_activity_node_id: move_activity.fk_activity_node_id,
            topic_id:
              move_activity.LessonActivitiesQuestions.length > 0
                ? move_activity.LessonActivitiesQuestions[0].topic_id
                : null,
            fk_question_id:
              move_activity.LessonActivitiesQuestions.length > 0
                ? move_activity.LessonActivitiesQuestions[0].fk_question_id
                : null,
            fk_subject_id: move_activity.fk_sub_id,
          }
        ) //console.log(studentKey);
        //console.log(responseUpcomingList)
        if (responseUpcomingList.data.length > 0) {
          const updatedData = responseUpcomingList.data.map(item => ({
            ...item,
            checked: false, // Add the checked property to each item
          }))
          setUpcomingSessionData(updatedData)
          //setUpcomingSessionData(responseUpcomingList.data);
          setmodal_move_activity(true)
        } else {
          toast.error("Not found upcoming sessions!", {
            autoClose: 3000, // 3 seconds in this example
          })
          setUpcomingSessionData([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching upcoming session deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchLessonReportData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        // setLoader(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/SessionStudentMastryScoreDetails", {
            pk_ses_key: PreNextKey,
            pk_student_key: student_key,
          })
          .then(responseLessonReport => {
            // console.log(responseLessonReport.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            if (responseLessonReport.data.length > 0) {
              //setLoader(false);
              // console.log(responseLessonReport.data)
              setLessonReportData(responseLessonReport.data)
            } else {
              setLessonReportData([])
            }
          })
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }

  const fetchHomeworkReportData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        // setLoader(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(
            BASE_URL +
              "sessionActivity/SessionHomeworkStudentMastryScoreDetails",
            {
              pk_ses_key: PreNextKey,
              pk_student_key: student_key,
            }
          )
          .then(responseHomeworkReport => {
            // console.log(responseLessonReport.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            if (responseHomeworkReport.data.length > 0) {
              //setLoader(false);
              // console.log(responseHomeworkReport.data)
              setHomeworkReportData(responseHomeworkReport.data)
            } else {
              setHomeworkReportData([])
            }
          })
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }
  //kathir end

  //Kathir exter function

  const getUniqueSubjectNames = jsonData => {
    const uniqueSubjectNames = new Set()

    // Check if the necessary data is available
    if (
      jsonData &&
      jsonData.session &&
      jsonData.session.tbl_session_time &&
      jsonData.session.tbl_session_time.tbl_student_enrolment
    ) {
      // Iterate through student enrollments
      jsonData.session.tbl_session_time.tbl_student_enrolment.forEach(
        enrollment => {
          // Check if the necessary data is available and is an array
          if (
            enrollment &&
            enrollment.tbl_enrolment_subject &&
            Array.isArray(enrollment.tbl_enrolment_subject)
          ) {
            // Iterate through enrollment subjects
            enrollment.tbl_enrolment_subject.forEach(subject => {
              // Assuming subject_name is a property of the subject object and is not null/undefined
              if (subject && subject.subject_name != null) {
                uniqueSubjectNames.add(subject.subject_name)
              }
            })
          }
        }
      )
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectNames)
  }

  const getUniqueStudentSubjectNames = jsonData => {
    const uniqueSubjectNames = new Set()

    //console.log(jsonData);

    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          // console.log(subject);
          // Assuming subject_name is a property of the subject object and is not null/undefined
          if (subject && subject.subject_name != null) {
            uniqueSubjectNames.add(subject.subject_name)
          }
        })
      }
      //});
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectNames)
  }

  const getUniqueTopicsNames = jsonData => {
    const uniqueTopicsNames = new Set()

    //console.log(jsonData);

    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          subject.forEach(data => {
            if (data && data.topic_name != null) {
              uniqueTopicsNames.add(data.topic_name)
            }
          })
        })
      }
      //});
    }

    // Convert Set to array if needed
    return Array.from(uniqueTopicsNames)
  }

  const calculateSessionDuration = (sesStartTime, sesEndTime) => {
    if (sesStartTime && sesEndTime) {
      const currentDate = "2024-02-04"
      const startTime = moment(
        `${currentDate} ${sesStartTime}`,
        "YYYY-MM-DD HH:mm:ss"
      )
      const endTime = moment(
        `${currentDate} ${sesEndTime}`,
        "YYYY-MM-DD HH:mm:ss"
      )

      const duration = moment.duration(endTime.diff(startTime)).asMinutes()
      return `${duration} mins`
    } else {
      return "-"
    }
  }

  function onDelectActivityPopup(getActivityKey) {
    setActivityKey(getActivityKey)
    tog_delete_activity()
  }

  const onDelectActivity = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (session_key) {
        setIsDASubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/deleteActivity", {
            pk_ses_key: session_key,
            pk_lesson_activity_key: activity_key,
          })
          .then(responseActivityDelete => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityDelete.data) === 1) {
              toast.success("Activity deleted successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              fetchLessonData()
              fetchHomeWorkData()
              fetchPreNextData()
              tog_delete_activity()
              setIsDASubmitting(false)
            } else {
              setIsDASubmitting(false)
              toast.error(
                "Failure! Unable to delete activity. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
            }
          })
          .catch(error => {
            setIsDASubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsDASubmitting(false)
        navigate("/session")
      }
    } else {
      setIsDASubmitting(false)
      navigate("/login")
    }
  }

  const onMoveActivityUpcomingSession = () => {
    // //function onDelectActivity() {

    //console.log(move_activity)

    if (authUser && authUser != "" && authUser != null) {
      if (moveingSessionKey) {
        setIsMASubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/submitMoveActivityToSession", {
            pk_ses_key: moveingSessionKey,
            pk_lesson_activity_key: move_activity.pk_lesson_activity_key,
            pk_student_key: move_activity.fk_student_key,
            fk_lesson_key: move_activity.fk_lesson_key,
          })
          .then(responseActivityMove => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMove.data) === 1) {
              toast.success(
                "Activity rolled over to next session(s) successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
              tog_moveing_popup()
              tog_move_activity()
              setIsMASubmitting(false)
              setmoveingSessionKey([])
              fetchPreNextData()
              fetchLessonData()
              fetchHomeWorkData()
            } else {
              setIsMASubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMASubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMASubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMASubmitting(false)
      navigate("/login")
    }
  }

  const onMoveActivityHomework = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (move_activity) {
        setIsMAHSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/submitMoveActivityToHomework", {
            pk_ses_key: move_activity.fk_sest_key,
            pk_lesson_activity_key: move_activity.pk_lesson_activity_key,
            pk_student_key: move_activity.fk_student_key,
          })
          .then(responseActivityMoveHome => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMoveHome.data) === 1) {
              toast.success(
                "Activity Rolled over to same session homework successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
              tog_move_home_activity()
              setIsMAHSubmitting(false)
              fetchPreNextData()
              fetchLessonData()
              fetchHomeWorkData()
            } else {
              setIsMAHSubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMAHSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMAHSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMAHSubmitting(false)
      navigate("/login")
    }
  }

  const onMoveAllActivityHomework = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (selectedActivityKeys) {
        setIsMAHmSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

        axios
          .post(BASE_URL + "session/submitMovemultipleActivityToHomework", {
            selectedactivitykeys: selectedActivityKeys,
            pk_student_key: studentKey,
          })
          .then(responseActivityMoveHome => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMoveHome.data) === 1) {
              toast.success(
                "Activity Rolled over to same session homework successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              tog_move_home_all_activity()
              setIsMAHmSubmitting(false)
              tog_move_multiple_activity()
              setSelectedActivityKeys([])
              setselectedActivitySubject([])
              setSelectAllChecked(false)
              fetchPreNextData()
              fetchLessonData()
              fetchHomeWorkData()
            } else {
              setIsMAHmSubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMAHmSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMAHmSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMAHmSubmitting(false)
      navigate("/login")
    }
  }

  const columns_session = [
    {
      name: "Date",
      Header: "Date",
      accessor: row => (row.ses_date ? new Date(row.ses_date) : null), // Use raw date for sorting
      Cell: ({ value }) => {
        if (!value) return "-"

        const day = String(value.getDate()).padStart(2, "0")
        const month = String(value.getMonth() + 1).padStart(2, "0")
        const year = value.getFullYear()
        return `${day}-${month}-${year}`
      },
      // disableFilters: true,
      // disableSortBy: true,
    },
    {
      name: "Session Name",
      Header: "Session Name",
      // accessor: 'st_first_name',
      accessor: row =>
        row.sest_name && row.sest_name != "" ? row.sest_name : "-",
    },
    {
      name: "Subject",
      Header: "Subject",
      accessor: row =>
        row.sub_name != null && row.sub_name != "" ? row.sub_name : "-",
    },
    {
      name: "Action",
      Header: "Action",
      accessor: "pk_ses_key",
      Cell: ({ row }) => {
        const { pk_ses_key } = row.values

        return (
          <input
            type="checkbox"
            className="form-check-input"
            checked={row.original.checked}
            onChange={e => handleCheckboxChange(pk_ses_key, e.target.checked)}
          />
        )
      },
      //accessor: row => row.attendance_status != null && row.attendance_status != '' ? parseInt(row.attendance_status) === 0 ? 'Pending' : parseInt(row.attendance_status) === 1 ? 'Ongoing' : parseInt(row.attendance_status) === 2 ? 'Attended' : parseInt(row.attendance_status) === 6 ? 'Canceled' : 'Pending': '-',
    },

    //  {
    //       Header: "Status",
    //       disableSortBy: true,
    //       accessor: 'pk_student_key',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn status-btn'> ON GOING</button></>),

    // },
    // {
    //       Header: "#",
    //       disableSortBy: true,
    //       accessor: 'pk_student_keys',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn'><i class="mdi mdi-chevron-right-circle-outline" style={{ color: '#979797', fontSize: '20px'}}></i></button></>),

    // },
    // {
    //   accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

    //   selector: (row) => row.values.pk_student_key, show: false,
    // },
  ]
  const columns_multi_session = [
    {
      name: "Date",
      Header: "Date",
      accessor: row => (row.ses_date ? new Date(row.ses_date) : null), // Use raw date for sorting
      Cell: ({ value }) => {
        if (!value) return "-"

        const day = String(value.getDate()).padStart(2, "0")
        const month = String(value.getMonth() + 1).padStart(2, "0")
        const year = value.getFullYear()
        return `${day}-${month}-${year}`
      },
      // disableFilters: true,
      // disableSortBy: true,
    },
    {
      name: "Session Name",
      Header: "Session Name",
      // accessor: 'st_first_name',
      accessor: row =>
        row.sest_name && row.sest_name != "" ? row.sest_name : "-",
    },
    {
      name: "Subject",
      Header: "Subject",
      accessor: row =>
        row.sub_name != null && row.sub_name != "" ? row.sub_name : "-",
    },
    {
      name: "Action",
      Header: "Action",
      accessor: "pk_ses_key",
      Cell: ({ row }) => {
        const { pk_ses_key } = row.values

        return (
          <input
            type="checkbox"
            className="form-check-input"
            checked={row.original.checked}
            onChange={e =>
              handleCheckboxMulChange(pk_ses_key, e.target.checked)
            }
          />
        )
      },
      //accessor: row => row.attendance_status != null && row.attendance_status != '' ? parseInt(row.attendance_status) === 0 ? 'Pending' : parseInt(row.attendance_status) === 1 ? 'Ongoing' : parseInt(row.attendance_status) === 2 ? 'Attended' : parseInt(row.attendance_status) === 6 ? 'Canceled' : 'Pending': '-',
    },

    //  {
    //       Header: "Status",
    //       disableSortBy: true,
    //       accessor: 'pk_student_key',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn status-btn'> ON GOING</button></>),

    // },
    // {
    //       Header: "#",
    //       disableSortBy: true,
    //       accessor: 'pk_student_keys',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn'><i class="mdi mdi-chevron-right-circle-outline" style={{ color: '#979797', fontSize: '20px'}}></i></button></>),

    // },
    // {
    //   accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

    //   selector: (row) => row.values.pk_student_key, show: false,
    // },
  ]
  function onPreviewActivity(activityData) {
    //console.log(key)
    // console.log(activityData)
    if (
      activityData.pk_lesson_activity_key &&
      activityData.fk_sub_id &&
      activityData.fk_student_key
    ) {
      if (parseInt(activityData.activity_type) === 0) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-assessment-details/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 1) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-video/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 2) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-diagnostic-details/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 3) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-link/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 4) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-task/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 5) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-worksheet/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 6) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-video/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 7) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-worksheet/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
    // setDropdownOpen(prevState => ({
    //   ...prevState,
    //   [key]: !prevState[key] || false
    // }));
  }

  function onManualMarkingActivity(activityDetails) {
    if (activityDetails && parseInt(activityDetails.activity_type) === 0) {
      if (
        activityDetails.pk_lesson_activity_key &&
        activityDetails.fk_sub_id &&
        activityDetails.fk_student_key
      ) {
        navigate(
          `/manual-assessment/${activityDetails.pk_lesson_activity_key}`,
          {
            state: {
              type: `${activityDetails.fk_sub_id}`,
              st_key: `${activityDetails.fk_student_key}`,
            },
          }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else if (
      activityDetails &&
      parseInt(activityDetails.activity_type) === 5
    ) {
      if (
        activityDetails.pk_lesson_activity_key &&
        activityDetails.fk_sub_id &&
        activityDetails.fk_student_key
      ) {
        navigate(
          `/manual-worksheet/${activityDetails.pk_lesson_activity_key}`,
          {
            state: {
              type: `${activityDetails.fk_sub_id}`,
              st_key: `${activityDetails.fk_student_key}`,
            },
          }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else if (
      activityDetails &&
      parseInt(activityDetails.activity_type) === 7
    ) {
      if (
        activityDetails.pk_lesson_activity_key &&
        activityDetails.fk_sub_id &&
        activityDetails.fk_student_key
      ) {
        navigate(
          `/manual-lcms-worksheet/${activityDetails.pk_lesson_activity_key}`,
          {
            state: {
              type: `${activityDetails.fk_sub_id}`,
              st_key: `${activityDetails.fk_student_key}`,
            },
          }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }

  // const CountTotalCoins = enrolment => {
  //   let total_coins_stu = 0

  //   let Completed_total_stu = 0

  //   if (
  //     enrolment.tbl_student &&
  //     enrolment.tbl_student.Lesson &&
  //     enrolment.tbl_student.Lesson.LessonActivities.length > 0
  //   ) {
  //     enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
  //       Completed_total_stu +=
  //         activity.coins_earned != null && activity.coins_earned != ""
  //           ? parseInt(activity.coins_earned)
  //           : 0
  //       //console.log("tim"+activity.LessonActivitiesQuestions.length);
  //       total_coins_stu +=
  //         activity.LessonActivitiesQuestions.length > 0
  //           ? activity.LessonActivitiesQuestions.length * 2
  //           : 0
  //     })
  //   }

  //   return Completed_total_stu + "/" + total_coins_stu
  // }
 // lesson and homework count coin  and hours Old start
  const CountTotalCoins = enrolment => {
    let total_coins_stu = 0

    let Completed_total_stu = 0

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        Completed_total_stu +=
          activity.coins_earned != null && activity.coins_earned != ""
            ? parseInt(activity.coins_earned)
            : 0
        //console.log("tim"+activity.LessonActivitiesQuestions.length);

        if (
          activity.LessonActivitiesQuestions.length > 0 &&
          parseInt(activity.activity_type) === 0
        ) {
          activity.LessonActivitiesQuestions.forEach(lsquestion => {
            if (
              parseInt(lsquestion.fk_sub_id) === 2 &&
              lsquestion.question.length > 0
            ) {
              lsquestion.question.forEach(lsquestionParts => {
                total_coins_stu += lsquestionParts.question_parts.length * 2
              })
            } else if (
              parseInt(lsquestion.fk_sub_id) === 1 &&
              lsquestion.english_question.length > 0
            ) {
              lsquestion.english_question.forEach(lsquestionParts => {
                if (
                  parseInt(lsquestionParts.english_question_category_id) ===
                    10 ||
                  parseInt(lsquestionParts.english_question_category_id) === 13
                ) {
                  let total_part = lsquestionParts.english_question_parts
                  let array_part_data = total_part.filter(
                    part =>
                      part.english_question_answers.length > 0 &&
                      part.english_question_answers[0].english_answer_text &&
                      part.english_question_answers[0].english_answer_text != ""
                  )
                  total_coins_stu += array_part_data.length * 2 || 0
                } else {
                  total_coins_stu +=
                    lsquestionParts.english_question_parts.length * 2
                }
                // total_coins_stu += lsquestionParts.english_question_parts.length * 2;
              })
            }
          })
        } else if (
          activity.LessonActivitiesQuestions.length > 0 &&
          parseInt(activity.activity_type) === 2
        ) {
          activity.LessonActivitiesQuestions.forEach(lsquestion => {
            if (
              parseInt(lsquestion.fk_sub_id) === 2 &&
              lsquestion.question.length > 0
            ) {
              //lsquestion.question.forEach(lsquestionParts => {
              total_coins_stu += lsquestion.question.length * 2
              //});
            } else if (
              parseInt(lsquestion.fk_sub_id) === 1 &&
              lsquestion.english_question.length > 0
            ) {
              // lsquestion.english_question.forEach(lsquestionParts => {
              total_coins_stu += lsquestion.english_question.length * 2
              //});
            }
          })
        } else {
          activity.LessonActivitiesQuestions.forEach(lsquestion => {
            if (lsquestion.question.length > 0) {
              //lsquestion.question.forEach(lsquestionParts => {
              total_coins_stu += lsquestion.question.length * 2
              //});
            }
          })
        }
      })
    }

    return Completed_total_stu + "/" + total_coins_stu
  }

  const CountHours = enrolment => {
    let total_time = 0

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      //total_time = enrolment.tbl_student.Lesson.LessonActivities.length > 0 ? enrolment.tbl_student.Lesson.LessonActivities.length * 5 : 0;
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        //console.log("tim"+activity.LessonActivitiesQuestions.length);
        total_time += parseInt(activity.total_activity_time) || 0
      })
      let hours = Math.floor(total_time / 60)

      // Calculate remaining minutes
      let minutes = total_time % 60

      // console.log(hours + " hours and " + minutes + " minutes");
      return hours + " h " + minutes + " m"
    } else {
      return null
    }
  }

   // lesson and homework count coin  and hours Old end
 // lesson and homework count coin  and hours New start
 const CountTotalCoinsForActivity = activityData => {
  let total_coins_stu = 0

  let Completed_total_stu = 0

  if (
    activityData &&  activityData.length > 0
  ) {
    activityData.forEach(activity => {
      Completed_total_stu +=
        activity.coins_earned != null && activity.coins_earned != ""
          ? parseInt(activity.coins_earned)
          : 0
      //console.log("tim"+activity.LessonActivitiesQuestions.length);

      if (
        activity.LessonActivitiesQuestions.length > 0 &&
        parseInt(activity.activity_type) === 0
      ) {
        activity.LessonActivitiesQuestions.forEach(lsquestion => {
          if (
            parseInt(lsquestion.fk_sub_id) === 2 &&
            lsquestion.question.length > 0
          ) {
            lsquestion.question.forEach(lsquestionParts => {
              total_coins_stu += lsquestionParts.question_parts.length * 2
            })
          } else if (
            parseInt(lsquestion.fk_sub_id) === 1 &&
            lsquestion.english_question.length > 0
          ) {
            lsquestion.english_question.forEach(lsquestionParts => {
              if (
                parseInt(lsquestionParts.english_question_category_id) ===
                  10 ||
                parseInt(lsquestionParts.english_question_category_id) === 13
              ) {
                let total_part = lsquestionParts.english_question_parts
                let array_part_data = total_part.filter(
                  part =>
                    part.english_question_answers.length > 0 &&
                    part.english_question_answers[0].english_answer_text &&
                    part.english_question_answers[0].english_answer_text != ""
                )
                total_coins_stu += array_part_data.length * 2 || 0
              } else {
                total_coins_stu +=
                  lsquestionParts.english_question_parts.length * 2
              }
              // total_coins_stu += lsquestionParts.english_question_parts.length * 2;
            })
          }
        })
      } else if (
        activity.LessonActivitiesQuestions.length > 0 &&
        parseInt(activity.activity_type) === 2
      ) {
        activity.LessonActivitiesQuestions.forEach(lsquestion => {
          if (
            parseInt(lsquestion.fk_sub_id) === 2 &&
            lsquestion.question.length > 0
          ) {
            //lsquestion.question.forEach(lsquestionParts => {
            total_coins_stu += lsquestion.question.length * 2
            //});
          } else if (
            parseInt(lsquestion.fk_sub_id) === 1 &&
            lsquestion.english_question.length > 0
          ) {
            // lsquestion.english_question.forEach(lsquestionParts => {
            total_coins_stu += lsquestion.english_question.length * 2
            //});
          }
        })
      } else {
        activity.LessonActivitiesQuestions.forEach(lsquestion => {
          if (lsquestion.question.length > 0) {
            //lsquestion.question.forEach(lsquestionParts => {
            total_coins_stu += lsquestion.question.length * 2
            //});
          }
        })
      }
    })
  }

  return Completed_total_stu + "/" + total_coins_stu
}

const CountHoursForActivity = activityData => {
  let total_time = 0

  if (
    activityData && activityData.length > 0
  ) {
    //total_time = enrolment.tbl_student.Lesson.LessonActivities.length > 0 ? enrolment.tbl_student.Lesson.LessonActivities.length * 5 : 0;
    activityData.forEach(activity => {
      //console.log("tim"+activity.LessonActivitiesQuestions.length);
      total_time += parseInt(activity.total_activity_time) || 0
    })
    let hours = Math.floor(total_time / 60)

    // Calculate remaining minutes
    let minutes = total_time % 60

    // console.log(hours + " hours and " + minutes + " minutes");
    return hours + " h " + minutes + " m"
  } else {
    return null
  }
}
// lesson and homework count coin  and hours New end

  const CountLessonTotalCoins = enrolment => {
    let total_coins_stu = 0

    let Completed_total_stu = 0
    //console.log(enrolment);

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        //console.log(activity);

        if (parseInt(activity.marked_for_homework) === 0) {
          Completed_total_stu +=
            activity.coins_earned != null && activity.coins_earned != ""
              ? parseInt(activity.coins_earned)
              : 0
          //console.log("tim"+activity.LessonActivitiesQuestions.length);
          // total_coins_stu +=
          //   activity.LessonActivitiesQuestions.length > 0
          //     ? activity.LessonActivitiesQuestions.length * 2
          //     : 0
          if (
            activity.LessonActivitiesQuestions.length > 0 &&
            parseInt(activity.activity_type) === 0
          ) {
            activity.LessonActivitiesQuestions.forEach(lsquestion => {
              if (
                parseInt(lsquestion.fk_sub_id) === 2 &&
                lsquestion.question.length > 0
              ) {
                lsquestion.question.forEach(lsquestionParts => {
                  total_coins_stu += lsquestionParts.question_parts.length * 2
                })
              } else if (
                parseInt(lsquestion.fk_sub_id) === 1 &&
                lsquestion.english_question.length > 0
              ) {
                lsquestion.english_question.forEach(lsquestionParts => {
                  if (
                    parseInt(lsquestionParts.english_question_category_id) ===
                      10 ||
                    parseInt(lsquestionParts.english_question_category_id) ===
                      13
                  ) {
                    let total_part = lsquestionParts.english_question_parts
                    let array_part_data = total_part.filter(
                      part =>
                        part.english_question_answers.length > 0 &&
                        part.english_question_answers[0].english_answer_text &&
                        part.english_question_answers[0].english_answer_text !=
                          ""
                    )
                    total_coins_stu += array_part_data.length * 2 || 0
                  } else {
                    total_coins_stu +=
                      lsquestionParts.english_question_parts.length * 2
                  }
                  // total_coins_stu += lsquestionParts.english_question_parts.length * 2;
                })
              }
            })
          } else if (
            activity.LessonActivitiesQuestions.length > 0 &&
            parseInt(activity.activity_type) === 2
          ) {
            activity.LessonActivitiesQuestions.forEach(lsquestion => {
              if (
                parseInt(lsquestion.fk_sub_id) === 2 &&
                lsquestion.question.length > 0
              ) {
                //lsquestion.question.forEach(lsquestionParts => {
                total_coins_stu += lsquestion.question.length * 2
                //});
              } else if (
                parseInt(lsquestion.fk_sub_id) === 1 &&
                lsquestion.english_question.length > 0
              ) {
                // lsquestion.english_question.forEach(lsquestionParts => {
                total_coins_stu += lsquestion.english_question.length * 2
                //});
              }
            })
          } else {
            activity.LessonActivitiesQuestions.forEach(lsquestion => {
              if (lsquestion.question.length > 0) {
                //lsquestion.question.forEach(lsquestionParts => {
                total_coins_stu += lsquestion.question.length * 2
                //});
              }
            })
          }
        }
      })
    }

    return Completed_total_stu + "/" + total_coins_stu
  }

  const CountLessonHours = enrolment => {
    let total_time = 0

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        if (parseInt(activity.marked_for_homework) === 0) {
          total_time += parseInt(activity.total_activity_time) || 0
        }
      })
      // enrolment.tbl_student.Lesson.LessonActivities.forEach((activity) => {

      //     //console.log("tim"+activity.LessonActivitiesQuestions.length);
      //     total_coins_stu += activity.LessonActivitiesQuestions.length > 0 ? activity.LessonActivitiesQuestions.length * 2 : 0;

      // });
      let hours = Math.floor(total_time / 60)

      // Calculate remaining minutes
      let minutes = total_time % 60

      // console.log(hours + " hours and " + minutes + " minutes");
      return hours + " h " + minutes + " m"
    } else {
      return null
    }
  }

  const CountLessonTotalActivity = enrolment => {
    let total_activity_stu = 0
    let completed_activity_stu = 0

    var icon = <i className="mdi mdi-shuffle-variant"></i>

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      if (parseInt(enrolment.tbl_student.Lesson.is_lesson_sequential) === 1) {
        icon = <i className=" mdi mdi-sort-ascending"></i>
      } else {
        icon = <i className="mdi mdi-shuffle-variant"></i>
      }

      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        if (parseInt(activity.marked_for_homework) === 0) {
          completed_activity_stu +=
            activity.status != null && activity.status == "1" ? 1 : 0
          total_activity_stu += 1
        }
      })
    }

    if (total_activity_stu === 0) {
      return (
        <p className="mt-1 mb-1 cs-txt-danger font-size-10">
          No activities assigned
        </p>
      )
    }

    let percentage = (completed_activity_stu / total_activity_stu) * 100

    var view_report = ""
    if (total_activity_stu === completed_activity_stu) {
      view_report = (
        <Button
          className="btn btn-secondary w-100"
          color=""
          onClick={() => {
            tog_lesson_report(sessionPreNextData.session.pk_ses_key)
          }}
        >
          {" "}
          View Lesson Report{" "}
        </Button>
      )
    }

    return (
      <>
        <p className="font-size-10 fw-400 mt-2">
          {completed_activity_stu}/{total_activity_stu} activities completed (
          {percentage.toFixed(2)}%)<span className="float-end "></span>
        </p>
        {view_report}
      </>
    )
  }

  const CountHomeWorkTotalCoins = enrolment => {
    let total_coins_stu = 0

    let Completed_total_stu = 0
    //console.log(enrolment);

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        //console.log(activity);

        if (parseInt(activity.marked_for_homework) === 1) {
          Completed_total_stu +=
            activity.coins_earned != null && activity.coins_earned != ""
              ? parseInt(activity.coins_earned)
              : 0
          //console.log("tim"+activity.LessonActivitiesQuestions.length);
          // total_coins_stu +=
          //   activity.LessonActivitiesQuestions.length > 0
          //     ? activity.LessonActivitiesQuestions.length * 2
          //     : 0

          if (
            activity.LessonActivitiesQuestions.length > 0 &&
            parseInt(activity.activity_type) === 0
          ) {
            activity.LessonActivitiesQuestions.forEach(lsquestion => {
              if (
                parseInt(lsquestion.fk_sub_id) === 2 &&
                lsquestion.question.length > 0
              ) {
                lsquestion.question.forEach(lsquestionParts => {
                  total_coins_stu += lsquestionParts.question_parts.length * 2
                })
              } else if (
                parseInt(lsquestion.fk_sub_id) === 1 &&
                lsquestion.english_question.length > 0
              ) {
                lsquestion.english_question.forEach(lsquestionParts => {
                  if (
                    parseInt(lsquestionParts.english_question_category_id) ===
                      10 ||
                    parseInt(lsquestionParts.english_question_category_id) ===
                      13
                  ) {
                    let total_part = lsquestionParts.english_question_parts
                    let array_part_data = total_part.filter(
                      part =>
                        part.english_question_answers.length > 0 &&
                        part.english_question_answers[0].english_answer_text &&
                        part.english_question_answers[0].english_answer_text !=
                          ""
                    )
                    total_coins_stu += array_part_data.length * 2 || 0
                  } else {
                    total_coins_stu +=
                      lsquestionParts.english_question_parts.length * 2
                  }
                  // total_coins_stu += lsquestionParts.english_question_parts.length * 2;
                })
              }
            })
          } else if (
            activity.LessonActivitiesQuestions.length > 0 &&
            parseInt(activity.activity_type) === 2
          ) {
            activity.LessonActivitiesQuestions.forEach(lsquestion => {
              if (
                parseInt(lsquestion.fk_sub_id) === 2 &&
                lsquestion.question.length > 0
              ) {
                //lsquestion.question.forEach(lsquestionParts => {
                total_coins_stu += lsquestion.question.length * 2
                //});
              } else if (
                parseInt(lsquestion.fk_sub_id) === 1 &&
                lsquestion.english_question.length > 0
              ) {
                // lsquestion.english_question.forEach(lsquestionParts => {
                total_coins_stu += lsquestion.english_question.length * 2
                //});
              }
            })
          } else {
            activity.LessonActivitiesQuestions.forEach(lsquestion => {
              if (lsquestion.question.length > 0) {
                //lsquestion.question.forEach(lsquestionParts => {
                total_coins_stu += lsquestion.question.length * 2
                //});
              }
            })
          }
        }
      })
    }

    return Completed_total_stu + "/" + total_coins_stu
  }

  const CountHomeWorkHours = enrolment => {
    let total_time = 0

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        if (parseInt(activity.marked_for_homework) === 1) {
          total_time += parseInt(activity.total_activity_time) || 0
        }
      })
      //   enrolment.tbl_student.Lesson.LessonActivities.forEach((activity) => {

      //     //console.log("tim"+activity.LessonActivitiesQuestions.length);
      //     total_time += parseInt(activity.total_activity_time) || 0;

      // });
      let hours = Math.floor(total_time / 60)

      // Calculate remaining minutes
      let minutes = total_time % 60

      // console.log(hours + " hours and " + minutes + " minutes");
      return hours + " h " + minutes + " m"
    } else {
      return null
    }
  }

  const CountHomeWorkTotalActivity = enrolment => {
    let total_activity_stu = 0
    let completed_activity_stu = 0

    var icon = <i className="mdi mdi-shuffle-variant"></i>

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      if (parseInt(enrolment.tbl_student.Lesson.is_lesson_sequential) === 1) {
        icon = <i className=" mdi mdi-sort-ascending"></i>
      } else {
        icon = <i className="mdi mdi-shuffle-variant"></i>
      }

      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        if (parseInt(activity.marked_for_homework) === 1) {
          completed_activity_stu +=
            activity.status != null && activity.status == "1" ? 1 : 0
          total_activity_stu += 1
        }
      })
    }

    if (total_activity_stu === 0) {
      return (
        <p className="mt-1 mb-1 cs-txt-danger font-size-10 ">
          No activities assigned
        </p>
      )
    }

    let percentage = (completed_activity_stu / total_activity_stu) * 100

    var view_report = ""
    if (total_activity_stu === completed_activity_stu) {
      view_report = (
        <Button
          className="btn btn-secondary w-100"
          color=""
          onClick={() => {
            tog_home_work_report(sessionPreNextData.session.pk_ses_key)
          }}
        >
          {" "}
          View Home Work Report{" "}
        </Button>
      )
    }

    return (
      <>
        <p className="font-size-10 fw-400 mt-2">
          {completed_activity_stu}/{total_activity_stu} activities completed (
          {percentage.toFixed(2)}%)<span className="float-end "></span>
        </p>
        {view_report}
      </>
    )
  }

  const CountTotalActivity = enrolment => {
    let total_activity_stu = 0
    let completed_activity_stu = 0

    var icon = <i className="mdi mdi-shuffle-variant"></i>

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      if (parseInt(enrolment.tbl_student.Lesson.is_lesson_sequential) === 1) {
        icon = <i className=" mdi mdi-sort-ascending"></i>
      } else {
        icon = <i className="mdi mdi-shuffle-variant"></i>
      }

      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        completed_activity_stu +=
          activity.status != null && activity.status == "1" ? 1 : 0
        total_activity_stu += 1
      })
    }

    if (total_activity_stu === 0) {
      return (
        <p className="mt-1 mb-1 cs-txt-danger font-size-10">
          No activities assigned
        </p>
      )
    }

    let percentage = (completed_activity_stu / total_activity_stu) * 100

    return (
      <p className="font-size-10 fw-400 mt-2">
        {completed_activity_stu}/{total_activity_stu} activities completed (
        {percentage.toFixed(2)}%)<span className="float-end ">{icon}</span>
      </p>
    )
  }

  const [dropdownOpen, setDropdownOpen] = useState({})

  const toggleDropdown = key => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key] || false,
    }))
  }

  const onUpdateNotes = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        setIsNotesSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/lessonNotes", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
            lessonNote: lessonNote,
            homeworkNote: HomeWorkNote,
          })
          .then(responseNotes => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseNotes.data) === 1) {
              toast.success("Updated successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              setIsNotesSubmitting(false)
              fetchLessonData()
              fetchHomeWorkData()
            } else {
              setIsNotesSubmitting(false)
              toast.error("Failure! Unable to Notes updated. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            // setIsDASubmitting(false);
            //console.log(error)
            setIsNotesSubmitting(false)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsNotesSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsNotesSubmitting(false)
      navigate("/login")
    }
  }

  //KATHIR EXTRA FUNCTION

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
      </div>
    )
  }

  const tog_lesson_report = key => {
    setmodal_lesson_report(!modal_lesson_report)

    setpreNextKey(key)
    //removeBodyCss();
  }
  useEffect(() => {
    if (modal_lesson_report && PreNextKey != null && PreNextKey != "") {
      fetchLessonReportData()
    }
  }, [modal_lesson_report, PreNextKey])

  const tog_home_work_report = key => {
    setmodal_home_work_report(!modal_home_work_report)

    setpreNextKey(key)
    //removeBodyCss();
  }

  useEffect(() => {
    if (modal_home_work_report && PreNextKey != null && PreNextKey != "") {
      fetchHomeworkReportData()
    }
  }, [modal_home_work_report, PreNextKey])

  useEffect(() => {
    fetchPreNextData()
  }, [CurrentPreviousSessioncount])

  const trigger_next = () => {
    setpreNextType(2)
    setCurrectPreviousSessioncount(CurrentPreviousSessioncount - 1)
    setSelectedActivityKeys([])
    setselectedActivitySubject([])
    setSelectAllChecked(false)
  }

  const trigger_next_previous = () => {
    setpreNextType(1)
    setCurrectPreviousSessioncount(CurrentPreviousSessioncount - 1)
    setSelectedActivityKeys([])
    setselectedActivitySubject([])
    setSelectAllChecked(false)
  }
  const trigger_previous = () => {
    setpreNextType(1)
    setCurrectPreviousSessioncount(CurrentPreviousSessioncount + 1)
    setSelectedActivityKeys([])
    setselectedActivitySubject([])
    setSelectAllChecked(false)
  }
  // console.log(PreviousSessioncount);
  // console.log(CurrentPreviousSessioncount);
  //lesson drag and drop start

  const [UpdatedlessonActivityArray, setUpdatedLessonActivityArray] = useState(
    []
  )

  useEffect(() => {
    if (
      sessionLessonData && sessionLessonData.Lesson && sessionLessonData.Lesson.LessonActivities &&
      sessionLessonData.Lesson.LessonActivities.length > 0
    ) {
      if (
        parseInt(sessionLessonData.Lesson.is_lesson_sequential) === 1
      ) {
        setswitch2(true)
      }
      setLessonActivityArray(
        sessionLessonData.Lesson.LessonActivities
      )
    } else {
      setLessonActivityArray([])
    }
    // sessionLessonData &&
    //   sessionLessonData.session &&
    //   sessionLessonData.session.tbl_session_time &&
    //   sessionLessonData.session.tbl_session_time.tbl_student_enrolment.forEach(
    //     (enrolment, index) => {
    //       if (
    //         enrolment.tbl_student &&
    //         enrolment.tbl_student.Lesson &&
    //         enrolment.tbl_student.Lesson.LessonActivities.length > 0
    //       ) {
    //         if (
    //           parseInt(enrolment.tbl_student.Lesson.is_lesson_sequential) === 1
    //         ) {
    //           setswitch2(true)
    //         }
    //         setLessonActivityArray(
    //           enrolment.tbl_student.Lesson.LessonActivities
    //         )
    //       } else {
    //         setLessonActivityArray([])
    //       }
    //     }
    //   )
  }, [sessionLessonData])

  const handleDragEnd = async result => {
    if (!result.destination) return

    const updatedActivities = Array.from(lessonActivityArray)
    const [reorderedItem] = updatedActivities.splice(result.source.index, 1)
    updatedActivities.splice(result.destination.index, 0, reorderedItem)

    setLessonActivityArray(updatedActivities)
    setUpdatedLessonActivityArray(updatedActivities)
  }

  const fetchLessonOrderData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        //console.log(lessonActivityArray);
        const filteredSessionsActivity = lessonActivityArray.map(
          lesActivity => ({
            fk_lesson_key: lesActivity.fk_lesson_key,
            pk_lesson_activity_key: lesActivity.pk_lesson_activity_key,
          })
        )
        //console.log(filteredSessionsActivity);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/lessonOrder", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
            LessonOrder: filteredSessionsActivity,
          })
          .then(responseLessonOrder => {
            //console.log(responseLessonOrder)
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            setUpdatedLessonActivityArray([])

            // if (responseLessonReport.data.length > 0) {
            //   //setLoader(false);
            //   console.log(responseLessonReport.data);
            //   setLessonReportData(responseLessonReport.data);

            // } else {
            //   setLessonReportData([]);

            // }
          })
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }

  useEffect(() => {
    if (UpdatedlessonActivityArray && UpdatedlessonActivityArray.length > 0) {
      fetchLessonOrderData()
      //setswitch2(true)
    }
  }, [UpdatedlessonActivityArray])

  //kathir
  useEffect(() => {
    if (UpdatedlessonActivityArray && UpdatedlessonActivityArray.length > 0) {
    } else {
      if (lessonActivityArray && lessonActivityArray.length > 0) {
        fetchLessonOrderDisableData()
      } else {
        setswitch2(false)
      }
    }
  }, [switch2])

  const fetchLessonOrderDisableData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        const filteredSessionsActivity = lessonActivityArray.map(
          lesActivity => ({
            fk_lesson_key: lesActivity.fk_lesson_key,
            pk_lesson_activity_key: lesActivity.pk_lesson_activity_key,
          })
        )
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/lessonSwichOrder", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
            LessonOrder: filteredSessionsActivity,
            order: switch2,
          })
          .then(responseLessonOrder => {
            // console.log(responseLessonOrder)
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            // if (responseLessonReport.data.length > 0) {
            //   //setLoader(false);
            //   console.log(responseLessonReport.data);
            //   setLessonReportData(responseLessonReport.data);
            // } else {
            //   setLessonReportData([]);
            // }
          })
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }

  //lesson drag and drop end

  //homework drag and drop start

  const [UpdatedhomeworkActivityArray, setUpdatedhomeworkActivityArray] =
    useState([])
  const [switchhome, setswitchhome] = useState(false)

  useEffect(() => {
    if (
      sessionHomeWorkData && sessionHomeWorkData.Lesson && sessionHomeWorkData.Lesson.LessonActivities &&
      sessionHomeWorkData.Lesson.LessonActivities.length > 0
    ) {
      if (
        parseInt(sessionHomeWorkData.Lesson.is_homework_sequential) ===
        1
      ) {
        setswitchhome(true)
      }
      sethomeworkActivityArray(
        sessionHomeWorkData.Lesson.LessonActivities
      )
    } else {
      sethomeworkActivityArray([])
    }
    // sessionHomeWorkData &&
    //   sessionHomeWorkData.session &&
    //   sessionHomeWorkData.session.tbl_session_time &&
    //   sessionHomeWorkData.session.tbl_session_time.tbl_student_enrolment.map(
    //     (enrolment, index) => {
    //       if (
    //         enrolment.tbl_student &&
    //         enrolment.tbl_student.Lesson &&
    //         enrolment.tbl_student.Lesson.LessonActivities.length > 0
    //       ) {
    //         if (
    //           parseInt(enrolment.tbl_student.Lesson.is_homework_sequential) ===
    //           1
    //         ) {
    //           setswitchhome(true)
    //         }
    //         sethomeworkActivityArray(
    //           enrolment.tbl_student.Lesson.LessonActivities
    //         )
    //       } else {
    //         sethomeworkActivityArray([])
    //       }
    //     }
    //   )
  }, [sessionHomeWorkData])

  const handleHomeDragEnd = async result => {
    if (!result.destination) return

    const updatedActivities = Array.from(homeworkActivityArray)
    const [reorderedItem] = updatedActivities.splice(result.source.index, 1)
    updatedActivities.splice(result.destination.index, 0, reorderedItem)

    sethomeworkActivityArray(updatedActivities)
    setUpdatedhomeworkActivityArray(updatedActivities)
  }

  const fetchHomeOrderData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        const filteredSessionsActivity = homeworkActivityArray.map(
          lesActivity => ({
            fk_lesson_key: lesActivity.fk_lesson_key,
            pk_lesson_activity_key: lesActivity.pk_lesson_activity_key,
          })
        )
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/homeworkOrder", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
            HomeOrder: filteredSessionsActivity,
          })
          .then(responseHomeOrder => {
            // console.log(responseHomeOrder)
            setUpdatedhomeworkActivityArray([])
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            // if (responseLessonReport.data.length > 0) {
            //   //setLoader(false);
            //   console.log(responseLessonReport.data);
            //   setLessonReportData(responseLessonReport.data);

            // } else {
            //   setLessonReportData([]);

            // }
          })
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }

  useEffect(() => {
    if (
      UpdatedhomeworkActivityArray &&
      UpdatedhomeworkActivityArray.length > 0
    ) {
      fetchHomeOrderData()
      //setswitchhome(true)
    }
  }, [UpdatedhomeworkActivityArray])

  //kathir
  useEffect(() => {
    if (
      UpdatedhomeworkActivityArray &&
      UpdatedhomeworkActivityArray.length > 0
    ) {
    } else {
      if (homeworkActivityArray && homeworkActivityArray.length > 0) {
        fetchHomeOrderDisableData()
      } else {
        setswitchhome(false)
      }
    }
  }, [switchhome])

  const fetchHomeOrderDisableData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        const filteredSessionsActivity = homeworkActivityArray.map(
          lesActivity => ({
            fk_lesson_key: lesActivity.fk_lesson_key,
            pk_lesson_activity_key: lesActivity.pk_lesson_activity_key,
          })
        )
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/homeworkSwichOrder", {
            pk_ses_key: session_key,
            pk_student_key: student_key,
            HomeOrder: filteredSessionsActivity,
            order: switchhome,
          })
          .then(responseHomeOrder => {
            // console.log(responseHomeOrder)
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            // if (responseLessonReport.data.length > 0) {
            //   //setLoader(false);
            //   console.log(responseLessonReport.data);
            //   setLessonReportData(responseLessonReport.data);
            // } else {
            //   setLessonReportData([]);
            // }
          })
          .catch(error => {
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }

  const handleSetTypeClick = type => {
    // console.log(type);
    localStorage.removeItem("typeAct")
    localStorage.setItem("typeAct", type)
  }

  //lesson drag and drop end

  //lesson next and preview activity
  const [ActivityNavSeleceted, setActivityNavSeleceted] = useState(0)
  const handleNext = () => {
    if (ActivityNavSeleceted < lessonActivityArray.length - 1) {
      setActivityNavSeleceted(prev => prev + 1)
    }
  }

  const handlePrevious = () => {
    if (ActivityNavSeleceted > 0) {
      setActivityNavSeleceted(prev => prev - 1)
    }
  }

  // Scroll to the active card when it changes
  useEffect(() => {
    const activeCard =
      document.getElementsByClassName("card-activity")[ActivityNavSeleceted]
    if (activeCard) {
      activeCard.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [ActivityNavSeleceted])

  //Home Work next previous
  const [ActivityNavHomeSeleceted, setActivityNavHomeSeleceted] = useState(0)
  const handleHomeNext = () => {
    if (ActivityNavHomeSeleceted < homeworkActivityArray.length - 1) {
      setActivityNavHomeSeleceted(prev => prev + 1)
    }
  }

  const handleHomePrevious = () => {
    if (ActivityNavHomeSeleceted > 0) {
      setActivityNavHomeSeleceted(prev => prev - 1)
    }
  }

  // Scroll to the active card when it changes
  useEffect(() => {
    const activeCard =
      document.getElementsByClassName("card-home-activity")[
        ActivityNavHomeSeleceted
      ]
    if (activeCard) {
      activeCard.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [ActivityNavHomeSeleceted])

  //Rollover multiple moving option start

  // State to store selected activity keys
  const [selectedActivityKeys, setSelectedActivityKeys] = useState([])
  const [selectedActivitySubject, setselectedActivitySubject] = useState([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  // console.log(selectedActivityKeys)
  // console.log(selectedActivitySubject)
  // console.log(selectAllChecked)
  const handleSelectAllChange = LessonActivities => {
    const newSelectAllState = !selectAllChecked
    setSelectAllChecked(newSelectAllState)

    // Get all activity keys and student keys
    const allActivityKeys = LessonActivities.map(
      activity => activity.pk_lesson_activity_key
    )
    const allActivitySubjects = LessonActivities.map(activity => ({
      pk_lesson_activity_key: activity.pk_lesson_activity_key,
      fk_sub_id: activity.fk_sub_id,
    }))

    // Set selected activities and subjects based on the select-all state
    setSelectedActivityKeys(newSelectAllState ? allActivityKeys : [])
    setselectedActivitySubject(newSelectAllState ? allActivitySubjects : [])

    // // Set the student key only once if selecting all, otherwise clear it
    // if (newSelectAllState && LessonActivities.length > 0) {
    //   setActivityStudentKey(LessonActivities[0].fk_student_key);
    // } else {
    //   setActivityStudentKey('');
    // }
  }
  // Function to handle checkbox change
  const handleCheckboxActivityChange = (
    activityKey,
    activity,
    totalActivities
  ) => {
    // setSelectedActivityKeys((prevSelectedKeys) => {
    //   if (prevSelectedKeys.includes(activityKey)) {
    //     // If activity key is already selected, remove it from the array
    //     return prevSelectedKeys.filter((key) => key !== activityKey);
    //   } else {
    //     // If not selected, add it to the array
    //     return [...prevSelectedKeys, activityKey];
    //   }
    // });
    setSelectedActivityKeys(prevSelectedKeys => {
      const updatedKeys = prevSelectedKeys.includes(activityKey)
        ? prevSelectedKeys.filter(key => key !== activityKey)
        : [...prevSelectedKeys, activityKey]

      // Update selectAllChecked based on the length of selected keys
      setSelectAllChecked(updatedKeys.length === totalActivities)

      return updatedKeys
    })
    setselectedActivitySubject(prevSelectedActivities => {
      const activityObject = {
        pk_lesson_activity_key: activityKey,
        fk_sub_id: activity.fk_sub_id,
      }

      if (
        prevSelectedActivities.some(
          activity => activity.pk_lesson_activity_key === activityKey
        )
      ) {
        // If the activity is already selected, remove it
        const updatedActivities = prevSelectedActivities.filter(
          activity => activity.pk_lesson_activity_key !== activityKey
        )
        // console.log(`Removed activity:`, activityObject);
        return updatedActivities
      } else {
        // If not selected, add the activity object
        // console.log(`Added activity:`, activityObject);
        return [...prevSelectedActivities, activityObject]
      }
    })
  }

  //console.log(selectedActivitySubject);

  const [studentKey, setStudentKey] = useState("")

  const [MultipleUpcomingSessionData, setMultipleUpcomingSessionData] =
    useState([])

  const [show_modal_move_multiple_activity, setmodal_move_multiple_activity] =
    useState(false)

  const tog_move_multiple_activity = () => {
    setmodal_move_multiple_activity(!show_modal_move_multiple_activity)
    // removeBodyCss();
  }
  const [modal_move_home_all_activity, setmodal_move_home_all_activity] =
    useState(false)

  const tog_move_home_all_activity = () => {
    setmodal_move_home_all_activity(!modal_move_home_all_activity)
    // removeBodyCss();
  }

  const [moveingMultipleSessionKey, setmoveingMultipleSessionKey] = useState([])
  const [isMulMoningSubmitting, setIsMulMoningSubmitting] = useState(false)
  const [modal_multiple_moveing_conform, setmodal_multiple_moveing_conform] =
    useState(false)
  const tog_multiple_moveing_popup = () => {
    setmodal_multiple_moveing_conform(!modal_multiple_moveing_conform)
    // removeBodyCss();
  }

  function onMoveingMultipleActivityPopup() {
    // setmoveingSessionKey(sessionKey)

    if (moveingMultipleSessionKey.length === 0) {
      toast.error("Please select at least one session!", {
        autoClose: 3000, // 3 seconds in this example
      })
    } else {
      tog_multiple_moveing_popup()
    }
  }
  function onMoveingMultipleActivityHomePopup() {
    tog_move_home_all_activity()
  }
  const [rollOveractiveTab, setrollOverActiveTab] = useState("lessonPlan")
  const handlerollOverTabChange = tab => {
    setrollOverActiveTab(tab)

    const updatedSessions = MultipleUpcomingSessionData.map(session => ({
      ...session,
      checked: false,
    }))

    setMultipleUpcomingSessionData(updatedSessions)
    setmoveingMultipleSessionKey([])
  }

  const handleCheckboxMulChange = (key, isChecked) => {
    const updatedSessions = MultipleUpcomingSessionData.map(session => {
      if (session.pk_ses_key === key) {
        return { ...session, checked: isChecked }
      }
      return session
    })

    setMultipleUpcomingSessionData(updatedSessions)
    if (isChecked) {
      // Add key to the selectedKeys array if checked
      setmoveingMultipleSessionKey(prev => [...prev, key])
    } else {
      // Remove key from the selectedKeys array if unchecked
      setmoveingMultipleSessionKey(prev => prev.filter(k => k !== key))
    }
  }

  const fetchMultipleUpcommingSessionData = async (
    fk_student_key,
    subjcet_id
  ) => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      //setmoveTosession(false);
      setStudentKey(fk_student_key)
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const uniqueSubjects = selectedActivitySubject.reduce((acc, item) => {
          if (!acc.some(obj => obj.subject_id === item.fk_sub_id)) {
            acc.push({ subject_id: item.fk_sub_id })
          }
          return acc
        }, [])
        //console.log(uniqueSubjects);
        const responseSessionUpcomingList = await axios.post(
          BASE_URL + "session/getAllUpcommingActivitySessionList",
          {
            pk_student_key: fk_student_key,
            fk_subject_id: uniqueSubjects,
          }
        ) //console.log(studentKey);
        //console.log(responseUpcomingList)
        if (responseSessionUpcomingList.data.length > 0) {
          const updatedData = responseSessionUpcomingList.data.map(item => ({
            ...item,
            checked: false, // Add the checked property to each item
          }))
          setMultipleUpcomingSessionData(updatedData)
          //setUpcomingSessionData(responseUpcomingList.data);
          setmodal_move_multiple_activity(true)
        } else {
          toast.error("Not found upcoming sessions!", {
            autoClose: 3000, // 3 seconds in this example
          })
          setMultipleUpcomingSessionData([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching upcoming session deatails:", error)
      }
    } else {
      navigate("/login")
      //setLoading(false)
    }
  }

  const onMoveMultipleActivityUpcomingSession = () => {
    // //function onDelectActivity() {

    //console.log(selectedActivityKeys);

    if (authUser && authUser != "" && authUser != null) {
      if (moveingMultipleSessionKey) {
        setIsMulMoningSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(
            BASE_URL + "session/submitMoveMultipleActivityToSessionMultiple",
            {
              pk_ses_key_array: moveingMultipleSessionKey,
              pk_lesson_activity_key_array: selectedActivityKeys,
              pk_student_key: studentKey,
            }
          )
          .then(responseActivityMove => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMove.data) === 1) {
              toast.success(
                "Activity rolled over to next session(s) successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
              tog_multiple_moveing_popup()
              tog_move_multiple_activity()
              setIsMulMoningSubmitting(false)
              setSelectedActivityKeys([])
              setselectedActivitySubject([])
              setSelectAllChecked(false)
              window.location.reload()
            } else {
              setIsMulMoningSubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMulMoningSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMulMoningSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMulMoningSubmitting(false)
      navigate("/login")
    }
  }

  //Rollover multiple moving option end

  //incompleted sctivity start
  const optionPendingActivityType = [
    { label: "Lesson Plan", value: "0" },
    { label: "Homework", value: "1" },
  ]
  const [NextSessionData, setnextsessiondeatils] = useState([])
  //setActivityNextLoading(true)
  const [PendingActivitySeleteAll, setPendingActivitySeleteAll] =
    useState(false)
  const [
    SessionStudentPendingActivityArray,
    setSessionStudentPendingActivityArray,
  ] = useState([])
  const [SessionStudentPendingActivity, setSessionStudentPendingActivity] =
    useState([])
  const [ActivityPendingLoading, setActivityPendingLoading] = useState(false)

  const [TotalPendingActivity, setTotalPendingActivity] = useState(0)

  const [SessionStudentNextActivity, setSessionStudentNextActivity] = useState(
    []
  )
  const [SessionStudentSubject, setSessionStudentSubject] = useState([])
  // const [
  //   SessionStudentSubjectNextActivity,
  //   setSessionStudentSubjectNextActivity,
  // ] = useState([])
  const [SessiosNextActivity, setSessiosNextActivity] = useState([])
  const [ActivityNextLoading, setActivityNextLoading] = useState(false)
  // const [ActivitySubjectNextLoading, setActivitysubjectNextLoading] =
  //   useState(false)
  const [modal_center3, setmodal_center3] = useState(false)
  const [modal_center4, setmodal_center4] = useState(false)

  const fetchNextSessionData = async (pk_student_key, activity_method) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsenextsessiondetails = await axios.post(
          BASE_URL + "session/getNextSessionDetails",
          {
            pk_student_key: pk_student_key,
            pk_ses_key: session_key,
            is_homework: activity_method,
            is_current_session: 1,
          }
        )

        if (responsenextsessiondetails.data != "") {
          setnextsessiondeatils(responsenextsessiondetails.data[0])
        } else {
          setnextsessiondeatils([])
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchSessionStudentPendingActivityData = async (
    pk_student_key,
    activity_method
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      setPendingActivitySeleteAll(false)
      setSessionStudentPendingActivityArray([])
      setSessionStudentPendingActivity([])
      setActivityPendingLoading(true)
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsesessionpendingactivitydetails = await axios.post(
          BASE_URL + "session/SessionStudentPendingActivity",
          {
            pk_student_key: pk_student_key,
            pk_ses_key_cur: session_key,
            is_homework: activity_method,
          }
        )

        if (responsesessionpendingactivitydetails.data.length > 0) {
          setPendingActivitySeleteAll(false)
          setSessionStudentPendingActivityArray([])
          setSessionStudentPendingActivity(
            responsesessionpendingactivitydetails.data
          )
          setActivityPendingLoading(false)
        } else {
          setPendingActivitySeleteAll(false)
          setSessionStudentPendingActivityArray([])
          setSessionStudentPendingActivity([])
          setActivityPendingLoading(false)
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
        setActivityPendingLoading(false)
      }
    } else {
      navigate("/login")
      setActivityPendingLoading(false)
    }
  }

  const fetchSessionStudentNextActivityData = async (
    pk_student_key,
    is_homework,
    session_key,
    subject_id
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      setActivityNextLoading(true)

      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsesessionnextactivitydetails = await axios.post(
          BASE_URL + "activity/getAssessmentActivitiesNext",
          {
            activity_level_id: 0,
            attended: "",
            search: "",
            student_key: pk_student_key,
            session_key: session_key,
            subject_id: subject_id,
            is_homework: is_homework,
          }
        )

        if (
          responsesessionnextactivitydetails.data.activities &&
          responsesessionnextactivitydetails.data.activities.length > 0
        ) {
          setSessionStudentNextActivity(
            responsesessionnextactivitydetails.data.activities
          )
          //setSessionStudentNextActivity(prevData => [...prevData, ...responsesessionnextactivitydetails.data.activities]);
          setActivityNextLoading(false)
        } else {
          setSessionStudentNextActivity([])
          setSessionStudentNextActivity(prevData => [...prevData, ...[]])
          setActivityNextLoading(false)
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
        setActivityNextLoading(false)
      }
    } else {
      navigate("/login")
      setActivityNextLoading(false)
    }
  }

  // const fetchSessionStudentSubjectNextActivityData = async (
  //   pk_student_key,
  //   is_homework,
  //   session_key,
  //   subject_id
  // ) => {
  //   if (authUser && authUser != "" && authUser != null) {
  //     setActivitysubjectNextLoading(true)
  //     try {
  //       axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
  //       const responsesessionnextactivitydetails = await axios.post(
  //         BASE_URL + "activity/getAssessmentActivitiesNext",
  //         {
  //           activity_level_id: 0,
  //           attended: "",
  //           search: "",
  //           student_key: pk_student_key,
  //           session_key: session_key,
  //           subject_id: subject_id,
  //           is_homework: is_homework,
  //         }
  //       )

  //       if (
  //         responsesessionnextactivitydetails.data.activities &&
  //         responsesessionnextactivitydetails.data.activities.length > 0
  //       ) {
  //         //setSessionStudentNextActivity(responsesessionnextactivitydetails.data.activities)
  //         setSessionStudentSubjectNextActivity(
  //           responsesessionnextactivitydetails.data.activities
  //         )
  //         //setSessionStudentNextActivity(prevData => [...prevData, ...responsesessionnextactivitydetails.data.activities]);
  //         setActivitysubjectNextLoading(false)
  //       } else {
  //         setSessionStudentSubjectNextActivity([])
  //         //setSessionStudentNextActivity(prevData => [...prevData, ...[]])
  //         setActivitysubjectNextLoading(false)
  //       }
  //     } catch (error) {
  //       console.error("Error in fetching session deatails:", error)
  //       setActivitysubjectNextLoading(false)
  //     }
  //   } else {
  //     navigate("/login")
  //     setActivitysubjectNextLoading(false)
  //   }
  // }

  useEffect(() => {
    let totalPending = 0

    SessionStudentPendingActivity.forEach(enroll => {
      const isNextSessionAvailable =
        NextSessionData &&
        NextSessionData.next_session_date &&
        NextSessionData.next_session_date !== ""

      if (isNextSessionAvailable) {
        const activities = enroll.Lesson?.LessonActivities || []
        const incompleteActivities = activities.filter(
          activity =>
            activity.status === 0 ||
            ((activity.mastryScore === null ||
              parseInt(activity.mastryScore) < 75) &&
              activity.status === 1 &&
              [0, 2, 6, 7].includes(activity.activity_type))
        )

        totalPending += incompleteActivities.length
      }
    })

    setTotalPendingActivity(totalPending)
  }, [SessionStudentPendingActivity, NextSessionData])
  //console.log(TotalPendingActivity)

  useEffect(() => {
    const newIncompleteActivities = []

    SessionStudentPendingActivity.forEach(enroll => {
      const isNextSessionAvailable =
        NextSessionData &&
        NextSessionData.next_session_date &&
        NextSessionData.next_session_date !== ""

      if (!isNextSessionAvailable) {
        return // Skip if the next session is not available
      }

      const activities = enroll.Lesson?.LessonActivities || []
      const incompleteActivities = activities.filter(
        activity =>
          activity.status === 0 ||
          ((activity.mastryScore === null ||
            parseInt(activity.mastryScore) < 75) &&
            activity.status === 1 &&
            [0, 2, 6, 7].includes(activity.activity_type))
      )

      if (incompleteActivities.length > 0) {
        newIncompleteActivities.push(...incompleteActivities)
      }
    })

    // Update the state with the new incomplete activities
    setSessionStudentPendingActivityArray(prevArray => [
      ...prevArray,
      ...newIncompleteActivities,
    ])
  }, [SessionStudentPendingActivity, NextSessionData]) // Dependencies ensure it runs only when these change

  const OnSubmitNavActivityAssign = async (
    next_session_key,
    next_student_key,
    activity_method
  ) => {
    if (
      next_session_key &&
      next_student_key &&
      SessiosNextActivity?.length > 0
    ) {
      // Remove specific fields from SessiosNextActivity
      const updatedSessiosNextActivity = SessiosNextActivity.map(
        ({
          activity_level_data,
          activity_level_id_data,
          activity_topic_data,
          activity_subject,
          ...rest
        }) => rest
      )

      if (updatedSessiosNextActivity.length > 0) {
        // Convert the updated data to JSON and store in localStorage
        localStorage.setItem(
          "selectedData",
          JSON.stringify(updatedSessiosNextActivity)
        )
      } else {
        localStorage.removeItem("selectedData")
      }

      // Update typeAct in localStorage
      localStorage.setItem("typeAct", activity_method === 1 ? 2 : 1)

      // Update session and student keys
      localStorage.setItem("session_key", next_session_key)
      localStorage.setItem("student_key", next_student_key)



      OnSubmitActivityDataAuto()
      // Navigate to the new activity session
      //navigate("/session-newactivity")
    } else {
      // Skip if validation fails
      console.warn("Invalid inputs or empty activity list.")
    }
  }


  const OnSubmitActivityDataAuto = async () => {
    try {
      setSessiosNextActivity([])

      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

          const response = await axios
            .post(BASE_URL + "activity/createActivities", {
              activities:
                JSON.parse(localStorage.getItem("selectedData")) || [],
              is_homework: localStorage.getItem("typeAct") && localStorage.getItem("typeAct") == 1 ? 0 : 1, 
              lesson_key:
                localStorage.getItem("lesson_key") &&
                localStorage.getItem("lesson_key") != null
                  ? localStorage.getItem("lesson_key")
                  : "",
              session_key: session_key || "",
              student_key: student_key || "",
            })
            .then(responseActivity => {
              const activitiesData = responseActivity.data

              if (activitiesData == 1) {
                toast.success(" Activities added successfully", {
                  autoClose: 3000,
                })
                setSessiosNextActivity([])
                localStorage.removeItem("selectedData")
                fetchLessonData()
                fetchHomeWorkData()
              } else {
                toast.success(" Activities added successfully", {
                  autoClose: 3000,
                })
                setSessiosNextActivity([])
                localStorage.removeItem("selectedData")
                fetchLessonData()
                fetchHomeWorkData()

                setIsSubmitting(false)
              }
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
            })
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      toast.error("Failure! Unable to create activity. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      })

      setIsSubmitting(false) // Re-enable the submit button
    } finally {
      setIsSubmitting(false) // Re-enable the submit button
    }
  }

  const validation1 = useFormik({
    initialValues: {
      pk_student_key: student_key,
      activities: [],
      next_session: "", // Initialize as empty
      activity_method: "0",
    },
    validationSchema: Yup.object({
      //pk_student_key: Yup.mixed().required("Student list is required"),
      activity_method: Yup.mixed().required("Activity method is required"),
      activities: Yup.array()
        .min(1, "At least one activity must be selected.") // Ensure at least one activity is selected
        .required("Activities are required."),
    }),
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      setIsSubmitting(true) // Disable submit button

      // const selectedStudentSession =
      //   sessionData.session.tbl_session_time.tbl_student_enrolment.filter(
      //     enroll => enroll.fk_stu_key === values.pk_student_key.value
      //   )

      // const nextSession =
      //   selectedStudentSession.length > 0
      //     ? selectedStudentSession[0].tbl_student.next_session_key
      //     : ""
      const nextSession = NextSessionData.next_session

      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

        const response = await axios.post(
          BASE_URL + "session/MoveActivityTonextSession",
          {
            ...values,
            pk_student_key: values.pk_student_key,
            pk_ses_key_new: nextSession,
          }
        )

        if (parseInt(response.data) === 1) {
          tog_center3()
          actions.resetForm()
          setSessiosNextActivity([])
          toast.success("Moved to the session successfully", {
            autoClose: 3000,
          })

          //fetchData()
        } else {
          toast.error(
            "Activity not moved to the session, Try again later",
            {
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
        console.error("Error fetching data:", error)
      } finally {
        setIsSubmitting(false) // Re-enable the submit button
      }
    },
  })

  // Grouping activities by `activity_level_id_data`
  const groupedByLevel = SessiosNextActivity.reduce((acc, activity) => {
    const { activity_level_id_data } = activity

    // Debugging line to check if activity_level_id_data is available

    if (activity_level_id_data !== undefined) {
      if (!acc[activity_level_id_data]) {
        acc[activity_level_id_data] = []
      }
      acc[activity_level_id_data].push(activity)
    }
    return acc
  }, {})
  useEffect(() => {
    setSessiosNextActivity([])
    function getRandomEven(min, max) {
      const random = Math.floor(Math.random() * ((max - min) / 2 + 1))
      return min + random * 2
    }

    // Only proceed if there is data
    if (
      SessionStudentNextActivity &&
      Array.isArray(SessionStudentNextActivity) &&
      SessionStudentNextActivity.length > 0
    ) {
      // Declare a new result array to collect valid activities
      let processingArray = []
      // Check for any numbers other than 1 or 2
      // let hasOtherNumbers = SessionStudentSubject.some(
      //   id => id !== 1 && id !== 2
      // )

      // if (hasOtherNumbers) {
      //   processingArray = [
      //     ...SessionStudentNextActivity,
      //     ...SessionStudentSubjectNextActivity,
      //   ]
      // } else {
      //   // If only 1 or 2 are present
      //   if (SessionStudentSubject.length === 1) {
      //     processingArray = SessionStudentNextActivity
      //   } else {
      //     processingArray = [
      //       ...SessionStudentNextActivity,
      //       ...SessionStudentSubjectNextActivity,
      //     ]
      //   }
      // }
      processingArray = SessionStudentNextActivity

      const result = []
      processingArray.forEach((yearLevel, index1) => {
        yearLevel.LevelTopics.forEach((topic, index) => {
          if (
            topic.topic_completed_existing > 0 &&
            topic.topic_Incompleted_existing === 0
          ) {
            if (topic.TopicActivities.length > 0) {
              if (
                NextSessionData.activity_count +
                  parseInt(TotalPendingActivity) +
                  result.length <=
                20
              ) {
                const options = [5, 10, 15, 20, 25, 30]
                const randomValue =
                  options[Math.floor(Math.random() * options.length)]
                result.push({
                  id: topic.TopicActivities[0].activity_node_id,
                  name: topic.TopicActivities[0].node_name,
                  subject_id: topic.subject_id,
                  topicID: topic.activity_topic_id,
                  coin: getRandomEven(4, 20),
                  duration: randomValue + " mins",
                  is_homework: validation1.values.activity_method,
                  type: "Assessment",
                  activity_node_id: topic.TopicActivities[0].activity_node_id,
                  activity_type: 0,
                  activity_level_data: yearLevel.level_name,
                  activity_level_id_data: yearLevel.activity_level_id,
                  activity_topic_data: topic.topic_name,
                  activity_subject: yearLevel.subject_name,
                  level_id: yearLevel.activity_level_id,
                  level_name: yearLevel.level_name,
                  topic_name: topic.topic_name,
                })
              }
            } else {
              // Check subsequent topics for activities
              for (let i = index + 1; i < yearLevel.LevelTopics.length; i++) {
                const nextTopic = yearLevel.LevelTopics[i]
                if (nextTopic.TopicActivities.length > 0) {
                  // Skip activity assignment if conditions met
                  if (
                    nextTopic.topic_completed_existing > 0 ||
                    nextTopic.topic_Incompleted_existing > 0
                  ) {
                    // Skip this iteration as the activity is automatically assigned
                  } else {
                    if (
                      NextSessionData.activity_count +
                        parseInt(TotalPendingActivity) +
                        result.length <=
                      20
                    ) {
                      const options = [5, 10, 15, 20, 25, 30]
                      const randomValue =
                        options[Math.floor(Math.random() * options.length)]
                      result.push({
                        // level_name: yearLevel.level_name,
                        // topic_name: nextTopic.topic_name,
                        // activity: nextTopic.TopicActivities[0],
                        id: nextTopic.TopicActivities[0].activity_node_id,
                        name: nextTopic.TopicActivities[0].node_name,
                        subject_id: nextTopic.subject_id,
                        topicID: nextTopic.activity_topic_id,
                        coin: getRandomEven(4, 20),
                        duration: randomValue + " mins",
                        is_homework: validation1.values.activity_method,
                        type: "Assessment",
                        activity_node_id:
                          nextTopic.TopicActivities[0].activity_node_id,
                        activity_type: 0,
                        activity_level_data: yearLevel.level_name,
                        activity_level_id_data: yearLevel.activity_level_id,
                        activity_topic_data: nextTopic.topic_name,
                        activity_subject: yearLevel.subject_name,
                        level_id: yearLevel.activity_level_id,
                        level_name: yearLevel.level_name,
                        topic_name: topic.topic_name,
                      })
                    }
                  }
                  break // Stop once a valid next topic is found
                }
              }
            }
          }
        })
      })

      // Update state with the new result array
      setSessiosNextActivity(prevResults => [...prevResults, ...result])
    }
  }, [SessionStudentNextActivity]) // Trigger effect when SessionStudentNextActivity changes

  const validation2 = useFormik({
    initialValues: {
      pk_student_key: student_key,
      next_session: "", // Initialize as empty
      activity_subject: "",
      activity_method: "0",
    },
    validationSchema: Yup.object({
      //pk_student_key: Yup.mixed().required("Student list is required"),
      activity_method: Yup.mixed().required("Activity method is required"),
      activity_subject: Yup.mixed().required("Subject is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      setIsSubmitting(true) // Disable submit button

      const nextSession = NextSessionData.next_session

      try {
        OnSubmitNavActivityAssign(
          nextSession,
          values.pk_student_key,
          values.activity_method
        )
      } catch (error) {
        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
        console.error("Error fetching data:", error)
      } finally {
        setIsSubmitting(false) // Re-enable the submit button
      }
    },
  })

  const tog_center3 = () => {
    setmodal_center3(!modal_center3)
    validation1.setFieldValue("pk_student_key", student_key)
    validation1.setFieldValue("activities", [])
    fetchNextSessionData(student_key, 0)
    setnextsessiondeatils("")
    fetchSessionStudentPendingActivityData(student_key, 0)
    setPendingActivitySeleteAll(false)
    setSessionStudentPendingActivityArray([])
    setSessionStudentPendingActivity([])
    setSessionStudentNextActivity([])
    setSessiosNextActivity([])
  }

  const tog_center4 = () => {
    setmodal_center4(!modal_center4)
    validation2.setFieldValue("pk_student_key", student_key)
    fetchNextSessionData(student_key, 0)
    setnextsessiondeatils("")
    setSessionStudentNextActivity([])
    setSessiosNextActivity([])
  }

  //incompleted activity end
  // add Comment
  const [modal_commend, setmodal_commend] = useState(false)
  const [IsCommendSubmitting, setIsCommendSubmitting] = useState(false)
  const [activityCommend, setactivityCommend] = useState(null)
  const tog_commend = () => {
    setmodal_commend(!modal_commend)
  }

  const handle_commend = data => {
    setactivityCommend(data)
    setmodal_commend(true)
  }

  const handleAddCommentChange = newComment => {
    setactivityCommend(prevState => ({
      ...prevState,
      tutor_comments: newComment, // Update the tutor_comments field
    }))
  }

  const onActivityCommend = () => {
    // //function onDelectActivity() {

    //console.log();
    if (authUser && authUser != "" && authUser != null) {
      if (session_key) {
        setIsCommendSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/addCommend", {
            pk_lesson_activity_key: activityCommend.pk_lesson_activity_key,
            commend: activityCommend.tutor_comments,
          })
          .then(responseActivityCommend => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityCommend.data) === 1) {
              toast.success("Comment added successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              fetchLessonData()
              fetchHomeWorkData()
              tog_commend()
              setIsCommendSubmitting(false)
            } else {
              setIsCommendSubmitting(false)
              toast.error("Failure! Unable to commend added. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsCommendSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsCommendSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsCommendSubmitting(false)
      navigate("/login")
    }
  }

  const onActivityToHomework = (activity_key, is_homework_change) => {
    // //function onDelectActivity() {

    //console.log();
    if (authUser && authUser != "" && authUser != null) {
      if (session_key) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/lessonToHomework", {
            pk_lesson_activity_key: activity_key,
            moveHomework: is_homework_change,
          })
          .then(responseActivityMove => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMove.data) === 1) {
              toast.success("Activity move successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              fetchLessonData()
              fetchHomeWorkData()
            } else {
              toast.error("Failure! Unable to activity move. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }
  // add commend
  const handleLinkClick = (student_key, session_key, subject_ID) => {
    // Store data in localStorage

    setSubject_ID(subject_ID)
    setCurrectPreviousSessioncount(1)
    setSessionPreNextData([])
    localStorage.setItem("session_key", session_key)
    localStorage.setItem("student_key", student_key)
    localStorage.setItem("subject_ID", subject_ID)
    const path = location.pathname
    if (path.includes("edit-lesson-plan")) {
      navigate("/edit-lesson-plan", { replace: true })
    } else {
      navigate("/edit-lesson-plan", { replace: true })
    }
  }

  //Edit mylibary
  const optionGroup4 = [
    // {
    //   label: "Select Duration",
    //   options: [
    { label: "Less than 2 minutes", value: "AlLess than 2 minutesl" },
    { label: "2-5 minutes", value: "2-5 minutes" },
    { label: "5-10 minutes", value: "5-10 minutes" },
    { label: "10-15 minutes", value: "10-15 minutes" },
    { label: "15-20 minutes", value: "15-20 minutes" },
    { label: "20-30 minutes", value: "20-30 minutes" },
    { label: "5-10 minutes", value: "5-10 minutes" },
    { label: "30-60 minutes", value: "30-60 minutes" },
    //   ],
    // },
  ]
  const [isSubmitting1, setIsSubmitting1] = useState(false)
  const formRef = useRef(null)
  const [allEditMylibrarydata, setAllEditMylibrarydata] = useState([])
  const [selectedOption3edit, setSelectedOptionedit] = useState("")
  //modal edit
  const [modal_largeEdit, setmodal_largeEdit] = useState(false)
  const tog_largeEdit = () => {
    setmodal_largeEdit(!modal_largeEdit)
  }
  useEffect(() => {
    const activitytype = allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].activity_type
      : ""

    setSelectedOptionedit(activitytype)
  }, [allEditMylibrarydata])

  const handleRowEditActivityClick = async e => {
    //console.log(e)
    if (authUser && authUser != "" && authUser != null) {
      // setLoading(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      const response = await axios
        .post(BASE_URL + "mylibrary/getSingleMyLibraryById", {
          pk_tutor_library_item_id: e.fk_activity_node_id,
        })
        .then(responsesinglemylibrary => {
          if (responsesinglemylibrary.data.length > 0) {
            setAllEditMylibrarydata(responsesinglemylibrary.data)
          } else {
            toast.error(
              "Failure! Unable to Editable session. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            )
          }
        })
        .catch(error => {
          toast.error("Failure! Unable to Editable session. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          })
        })
    }
    // console.log(e.sest_from_date);
    // console.log(e.sest_start_time);
  }
  /* Edit activity form function*/

  const basicSchema1 = Yup.object().shape({
    act_subject: Yup.string().required("Subject name is required."),
    act_type: Yup.string().required("Activity type is required."),
    act_name: Yup.string()
      .required("Activity name is required.")
      .min(2, "Minimum 2 characters required")
      .max(250, "Maximum 250 characters is required.")
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    act_source: Yup.string().required("Source is required."),
    act_duration: Yup.string().required("Duration is required."),
    act_link: Yup.string().when("act_type", {
      is: "3",
      then: schema =>
        schema
          .required("Link is required.")
          .matches(
            /^(https?:\/\/|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=%]*)?$/,
            "Enter a valid link"
          ),
      otherwise: schema => schema.notRequired(),
    }),
    act_video: Yup.string().when("act_type", {
      is: "1",
      then: schema =>
        schema
          .required("Video is required.")
          .matches(
            /^(https?:\/\/|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=%]*)?$/,
            "Enter a valid video link"
          ),
      otherwise: schema => schema.notRequired(),
    }),
    act_file: Yup.mixed().when("act_type", {
      is: "5",
      then: schema => schema.required("File is required."),
      otherwise: schema => schema.notRequired(),
    }),
  })

  const initialValues1 = {
    /*subjects*/
    // pk_tutor_library_item_key: allEditMylibrarydata ? allEditMylibrarydata.pk_tutor_library_item_key : '',
    act_subject: allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].fk_sub_id
      : "",
    act_type: allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].activity_type
      : "",
    act_name: allEditMylibrarydata[0] ? allEditMylibrarydata[0].name : "",
    act_source: allEditMylibrarydata[0] ? allEditMylibrarydata[0].source : "",
    act_duration: allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].duration
      : "",
    act_link:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 3
        ? allEditMylibrarydata[0].link
        : "",
    act_video:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 1
        ? allEditMylibrarydata[0].link
        : "",
    act_file1:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 5
        ? allEditMylibrarydata[0].link
        : "",
    act_file:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 5
        ? allEditMylibrarydata[0].link
        : "",
    act_description: allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].description
      : "",
  }
  // console.log('esf',allEditMylibrarydata)

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: basicSchema1,
    enableReinitialize: true,
    //  onSubmit,
    onSubmit: async formValues => {
      try {
        if (authUser && authUser != "" && authUser != null) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          if (values1.act_file instanceof File && values1.act_type == "5") {
            const formData = new FormData()
            formData.append(
              "pk_tutor_library_item_key",
              allEditMylibrarydata[0].pk_tutor_library_item_key
            )
            formData.append("act_subject", values1.act_subject)
            formData.append("act_type", values1.act_type)
            formData.append("act_name", values1.act_name)
            formData.append("act_source", values1.act_source)
            formData.append("act_duration", values1.act_duration)
            formData.append("act_file", values1.act_file)
            formData.append("act_description", values1.act_description)

            const response = await axios.post(
              BASE_URL + "mylibrary/updateMyLibrary1",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            // const response = await axios.put(BASE_URL+'mylibrary/updateMyLibrary', {
            //   pk_tutor_library_item_key: allEditMylibrarydata[0].pk_tutor_library_item_key,
            //   ...formValues
            // });
            // console.log("response", response)

            if (response.data.status && response.data.status === true) {
              // Reset the form

              // console.log(response)
              toast.success("Activity updated successfully", {
                autoClose: 3000,
              })
              fetchLessonData()
              fetchHomeWorkData()
              setmodal_largeEdit(false)
              setIsSubmitting1(false)
            } else {
              formRef.current.reset()
              toast.error(
                "Failure! Unable to update the activity in my library. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmitting1(false) // Re-enable the submit button
            }
          } else {
            const response = await axios.post(
              BASE_URL + "mylibrary/updateMyLibrary",
              {
                pk_tutor_library_item_key:
                  allEditMylibrarydata[0].pk_tutor_library_item_key,
                ...formValues,
              }
            )

            // Reset the form
            //formRef.current.reset();

            if (response.data.status && response.data.status === true) {
              // console.log(response)
              toast.success("Activity updated successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              fetchLessonData()
              fetchHomeWorkData()
              setmodal_largeEdit(false)
              setIsSubmitting1(false) // Re-enable the submit button
            } else {
              toast.error(
                "Failure! Unable to update the activity in my library. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmitting1(false) // Re-enable the submit button
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        toast.error(
          "Failure! Unable to update the activity in my library. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        )

        setIsSubmitting1(false) // Re-enable the submit button
      } finally {
        setIsSubmitting1(false) // Re-enable the submit button
      }
    },
  })

  // Now, you can set variables as needed:
  const values1 = formik1.values
  const handleBlur1 = formik1.handleBlur
  const touched1 = formik1.touched
  const handleChange1 = formik1.handleChange
  const errors1 = formik1.errors
  const handleSubmit1 = formik1.handleSubmit
  const setTouched1 = formik1.setTouched
  const resetForm1 = formik1.resetForm
  const setValues1 = formik1.setValues
  const handleReset1 = formik1.handleReset

  const handleRemoveFile = () => {
    formik1.setFieldValue("act_file1", "")
  }

  // add tuto note
  const [modal_tutorNote, setmodal_tutorNote] = useState(false)
  const [IsTutorNoteSubmitting, setIsTutorNoteSubmitting] = useState(false)
  const [tutorNote, setTutorNote] = useState(null)
  const tog_tutorNote = () => {
    setmodal_tutorNote(!modal_tutorNote)
  }

  const handle_tutorNote = data => {
    setTutorNote(data)
    setmodal_tutorNote(true)
  }

  const handleAddNoteChange = newComment => {
    setTutorNote(newComment)
  }

  const onTutorNote = () => {
    // //function onDelectActivity() {

    //console.log();
    if (authUser && authUser != "" && authUser != null) {
      if (session_key) {
        setIsTutorNoteSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "sessionActivity/addTutorStudentNotes", {
            tutor_notes: tutorNote,
            pk_student_key: student_key,
          })
          .then(responseTutorNote => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseTutorNote.data) === 1) {
              toast.success("Notes added successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              fetchData()
              tog_tutorNote()
              setIsTutorNoteSubmitting(false)
            } else {
              setIsTutorNoteSubmitting(false)
              toast.error("Failure! Unable to notes added. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsTutorNoteSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsTutorNoteSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsTutorNoteSubmitting(false)
      navigate("/login")
    }
  }

  //flexi start

  const onFlexiSession = (session_key_data, is_flexi) => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (session_key_data) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/flexisession", {
            pk_ses_key: session_key_data,
            is_flexi: is_flexi,
          })
          .then(responseFlexiSession => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseFlexiSession.data) === 1) {
              if (is_flexi == 1) {
                toast.success("Session move to flexi successfully", {
                  autoClose: 3000, // 3 seconds in this example
                })
              } else {
                toast.success("Session remove to flexi successfully", {
                  autoClose: 3000, // 3 seconds in this example
                })
              }

              fetchPreNextData()
            } else {
              toast.error(
                "Failure! Unable to move to flexi session. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
            }
          })
          .catch(error => {
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/session")
      }
    } else {
      navigate("/login")
    }
  }
  //flexi end

  return (
    <React.Fragment>
      <Loader
        show={(loader && !loaderLesson && !loaderHomework) || loaderLesson || loaderHomework}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />
      {/* yogesh start */}
      <Modal isOpen={modal_large} toggle={tog_large} size="lg">
        <ModalHeader className="mt-0" toggle={tog_large}>
          Add New Activity
        </ModalHeader>
        <ModalBody>
          <Form autoComplete="off" onSubmit={handleSubmitadd} ref={formRefadd}>
            <Row className="mb-3 g-3">
              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Subject<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Select
                      menuPlacement="auto"
                      options={SessionStudentSubject}
                      name="act_subject"
                      value={SessionStudentSubject.find(
                        option =>
                          option.value &&
                          formikadd.values.act_subject &&
                          option.value.toString() ===
                            valuesadd.act_subject.toString()
                      )}
                      id="act_subject"
                      onChange={selectedOption => {
                        const event = {
                          target: {
                            name: "act_subject",
                            value: selectedOption["value"],
                          },
                        }
                        handleChangeadd(event)
                      }}
                      onBlur={event => {
                        handleBluradd(event)
                        setTouchedadd({ ...touchedadd, act_subject: true })
                      }}
                      className={
                        errorsadd.act_subject && touchedadd.act_subject
                          ? "input-error"
                          : ""
                      }
                    />
                    {errorsadd.act_subject && touchedadd.act_subject && (
                      <p className="error">{errorsadd.act_subject}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Activity type<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Select
                      menuPlacement="auto"
                      options={optionGroup3}
                      name="act_type"
                      value={optionGroup3.find(
                        option =>
                          option.value &&
                          valuesadd.act_type &&
                          option.value.toString() ===
                            valuesadd.act_type.toString()
                      )}
                      id="act_type"
                      onChange={selectedOption => {
                        const event = {
                          target: {
                            name: "act_type",
                            value: selectedOption["value"],
                          },
                        }
                        handleChangeadd(event)
                        handleInputChangeadd(event)
                      }}
                      onBlur={event => {
                        handleBluradd(event)
                        setTouchedadd({ ...touchedadd, act_type: true })
                      }}
                      className={
                        errorsadd.act_type && touchedadd.act_type
                          ? "input-error"
                          : ""
                      }
                    />
                    {errorsadd.act_type && touchedadd.act_type && (
                      <p className="error">{errorsadd.act_type}</p>
                    )}
                  </div>
                </div>
              </Col>

              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Additional Learning Resources Name
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Input
                      type="text"
                      value={valuesadd.act_name || ""}
                      onChange={handleChangeadd}
                      onBlur={handleBluradd}
                      id="act_name"
                      className={
                        errorsadd.act_name && touchedadd.act_name
                          ? "form-control input-error"
                          : ""
                      }
                    />
                    {errorsadd.act_name && touchedadd.act_name && (
                      <p className="error">{errorsadd.act_name}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Source<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Input
                      type="text"
                      value={valuesadd.act_source}
                      onChange={handleChangeadd}
                      onBlur={handleBluradd}
                      id="act_source"
                      className={
                        errorsadd.act_source && touchedadd.act_source
                          ? "form-control input-error"
                          : ""
                      }
                    />
                    {errorsadd.act_source && touchedadd.act_source && (
                      <p className="error">{errorsadd.act_source}</p>
                    )}
                  </div>
                </div>
              </Col>

              <Col className="col-12">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Description
                  </label>
                  <div className="">
                    <textarea
                      id={`act_description`}
                      placeholder=""
                      value={valuesadd.act_description}
                      onChange={handleChangeadd}
                      onBlur={handleBluradd}
                      rows="4"
                      className={
                        errorsadd.act_description && touchedadd.act_description
                          ? "form-control input-error"
                          : "form-control"
                      }
                    />

                    {errorsadd.act_description &&
                      touchedadd.act_description && (
                        <p className="error">{errorsadd.act_description}</p>
                      )}
                  </div>
                </div>
              </Col>

              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Duration<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Select
                      menuPlacement="auto"
                      options={optionGroup4}
                      name="act_duration"
                      value={optionGroup4.find(
                        option =>
                          option.value &&
                          valuesadd.act_duration &&
                          option.value.toString() ===
                            valuesadd.act_duration.toString()
                      )}
                      id="act_duration"
                      onChange={selectedOption => {
                        const event = {
                          target: {
                            name: "act_duration",
                            value: selectedOption["value"],
                          },
                        }
                        handleChangeadd(event)
                      }}
                      onBlur={event => {
                        handleBluradd(event)
                        setTouchedadd({ ...touchedadd, act_duration: true })
                      }}
                      className={
                        errorsadd.act_duration && touchedadd.act_duration
                          ? "input-error"
                          : ""
                      }
                    />
                    {errorsadd.act_duration && touchedadd.act_duration && (
                      <p className="error">{errorsadd.act_duration}</p>
                    )}
                  </div>
                </div>
              </Col>

              {selectedOption3 === "1" && (
                <Col className="col-6">
                  <div className="">
                    <label
                      htmlFor="example-text-input"
                      className="col-form-label"
                    >
                      Video<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="">
                      <Input
                        type="text"
                        value={valuesadd.act_video}
                        onChange={handleChangeadd}
                        onBlur={handleBluradd}
                        placeholder="URL"
                        id="act_video"
                        className={
                          errorsadd.act_video && touchedadd.act_video
                            ? "form-control input-error"
                            : ""
                        }
                      />
                      {errorsadd.act_video && touchedadd.act_video && (
                        <p className="error">{errorsadd.act_video}</p>
                      )}
                    </div>
                  </div>
                </Col>
              )}
              {selectedOption3 === "3" && (
                <Col className="col-6">
                  <div className="">
                    <label
                      htmlFor="example-text-input"
                      className="col-form-label"
                    >
                      Link<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="">
                      <Input
                        type="text"
                        value={valuesadd.act_link}
                        onChange={handleChangeadd}
                        onBlur={handleBluradd}
                        placeholder="link"
                        id="act_link"
                        className={
                          errorsadd.act_link && touchedadd.act_link
                            ? "form-control input-error"
                            : ""
                        }
                      />
                      {errorsadd.act_link && touchedadd.act_link && (
                        <p className="error">{errorsadd.act_link}</p>
                      )}
                    </div>
                  </div>
                </Col>
              )}
              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Tutor Comment
                  </label>
                  <div className="">
                    <Input
                      type="text"
                      value={valuesadd.tutor_comments}
                      onChange={handleChangeadd}
                      onBlur={handleBluradd}
                      placeholder="Tutor comments.."
                      id="tutor_comments"
                      className={
                        errorsadd.tutor_comments && touchedadd.tutor_comments
                          ? "form-control input-error"
                          : ""
                      }
                    />

                    {errorsadd.tutor_comments && touchedadd.tutor_comments && (
                      <p className="error">{errorsadd.tutor_comments}</p>
                    )}
                  </div>
                </div>
              </Col>
              {selectedOption3 === "5" && (
                <Col className="col-12">
                  <div className="">
                    <label
                      htmlFor="example-text-input"
                      className="col-form-label"
                    >
                      Upload PDF<span style={{ color: "red" }}>*</span>
                    </label>

                    <span> File size should not exceed 2mb</span>
                    <div className="">
                      <Input
                        type="file"
                        accept=".pdf"
                        onChange={event => {
                          formikadd.setFieldValue(
                            "act_file",
                            event.currentTarget.files[0]
                          )
                        }}
                        onBlur={handleBluradd}
                        id="act_file"
                        name="act_file"
                        className={
                          errorsadd.act_file && touchedadd.act_file
                            ? "form-control input-error"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <div className="text-end mt-4">
              <Button
                type="submit"
                variant="contained"
                color="danger"
                disabled={isSubmittingadd}
              >
                Add
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal_largeEdit} toggle={tog_largeEdit} size="lg">
        <ModalHeader className="mt-0" toggle={tog_largeEdit}>
          Edit New Activity
        </ModalHeader>
        <ModalBody>
          <Form autoComplete="off" onSubmit={handleSubmit1} ref={formRef}>
            <Row className="mb-3 g-3">
              {/* <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Subject<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className="">
                  {console.log(subjectOptions)}
                  {console.log(values1)}
                  <Select
                      menuPlacement="auto"
                      options={subjectOptions}
                      name="act_subject"
                      value={
                        subjectOptions.length > 0 &&
                        subjectOptions[0].options &&
                        subjectOptions[0].options.find(
                          option =>
                            option.value &&
                            values1.act_subject &&
                            option.value.toString() === values1.act_subject.toString()
                        )
                      }
                      id="act_subject"
                      onChange={selectedOption => {
                        const event = {
                          target: {
                            name: "act_subject",
                            value: selectedOption["value"],
                          },
                        }
                        handleChange1(event)
                      }}
                      onBlur={event => {
                        handleBlur1(event)
                        setTouched1({ ...touched1, act_subject: true })
                      }}
                      className={
                        errors1.act_subject && touched1.act_subject
                          ? "input-error"
                          : ""
                      }
                    />
                    {errors1.act_subject && touched1.act_subject && (
                      <p className="error">{errors1.act_subject}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Activity type<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className="">
                    {/* <Select
                      menuPlacement="auto"
                      options={optionGroup3}
                      name="act_type"
                      value={optionGroup3.find(options => options.value === values1.act_type)}
                      id="act_type"
                      onChange={(selectedOption) => {
                        const event = {
                          target: {
                            name: "act_type",
                            value: selectedOption ? selectedOption.value : "", // Ensure a valid value is set
                          },
                        };
                        handleChange1(event);
                      }}
                      onBlur={(event) => {
                        handleBlur1(event);
                        setTouched1({ ...touched1, act_type: true });
                      }}
                      className={errors1.act_type && touched1.act_type ? "input-error" : ""}
                    /> 
                    <Select
                      menuPlacement="auto"
                      options={optionGroup3}
                      name="act_type"
                      value={
                        optionGroup3.length > 0 &&
                        optionGroup3[0].options &&
                        optionGroup3[0].options.find(
                          option =>
                            option.value &&
                            values1.act_type &&
                            option.value.toString() === values1.act_type.toString()
                        )
                      }
                      
                      id="act_type"
                      // onChange={selectedOption => {
                      //   const selectedValue = selectedOption
                      //     ? selectedOption.value
                      //     : "" // Ensure a valid value is set
                      //   const event = {
                      //     target: {
                      //       name: "act_type",
                      //       value: selectedValue,
                      //     },
                      //   }

                      //   // Check the selected value and set a specific value based on a condition
                      //   let newValue = selectedValue // Default to the selected value
                      //   if (selectedValue === "desiredValue") {
                      //     // Change 'desiredValue' to your condition
                      //     newValue = "newValue" // Set the new value based on the condition
                      //   }

                      //   // Set the new value before calling handleInputChangeEdit
                      //   event.target.value = newValue
                      //   handleChange1(event)

                      //   handleInputChangeEdit(event)
                      // }}
                      // onBlur={event => {
                      //   handleBlur1(event)
                      //   setTouched1({ ...touched1, act_type: true })
                      // }}
                      onChange={selectedOption => {
                        const event = {
                          target: {
                            name: "act_type",
                            value: selectedOption["value"],
                          },
                        }
                        handleChange1(event)
                        handleInputChangeEdit(event)
                      }}
                      onBlur={event => {
                        handleBlur1(event)
                        setTouched1({ ...touched1, act_type: true })
                      }}
                      className={
                        errors1.act_type && touched1.act_type
                          ? "input-error"
                          : ""
                      }
                    />
                    {/* <Select
                      menuPlacement="auto" options={optionGroup3}
                      name="act_type"
                      value={optionGroup3.find(options => options.value && values1.act_type && options.value == values1.act_type)}
                      id="act_type"
                      onChange={(selectedOption) => {
                        const event = {
                          target: {
                            name: "act_type",
                            value: selectedOption["value"],
                          },
                        };
                        handleChange1(event);
                      }}
                      onBlur={(event) => {
                        handleBlur1(event);
                        setTouched1({ ...touched1, act_type: true });
                      }}
                      className={errors1.act_type && touched1.act_type ? "input-error" : ""}
                    /> 
                    {errors1.act_type && touched1.act_type && (
                      <p className="error">{errors1.act_type}</p>
                    )}
                  </div>
                </div>
              </Col> */}

              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Activity name<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Input
                      type="text"
                      value={values1.act_name}
                      onChange={handleChange1}
                      onBlur={handleBlur1}
                      id="act_name"
                      className={
                        errors1.act_name && touched1.act_name
                          ? "form-control input-error"
                          : ""
                      }
                    />
                    {errors1.act_name && touched1.act_name && (
                      <p className="error">{errors1.act_name}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Source<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Input
                      type="text"
                      value={values1.act_source}
                      onChange={handleChange1}
                      onBlur={handleBlur1}
                      id="act_source"
                      className={
                        errors1.act_source && touched1.act_source
                          ? "form-control input-error"
                          : ""
                      }
                    />
                    {errors1.act_source && touched1.act_source && (
                      <p className="error">{errors1.act_source}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="col-12">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Description
                  </label>
                  <div className="">
                    <textarea
                      id={`act_description`}
                      placeholder=""
                      value={values1.act_description}
                      onChange={handleChange1}
                      onBlur={handleBlur1}
                      rows="4"
                      className={
                        errors1.act_description && touched1.act_description
                          ? "form-control input-error"
                          : "form-control"
                      }
                    />

                    {errors1.act_description && touched1.act_description && (
                      <p className="error">{errors1.act_description}</p>
                    )}
                  </div>
                </div>
              </Col>

              <Col className="col-6">
                <div className="">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                  >
                    Duration<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="">
                    <Select
                      menuPlacement="auto"
                      options={optionGroup4}
                      name="act_duration"
                      value={optionGroup4.find(
                        option =>
                          option.value &&
                          values1.act_duration &&
                          option.value.toString() ===
                            values1.act_duration.toString()
                      )}
                      id="act_duration"
                      onChange={selectedOption => {
                        const event = {
                          target: {
                            name: "act_duration",
                            value: selectedOption["value"],
                          },
                        }
                        handleChange1(event)
                      }}
                      onBlur={event => {
                        handleBlur1(event)
                        setTouched1({ ...touched1, act_duration: true })
                      }}
                      className={
                        errors1.act_duration && touched1.act_duration
                          ? "input-error"
                          : ""
                      }
                    />
                    {errors1.act_duration && touched1.act_duration && (
                      <p className="error">{errors1.act_duration}</p>
                    )}
                  </div>
                </div>
              </Col>
              {console.log("tyr", selectedOption3edit)}
              {selectedOption3edit == 1 && (
                <Col className="col-6">
                  <div className="">
                    <label
                      htmlFor="example-text-input"
                      className="col-form-label"
                    >
                      Video<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="">
                      <Input
                        type="text"
                        value={values1.act_video || ""}
                        onChange={handleChange1}
                        onBlur={handleBlur1}
                        id="act_video"
                        className={
                          errors1.act_video && touched1.act_video
                            ? "form-control input-error"
                            : ""
                        }
                      />
                      {errors1.act_video && touched1.act_video && (
                        <p className="error">{errors1.act_video}</p>
                      )}
                    </div>
                  </div>
                </Col>
              )}
              {selectedOption3edit == 3 && (
                <Col className="col-6">
                  <div className="">
                    <label
                      htmlFor="example-text-input"
                      className="col-form-label"
                    >
                      Link<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="">
                      <Input
                        type="text"
                        value={values1.act_link || ""}
                        onChange={handleChange1}
                        onBlur={handleBlur1}
                        id="act_link"
                        className={
                          errors1.act_link && touched1.act_link
                            ? "form-control input-error"
                            : ""
                        }
                      />
                      {errors1.act_link && touched1.act_link && (
                        <p className="error">{errors1.act_link}</p>
                      )}
                    </div>
                  </div>
                </Col>
              )}
              {/* {selectedOption3edit === 5 && (
                <Col className="col-12">
                  <div className="">
                    <label
                      htmlFor="example-text-input"
                      className="col-form-label"
                    >
                      Upload PDF
                    </label>

                    <span> File size should not exceed 2mb</span>
                    <div className="">
                      <Input
                        type="file"
                        accept=".pdf"
                        onChange={event => {
                          formik1.setFieldValue(
                            "act_file",
                            event.currentTarget.files[0]
                          )
                        }}
                        onBlur={handleBlur}
                        id="act_file"
                        name="act_file"
                        className={
                          errors1.act_file && touched1.act_file
                            ? "form-control input-error"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </Col>
              )} */}
              {selectedOption3edit == 5 && (
                <div className="">
                  {values1.act_file1 ? (
                    <div>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Uploaded PDF<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="uploaded-file-info">
                        <span>{values1.act_file1}</span>
                        <button
                          type="button"
                          onClick={handleRemoveFile}
                          className="close-icon"
                        >
                          &times; {/* Close icon */}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <Col className="col-12">
                      <div className="">
                        <label
                          htmlFor="example-text-input"
                          className="col-form-label"
                        >
                          Upload PDF
                        </label>

                        <span> File size should not exceed 2mb</span>
                        <div className="">
                          <Input
                            type="file"
                            accept=".pdf"
                            onChange={event => {
                              formik1.setFieldValue(
                                "act_file",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={handleBlur1}
                            id="act_file"
                            name="act_file"
                            className={
                              errors1.act_file && touched1.act_file
                                ? "form-control input-error"
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </div>
              )}
            </Row>
            <div className="text-end mt-4">
              {/* <Button type="submit" color="danger">
                Add
              </Button> */}
              <Button
                type="submit"
                variant="contained"
                color="danger"
                disabled={isSubmitting1}
              >
                Edit
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      {/* popup for incomplete activity  start*/}
      <Modal
        isOpen={modal_center3}
        toggle={tog_center3}
        centered
        className="mastery-modal"
      >
        <ModalHeader className="mt-0 text-dark" toggle={tog_center3}>
          Incomplete Activities
        </ModalHeader>
        <ModalBody>
          <Form
            className=""
            onSubmit={e => {
              e.preventDefault()
              validation1.handleSubmit()
              return false
            }}
            action="#"
          >
            {/* <div className="mb-3">
              <Label className="font-size-12 mb-1 pb-0">
                Student List
              </Label>
              <Select
                // isMulti={true}
                options={studentOption}
                classNamePrefix="select2-selection"
                id="pk_student_key"
                onBlur={validation1.handleBlur}
                name="pk_student_key"
                value={validation1.values.pk_student_key || ""}
                onChange={selectedOption => {
                  const event = {
                    target: {
                      name: "pk_student_key",
                      value: selectedOption,
                    },
                  }
                  validation1.handleChange(event)
                  validation1.setFieldValue("activities", [])
                  fetchNextSessionData(
                    selectedOption,
                    validation1.values.activity_method
                  )
                  setnextsessiondeatils("")
                  fetchSessionStudentPendingActivityData(
                    selectedOption,
                    validation1.values.activity_method
                  )
                  setSessionStudentPendingActivity([])
                  setSessionStudentNextActivity([])
                  setSessiosNextActivity([])
                }}
                className={
                  validation1.touched.pk_student_key &&
                  validation1.errors.pk_student_key
                    ? "is-invalid cs-style"
                    : "cs-style"
                }
              />
              {validation1.touched.pk_student_key &&
              validation1.errors.pk_student_key ? (
                <FormFeedback type="invalid">
                  {validation1.errors.pk_student_key}
                </FormFeedback>
              ) : null}
            </div> */}
            <div className="mb-3">
              <Label className="font-size-12 mb-1 pb-0">Activity Method</Label>
              <Select
                // isMulti={true}
                options={optionPendingActivityType}
                classNamePrefix="select2-selection"
                id="activity_method"
                onBlur={validation1.handleBlur}
                name="activity_method"
                value={optionPendingActivityType.find(
                  option => option.value == validation1.values.activity_method
                )}
                onChange={selectedOption => {
                  // console.log(selectedOption)
                  const event = {
                    target: {
                      name: "activity_method",
                      value: selectedOption["value"],
                    },
                  }
                  validation1.handleChange(event)
                  validation1.setFieldValue("activities", [])
                  fetchNextSessionData(
                    validation1.values.pk_student_key,
                    selectedOption["value"]
                  )
                  setnextsessiondeatils("")
                  fetchSessionStudentPendingActivityData(
                    validation1.values.pk_student_key,
                    selectedOption["value"]
                  )
                  setSessionStudentPendingActivity([])
                  setSessionStudentNextActivity([])
                  setSessiosNextActivity([])
                }}
                className={
                  validation1.touched.activity_method &&
                  validation1.errors.activity_method
                    ? "is-invalid cs-style"
                    : "cs-style"
                }
              />
              {validation1.touched.activity_method &&
              validation1.errors.activity_method ? (
                <FormFeedback type="invalid">
                  {validation1.errors.activity_method}
                </FormFeedback>
              ) : null}
            </div>
            {validation1.values.pk_student_key &&
              (NextSessionData && NextSessionData.next_session_date ? (
                <div className="mb-3">
                  <Label className="font-size-12 mb-1 pb-0">
                     Session: {NextSessionData.sest_name} (
                    {moment(
                      NextSessionData.next_session_date,
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    ).format("DD MMM YYYY")}
                    ) (Total Activity :{" "}
                    {NextSessionData && NextSessionData.activity_count
                      ? NextSessionData.activity_count
                      : 0}
                    )
                  </Label>
                </div>
              ) : (
                <div className="alert alert-info">
                  No session available for this student.
                </div>
              ))}

            <div className="mb-3">
              {SessionStudentPendingActivity &&
              SessionStudentPendingActivity.length > 0 ? (
                <label
                  htmlFor="example-text-input"
                  className="col-form-label pt-0 font-size-12 mb-1 pb-0"
                >
                  Incomplete activities move to session
                </label>
              ) : null}
              <div className="activities-container">
                <Loader
                  show={ActivityPendingLoading}
                  type="inline"
                  spinnerSize="20px"
                  spinnerStyle={{
                    secondary: "#2D866D",
                  }}
                />
                <div className="form-check">
                  {SessionStudentPendingActivityArray &&
                  SessionStudentPendingActivityArray.length > 0 ? (
                    <>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`activity-all`}
                        name="activities"
                        checked={
                          PendingActivitySeleteAll ||
                          (SessionStudentPendingActivityArray &&
                            validation1.values.activities &&
                            SessionStudentPendingActivityArray.length ===
                              validation1.values.activities.length)
                        }
                        onChange={event => {
                          const value = event.target.checked
                          setPendingActivitySeleteAll(value)
                          console.log(value)
                          let next_activity_count =
                            NextSessionData &&
                            NextSessionData.activity_count != null &&
                            NextSessionData.activity_count <= 20
                              ? 20 - parseInt(NextSessionData.activity_count)
                              : 0

                          if (value === true) {
                            let selectedActivity = []
                            let exceededLimit = false
                            console.log("start loop")

                            SessionStudentPendingActivityArray.forEach(
                              activity => {
                                if (
                                  selectedActivity.length < next_activity_count
                                ) {
                                  console.log("add activity")
                                  console.log(activity)
                                  selectedActivity = [
                                    ...selectedActivity,
                                    activity.pk_lesson_activity_key,
                                  ]
                                } else if (!exceededLimit) {
                                  // Show the error message only once when the limit is reached
                                  exceededLimit = true
                                  toast.error(
                                    "Sorry, a maximum of 20 activities are allowed session",
                                    { autoClose: 3000 } // 3 seconds
                                  )
                                }
                              }
                            )

                            validation1.setFieldValue(
                              "activities",
                              selectedActivity
                            )
                          } else {
                            // Handle deselection or reset if value is not true
                            validation1.setFieldValue("activities", [])
                          }
                        }}
                      />
                      <label htmlFor="exampleCheckbox">Select All</label>
                    </>
                  ) : (
                    !ActivityPendingLoading ? <div
                      key={`no-activities`}
                      className="alert alert-info mb-3 align-items-center"
                    >
                      No pending activities for this student.
                    </div> : null
                  )}
                </div>
                {!validation1.values.pk_student_key ? (
                  <div className="alert alert-warning">
                    Please select a student to list the activities.
                  </div>
                ) : SessionStudentPendingActivity &&
                  SessionStudentPendingActivity.length > 0 ? (
                  SessionStudentPendingActivity.map(enroll => {
                    const isNextSessionAvailable =
                      NextSessionData &&
                      NextSessionData.next_session_date &&
                      NextSessionData.next_session_date !== ""

                    if (!isNextSessionAvailable) {
                      return (
                        <div
                          key={`no-session-${enroll.pk_student_key}`}
                          className="alert alert-info"
                        >
                          Session not available for this student.
                        </div>
                      )
                    }

                    const activities = enroll.Lesson?.LessonActivities || []
                    const incompleteActivities = activities.filter(
                      activity =>
                        activity.status === 0 ||
                        ((activity.mastryScore === null ||
                          parseInt(activity.mastryScore) < 75) &&
                          activity.status === 1 &&
                          [0, 2, 6, 7].includes(activity.activity_type))
                    )

                    if (incompleteActivities.length === 0) {
                      return null
                    }
                    //console.log(validation1.values.activities);
                    return (
                      <div
                        key={`activities-${enroll.pk_ses_key}`}
                        className="mb-3"
                      >
                        <Label className="font-size-12 mb-1 pb-0">
                          <strong>
                            {" "}
                            Date:{" "}
                            {moment(
                              enroll.ses_date,
                              "YYYY-MM-DDTHH:mm:ss.SSSZ"
                            ).format("DD MMM YYYY")}{" "}
                          </strong>
                        </Label>
                        {incompleteActivities.map(activity => (
                          <div
                            key={activity.pk_lesson_activity_key}
                            className="form-check"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`activity-${activity.pk_lesson_activity_key}`}
                              name="activities"
                              value={activity.pk_lesson_activity_key}
                              checked={validation1.values.activities.includes(
                                activity.pk_lesson_activity_key
                              )}
                              onChange={event => {
                                const value = event.target.value
                                let next_activity_count =
                                  NextSessionData &&
                                  NextSessionData.activity_count != null &&
                                  NextSessionData.activity_count <= 20
                                    ? 20 -
                                      parseInt(NextSessionData.activity_count)
                                    : 0
                                if (
                                  validation1.values.activities.includes(value)
                                ) {
                                  setPendingActivitySeleteAll(false)
                                  const updatedActivities =
                                    validation1.values.activities.includes(
                                      value
                                    )
                                      ? validation1.values.activities.filter(
                                          item => item !== value
                                        )
                                      : [
                                          ...validation1.values.activities,
                                          value,
                                        ]
                                  validation1.setFieldValue(
                                    "activities",
                                    updatedActivities
                                  )
                                } else if (
                                  validation1.values.activities.length <
                                    next_activity_count &&
                                  !validation1.values.activities.includes(value)
                                ) {
                                  const updatedActivities =
                                    validation1.values.activities.includes(
                                      value
                                    )
                                      ? validation1.values.activities.filter(
                                          item => item !== value
                                        )
                                      : [
                                          ...validation1.values.activities,
                                          value,
                                        ]
                                  validation1.setFieldValue(
                                    "activities",
                                    updatedActivities
                                  )
                                } else {
                                  toast.error(
                                    "Sorry, a maximum of 20 activities are allowed session",
                                    {
                                      autoClose: 3000, // 3 seconds
                                    }
                                  )
                                }
                              }}
                            />
                            <label>
                              {
                                // Customize the displayed name based on the activity type and subject
                                activity.activity_type === 0 &&
                                [1, 2].includes(activity.fk_sub_id)
                                  ? activity.activity_node[0]?.node_name || ""
                                  : activity.activity_type === 1
                                  ? activity.activity_node[0]?.name || ""
                                  : activity.activity_type === 2 &&
                                    [1, 2].includes(activity.fk_sub_id)
                                  ? getUniqueTopicsNames(activity?.topics)
                                  : activity.activity_type === 3
                                  ? activity.activity_node[0]?.name || ""
                                  : activity.activity_type === 4
                                  ? activity.activity_node[0]?.name || ""
                                  : activity.activity_type === 5
                                  ? activity.activity_node[0]?.name || ""
                                  : activity.activity_type === 6 &&
                                    [1, 2].includes(activity.fk_sub_id)
                                  ? activity.activity_node[0]?.video_name || ""
                                  : activity.activity_type === 7 &&
                                    [1, 2].includes(activity.fk_sub_id)
                                  ? activity.activity_node[0]?.node_name || ""
                                  : "Activity"
                              }
                            </label>
                            <label>
                              {" - "}Status :
                              <span className="text-danger">
                                {activity.status === 0
                                  ? "Incomplete"
                                  : activity.status === 1 &&
                                    parseInt(activity.mastryScore) < 75
                                  ? "Score below 75%"
                                  : "Incomplete"}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    )
                  })
                ) : null}
                {validation1.touched.activities &&
                  validation1.errors.activities && (
                    <p className="text-danger">
                      {validation1.errors.activities}
                    </p>
                  )}
              </div>
            </div>
            <div className="text-end">
              <div className="btn-group">
                {validation1.values.activities.length > 0 &&
                SessionStudentPendingActivity &&
                SessionStudentPendingActivity.length > 0 ? (
                  <button
                    type="submit"
                    className="btn btn-danger"
                    disabled={isSubmitting}
                  >
                    Move
                  </button>
                ) : null}
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      {/* popup for incomplete activity  end*/}

      {/* popup for incomplete activity  start*/}
      <Modal
        isOpen={modal_center4}
        toggle={tog_center4}
        centered
        className="mastery-modal"
      >
        <ModalHeader className="mt-0 text-dark" toggle={tog_center4}>
          Auto Assign Next Activity
        </ModalHeader>
        <ModalBody>
          <Form
            className=""
            onSubmit={e => {
              e.preventDefault()
              validation2.handleSubmit()
              return false
            }}
            action="#"
          >
            <div className="mb-3">
              <Label className="font-size-12 mb-1 pb-0">Subject</Label>
              <Select
                // isMulti={true}
                options={SessionStudentSubject}
                classNamePrefix="select2-selection"
                id="activity_subject"
                onBlur={validation2.handleBlur}
                name="activity_subject"
                value={SessionStudentSubject.find(
                  option => option.value === validation2.values.activity_subject
                )}
                onChange={selectedOption => {
                  const event = {
                    target: {
                      name: "activity_subject",
                      value: selectedOption["value"],
                    },
                  }
                  validation2.handleChange(event)
                  const isNextSessionAvailable =
                    NextSessionData &&
                    NextSessionData.next_session_date &&
                    NextSessionData.next_session_date !== ""

                  if (isNextSessionAvailable) {
                    fetchSessionStudentNextActivityData(
                      validation2.values.pk_student_key,
                      validation2.values.activity_method,
                      NextSessionData.next_session,
                      selectedOption["value"]
                    )
                  } else {
                    toast.error("No session available for this student.", {
                      autoClose: 3000, // 3 seconds in this example
                    })
                  }
                  setSessionStudentNextActivity([])
                  setSessiosNextActivity([])
                }}
                className={
                  validation2.touched.activity_subject &&
                  validation2.errors.activity_subject
                    ? "is-invalid cs-style"
                    : "cs-style"
                }
              />
              {validation2.touched.activity_subject &&
              validation2.errors.activity_subject ? (
                <FormFeedback type="invalid">
                  {validation2.errors.activity_subject}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="font-size-12 mb-1 pb-0">Activity Method</Label>
              <Select
                // isMulti={true}
                options={optionPendingActivityType}
                classNamePrefix="select2-selection"
                id="activity_method"
                onBlur={validation2.handleBlur}
                name="activity_method"
                value={optionPendingActivityType.find(
                  option => option.value == validation2.values.activity_method
                )}
                onChange={selectedOption => {
                  // console.log(selectedOption)
                  const event = {
                    target: {
                      name: "activity_method",
                      value: selectedOption["value"],
                    },
                  }
                  validation2.handleChange(event)

                  const isNextSessionAvailable =
                    NextSessionData &&
                    NextSessionData.next_session_date &&
                    NextSessionData.next_session_date !== ""

                  if (isNextSessionAvailable) {
                    fetchSessionStudentNextActivityData(
                      validation2.values.pk_student_key,
                      selectedOption["value"],
                      NextSessionData.next_session,
                      validation2.values.activity_subject
                    )
                  } else {
                    toast.error("No session available for this student.", {
                      autoClose: 3000, // 3 seconds in this example
                    })
                  }

                  setSessionStudentNextActivity([])
                  setSessiosNextActivity([])
                }}
                className={
                  validation2.touched.activity_method &&
                  validation2.errors.activity_method
                    ? "is-invalid cs-style"
                    : "cs-style"
                }
              />
              {validation2.touched.activity_method &&
              validation2.errors.activity_method ? (
                <FormFeedback type="invalid">
                  {validation2.errors.activity_method}
                </FormFeedback>
              ) : null}
            </div>
            {validation2.values.pk_student_key &&
              (NextSessionData && NextSessionData.next_session_date ? (
                <div className="mb-3">
                  <Label className="font-size-12 mb-1 pb-0">
                    Session: {NextSessionData.sest_name} (
                    {moment(
                      NextSessionData.next_session_date,
                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                    ).format("DD MMM YYYY")}
                    ) (Total Activity :{" "}
                    {NextSessionData && NextSessionData.activity_count
                      ? NextSessionData.activity_count
                      : 0}
                    )
                  </Label>
                </div>
              ) : (
                <div className="alert alert-info">
                  No session available for this student.
                </div>
              ))}

            <div className="mb-3">
              <div className="activities-container">
                <Loader
                  show={ActivityNextLoading}
                  type="inline"
                  spinnerSize="20px"
                  spinnerStyle={{
                    secondary: "#2D866D",
                  }}
                />
                {!validation1.values.pk_student_key ? (
                  <div className="alert alert-warning">
                    Student Key Not founded
                  </div>
                ) : SessionStudentNextActivity &&
                  SessionStudentNextActivity.length > 0 &&
                  !ActivityNextLoading ? (
                  // Check if there are no activities in SessiosNextActivity
                  SessiosNextActivity.length === 0 ? (
                    <p>
                      <strong>
                        {" "}
                        Automation not found any activities{" "}
                        <small>
                          Because no activity has been assigned, or topic-based
                          wise any activity has been not completed in past
                          session, or session maximum 20 activitys reached
                        </small>
                      </strong>
                    </p> // Display message if SessiosNextActivity is empty
                  ) : (
                    <>
                      <p className="mb-3">
                        <strong>
                          Automation found below activities:{" "}
                          <small>
                            Note: The activities for each past session is based
                            on the initial topic. Ensure all pendings activities
                            are completed before proceeding to the get next
                            activities and also check session available
                            space for assign activities.{" "}
                          </small>
                        </strong>
                      </p>
                      {Object.keys(groupedByLevel).map(levelId => {
                        const activitiesForLevel = groupedByLevel[levelId]

                        // Find the correct `activity_level_data` using the current `levelId`
                        const levelData =
                          activitiesForLevel[0]?.activity_level_data
                        const subjectData =
                          activitiesForLevel[0]?.activity_subject
                        return (
                          <div
                            key={`activitiesData-${levelId}`}
                            className="mb-3"
                          >
                            <Label className="font-size-12 mb-1 pb-0">
                              <strong>
                                {levelData} ({subjectData})
                              </strong>
                            </Label>
                            <div className="form-check">
                              {activitiesForLevel.length > 0 ? (
                                activitiesForLevel.map((activity, index) => (
                                  <Label
                                    className="font-size-12 mb-1 pb-0"
                                    key={`activity-${activity.id}`}
                                  >
                                    <strong>
                                      {activity.activity_topic_data}
                                    </strong>
                                    : {activity.name}
                                  </Label>
                                ))
                              ) : (
                                <p>Automation did not find any activities.</p>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )
                ) : null}
              </div>
            </div>
            <div className="text-end">
              <div className="btn-group">
                {!ActivityNextLoading &&
                SessiosNextActivity.length > 0 &&
                NextSessionData.next_session ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={isSubmitting}
                    //onClick={()=>{OnSubmitActivityData(NextSessionData.next_session, validation1.values.pk_student_key, validation1.values.activity_method)}}
                    onClick={() => {
                      OnSubmitNavActivityAssign(
                        NextSessionData.next_session,
                        validation2.values.pk_student_key,
                        validation2.values.activity_method
                      )
                    }}
                  >
                    Move
                  </button>
                ) : null}
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      {/* popup for incomplete activity  end*/}
      {/* popup for activity delete start*/}
      <Modal
        isOpen={modal_delete_activity}
        toggle={tog_delete_activity}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">Are you sure you want to delete?</h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isDASubmitting}
              onClick={onDelectActivity}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_delete_activity}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity delete  end*/}
      {/* popup for activity Move start*/}
      <Modal
        isOpen={modal_move_activity}
        toggle={tog_move_activity}
        size="lg"
        centered
      >
        <ModalHeader
          className="mt-0 w-100"
          close={
            moveingSessionKey.length > 0 ? (
              <button
                className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3"
                onClick={() => {
                  onMoveingActivityPopup()
                }}
              >
                Submit
              </button>
            ) : (
              ""
            )
          }
        >
          <label>Choose session</label>
        </ModalHeader>
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div>
            <TableComm
              // loading={loading}
              columns={columns_session}
              defaultId={"ses_date"}
              data={UpcomingSessionData}
              //onRowClick={handleStudentViewClick}
              //hiddenColumns={['ses_is_completed']}
            />
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity move  end*/}
      {/* popup for activity Roll over to homework start*/}
      <Modal isOpen={modal_moveing_conform} toggle={tog_moveing_popup} centered>
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              Are you sure you want to Roll over to next session(s)?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMASubmitting}
              onClick={() => onMoveActivityUpcomingSession()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_moveing_popup}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity Roll over to homework  end*/}
      {/* popup for activity Roll over to homework start*/}
      <Modal
        isOpen={modal_move_home_activity}
        toggle={tog_move_home_activity}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              Are you sure you want to Roll over to same session homework?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMAHSubmitting}
              onClick={() => onMoveActivityHomework()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_move_home_activity}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity Roll over to homework  end*/}
      {/* popup for multiple activity Move start*/}
      {/* <Modal
        isOpen={show_modal_move_multiple_activity}
        toggle={tog_move_multiple_activity}
        size="lg"
        centered
      >
       <ModalHeader className="mt-0 w-100" close={ moveingMultipleSessionKey.length > 0 ? (<button className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3" onClick={()=> {onMoveingMultipleActivityPopup();}}>Submit</button>) : ('')}>
          <label>Choose session</label>
        </ModalHeader>
        <ModalBody className="text-center">
          <div>
          <TableComm
            // loading={loading}
            columns={columns_multi_session}
            defaultId={"ses_date"}
            data={MultipleUpcomingSessionData}
          //onRowClick={handleStudentViewClick}
          //hiddenColumns={['ses_is_completed']}
          />
          </div>
         
        </ModalBody>
      </Modal> */}
      <Modal
        isOpen={show_modal_move_multiple_activity}
        toggle={tog_move_multiple_activity}
        size="lg"
        centered
      >
        <ModalHeader
          className="mt-0 w-100"
          close={
            moveingMultipleSessionKey.length > 0 ? (
              <button
                className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3"
                onClick={() => {
                  onMoveingMultipleActivityPopup()
                }}
              >
                Submit
              </button>
            ) : (
              ""
            )
          }
        >
          {/* Tab Navigation */}

          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${
                  rollOveractiveTab === "lessonPlan" ? "active" : ""
                }`}
                id="lessonPlan-tab"
                data-bs-toggle="tab"
                role="tab"
                // onClick={() => {
                //   setrollOverActiveTab("lessonPlan")
                //   setmoveingMultipleSessionKey([])
                // }}
                onClick={() => handlerollOverTabChange("lessonPlan")}
              >
                Roll over to lesson plan
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${
                  rollOveractiveTab === "homework" ? "active" : ""
                }`}
                id="homework-tab"
                data-bs-toggle="tab"
                role="tab"
                // onClick={() => {
                //   setrollOverActiveTab("homework")
                //   setmoveingMultipleSessionKey([])
                // }}
                onClick={() => handlerollOverTabChange("homework")}
              >
                Roll over to home work
              </a>
            </li>
          </ul>
        </ModalHeader>

        <ModalBody className="text-center">
          <div>
            {/* Tab Content */}
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  rollOveractiveTab === "lessonPlan" ? "show active" : ""
                }`}
                // id="lessonPlan"
                role="tabpanel"
              >
                <label>Choose session</label>
                {/* Content for the Lesson Plan Tab */}
                <TableComm
                  columns={columns_multi_session}
                  defaultId={"ses_date"}
                  data={MultipleUpcomingSessionData}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  rollOveractiveTab === "homework" ? "show active" : ""
                }`}
                // id="homework"
                role="tabpanel"
              >
                <p>
                  Click submit to roll over all activities to the same session
                  home work
                </p>
                <button
                  className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3"
                  onClick={() => {
                    onMoveingMultipleActivityHomePopup()
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for multiple activity move  end*/}
      <Modal
        isOpen={modal_move_home_all_activity}
        toggle={tog_move_home_all_activity}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              Are you sure you want to Roll over activities to same session
              homework?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMAHmSubmitting}
              onClick={() => onMoveAllActivityHomework()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_move_home_all_activity}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity Roll over to lession or homewor start*/}
      <Modal
        isOpen={modal_multiple_moveing_conform}
        toggle={tog_multiple_moveing_popup}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              Are you sure you want to Roll over to next session(s)?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMulMoningSubmitting}
              onClick={() => onMoveMultipleActivityUpcomingSession()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_multiple_moveing_popup}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity Roll over to homework  end*/}

      {/* lesson activity report start */}
      <Modal isOpen={modal_lesson_report} toggle={tog_lesson_report} centered>
        <ModalHeader className="mt-0" toggle={tog_lesson_report}>
          View Lesson Report
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>Topic</th>
                  <th>Questions Completed</th>
                  <th>Mastery Score</th>
                </tr>
              </thead>

              <tbody>
                {LessonReportData.length > 0 ? (
                  LessonReportData.map(topic => (
                    <tr key={topic.activity_node[0].node_name}>
                      <th scope="row">{topic.activity_node[0].node_name}</th>
                      <td>{topic.question_attended}</td>
                      <td>{topic.masteryscore}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <th colSpan="3">No data</th>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
      {/* lesson activity report end */}
      {/* homework activity report start */}
      <Modal
        isOpen={modal_home_work_report}
        toggle={tog_home_work_report}
        centered
      >
        <ModalHeader className="mt-0" toggle={tog_home_work_report}>
          View Home Work Report
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>Topic</th>
                  <th>Questions Completed</th>
                  <th>Mastery Score</th>
                </tr>
              </thead>
              <tbody>
                {HomeworkReportData.length > 0 ? (
                  HomeworkReportData.map(topic => (
                    <tr key={topic.activity_node[0].node_name}>
                      <th scope="row">{topic.activity_node[0].node_name}</th>
                      <td>{topic.question_attended}</td>
                      <td>{topic.masteryscore}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <th colSpan="3">No data</th>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
      {/* Home work activity report end */}
      {/* Assessment activity list start */}
      <Modal
        isOpen={modal_assessment}
        toggle={tog_Assessment}
        centered
        className="mastery-modal1"
      >
        <ModalHeader className="mt-0 text-dark" toggle={tog_Assessment}>
          Assessment activities
        </ModalHeader>
        <ModalBody className="d-flex">
          <div className="flex-grow-1" style={{ paddingRight: "20px" }}>
            <Loader
              ref={loaderRef}
              show={InnerLoder}
              type="inline"
              spinnerSize="20px"
              spinnerStyle={{ secondary: "#2D866D" }}
            />

            {!InnerLoder &&
            assignActivityLevelData?.activities?.length > 0 &&
            currentTopic ? (
              <div>
                <div className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label pt-0 font-size-12 mb-1 pb-0"
                  >
                    Year Level Name:{" "}
                    {assignActivityLevelData.activities[0].level_name || ""}
                  </label>
                </div>
                <span className="bg-white font-size-14 fw-semibold">
                  Topic Name: {currentTopic.topic_name}
                  <span className="score-float">
                    Mastery Score:{" "}
                    {currentTopic.TopicActivities &&
                    currentTopic.TopicActivities.filter(
                      item => parseInt(item.lastTwoWeekExist) > 0
                    ).length > 0
                      ? (
                          currentTopic.TopicActivities.filter(
                            item => parseInt(item.lastTwoWeekExist) > 0
                          )
                            .map(data => data.mastryScore)
                            .reduce((sum, score) => sum + parseInt(score), 0) /
                          currentTopic.TopicActivities.filter(
                            item => parseInt(item.lastTwoWeekExist) > 0
                          ).length
                        ).toFixed(0)
                      : "0"}
                  </span>
                </span>

                <div className="table-responsive mt-2">
                  <Table className="table mb-0">
                    <tbody>
                      {currentTopic.TopicActivities.map(
                        (topicTitle, topicTitleIndex) => (
                          <tr key={topicTitleIndex}>
                            <th
                              scope="row"
                              width="400"
                              style={{
                                backgroundColor:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "#f0f8ff"
                                    : "transparent",
                                fontWeight:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {topicTitle.node_name}
                            </th>
                            <td
                              width="230"
                              style={{
                                backgroundColor:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "#f0f8ff"
                                    : "transparent",
                                fontWeight:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {topicTitle.lastTwoWeekExist &&
                              parseInt(topicTitle.lastTwoWeekExist) > 0 ? (
                                <div
                                  className="status-ac attempted"
                                  onClick={() =>
                                    activityAttemptedStatus(topicTitle)
                                  }
                                >
                                  ATTEMPTED
                                </div>
                              ) : (
                                <div className="status-ac unattempted">
                                  UNATTEMPTED
                                </div>
                              )}
                            </td>
                            <td
                              width="100"
                              style={{
                                backgroundColor:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "#f0f8ff"
                                    : "transparent",
                                fontWeight:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {topicTitle.lastTwoWeekExist &&
                              parseInt(topicTitle.lastTwoWeekExist)
                                ? topicTitle.mastryScore
                                : "-"}
                            </td>
                            <td
                              width="200"
                              className="text-end"
                              style={{
                                backgroundColor:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "#f0f8ff"
                                    : "transparent",
                                fontWeight:
                                  topicTitle.activity_node_id ===
                                  assignActivityLevelData.activities[0]
                                    .activity_node_id
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              <div className="btn-group">
                                <Button
                                  color="danger"
                                  className={
                                    topicTitle.data_exists == 1
                                      ? "selector-display-parttype-2"
                                      : ""
                                  }
                                  disabled={
                                    (Array.isArray(existingData) &&
                                      existingData.length > 0 &&
                                      existingData.some(
                                        item =>
                                          item.id ===
                                          topicTitle.activity_node_id
                                      ) &&
                                      existingData.some(
                                        item => item.activity_type === 0
                                      )) ||
                                    topicTitle.data_exists == 1
                                    // topicTitle.data_exists == 1 || parseInt(topicTitle.lastTwoWeekExist) > 0
                                  }
                                  onClick={() =>
                                    handleLocalStorageAssignClick(
                                      topicTitle,
                                      currentTopic,
                                      assignActivityLevelData.activities[0],
                                      0
                                    )
                                  }
                                >
                                  {topicTitle.data_exists == 1 ? (
                                    <>
                                      <i className="fa fa-check green-check"></i>{" "}
                                      {" Selected"}
                                    </>
                                  ) : (
                                    "Select"
                                  )}
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    color="secondary"
                    onClick={handlePreviousTopic}
                    disabled={currentTopicIndex === 0}
                  >
                    Previous Topic
                  </Button>
                  <Button
                    color="secondary"
                    onClick={handleNextTopic}
                    disabled={
                      currentTopicIndex ===
                      assignActivityLevelData.activities[0].LevelTopics.length -
                        1
                    }
                  >
                    Next Topic
                  </Button>
                </div>
              </div>
            ) : (
              !InnerLoder && (
                <p className="text-center p-2 text-dark fw-500">
                  No topics found for this activity
                </p>
              )
            )}
          </div>

          {/* Selected Activities */}
          {Array.isArray(existingData) && existingData.length > 0 && (
            <div className="fo-right" style={{ width: "300px" }}>
              <Card className="border-top">
                <CardBody
                  className=""
                  style={{ padding: "12px", paddingTop: "0" }}
                >
                  <h6
                    className="mb-12 font-size-16 fw-500 text-dark"
                    style={{ paddingTop: "36px" }}
                  >
                    Selected Activities <span>({existingData.length})</span>{" "}
                  </h6>
                  <div className="activities-cts overflow-auto">
                    {existingData.map(Assessmentlistdata => (
                      <div
                        className="bg-supermild position-relative"
                        style={{ padding: "16px" }}
                        key={Assessmentlistdata.id}
                      >
                        <div className="close-icon">
                          <i
                            className="mdi mdi-close-thick"
                            onClick={() =>
                              removeLocalStorageAssignItem(
                                Assessmentlistdata.id
                              )
                            }
                          ></i>
                        </div>
                        <h6 className="two_line-ellipse font-size-15 fw-700 text-dark w-90">
                          {Assessmentlistdata.level_name &&
                          Assessmentlistdata.level_name != ""
                            ? Assessmentlistdata.level_name + " - "
                            : null}
                          {Assessmentlistdata.topic_name &&
                          Assessmentlistdata.topic_name != ""
                            ? Assessmentlistdata.topic_name + " - "
                            : null}
                          {Assessmentlistdata.name}
                        </h6>
                        <div className="act-info mt-3">
                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-2">
                            <li className="font-size-15">
                              <img className="" width={10} src={reel} alt="" />{" "}
                              {Assessmentlistdata.type}
                            </li>
                            <span className="circle"></span>
                            <li className="font-size-15">
                              {Assessmentlistdata.duration}
                            </li>
                            <span className="circle"></span>
                            <li className="font-size-15">
                              <img
                                className=""
                                width={12}
                                src={awicon}
                                alt=""
                              />{" "}
                              {Assessmentlistdata.coin}
                            </li>
                          </ul>
                          <div className="comment-section mt-3">
                            <label
                              htmlFor={`comment-${Assessmentlistdata.id}`}
                              className="font-size-15"
                            >
                              Tutor Comment:
                            </label>
                            <textarea
                              id={`comment-${Assessmentlistdata.id}`}
                              className="form-control"
                              placeholder="Add your comment here"
                              value={Assessmentlistdata.tutor_comments || ""} // Display existing comment or empty string
                              onChange={e =>
                                handleCommentChange(
                                  Assessmentlistdata.id,
                                  e.target.value
                                )
                              }
                              rows="3"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="btn-group w-100 flex-column py-4">
                    <Button
                      className="w-100 btn-lg cst-danger"
                      color="danger"
                      disabled={existingData.length === 0}
                      onClick={OnSubmitActivityData}
                    >
                      Add Activities
                    </Button>
                    <Button
                      className="w-100 cb-secondary"
                      onClick={() => window.history.back()}
                    >
                      Go Back
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </ModalBody>
      </Modal>
      {/* Assessment activity list end */}

      <Modal
        isOpen={modal_diagnostics}
        toggle={tog_Diagnostics}
        centered
        className="mastery-modal1"
      >
        <ModalHeader className="mt-0 text-dark" toggle={tog_Diagnostics}>
          Diagnostics activities
        </ModalHeader>
        <ModalBody className="d-flex">
          <div className="flex-grow-1" style={{ paddingRight: "20px" }}>
            <Loader
              ref={loaderRef}
              show={InnerLoder}
              type="inline"
              spinnerSize="20px"
              spinnerStyle={{ secondary: "#2D866D" }}
            />
            {!InnerLoder &&
            assignDiagnosticsActivity.length > 0 &&
            currentDiagnosticsActivity ? (
              <div>
                <div className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-form-label pt-0 font-size-12 mb-1 pb-0"
                  >
                    Year Level Name:{" "}
                    {currentDiagnosticsActivity.level_name || ""}
                  </label>
                </div>
                {/* Render topics for current activity */}
                {!InnerLoder &&
                currentDiagnosticsActivity.topics &&
                currentDiagnosticsActivity.topics.length > 0 ? (
                  currentDiagnosticsActivity.topics.map((topic, index1) => (
                    <div key={index1} className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <th
                              scope="row"
                              width="600"
                              style={{
                                backgroundColor:
                                  topic.activity_master_topic
                                    .activity_topic_id ===
                                  currentDiagnosticslistActivity?.topics?.[
                                    currentDiagnosticslistActivity?.topics
                                      .length - 1
                                  ]?.[0]?.activity_topic_id
                                    ? "#f0f8ff"
                                    : "transparent",
                                fontWeight:
                                  topic.activity_master_topic
                                    .activity_topic_id ===
                                  currentDiagnosticslistActivity?.topics?.[
                                    currentDiagnosticslistActivity?.topics
                                      .length - 1
                                  ]?.[0]?.activity_topic_id
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {topic.activity_master_topic &&
                                topic.activity_master_topic.topic_name}
                            </th>
                            <td
                              width="380"
                              style={{
                                backgroundColor:
                                  topic.activity_master_topic
                                    .activity_topic_id ===
                                  currentDiagnosticslistActivity?.topics?.[
                                    currentDiagnosticslistActivity?.topics
                                      .length - 1
                                  ]?.[0]?.activity_topic_id
                                    ? "#f0f8ff"
                                    : "transparent",
                                fontWeight:
                                  topic.activity_master_topic
                                    .activity_topic_id ===
                                  currentDiagnosticslistActivity?.topics?.[
                                    currentDiagnosticslistActivity?.topics
                                      .length - 1
                                  ]?.[0]?.activity_topic_id
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {topic.activity_master_topic &&
                              topic.activity_master_topic.lastTwoWeekExist &&
                              parseInt(
                                topic.activity_master_topic.lastTwoWeekExist
                              ) > 0 ? (
                                <div
                                  className="status-ac attempted"
                                  onClick={() =>
                                    activityAttemptedStatus(
                                      topic.activity_master_topic
                                    )
                                  }
                                >
                                  ATTEMPTED
                                </div>
                              ) : (
                                <div className="status-ac unattempted">
                                  UNATTEMPTED
                                </div>
                              )}
                            </td>
                            <td
                              width="556"
                              className="text-end"
                              style={{
                                backgroundColor:
                                  topic.activity_master_topic
                                    .activity_topic_id ===
                                  currentDiagnosticslistActivity?.topics?.[
                                    currentDiagnosticslistActivity?.topics
                                      .length - 1
                                  ]?.[0]?.activity_topic_id
                                    ? "#f0f8ff"
                                    : "transparent",
                                fontWeight:
                                  topic.activity_master_topic
                                    .activity_topic_id ===
                                  currentDiagnosticslistActivity?.topics?.[
                                    currentDiagnosticslistActivity?.topics
                                      .length - 1
                                  ]?.[0]?.activity_topic_id
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              <div className="btn-group">
                                <Button
                                  color="danger"
                                  className={
                                    topic.activity_master_topic.data_exists ===
                                    1
                                      ? "selector-display-parttype-2 me-2"
                                      : "me-2"
                                  }
                                  disabled={
                                    (Array.isArray(existingData) &&
                                      existingData.length > 0 &&
                                      existingData.some(
                                        item =>
                                          item.topicID ===
                                          topic.activity_master_topic
                                            .activity_topic_id
                                      ) &&
                                      existingData.some(
                                        item => item.activity_type === 2
                                      )) ||
                                    topic.activity_master_topic.data_exists ===
                                      1
                                  }
                                  onClick={() =>
                                    handleLocalStorageAssignClick(
                                      topic,
                                      currentDiagnosticsActivity,
                                      [],
                                      2
                                    )
                                  }
                                >
                                  {topic.activity_master_topic.data_exists ===
                                  1 ? (
                                    <>
                                      <i className="fa fa-check green-check"></i>{" "}
                                      {" Selected"}
                                    </>
                                  ) : (
                                    "Select"
                                  )}
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))
                ) : (
                  <p className="text-center p-2 text-dark fw-500">
                    No topics found for this activity
                  </p>
                )}
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    color="secondary"
                    onClick={goToPreviousLevel}
                    disabled={currentLevelIndex === 0}
                  >
                    Previous Level
                  </Button>
                  <Button
                    color="secondary"
                    onClick={goToNextLevel}
                    disabled={
                      currentLevelIndex ===
                      assignDiagnosticsActivity[0].activities.length - 1
                    }
                  >
                    Next Level
                  </Button>
                </div>
              </div>
            ) : (
              !InnerLoder && (
                <p className="text-center p-2 text-dark fw-500">
                  No activities found
                </p>
              )
            )}
          </div>
          {Array.isArray(existingData) && existingData.length > 0 && (
            <div className="fo-right" style={{ width: "300px" }}>
              <Card className="border-top">
                <CardBody
                  className=""
                  style={{ padding: "12px", paddingTop: "0" }}
                >
                  <h6
                    className="mb-12 font-size-16 fw-500 text-dark"
                    style={{ paddingTop: "36px" }}
                  >
                    Selected Activities <span>({existingData.length})</span>{" "}
                  </h6>
                  <div className="activities-cts overflow-auto">
                    {existingData.map(Assessmentlistdata => (
                      <div
                        className="bg-supermild position-relative"
                        style={{ padding: "16px" }}
                        key={Assessmentlistdata.id}
                      >
                        <div className="close-icon">
                          <i
                            className="mdi mdi-close-thick"
                            onClick={() =>
                              removeLocalStorageAssignItem(
                                Assessmentlistdata.id
                              )
                            }
                          ></i>
                        </div>
                        <h6 className="two_line-ellipse font-size-15 fw-700 text-dark w-90">
                          {Assessmentlistdata.level_name &&
                          Assessmentlistdata.level_name != ""
                            ? Assessmentlistdata.level_name + " - "
                            : null}
                          {Assessmentlistdata.topic_name &&
                          Assessmentlistdata.topic_name != ""
                            ? Assessmentlistdata.topic_name + " - "
                            : null}
                          {Assessmentlistdata.name}
                        </h6>
                        <div className="act-info mt-3">
                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-2">
                            <li className="font-size-15">
                              <img className="" width={10} src={reel} alt="" />{" "}
                              {Assessmentlistdata.type}
                            </li>
                            <span className="circle"></span>
                            <li className="font-size-15">
                              {Assessmentlistdata.duration}
                            </li>
                            <span className="circle"></span>
                            <li className="font-size-15">
                              <img
                                className=""
                                width={12}
                                src={awicon}
                                alt=""
                              />{" "}
                              {Assessmentlistdata.coin}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="btn-group w-100 flex-column py-4">
                    <Button
                      className="w-100 btn-lg cst-danger"
                      color="danger"
                      disabled={existingData.length === 0}
                      onClick={OnSubmitActivityData}
                    >
                      Add Activities
                    </Button>
                    <Button
                      className="w-100 cb-secondary"
                      onClick={() => window.history.back()}
                    >
                      Go Back
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </ModalBody>
      </Modal>

      {/* command start */}
      <Modal isOpen={modal_commend} toggle={tog_commend} centered>
        <ModalHeader className="mt-0" toggle={tog_commend}>
          Comment
        </ModalHeader>
        <ModalBody>
          <div className="comment-section mt-3">
            <label className="font-size-15">Tutor Comment:</label>
            <textarea
              id={`commenTutor}`}
              className="form-control"
              placeholder="Add your comment here"
              value={(activityCommend && activityCommend.tutor_comments) || ""} // Display existing comment or empty string
              onChange={
                e => handleAddCommentChange(e.target.value)
                // handleCommentChange(
                //   Assessmentlistdata.id,
                //   e.target.value
                // )
              }
              rows="3"
            />
          </div>
          <div className="text-end mt-3">
            <div className="btn-group">
              <button
                className="btn bg-danger text-white  me-3 font-size-12"
                disabled={IsCommendSubmitting}
                onClick={() => {
                  onActivityCommend()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* commend end */}
      {/* Notes start */}
      <Modal isOpen={modal_tutorNote} toggle={tog_tutorNote} centered>
        <ModalHeader className="mt-0" toggle={tog_tutorNote}>
          Notes
        </ModalHeader>
        <ModalBody>
          <div className="comment-section mt-3">
            <label className="font-size-15">Tutor Notes:</label>
            <textarea
              id={`notesTutor}`}
              className="form-control"
              placeholder="Add your notes here"
              value={tutorNote || ""} // Display existing comment or empty string
              onChange={
                e => handleAddNoteChange(e.target.value)
                // handleCommentChange(
                //   Assessmentlistdata.id,
                //   e.target.value
                // )
              }
              rows="3"
            />
          </div>
          <div className="text-end mt-3">
            <div className="btn-group">
              <button
                className="btn bg-danger text-white  me-3 font-size-12"
                disabled={IsTutorNoteSubmitting}
                onClick={() => {
                  onTutorNote()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* commend end */}

      <div className="page-content bg-mild-purple">
        <Container fluid>
          <div className="page-title-box">
            <div className="custom-pg_header flex-wrap gap-2 fo-pd">
              <div>
                <div className="d-flex align-items-center">
                  <div
                    className="short-ic"
                    onClick={() => window.history.back()}
                  >
                    <img className="bc-bg2 mr-30" src={chevleft} />
                  </div>

                  <div>
                    <h6 className="page-title inner-pg-title mb-0">
                      {" "}
                      Create Session Plan
                    </h6>
                    <p className="mb-0 font-size-15 mt-02 fs-sans-s">
                      {sessionStudentData &&
                      sessionStudentData.session &&
                      sessionStudentData.session.tbl_session_time &&
                      sessionStudentData.session.tbl_session_time.sest_type
                        ? sessionStudentData.session.tbl_session_time.sest_type
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center flex-wrap gap-2">
                <div
                  className="me-2 d-flex align-items-center aw-button"
                  color=""
                  onClick={tog_center4}
                >
                  Auto Assign Next Activity
                </div>
                <div
                  className="me-2 d-flex align-items-center aw-button"
                  color=""
                  onClick={tog_center3}
                >
                  Move Incomplete Activity
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between pd-10 bg-white mb-10 mt-10">
            <div className="d-flex align-items-center">
              <div className="mr-15">
                <h6 class="mb-0 font-size-14 text-dark fw-700">
                  {sessionStudentData &&
                  sessionStudentData.session &&
                  sessionStudentData.session.tbl_session_time &&
                  sessionStudentData.session.tbl_session_time.sest_name
                    ? sessionStudentData.session.tbl_session_time.sest_name
                    : "-"}{" "}
                  {sessionStudentData?.session?.ses_is_flexi == 1 && (
                    <span className="badge tb-danger font-size-10 text-uppercase cp-bdg">
                      Flexi
                    </span>
                  )}
                </h6>

                <p class="mb-0 font-size-12 fs-sans-s">
                  {sessionStudentData &&
                  sessionStudentData.session &&
                  sessionStudentData.session.tbl_session_time &&
                  sessionStudentData.session.tbl_session_time.sest_type
                    ? sessionStudentData.session.tbl_session_time.sest_type
                    : "-"}
                </p>
              </div>
              <div className="" style={{ minWidth: "250px" }}>
                <Select
                  // isMulti={true}
                  options={upcommingSessionArrayOption}
                  classNamePrefix="select2-selection-bold"
                  id="sessioOption"
                  // onBlur={validation1.handleBlur}
                  name="sessioOption"
                  value={upcommingSessionArrayOption.find(
                    option => option.value == session_key
                  )}
                  onChange={selectedOption => {
                    console.log(selectedOption)
                    handleLinkClick(
                      student_key,
                      selectedOption["value"],
                      subject_ID
                    )
                  }}
                  className={"cs-style"}
                />
                {/* <h6 class="mb-0 font-size-12 text-dark fw-700">
                  {sessionStudentData &&
                  sessionStudentData.session &&
                  sessionStudentData.session.ses_date
                    ? moment(
                        sessionStudentData.session.ses_date,
                        moment.ISO_8601
                      ).format("DD MMM YYYY (ddd)")
                    : "-"}
                </h6>
                <p class="mb-0 font-size-12 fs-sans-s">
                  {sessionStudentData &&
                  sessionStudentData.session &&
                  sessionStudentData.session.ses_start_time
                    ? moment(
                        sessionStudentData.session.ses_start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")
                    : "-"}{" "}
                  -{" "}
                  {sessionStudentData &&
                  sessionStudentData.session &&
                  sessionStudentData.session.ses_end_time
                    ? moment(
                        sessionStudentData.session.ses_end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")
                    : "-"}
                </p> */}
              </div>
              <div className="text-end" style={{ minWidth: "250px" }}>
                <h6 class="mb-0 font-size-12 text-dark fw-700">
                  {sessionStudentData && sessionStudentData != ""
                    ? getUniqueSubjectNames(sessionStudentData).join(" & ") ||
                      "-"
                    : "-"}
                </h6>
                <p class="mb-0 font-size-12 fs-sans-s"></p>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div>
                <HoverCard.Root>
                  <HoverCard.Trigger asChild>
                    <img
                      width={45}
                      height={45}
                      className="rounded-circle obj-fit-cover me-10"
                      src={st2}
                      alt=""
                      onClick={() =>
                        handle_tutorNote(
                          sessionStudentData?.session?.tutor_notes || ""
                        )
                      }
                    />
                  </HoverCard.Trigger>
                  <HoverCard.Portal>
                    <HoverCard.Content
                      className="HoverCardContent"
                      sideOffset={5}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 7,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 15,
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <h5>Notes</h5>
                            <div
                              onClick={() =>
                                handle_tutorNote(
                                  sessionStudentData?.session?.tutor_notes || ""
                                )
                              }
                            >
                              <i className="fas fa-pen"></i>
                            </div>
                          </div>
                          <div className="Text">
                            {sessionStudentData?.session?.tutor_notes ??
                              "No notes available"}
                          </div>
                          {/* <div style={{ display: "flex", gap: 15 }}>
                          <div style={{ display: "flex", gap: 5 }}>
                            <div className="Text bold">0</div>{" "}
                            <div className="Text faded">Following</div>
                          </div>
                          <div style={{ display: "flex", gap: 5 }}>
                            <div className="Text bold">2,900</div>{" "}
                            <div className="Text faded">Followers</div>
                          </div>
                        </div> */}
                        </div>
                      </div>

                      <HoverCard.Arrow className="HoverCardArrow" />
                    </HoverCard.Content>
                  </HoverCard.Portal>
                </HoverCard.Root>
              </div>
              <div className="w-100" style={{ minWidth: "150px" }}>
                {/* { sessionStudentData.session &&
                  sessionStudentData.session.tbl_session_time &&
                  sessionStudentData.session.tbl_session_time.tbl_student_enrolment ? console.log(sessionStudentData.session.tbl_session_time.tbl_student_enrolment) : ''} */}
                <Select
                  // isMulti={true}
                  options={studentArrayOption}
                  className="cs-style"
                  classNamePrefix="select2-selection-bold"
                  id="studentOption"
                  // onBlur={validation1.handleBlur}
                  name="studentOption"
                  value={studentArrayOption.find(
                    option => option.value == student_key
                  )}
                  onChange={selectedOption => {
                    console.log(selectedOption)
                    handleLinkClick(
                      selectedOption["value"],
                      session_key,
                      selectedOption["subject_id"]
                    )
                  }}
                />
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#333",
                    textAlign: "center", // Center the text inside the span
                  }}
                >
                  {
                    studentArrayOption.find(
                      option => option.value == student_key
                    )?.level_name
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="ehp-height mt-20">
            <div className="kanban-wrapper d-flex overflow-auto">
              <div className="kb-child ehp-ch_height ehp-w p-0 me-10 border-0">
                <div
                  className="ss-details border-bottom1 pd-10 bg-white p-2 mb-10 state-bg"
                  style={{ backgroundColor: "#fafbfd" }}
                >
                  <div className="d-flex mb-1">
                    <h5 className="mb-0 text-dark font-size-16 fw-700">
                      {PreNextType && PreNextType === 1
                        ? "Past session"
                        : PreNextType && PreNextType === 2
                        ? "Next Session"
                        : null}
                    </h5>
                    {PreNextType && PreNextType === 1 ? (
                      <p className="bc-bg2 mr-30 text-center ms-2">
                        <strong>{CurrentPreviousSessioncount}</strong>
                      </p>
                    ) : PreNextType && PreNextType === 2 ? null : null}
                  </div>
                  <div className="navigator d-flex align-items-center justify-content-between">
                    {/* <div></div> */}
                    {(PreNextType && PreNextType === 2) ||
                    PreviousSessioncount > 0 ? (
                      <span
                        className="bg-white p-1 px-2 rounded-4 pe-auto"
                        style={{ cursor: "pointer" }}
                        onClick={trigger_previous}
                      >
                        <i className="mdi mdi-chevron-left"></i>
                      </span>
                    ) : null}
                    <div className="font-size-16 fw-400 text-dark text-center opacity-75 ms-2">
                      {sessionPreNextData &&
                      sessionPreNextData.session &&
                      sessionPreNextData.session.ses_date
                        ? moment(
                            sessionPreNextData.session.ses_date,
                            moment.ISO_8601
                          ).format("DD MMM YYYY")
                        : "No data"}
                    </div>
                    <div className="d-flex font-size-16 fw-400 text-dark text-center opacity-75 ms-2">
                      {sessionPreNextData?.session?.ses_date &&
                      moment(sessionPreNextData.session.ses_date)
                        .tz(localStorage.getItem("timezone") || "Europe/London")
                        .add(
                          parseInt(localStorage.getItem("flexiDays")) + 1,
                          "days"
                        ) >=
                        moment().tz(
                          localStorage.getItem("timezone") || "Europe/London"
                        ) &&
                      moment(sessionPreNextData.session.ses_date).tz(
                        localStorage.getItem("timezone") || "Europe/London"
                      ) <=
                        moment().tz(
                          localStorage.getItem("timezone") || "Europe/London"
                        ) ? (
                        <div className="switch-wrapper">
                          Flexi
                          <div className="sw-child">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#ec4561"
                              onChange={() => {
                                setSwitchFlexi(!SwitchFlexi),
                                  onFlexiSession(
                                    sessionPreNextData?.session?.pk_ses_key,
                                    !SwitchFlexi ? 1 : 0
                                  )
                              }}
                              checked={SwitchFlexi}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {CurrentPreviousSessioncount > 1 ? (
                      <span
                        className="bg-white p-1 px-2 rounded-4 pe-auto"
                        style={{ cursor: "pointer" }}
                        onClick={trigger_next_previous}
                      >
                        <i className="mdi mdi-chevron-right"></i>
                      </span>
                    ) : null}

                    {PreNextType &&
                    PreNextType === 1 &&
                    CurrentPreviousSessioncount === 1 ? (
                      <span
                        className="bg-white p-1 px-2 rounded-4 pe-auto"
                        style={{ cursor: "pointer" }}
                        onClick={trigger_next}
                      >
                        <i className="mdi mdi-chevron-right"></i>
                      </span>
                    ) : null}
                  </div>

                  <div className="d-flex justify-content-between align-items-end">
                    <div className="align-items-center gap-2 flex-wrap ">
                      {sessionPreNextData &&
                      sessionPreNextData.session &&
                      sessionPreNextData.session.tbl_session_time &&
                      sessionPreNextData.session.tbl_session_time
                        .tbl_student_enrolment &&
                      sessionPreNextData.session.tbl_session_time
                        .tbl_student_enrolment[0] &&
                      sessionPreNextData.session.tbl_session_time
                        .tbl_student_enrolment[0].tbl_session_attendance &&
                      sessionPreNextData.session.tbl_session_time
                        .tbl_student_enrolment[0].tbl_session_attendance
                        .attendance_status != null ? (
                        sessionPreNextData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_session_attendance
                          .attendance_status === 0 ? (
                          <span class="badge tb-primary font-size-10 text-uppercase cp-bdg">
                            pending
                          </span>
                        ) : sessionPreNextData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status === 1 ? (
                          <span class="badge tb-warning font-size-10 text-uppercase cp-bdg">
                            Ongoing
                          </span>
                        ) : sessionPreNextData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status === 2 ? (
                          <span class="badge tb-success font-size-10 text-uppercase cp-bdg">
                            Attended
                          </span>
                        ) : sessionPreNextData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status === 3 ? (
                          <span class="badge tb-danger font-size-10 text-uppercase cp-bdg">
                            Missed
                          </span>
                        ) : sessionPreNextData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status === 5 ? (
                          <span class="badge tb-warning font-size-10 text-uppercase cp-bdg">
                            Deferment Requested
                          </span>
                        ) : sessionPreNextData.session.tbl_session_time
                            .tbl_student_enrolment[0].tbl_session_attendance
                            .attendance_status === 6 ? (
                          <span class="badge tb-danger font-size-10 text-uppercase cp-bdg">
                            Cancelled
                          </span>
                        ) : null
                      ) : null}

                      <h6 className="mb-0 font-size-14 fw-700 text-dark">
                        {sessionPreNextData &&
                        sessionPreNextData.session &&
                        sessionPreNextData.session.tbl_session_time &&
                        sessionPreNextData.session.tbl_session_time.sest_name
                          ? sessionPreNextData.session.tbl_session_time
                              .sest_name
                          : null}{" "}
                        {sessionPreNextData?.session?.ses_is_flexi == 1 && (
                          <span className="badge tb-danger font-size-10 text-uppercase cp-bdg">
                            Flexi
                          </span>
                        )}
                      </h6>

                      <p class="mb-0 fs-sans-s font-size-12">
                        {sessionPreNextData &&
                        sessionPreNextData.session &&
                        sessionPreNextData.session.sub_name
                          ? sessionPreNextData.session.sub_name
                          : ""}
                      </p>
                      <p className="mb-0 fs-sans-s font-size-12">
                        {sessionPreNextData &&
                        sessionPreNextData.session &&
                        sessionPreNextData.session.tbl_session_time &&
                        sessionPreNextData.session.tbl_session_time.sest_type
                          ? sessionPreNextData.session.tbl_session_time
                              .sest_type
                          : null}
                      </p>
                    </div>
                    <div className="text-end">
                      <h6 className="mb-0 fs-sans-s fw-700 font-size-12">
                        {sessionPreNextData &&
                        sessionPreNextData.session &&
                        sessionPreNextData.session.ses_date
                          ? moment(
                              sessionPreNextData.session.ses_date,
                              moment.ISO_8601
                            ).format("DD MMM YYYY (ddd)")
                          : null}
                      </h6>
                      <p className="mb-0 fs-sans-s font-size-12">
                        {sessionPreNextData &&
                        sessionPreNextData.session &&
                        sessionPreNextData.session.ses_start_time
                          ? moment(
                              sessionPreNextData.session.ses_start_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")
                          : null}{" "}
                        -{" "}
                        {sessionPreNextData &&
                        sessionPreNextData.session &&
                        sessionPreNextData.session.ses_end_time
                          ? moment(
                              sessionPreNextData.session.ses_end_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")
                          : null}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="lesson-plan-wrapper bg-white p-2">
                  {/* LESSON HEADER START */}

                  {sessionPreNextData &&
                    sessionPreNextData.session &&
                    sessionPreNextData.session.tbl_session_time &&
                    sessionPreNextData.session.tbl_session_time.tbl_student_enrolment.map(
                      (enrolment, index) => {
                        //console.log(enrolment);

                        return (
                          <div className="section-divider">
                            <div className="border-bottom pb-3 mb-2">
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-0 text-dark font-size-16 fw-700">
                                  Lesson Plan
                                </h6>
                                <div className="d-flex justify-content-end me-3">
                                  {sessionPreNextData &&
                                  sessionPreNextData.session &&
                                  sessionPreNextData.session.ses_is_completed &&
                                  parseInt(
                                    sessionPreNextData.session.ses_is_completed
                                  ) === 1 &&
                                  selectedActivityKeys &&
                                  selectedActivityKeys.length > 0 ? (
                                    <button
                                      className="btn bg-danger text-white  me-3 font-size-12"
                                      onClick={() => {
                                        fetchMultipleUpcommingSessionData(
                                          enrolment.fk_stu_key,
                                          enrolment.tbl_enrolment_subject
                                        )
                                      }}
                                    >
                                      Roll over
                                    </button>
                                  ) : null}
                                  {sessionPreNextData &&
                                  sessionPreNextData.session &&
                                  sessionPreNextData.session.ses_is_completed &&
                                  parseInt(
                                    sessionPreNextData.session.ses_is_completed
                                  ) === 1 &&
                                  enrolment.tbl_student &&
                                  enrolment.tbl_student.Lesson &&
                                  enrolment.tbl_student.Lesson.LessonActivities
                                    .length > 0 ? (
                                    <input
                                      type="checkbox"
                                      checked={selectAllChecked}
                                      onChange={() =>
                                        handleSelectAllChange(
                                          enrolment.tbl_student.Lesson
                                            .LessonActivities
                                        )
                                      }
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between list-unstyled mb-0">
                              <div className="d-flex align-items-center font-size-16 fw-400 text-dark">
                                {" "}
                                <img
                                  className="me-10"
                                  width={24}
                                  src={awicon}
                                  alt=""
                                />
                                {CountLessonTotalCoins(enrolment) || null}{" "}
                              </div>
                              <div className="d-flex align-items-center font-size-16 fw-400 text-dark">
                                <span>
                                  {" "}
                                  <img
                                    className="me-10"
                                    width={24}
                                    src={duration}
                                    alt=""
                                  />
                                </span>
                                {CountLessonHours(enrolment) || null}
                              </div>
                            </div>

                            {CountLessonTotalActivity(enrolment) || null}
                          </div>
                        )
                      }
                    )}

                  {/* LESSON HEADER END */}

                  <div
                    className="events rm-heignt border-bottom1 pb-3 mb-3 mt-0"
                    style={{ minHeight: "100px" }}
                  >
                    {/* preNext Activity lesson start */}

                    {sessionPreNextData &&
                      sessionPreNextData.session &&
                      sessionPreNextData.session.tbl_session_time &&
                      sessionPreNextData.session.tbl_session_time.tbl_student_enrolment.map(
                        (enrolment, index) => {
                          //console.log(enrolment)

                          if (
                            enrolment.tbl_student &&
                            enrolment.tbl_student.Lesson &&
                            enrolment.tbl_student.Lesson.LessonActivities
                              .length > 0
                          ) {
                            // const activities = enrolment.tbl_student.Lesson.LessonActivities;

                            //     if (!activities || activities.length === 0) {
                            //       return <p className='text-danger'>No tasks assigned</p>;
                            //     }

                            //     const filteredActivities = activities.filter(activity => parseInt(activity.marked_for_homework) === 0);

                            //     if (filteredActivities.length === 0) {
                            //       return <p className='text-danger'>No tasks assigned</p>;
                            //     }

                            return enrolment.tbl_student.Lesson.LessonActivities.map(
                              (activity, index1) => {
                                if (
                                  parseInt(activity.marked_for_homework) === 0
                                ) {
                                  // console.log(activity)
                                  if (
                                    activity.activity_type === 0 &&
                                    activity.fk_sub_id === 2
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0

                                    // console.log(activity.activity_node[0].);

                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                question.question_parts &&
                                                  question.question_parts
                                                    .length > 0 &&
                                                  question.question_parts.forEach(
                                                    (questionpat, index4) => {
                                                      total_question += 1

                                                      total_coins += 2
                                                      if (
                                                        questionpat.StudentAnsAttempts &&
                                                        questionpat
                                                          .StudentAnsAttempts
                                                          .length > 0
                                                      ) {
                                                        questionpat.StudentAnsAttempts.forEach(
                                                          stuAnswer => {
                                                            total_time +=
                                                              parseInt(
                                                                stuAnswer.submitted_on
                                                              )
                                                          }
                                                        )
                                                        count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                      }
                                                    }
                                                  )
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }

                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild pd-10`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}

                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                       
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Assessment
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() => {
                                                  setNextActivity(true)
                                                  setExistingValue([])
                                                  localStorage.removeItem(
                                                    "selectedData"
                                                  ) // No `await` needed
                                                  setCurrentActivityData(
                                                    activity
                                                  )
                                                  fetchNextAssessmentActivityData(
                                                    activity.fk_sub_id,
                                                    activity.pk_lesson_activity_key,
                                                    activity.marked_for_homework
                                                  )
                                                }}
                                              >
                                                Add the next activity
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""
                                                    let attempt = ""
                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .question_parts
                                                              .length > 0
                                                          ) {
                                                            question.question_parts.forEach(
                                                              (
                                                                partQuestion,
                                                                index4
                                                              ) => {
                                                                if (
                                                                  partQuestion
                                                                    .StudentAnsAttempts
                                                                    .length > 0
                                                                ) {
                                                                  partQuestion.StudentAnsAttempts.forEach(
                                                                    (
                                                                      answerattquestion,
                                                                      index5
                                                                    ) => {
                                                                      attempt += 1
                                                                      color +=
                                                                        answerattquestion.points_obtained
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    //console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)
                                                    const attemptInt =
                                                      parseInt(attempt)
                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          attemptInt >= 2 &&
                                                          colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : attemptInt >= 1 &&
                                                              colorInt === 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : attemptInt >= 2 &&
                                                              colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {attemptInt >= 2 &&
                                                        colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 1 &&
                                                          colorInt === 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 2 &&
                                                          colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 0 &&
                                    activity.fk_sub_id === 1
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    let manual_mark = 0
                                    let manual_mark_review = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          manual_mark_review += parseInt(
                                            activityQuestion.marked_for_manual_marking
                                          )
                                          if (
                                            activityQuestion.english_question &&
                                            activityQuestion.english_question
                                              .length > 0
                                          ) {
                                            activityQuestion.english_question.forEach(
                                              (question, index3) => {
                                                let filteredManualMark =
                                                  question.english_question_parts.filter(
                                                    manualMark =>
                                                      parseInt(
                                                        manualMark.english_question_part_automark
                                                      ) === 0
                                                  )
                                                if (
                                                  filteredManualMark.length > 0
                                                ) {
                                                  manual_mark += 1
                                                }

                                                question.english_question_parts.forEach(
                                                  (questionpart, index4) => {
                                                    if (
                                                      parseInt(
                                                        question.english_question_category_id
                                                      ) === 10 ||
                                                      parseInt(
                                                        question.english_question_category_id
                                                      ) === 13
                                                    ) {
                                                      questionpart
                                                        .english_question_answers
                                                        .length > 0 &&
                                                      questionpart
                                                        .english_question_answers[0]
                                                        .english_answer_text &&
                                                      questionpart
                                                        .english_question_answers[0]
                                                        .english_answer_text !=
                                                        ""
                                                        ? ((total_question += 1),
                                                          (total_coins += 2))
                                                        : null
                                                    } else {
                                                      total_question += 1
                                                      total_coins += 2
                                                    }
                                                    // total_question += 1;
                                                    // total_coins +=2;
                                                    if (
                                                      questionpart.StudentAnsAttempts &&
                                                      questionpart
                                                        .StudentAnsAttempts
                                                        .length > 0
                                                    ) {
                                                      questionpart.StudentAnsAttempts.forEach(
                                                        stuAnswer => {
                                                          total_time +=
                                                            parseInt(
                                                              stuAnswer.submitted_on
                                                            )
                                                        }
                                                      )
                                                      count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                    }
                                                  }
                                                )
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Assessment
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {manual_mark &&
                                              manual_mark > 0 &&
                                              manual_mark_review === 0 &&
                                              activity.status == "1" ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onManualMarkingActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                    </span>{" "}
                                                    Mark
                                                  </DropdownItem>
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() => {
                                                  setNextActivity(true)
                                                  setExistingValue([])
                                                  localStorage.removeItem(
                                                    "selectedData"
                                                  ) // No `await` needed
                                                  fetchNextAssessmentActivityData(
                                                    activity.fk_sub_id,
                                                    activity.pk_lesson_activity_key,
                                                    activity.marked_for_homework
                                                  )
                                                }}
                                              >
                                                Add the next activity
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""
                                                    let attempt = ""
                                                    let manual_mark = 0
                                                    if (
                                                      activityQuestion.english_question &&
                                                      activityQuestion
                                                        .english_question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.english_question.forEach(
                                                        (question, index3) => {
                                                          let filteredManualMark =
                                                            question.english_question_parts.filter(
                                                              manualMark =>
                                                                parseInt(
                                                                  manualMark.english_question_part_automark
                                                                ) === 0
                                                            )
                                                          if (
                                                            filteredManualMark.length >
                                                            0
                                                          ) {
                                                            manual_mark += 1
                                                          }
                                                          if (
                                                            question
                                                              .english_question_parts
                                                              .length > 0
                                                          ) {
                                                            question.english_question_parts.forEach(
                                                              (
                                                                questionpart,
                                                                index4
                                                              ) => {
                                                                if (
                                                                  questionpart
                                                                    .StudentAnsAttempts
                                                                    .length > 0
                                                                ) {
                                                                  questionpart.StudentAnsAttempts.forEach(
                                                                    (
                                                                      answerattquestion,
                                                                      index5
                                                                    ) => {
                                                                      attempt += 1
                                                                      color +=
                                                                        answerattquestion.points_obtained
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)
                                                    const attemptInt =
                                                      parseInt(attempt)
                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          manual_mark > 0
                                                            ? ""
                                                            : attemptInt >= 2 &&
                                                              colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : attemptInt >= 1 &&
                                                              colorInt >= 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : attemptInt >= 2 &&
                                                              colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {manual_mark > 0 ? (
                                                          "M"
                                                        ) : attemptInt >= 2 &&
                                                          colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 1 &&
                                                          colorInt >= 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 2 &&
                                                          colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                        {manual_mark &&
                                        manual_mark > 0 &&
                                        manual_mark_review === 0 ? (
                                          <div class="manual_mark_div">
                                            <span className="d-flex align-items-center gap-1">
                                              <i className="font-size-18 mdi mdi-alert-outline"></i>
                                              <p className="font-size-12 mb-0">
                                                Manual marking required
                                              </p>
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  } else if (activity.activity_type === 1) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0

                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                           
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                       
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Video
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 2 &&
                                    activity.fk_sub_id === 1
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    let manual_mark = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.english_question &&
                                            activityQuestion.english_question
                                              .length > 0
                                          ) {
                                            let filteredManualMark = []
                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            //   if(filteredManualMark.length > 0){
                                            //     manual_mark += 1;
                                            //   }
                                            activityQuestion.english_question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Diagnostics
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.english_question &&
                                                      activityQuestion
                                                        .english_question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.english_question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : colorInt === 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 2 &&
                                    activity.fk_sub_id === 2
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0

                                    let manual_mark = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []
                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            //   if(filteredManualMark.length > 0){
                                            //     manual_mark += 1;
                                            //   }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Diagnostics
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : colorInt === 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (activity.activity_type === 3) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0

                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Link
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (activity.activity_type === 4) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Task
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (activity.activity_type === 5) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;
                                    let manual_mark_review = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          total_question += 1
                                          manual_mark_review += parseInt(
                                            activityQuestion.marked_for_manual_marking
                                          )
                                          if (
                                            activityQuestion.StudentAnsAttempts &&
                                            activityQuestion.StudentAnsAttempts
                                              .length > 0
                                          ) {
                                            activityQuestion.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_coins += 2
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            WorkSheet
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {manual_mark_review === 0 &&
                                              activity.status == "1" ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onManualMarkingActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                    </span>{" "}
                                                    Mark
                                                  </DropdownItem>
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                        {manual_mark_review === 0 ? (
                                          <div class="manual_mark_div">
                                            <span className="d-flex align-items-center gap-1">
                                              <i className="font-size-18 mdi mdi-alert-outline"></i>
                                              <p className="font-size-12 mb-0">
                                                Manual marking required
                                              </p>
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 6 &&
                                    (activity.fk_sub_id === 1 ||
                                      activity.fk_sub_id === 2)
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0]
                                            .video_name != ""
                                            ? activity.activity_node[0]
                                                .video_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Video
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 7 &&
                                    (activity.fk_sub_id === 1 ||
                                      activity.fk_sub_id === 2)
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;
                                    let manual_mark_review = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          total_question += 1
                                          manual_mark_review += parseInt(
                                            activityQuestion.marked_for_manual_marking
                                          )
                                          if (
                                            activityQuestion.StudentAnsAttempts &&
                                            activityQuestion.StudentAnsAttempts
                                              .length > 0
                                          ) {
                                            activityQuestion.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }

                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_coins += 2
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div className="set1 bg-supermild">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            WorkSheet
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 &&
                                              parseInt(
                                                activity.marked_for_homework
                                              ) === 0 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveHomeworkActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    same session homework
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              {manual_mark_review === 0 &&
                                              activity.status == "1" ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onManualMarkingActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                    </span>{" "}
                                                    Mark
                                                  </DropdownItem>
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                        {manual_mark_review === 0 ? (
                                          <div class="manual_mark_div">
                                            <span className="d-flex align-items-center gap-1">
                                              <i className="font-size-18 mdi mdi-alert-outline"></i>
                                              <p className="font-size-12 mb-0">
                                                Manual marking required
                                              </p>
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  } else {
                                    return null
                                  }
                                }
                              }
                            )
                          } else {
                            return null
                          }
                        }
                      )}
                    {/* preNextLesson activity End */}
                  </div>
                </div>

                <div className="homework-plan-wrapper bg-white p-2 mt-3">
                  {/* HOMEWORK HEADER START */}
                  {sessionPreNextData &&
                    sessionPreNextData.session &&
                    sessionPreNextData.session.tbl_session_time &&
                    sessionPreNextData.session.tbl_session_time.tbl_student_enrolment.map(
                      (enrolment, index) => {
                        //console.log(enrolment);

                        return (
                          <div className="section-divider">
                            <div className="border-bottom pb-3 mb-2">
                              <h6 className="mb-0 text-dark font-size-16 fw-700">
                                Homework
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between list-unstyled mb-0">
                              <div className="d-flex align-items-center gap-2 fw-400 font-size-16 text-dark">
                                {" "}
                                <img
                                  className="me-05"
                                  width={24}
                                  src={awicon}
                                  alt=""
                                />
                                {CountHomeWorkTotalCoins(enrolment) || "-"}{" "}
                              </div>
                              <div className="d-flex align-items-center gap-2 fw-400 font-size-16 text-dark">
                                <span>
                                  {" "}
                                  <img
                                    className="me-05"
                                    width={24}
                                    src={duration}
                                    alt=""
                                  />
                                </span>
                                {CountHomeWorkHours(enrolment) || "-"}
                              </div>
                            </div>

                            {CountHomeWorkTotalActivity(enrolment) || "-"}

                            {/* <small className='mt-2 mb-2'>All activities completed (100%) </small>

                    <Button className='btn btn-secondary w-100' color="" onClick={tog_center}>  View Lesson Report </Button> */}
                          </div>
                        )
                      }
                    )}

                  {/* HOMEWORK HEADER END */}

                  <div
                    className="events rm-heignt border-bottom1 pb-3 mb-3 mt-0"
                    style={{ minHeight: "100px" }}
                  >
                    {/* preNext Activity Home work start */}

                    {sessionPreNextData &&
                      sessionPreNextData.session &&
                      sessionPreNextData.session.tbl_session_time &&
                      sessionPreNextData.session.tbl_session_time.tbl_student_enrolment.map(
                        (enrolment, index) => {
                          //console.log(enrolment)

                          if (
                            enrolment.tbl_student &&
                            enrolment.tbl_student.Lesson &&
                            enrolment.tbl_student.Lesson.LessonActivities
                              .length > 0
                          ) {
                            // const activities = enrolment.tbl_student.Lesson.LessonActivities;

                            //     if (!activities || activities.length === 0) {
                            //       return <p className='text-danger'>No tasks assigned</p>;
                            //     }

                            //     const filteredActivities = activities.filter(activity => parseInt(activity.marked_for_homework) === 1);

                            //     if (filteredActivities.length === 0) {
                            //       return <p className='text-danger'>No tasks assigned</p>;
                            //     }

                            return enrolment.tbl_student.Lesson.LessonActivities.map(
                              (activity, index1) => {
                                if (
                                  parseInt(activity.marked_for_homework) === 1
                                ) {
                                  // console.log(activity)
                                  if (
                                    activity.activity_type === 0 &&
                                    activity.fk_sub_id === 2
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0

                                    // console.log(activity.activity_node[0].);

                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                question.question_parts &&
                                                  question.question_parts
                                                    .length > 0 &&
                                                  question.question_parts.forEach(
                                                    (questionpat, index4) => {
                                                      total_question += 1

                                                      total_coins += 2
                                                      if (
                                                        questionpat.StudentAnsAttempts &&
                                                        questionpat
                                                          .StudentAnsAttempts
                                                          .length > 0
                                                      ) {
                                                        questionpat.StudentAnsAttempts.forEach(
                                                          stuAnswer => {
                                                            total_time +=
                                                              parseInt(
                                                                stuAnswer.submitted_on
                                                              )
                                                          }
                                                        )
                                                        count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                      }
                                                    }
                                                  )
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Assessment
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() => {
                                                  setNextActivity(true)
                                                  setExistingValue([])
                                                  localStorage.removeItem(
                                                    "selectedData"
                                                  ) // No `await` needed
                                                  setCurrentActivityData(
                                                    activity
                                                  )
                                                  fetchNextAssessmentActivityData(
                                                    activity.fk_sub_id,
                                                    activity.pk_lesson_activity_key,
                                                    activity.marked_for_homework
                                                  )
                                                }}
                                              >
                                                Add the next activity
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""
                                                    let attempt = ""
                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .question_parts
                                                              .length > 0
                                                          ) {
                                                            question.question_parts.forEach(
                                                              (
                                                                partQuestion,
                                                                index4
                                                              ) => {
                                                                if (
                                                                  partQuestion
                                                                    .StudentAnsAttempts
                                                                    .length > 0
                                                                ) {
                                                                  partQuestion.StudentAnsAttempts.forEach(
                                                                    (
                                                                      answerattquestion,
                                                                      index5
                                                                    ) => {
                                                                      attempt += 1
                                                                      color +=
                                                                        answerattquestion.points_obtained
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    //console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)
                                                    const attemptInt =
                                                      parseInt(attempt)
                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          attemptInt >= 2 &&
                                                          colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : attemptInt >= 1 &&
                                                              colorInt === 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : attemptInt >= 2 &&
                                                              colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {attemptInt >= 2 &&
                                                        colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 1 &&
                                                          colorInt === 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 2 &&
                                                          colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 0 &&
                                    activity.fk_sub_id === 1
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    let manual_mark = 0
                                    let manual_mark_review = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          manual_mark_review += parseInt(
                                            activityQuestion.marked_for_manual_marking
                                          )
                                          if (
                                            activityQuestion.english_question &&
                                            activityQuestion.english_question
                                              .length > 0
                                          ) {
                                            activityQuestion.english_question.forEach(
                                              (question, index3) => {
                                                let filteredManualMark =
                                                  question.english_question_parts.filter(
                                                    manualMark =>
                                                      parseInt(
                                                        manualMark.english_question_part_automark
                                                      ) === 0
                                                  )
                                                if (
                                                  filteredManualMark.length > 0
                                                ) {
                                                  manual_mark += 1
                                                }

                                                question.english_question_parts.forEach(
                                                  (questionpart, index4) => {
                                                    if (
                                                      parseInt(
                                                        question.english_question_category_id
                                                      ) === 10 ||
                                                      parseInt(
                                                        question.english_question_category_id
                                                      ) === 13
                                                    ) {
                                                      questionpart
                                                        .english_question_answers
                                                        .length > 0 &&
                                                      questionpart
                                                        .english_question_answers[0]
                                                        .english_answer_text &&
                                                      questionpart
                                                        .english_question_answers[0]
                                                        .english_answer_text !=
                                                        ""
                                                        ? ((total_question += 1),
                                                          (total_coins += 2))
                                                        : null
                                                    } else {
                                                      total_question += 1
                                                      total_coins += 2
                                                    }
                                                    // total_question += 1;
                                                    // total_coins +=2;
                                                    if (
                                                      questionpart.StudentAnsAttempts &&
                                                      questionpart
                                                        .StudentAnsAttempts
                                                        .length > 0
                                                    ) {
                                                      questionpart.StudentAnsAttempts.forEach(
                                                        stuAnswer => {
                                                          total_time +=
                                                            parseInt(
                                                              stuAnswer.submitted_on
                                                            )
                                                        }
                                                      )
                                                      count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                    }
                                                  }
                                                )
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Assessment
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {manual_mark &&
                                              manual_mark > 0 &&
                                              manual_mark_review === 0 &&
                                              activity.status == "1" ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onManualMarkingActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                    </span>{" "}
                                                    Mark
                                                  </DropdownItem>
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() => {
                                                  setNextActivity(true)
                                                  setExistingValue([])
                                                  localStorage.removeItem(
                                                    "selectedData"
                                                  ) // No `await` needed
                                                  setCurrentActivityData(
                                                    activity
                                                  )
                                                  fetchNextAssessmentActivityData(
                                                    activity.fk_sub_id,
                                                    activity.pk_lesson_activity_key,
                                                    activity.marked_for_homework
                                                  )
                                                }}
                                              >
                                                Add the next activity
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""
                                                    let attempt = ""
                                                    let manual_mark = 0
                                                    if (
                                                      activityQuestion.english_question &&
                                                      activityQuestion
                                                        .english_question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.english_question.forEach(
                                                        (question, index3) => {
                                                          let filteredManualMark =
                                                            question.english_question_parts.filter(
                                                              manualMark =>
                                                                parseInt(
                                                                  manualMark.english_question_part_automark
                                                                ) === 0
                                                            )
                                                          if (
                                                            filteredManualMark.length >
                                                            0
                                                          ) {
                                                            manual_mark += 1
                                                          }
                                                          if (
                                                            question
                                                              .english_question_parts
                                                              .length > 0
                                                          ) {
                                                            question.english_question_parts.forEach(
                                                              (
                                                                questionpart,
                                                                index4
                                                              ) => {
                                                                if (
                                                                  questionpart
                                                                    .StudentAnsAttempts
                                                                    .length > 0
                                                                ) {
                                                                  questionpart.StudentAnsAttempts.forEach(
                                                                    (
                                                                      answerattquestion,
                                                                      index5
                                                                    ) => {
                                                                      attempt += 1
                                                                      color +=
                                                                        answerattquestion.points_obtained
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)
                                                    const attemptInt =
                                                      parseInt(attempt)
                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          manual_mark > 0
                                                            ? ""
                                                            : attemptInt >= 2 &&
                                                              colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : attemptInt >= 1 &&
                                                              colorInt >= 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : attemptInt >= 2 &&
                                                              colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {manual_mark > 0 ? (
                                                          "M"
                                                        ) : attemptInt >= 2 &&
                                                          colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 1 &&
                                                          colorInt >= 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : attemptInt >= 2 &&
                                                          colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                        {manual_mark &&
                                        manual_mark > 0 &&
                                        manual_mark_review === 0 ? (
                                          <div class="manual_mark_div">
                                            <span className="d-flex align-items-center gap-1">
                                              <i className="font-size-18 mdi mdi-alert-outline"></i>
                                              <p className="font-size-12 mb-0">
                                                Manual marking required
                                              </p>
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  } else if (activity.activity_type === 1) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0

                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Video
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 2 &&
                                    activity.fk_sub_id === 1
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    let manual_mark = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.english_question &&
                                            activityQuestion.english_question
                                              .length > 0
                                          ) {
                                            let filteredManualMark = []
                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            //   if(filteredManualMark.length > 0){
                                            //     manual_mark += 1;
                                            //   }
                                            activityQuestion.english_question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Diagnostics
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.english_question &&
                                                      activityQuestion
                                                        .english_question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.english_question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : colorInt === 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 2 &&
                                    activity.fk_sub_id === 2
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);

                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0

                                    let manual_mark = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []
                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            //   if(filteredManualMark.length > 0){
                                            //     manual_mark += 1;
                                            //   }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topics &&
                                          activity.topics.length > 0
                                            ? getUniqueTopicsNames(
                                                activity.topics
                                              )
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Diagnostics
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return (
                                                      <li
                                                        key={index2}
                                                        className={`${
                                                          colorInt === 1
                                                            ? "attempt2 text-warning bg-success-subtle"
                                                            : colorInt === 2
                                                            ? "attempt1 custom-success bg-success-subtle"
                                                            : colorInt === 0
                                                            ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                            : ""
                                                        }`}
                                                      >
                                                        {colorInt === 1 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 2 ? (
                                                          <i className="fas fa-check"></i>
                                                        ) : colorInt === 0 ? (
                                                          <i className="mdi mdi-window-close"></i>
                                                        ) : (
                                                          index2 + 1
                                                        )}
                                                      </li>
                                                    )
                                                  }
                                                )
                                              : null}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (activity.activity_type === 3) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0

                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Link
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (activity.activity_type === 4) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Task
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (activity.activity_type === 5) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;
                                    let manual_mark_review = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          total_question += 1
                                          manual_mark_review += parseInt(
                                            activityQuestion.marked_for_manual_marking
                                          )
                                          if (
                                            activityQuestion.StudentAnsAttempts &&
                                            activityQuestion.StudentAnsAttempts
                                              .length > 0
                                          ) {
                                            activityQuestion.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_coins += 2
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-success"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].name != ""
                                            ? activity.activity_node[0].name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            WorkSheet
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {manual_mark_review === 0 &&
                                              activity.status == "1" ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onManualMarkingActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                    </span>{" "}
                                                    Mark
                                                  </DropdownItem>
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                        {manual_mark_review === 0 ? (
                                          <div class="manual_mark_div">
                                            <span className="d-flex align-items-center gap-1">
                                              <i className="font-size-18 mdi mdi-alert-outline"></i>
                                              <p className="font-size-12 mb-0">
                                                Manual marking required
                                              </p>
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 6 &&
                                    (activity.fk_sub_id === 1 ||
                                      activity.fk_sub_id === 2)
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;

                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_question += 1
                                                total_coins += 2
                                                if (
                                                  question.StudentAnsAttempts &&
                                                  question.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  question.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0]
                                            .video_name != ""
                                            ? activity.activity_node[0]
                                                .video_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            Video
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  } else if (
                                    activity.activity_type === 7 &&
                                    (activity.fk_sub_id === 1 ||
                                      activity.fk_sub_id === 2)
                                  ) {
                                    // var total_coins =
                                    //   activity.LessonActivitiesQuestions
                                    //     .length > 0
                                    //     ? activity.LessonActivitiesQuestions
                                    //         .length * 2
                                    //     : 0
                                    // console.log(activity.activity_node[0].);
                                    let total_coins = 0
                                    let total_time = 0

                                    let total_question = 0
                                    let count_answer = 0
                                    //let percentage = 0;
                                    //let manual_mark = 0;
                                    let manual_mark_review = 0
                                    if (
                                      activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                    ) {
                                      //total_question += activity.LessonActivitiesQuestions.length;

                                      activity.LessonActivitiesQuestions.forEach(
                                        (activityQuestion, index2) => {
                                          total_question += 1
                                          manual_mark_review += parseInt(
                                            activityQuestion.marked_for_manual_marking
                                          )
                                          if (
                                            activityQuestion.StudentAnsAttempts &&
                                            activityQuestion.StudentAnsAttempts
                                              .length > 0
                                          ) {
                                            activityQuestion.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }

                                          if (
                                            activityQuestion.question &&
                                            activityQuestion.question.length > 0
                                          ) {
                                            let filteredManualMark = []

                                            // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                            // if(filteredManualMark.length > 0){
                                            //   manual_mark += 1;
                                            // }
                                            activityQuestion.question.forEach(
                                              (question, index3) => {
                                                total_coins += 2
                                              }
                                            )
                                          }
                                        }
                                      )
                                    }
                                    return (
                                      <div
                                        className={`${(() => {
                                          if (
                                            sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed === 1
                                          ) {
                                            if (
                                              activity.status == "1" &&
                                              activity.mastryScore
                                            ) {
                                              const score = parseInt(
                                                activity.mastryScore,
                                                10
                                              )
                                              if (score > 75)
                                                return "activity-card-success"
                                              if (score < 75)
                                                return "activity-card-blue"
                                            } else if (activity.status == "1") {
                                              return "activity-card-blue"
                                            }
                                            return "activity-card-danger"
                                          }
                                          return ""
                                        })()} set1 bg-supermild`}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionPreNextData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                          </div>
                                          <div>
                                            {sessionPreNextData &&
                                            sessionPreNextData.session &&
                                            sessionPreNextData.session
                                              .ses_is_completed &&
                                            parseInt(
                                              sessionPreNextData.session
                                                .ses_is_completed
                                            ) === 1 ? (
                                              <input
                                                type="checkbox"
                                                checked={selectedActivityKeys.includes(
                                                  activity.pk_lesson_activity_key
                                                )}
                                                onChange={() =>
                                                  handleCheckboxActivityChange(
                                                    activity.pk_lesson_activity_key,
                                                    activity,
                                                    enrolment.tbl_student.Lesson
                                                      .LessonActivities.length
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                          {activity.level_name &&
                                          activity.level_name != ""
                                            ? activity.level_name + " - "
                                            : null}
                                          {activity.topic_name &&
                                          activity.topic_name != ""
                                            ? activity.topic_name + " - "
                                            : null}
                                          {activity.activity_node[0] &&
                                          activity.activity_node[0].node_name !=
                                            ""
                                            ? activity.activity_node[0]
                                                .node_name
                                            : null}
                                        </h6>

                                        <div className="d-flex justify-content-between mb-2">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={reel}
                                                alt=""
                                              />
                                            </span>
                                            WorkSheet
                                          </div>
                                          <Dropdown
                                            isOpen={
                                              dropdownOpen[
                                                activity.pk_lesson_activity_key
                                              ]
                                            }
                                            toggle={() =>
                                              toggleDropdown(
                                                activity.pk_lesson_activity_key
                                              )
                                            }
                                          >
                                            <DropdownToggle className="p-0 bg-white">
                                              <i className="mdi mdi-dots-horizontal"></i>{" "}
                                            </DropdownToggle>
                                            <DropdownMenu className="custom-position">
                                              {manual_mark_review === 0 &&
                                              activity.status == "1" ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onManualMarkingActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                    </span>{" "}
                                                    Mark
                                                  </DropdownItem>
                                                </>
                                              ) : null}
                                              {sessionPreNextData &&
                                              sessionPreNextData.session &&
                                              sessionPreNextData.session
                                                .ses_is_completed === 1 ? (
                                                <>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onMoveActivityPopup(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    <span></span> Roll over to
                                                    next session
                                                  </DropdownItem>{" "}
                                                </>
                                              ) : null}
                                              <DropdownItem
                                                onClick={() =>
                                                  onPreviewActivity(activity)
                                                }
                                              >
                                                Preview
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  onDelectActivityPopup(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            <span>
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={duration}
                                                alt=""
                                              />
                                            </span>
                                            {activity.total_activity_time
                                              ? activity.total_activity_time
                                              : 0}{" "}
                                            mins
                                          </div>

                                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                            {" "}
                                            <img
                                              className="me-05"
                                              width={24}
                                              src={awicon}
                                              alt=""
                                            />
                                            {activity.coins_earned
                                              ? activity.coins_earned
                                              : 0}
                                            /{total_coins}{" "}
                                          </div>
                                        </div>

                                        <div className="question-count mt-2">
                                          <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                            {/* {activity.LessonActivitiesQuestions &&
                                            activity.LessonActivitiesQuestions
                                              .length > 0
                                              ? activity.LessonActivitiesQuestions.map(
                                                  (
                                                    activityQuestion,
                                                    index2
                                                  ) => {
                                                    let color = ""

                                                    if (
                                                      activityQuestion.question &&
                                                      activityQuestion.question
                                                        .length > 0
                                                    ) {
                                                      activityQuestion.question.forEach(
                                                        (question, index3) => {
                                                          if (
                                                            question
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            question.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index4
                                                              ) => {
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }

                                                    // console.log(color); // This logs the concatenated string, not each value separately

                                                    // Parse color to an integer
                                                    const colorInt =
                                                      parseInt(color)

                                                    return null
                                                    // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                    //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                    // </li>
                                                  }
                                                )
                                              : null} */}
                                          </ul>
                                        </div>
                                        {manual_mark_review === 0 ? (
                                          <div class="manual_mark_div">
                                            <span className="d-flex align-items-center gap-1">
                                              <i className="font-size-18 mdi mdi-alert-outline"></i>
                                              <p className="font-size-12 mb-0">
                                                Manual marking required
                                              </p>
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                    )
                                  } else {
                                    return null
                                  }
                                }
                              }
                            )
                          } else {
                            return null
                          }
                        }
                      )}
                    {/* preNextHomework activity End */}
                  </div>
                </div>
              </div>

              <div className="kb-child ehp-ch_height ehp-w bg-white p-2 me-10 border-0 state-bg">
                <div className="ss-details border-bottom1 mb-2">
                  <h5 className="mb-0 text-dark font-size-16 fw-700">
                    Lesson Plan
                  </h5>
                  <div className="">
                    <Label
                      className="mb-1 font-size-15 fw-700 ms-0"
                      style={{ color: "#4d4d4d" }}
                    >
                      Message From Tutor
                    </Label>

                    <Input
                      className="tp-height"
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachange(e)
                      }}
                      value={lessonNote}
                    />
                    {/* {textareabadge ? (
                      <span className="badgecount badge badge-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null} */}
                  </div>
                </div>

                <div className="lesson-plan-wrapper bg-white p-2">
                  {sessionStudentData &&
                    sessionStudentData.session &&
                    sessionStudentData.session.tbl_session_time &&
                    sessionStudentData.session.tbl_session_time.tbl_student_enrolment.map(
                      (enrolment, index) => {
                        return (
                          <div className="section-divider">
                            <div className="d-flex justify-content-between list-unstyled mb-2">
                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                {" "}
                                <img
                                  className="me-05"
                                  width={24}
                                  src={awicon}
                                  alt=""
                                />
                                {CountTotalCoinsForActivity(lessonActivityArray) || "-"}{" "}
                              </div>
                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                <span>
                                  {" "}
                                  <img
                                    className="me-05"
                                    width={24}
                                    src={duration}
                                    alt=""
                                  />
                                </span>
                                {CountHoursForActivity(lessonActivityArray) || "-"}
                              </div>
                            </div>

                            <div className="d-flex gap-5 mb-3 align-items-center justify-content-between">
                              <div className=" pe-3">
                                <h6 class="mb-0 font-size-14 fw-700 text-dark">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.tbl_session_time &&
                                  sessionStudentData.session.tbl_session_time
                                    .sest_name
                                    ? sessionStudentData.session.tbl_session_time
                                        .sest_name
                                    : "-"}
                                </h6>
                                <p class="mb-0 fs-sans-s font-size-12">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.tbl_session_time &&
                                  sessionStudentData.session.tbl_session_time
                                    .sest_type
                                    ? sessionStudentData.session.tbl_session_time
                                        .sest_type
                                    : "-"}
                                </p>
                              </div>
                              <div className="text-end">
                                <h6 class="mb-0 fs-sans-s fw-700 font-size-12">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.ses_date
                                    ? moment(
                                        sessionStudentData.session.ses_date,
                                        moment.ISO_8601
                                      ).format("DD MMM YYYY (ddd)")
                                    : "-"}
                                </h6>
                                <p class="mb-0 fs-sans-s font-size-12">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.ses_start_time
                                    ? moment(
                                        sessionStudentData.session
                                          .ses_start_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")
                                    : "-"}{" "}
                                  -{" "}
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.ses_end_time
                                    ? moment(
                                        sessionStudentData.session.ses_end_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="switch-wrapper">
                                {!switch2 ? "Random" : "Sequential"}{" "}
                                <div className="sw-child">
                                  <Switch
                                    // uncheckedIcon={<Offsymbol />}
                                    // checkedIcon={<OnSymbol />}
                                    id="lessonActivity"
                                    onColor="#a2a2a2"
                                    onChange={() => {
                                      setswitch2(!switch2)
                                    }}
                                    checked={switch2}
                                  />
                                </div>
                              </div>
                              <div className="switch-wrapper">
                                {!switch2 ? (
                                  <i class="mdi mdi-shuffle-variant"></i>
                                ) : (
                                  <i class=" mdi mdi-sort-ascending"></i>
                                )}{" "}
                              </div>
                            </div>
                            <Link
                              to={"/session-newactivity"}
                              className="btn btn-secondary w-100"
                              onClick={() => handleSetTypeClick(1)}
                            >
                              Add New Activity
                            </Link>
                            <div className="d-flex justify-content-between ">
                              <div className="switch-wrapper">
                                {ActivityNavSeleceted === 0 ? (
                                  ""
                                ) : (
                                  <span
                                    className="bg-white p-1 px-2 rounded-4 pe-auto"
                                    style={{ cursor: "pointer" }}
                                    onClick={handlePrevious}
                                  >
                                    <i className="mdi mdi-chevron-left"></i>
                                  </span>
                                )}
                              </div>
                              <div className="switch-wrapper">
                                {lessonActivityArray.length > 0 ? (
                                  ActivityNavSeleceted ===
                                  lessonActivityArray.length - 1 ? (
                                    ""
                                  ) : (
                                    <span
                                      className="bg-white p-1 px-2 rounded-4 pe-auto"
                                      style={{ cursor: "pointer" }}
                                      onClick={handleNext}
                                    >
                                      <i className="mdi mdi-chevron-right"></i>
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      }
                    )}

                  {sessionStudentData &&
                    sessionStudentData.session &&
                    sessionStudentData.session.tbl_session_time &&
                    sessionStudentData.session.tbl_session_time
                      .tbl_student_enrolment.length === 0 && (
                      <div className="section-divider">
                        <div className="d-flex justify-content-between list-unstyled mb-2">
                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                            {" "}
                            <img
                              className="me-05"
                              width={24}
                              src={awicon}
                              alt=""
                            />
                            -{" "}
                          </div>
                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                            <span>
                              {" "}
                              <img
                                className="me-05"
                                width={24}
                                src={duration}
                                alt=""
                              />
                            </span>
                            -
                          </div>
                        </div>

                        <div className="d-flex gap-5 mb-3 align-items-center justify-content-between">
                          <div className=" pe-3">
                            <h6 class="mb-0 font-size-14 fw-700 text-dark">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.tbl_session_time &&
                              sessionStudentData.session.tbl_session_time
                                .sest_name
                                ? sessionStudentData.session.tbl_session_time
                                    .sest_name
                                : "-"}
                            </h6>
                            <p class="mb-0 fs-sans-s font-size-12">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.tbl_session_time &&
                              sessionStudentData.session.tbl_session_time
                                .sest_type
                                ? sessionStudentData.session.tbl_session_time
                                    .sest_type
                                : "-"}
                            </p>
                          </div>
                          <div className="text-end">
                            <h6 class="mb-0 font-size-12 fw-700 text-dark">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.ses_date
                                ? moment(
                                    sessionStudentData.session.ses_date,
                                    moment.ISO_8601
                                  ).format("DD MMM YYYY (ddd)")
                                : "-"}
                            </h6>
                            <p class="mb-0 fs-sans-s font-size-12">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.ses_start_time
                                ? moment(
                                    sessionStudentData.session.ses_start_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                : "-"}{" "}
                              -{" "}
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.ses_end_time
                                ? moment(
                                    sessionStudentData.session.ses_end_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                : "-"}
                            </p>
                          </div>
                        </div>

                        {/* <div>
                          Random  <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#a2a2a2"
                            onChange={() => {
                              setswitch2(!switch2);
                            }}
                            checked={switch2}
                          />
                        </div> */}
                        <Link
                          to={"/session-newactivity"}
                          className="btn btn-secondary w-100"
                          onClick={() => handleSetTypeClick(1)}
                        >
                          Add New Activity
                        </Link>
                      </div>
                    )}
                  {/* <div className="events rm-heignt border-bottom1 pb-3 mb-3 mt-0"> */}
                  <div className="events border-bottom1 pb-3 mb-3 mt-0 lesson">
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="lessonActivities">
                        {provided => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {/* {/* 
                  {sessionLessonData &&
                  sessionLessonData.session &&
                  sessionLessonData.session.tbl_session_time &&
                  sessionLessonData.session.tbl_session_time.tbl_student_enrolment.map((enrolment, index) => { 


                   
          
                  
                     
                      // setLessonActivityArray(enrolment.tbl_student.Lesson.LessonActivities);
                      // console.log(lessonActivityArray); */}
                            {lessonActivityArray.map((activity, index1) => {
                              if (
                                activity.activity_type === 0 &&
                                activity.fk_sub_id === 2
                              ) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0

                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                //let percentage = 0;
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            question.question_parts &&
                                              question.question_parts.length >
                                                0 &&
                                              question.question_parts.forEach(
                                                (questionpat, index4) => {
                                                  total_question += 1

                                                  total_coins += 2
                                                  if (
                                                    questionpat.StudentAnsAttempts &&
                                                    questionpat
                                                      .StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    questionpat.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0]
                                              .node_name != ""
                                              ? activity.activity_node[0]
                                                  .node_name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Assessment
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_Assessment()
                                                    fetchNextAssessmentActivityData(
                                                      activity.fk_sub_id,
                                                      activity.pk_lesson_activity_key,
                                                      activity.marked_for_homework
                                                    )
                                                  }}
                                                >
                                                  View Activities
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>

                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""
                                                      let attempt = ""
                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .question_parts
                                                                .length > 0
                                                            ) {
                                                              question.question_parts.forEach(
                                                                (
                                                                  partQuestion,
                                                                  index4
                                                                ) => {
                                                                  if (
                                                                    partQuestion
                                                                      .StudentAnsAttempts
                                                                      .length >
                                                                    0
                                                                  ) {
                                                                    partQuestion.StudentAnsAttempts.forEach(
                                                                      (
                                                                        answerattquestion,
                                                                        index5
                                                                      ) => {
                                                                        attempt += 1
                                                                        color +=
                                                                          answerattquestion.points_obtained
                                                                      }
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      //console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)
                                                      const attemptInt =
                                                        parseInt(attempt)
                                                      return (
                                                        <li
                                                          key={index2}
                                                          className={`${
                                                            attemptInt >= 2 &&
                                                            colorInt === 1
                                                              ? "attempt2 text-warning bg-success-subtle"
                                                              : attemptInt >=
                                                                  1 &&
                                                                colorInt === 2
                                                              ? "attempt1 custom-success bg-success-subtle"
                                                              : attemptInt >=
                                                                  2 &&
                                                                colorInt === 0
                                                              ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                              : ""
                                                          }`}
                                                        >
                                                          {attemptInt >= 2 &&
                                                          colorInt === 1 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : attemptInt >= 1 &&
                                                            colorInt === 2 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : attemptInt >= 2 &&
                                                            colorInt === 0 ? (
                                                            <i className="mdi mdi-window-close"></i>
                                                          ) : (
                                                            index2 + 1
                                                          )}
                                                        </li>
                                                      )
                                                    }
                                                  )
                                                : null}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (
                                activity.activity_type === 0 &&
                                activity.fk_sub_id === 1
                              ) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                //let percentage = 0;
                                let manual_mark = 0
                                let manual_mark_review = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      manual_mark_review += parseInt(
                                        activityQuestion.marked_for_manual_marking
                                      )
                                      if (
                                        activityQuestion.english_question &&
                                        activityQuestion.english_question
                                          .length > 0
                                      ) {
                                        activityQuestion.english_question.forEach(
                                          (question, index3) => {
                                            let filteredManualMark =
                                              question.english_question_parts.filter(
                                                manualMark =>
                                                  parseInt(
                                                    manualMark.english_question_part_automark
                                                  ) === 0
                                              )
                                            if (filteredManualMark.length > 0) {
                                              manual_mark += 1
                                            }

                                            question.english_question_parts.forEach(
                                              (questionpart, index4) => {
                                                if (
                                                  parseInt(
                                                    question.english_question_category_id
                                                  ) === 10 ||
                                                  parseInt(
                                                    question.english_question_category_id
                                                  ) === 13
                                                ) {
                                                  questionpart
                                                    .english_question_answers
                                                    .length > 0 &&
                                                  questionpart
                                                    .english_question_answers[0]
                                                    .english_answer_text &&
                                                  questionpart
                                                    .english_question_answers[0]
                                                    .english_answer_text != ""
                                                    ? ((total_question += 1),
                                                      (total_coins += 2))
                                                    : null
                                                } else {
                                                  total_question += 1
                                                  total_coins += 2
                                                }
                                                // total_question += 1;
                                                // total_coins +=2;
                                                if (
                                                  questionpart.StudentAnsAttempts &&
                                                  questionpart
                                                    .StudentAnsAttempts.length >
                                                    0
                                                ) {
                                                  questionpart.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0]
                                              .node_name != ""
                                              ? activity.activity_node[0]
                                                  .node_name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Assessment
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                {manual_mark &&
                                                manual_mark > 0 &&
                                                manual_mark_review === 0 &&
                                                activity.status == "1" ? (
                                                  <>
                                                    <DropdownItem
                                                      onClick={() =>
                                                        onManualMarkingActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      <span>
                                                        <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                      </span>{" "}
                                                      Mark
                                                    </DropdownItem>
                                                  </>
                                                ) : null}
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_Assessment()
                                                    fetchNextAssessmentActivityData(
                                                      activity.fk_sub_id,
                                                      activity.pk_lesson_activity_key,
                                                      activity.marked_for_homework
                                                    )
                                                  }}
                                                >
                                                  View Activities
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""
                                                      let attempt = ""
                                                      let manual_mark = 0
                                                      if (
                                                        activityQuestion.english_question &&
                                                        activityQuestion
                                                          .english_question
                                                          .length > 0
                                                      ) {
                                                        activityQuestion.english_question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            let filteredManualMark =
                                                              question.english_question_parts.filter(
                                                                manualMark =>
                                                                  parseInt(
                                                                    manualMark.english_question_part_automark
                                                                  ) === 0
                                                              )
                                                            if (
                                                              filteredManualMark.length >
                                                              0
                                                            ) {
                                                              manual_mark += 1
                                                            }
                                                            if (
                                                              question
                                                                .english_question_parts
                                                                .length > 0
                                                            ) {
                                                              question.english_question_parts.forEach(
                                                                (
                                                                  questionpart,
                                                                  index4
                                                                ) => {
                                                                  if (
                                                                    questionpart
                                                                      .StudentAnsAttempts
                                                                      .length >
                                                                    0
                                                                  ) {
                                                                    questionpart.StudentAnsAttempts.forEach(
                                                                      (
                                                                        answerattquestion,
                                                                        index5
                                                                      ) => {
                                                                        attempt += 1
                                                                        color +=
                                                                          answerattquestion.points_obtained
                                                                      }
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)
                                                      const attemptInt =
                                                        parseInt(attempt)
                                                      return (
                                                        <li
                                                          key={index2}
                                                          className={`${
                                                            manual_mark > 0
                                                              ? ""
                                                              : attemptInt >=
                                                                  2 &&
                                                                colorInt === 1
                                                              ? "attempt2 text-warning bg-success-subtle"
                                                              : attemptInt >=
                                                                  1 &&
                                                                colorInt >= 2
                                                              ? "attempt1 custom-success bg-success-subtle"
                                                              : attemptInt >=
                                                                  2 &&
                                                                colorInt === 0
                                                              ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                              : ""
                                                          }`}
                                                        >
                                                          {manual_mark > 0 ? (
                                                            "M"
                                                          ) : attemptInt >= 2 &&
                                                            colorInt === 1 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : attemptInt >= 1 &&
                                                            colorInt >= 2 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : attemptInt >= 2 &&
                                                            colorInt === 0 ? (
                                                            <i className="mdi mdi-window-close"></i>
                                                          ) : (
                                                            index2 + 1
                                                          )}
                                                        </li>
                                                      )
                                                    }
                                                  )
                                                : null}
                                            </ul>
                                          </div>
                                          {manual_mark &&
                                          manual_mark > 0 &&
                                          manual_mark_review === 0 ? (
                                            <div class="manual_mark_div">
                                              <span className="d-flex align-items-center gap-1">
                                                <i className="font-size-18 mdi mdi-alert-outline"></i>
                                                <p className="font-size-12 mb-0">
                                                  Manual marking required
                                                </p>
                                              </span>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (activity.activity_type === 1) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0

                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Video
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handleRowEditActivityClick(
                                                      activity
                                                    )
                                                    tog_largeEdit()
                                                  }}
                                                >
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                               
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {/* {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return null
                                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                      // </li>
                                                    }
                                                  )
                                                : null} */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (
                                activity.activity_type === 2 &&
                                activity.fk_sub_id === 1
                              ) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                let manual_mark = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.english_question &&
                                        activityQuestion.english_question
                                          .length > 0
                                      ) {
                                        let filteredManualMark = []
                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        //   if(filteredManualMark.length > 0){
                                        //     manual_mark += 1;
                                        //   }
                                        activityQuestion.english_question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topics &&
                                            activity.topics.length > 0
                                              ? getUniqueTopicsNames(
                                                  activity.topics
                                                )
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Diagnostics
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_Diagnostics()
                                                    fetchNextDiagnosticsActivityData(
                                                      activity
                                                    )
                                                  }}
                                                >
                                                  View Activities
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.english_question &&
                                                        activityQuestion
                                                          .english_question
                                                          .length > 0
                                                      ) {
                                                        activityQuestion.english_question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return (
                                                        <li
                                                          key={index2}
                                                          className={`${
                                                            colorInt === 1
                                                              ? "attempt2 text-warning bg-success-subtle"
                                                              : colorInt === 2
                                                              ? "attempt1 custom-success bg-success-subtle"
                                                              : colorInt === 0
                                                              ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                              : ""
                                                          }`}
                                                        >
                                                          {colorInt === 1 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : colorInt === 2 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : colorInt === 0 ? (
                                                            <i className="mdi mdi-window-close"></i>
                                                          ) : (
                                                            index2 + 1
                                                          )}
                                                        </li>
                                                      )
                                                    }
                                                  )
                                                : null}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (
                                activity.activity_type === 2 &&
                                activity.fk_sub_id === 2
                              ) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0

                                let manual_mark = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []
                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        //   if(filteredManualMark.length > 0){
                                        //     manual_mark += 1;
                                        //   }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topics &&
                                            activity.topics.length > 0
                                              ? getUniqueTopicsNames(
                                                  activity.topics
                                                )
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Diagnostics
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_Diagnostics()
                                                    fetchNextDiagnosticsActivityData(
                                                      activity
                                                    )
                                                  }}
                                                >
                                                  View Activities
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return (
                                                        <li
                                                          key={index2}
                                                          className={`${
                                                            colorInt === 1
                                                              ? "attempt2 text-warning bg-success-subtle"
                                                              : colorInt === 2
                                                              ? "attempt1 custom-success bg-success-subtle"
                                                              : colorInt === 0
                                                              ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                              : ""
                                                          }`}
                                                        >
                                                          {colorInt === 1 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : colorInt === 2 ? (
                                                            <i className="fas fa-check"></i>
                                                          ) : colorInt === 0 ? (
                                                            <i className="mdi mdi-window-close"></i>
                                                          ) : (
                                                            index2 + 1
                                                          )}
                                                        </li>
                                                      )
                                                    }
                                                  )
                                                : null}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (activity.activity_type === 3) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0

                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span className="font-size-12">
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Link
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handleRowEditActivityClick(
                                                      activity
                                                    )
                                                    tog_largeEdit()
                                                  }}
                                                >
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>

                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {/* {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return null
                                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                      // </li>
                                                    }
                                                  )
                                                : null} */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (activity.activity_type === 4) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                //let percentage = 0;
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Task
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handleRowEditActivityClick(
                                                      activity
                                                    )
                                                    tog_largeEdit()
                                                  }}
                                                >
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {/* {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return null
                                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                      // </li>
                                                    }
                                                  )
                                                : null} */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (activity.activity_type === 5) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                //let percentage = 0;
                                //let manual_mark = 0;
                                let manual_mark_review = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      total_question += 1
                                      manual_mark_review += parseInt(
                                        activityQuestion.marked_for_manual_marking
                                      )
                                      if (
                                        activityQuestion.StudentAnsAttempts &&
                                        activityQuestion.StudentAnsAttempts
                                          .length > 0
                                      ) {
                                        activityQuestion.StudentAnsAttempts.forEach(
                                          stuAnswer => {
                                            total_time += parseInt(
                                              stuAnswer.submitted_on
                                            )
                                          }
                                        )
                                        count_answer += 1 // Increment count_answer by 1 for each occurrence
                                      }
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_coins += 2
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              WorkSheet
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                {manual_mark_review === 0 &&
                                                activity.status == "1" ? (
                                                  <>
                                                    <DropdownItem
                                                      onClick={() =>
                                                        onManualMarkingActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      <span>
                                                        <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                      </span>{" "}
                                                      Mark
                                                    </DropdownItem>
                                                  </>
                                                ) : null}
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handleRowEditActivityClick(
                                                      activity
                                                    )
                                                    tog_largeEdit()
                                                  }}
                                                >
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {/* {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return null
                                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                      // </li>
                                                    }
                                                  )
                                                : null} */}
                                            </ul>
                                          </div>
                                          {manual_mark_review === 0 ? (
                                            <div class="manual_mark_div">
                                              <span className="d-flex align-items-center gap-1">
                                                <i className="font-size-18 mdi mdi-alert-outline"></i>
                                                <p className="font-size-12 mb-0">
                                                  Manual marking required
                                                </p>
                                              </span>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (
                                activity.activity_type === 6 &&
                                (activity.fk_sub_id === 1 ||
                                  activity.fk_sub_id === 2)
                              ) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                //let percentage = 0;
                                //let manual_mark = 0;

                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_question += 1
                                            total_coins += 2
                                            if (
                                              question.StudentAnsAttempts &&
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0]
                                              .video_name != ""
                                              ? activity.activity_node[0]
                                                  .video_name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              Video
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {/* {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return null
                                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                      // </li>
                                                    }
                                                  )
                                                : null} */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else if (
                                activity.activity_type === 7 &&
                                (activity.fk_sub_id === 1 ||
                                  activity.fk_sub_id === 2)
                              ) {
                                // var total_coins =
                                //   activity.LessonActivitiesQuestions.length > 0
                                //     ? activity.LessonActivitiesQuestions
                                //         .length * 2
                                //     : 0
                                // console.log(activity.activity_node[0].);
                                let total_coins = 0
                                let total_time = 0

                                let total_question = 0
                                let count_answer = 0
                                //let percentage = 0;
                                //let manual_mark = 0;
                                let manual_mark_review = 0
                                if (
                                  activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length > 0
                                ) {
                                  //total_question += activity.LessonActivitiesQuestions.length;

                                  activity.LessonActivitiesQuestions.forEach(
                                    (activityQuestion, index2) => {
                                      total_question += 1
                                      manual_mark_review += parseInt(
                                        activityQuestion.marked_for_manual_marking
                                      )
                                      if (
                                        activityQuestion.StudentAnsAttempts &&
                                        activityQuestion.StudentAnsAttempts
                                          .length > 0
                                      ) {
                                        activityQuestion.StudentAnsAttempts.forEach(
                                          stuAnswer => {
                                            total_time += parseInt(
                                              stuAnswer.submitted_on
                                            )
                                          }
                                        )
                                        count_answer += 1 // Increment count_answer by 1 for each occurrence
                                      }

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length > 0
                                      ) {
                                        let filteredManualMark = []

                                        // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                        // if(filteredManualMark.length > 0){
                                        //   manual_mark += 1;
                                        // }
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            total_coins += 2
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                                return (
                                  <Draggable
                                    isDragDisabled={switch2}
                                    key={activity.pk_lesson_activity_id}
                                    draggableId={activity.pk_lesson_activity_id.toString()}
                                    index={index1}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() =>
                                          setActivityNavSeleceted(index1)
                                        }
                                        className={
                                          ActivityNavSeleceted === index1
                                            ? "active card-activity"
                                            : "card-activity"
                                        }
                                      >
                                        <div
                                          className={`${(() => {
                                            if (
                                              sessionStudentData &&
                                              sessionStudentData.session &&
                                              sessionStudentData.session
                                                .ses_is_completed === 1
                                            ) {
                                              if (
                                                activity.status == "1" &&
                                                activity.mastryScore
                                              ) {
                                                const score = parseInt(
                                                  activity.mastryScore,
                                                  10
                                                )
                                                if (score > 75)
                                                  return "activity-card-success"
                                                if (score < 75)
                                                  return "activity-card-blue"
                                              } else if (
                                                activity.status == "1"
                                              ) {
                                                return "activity-card-blue"
                                              }
                                              return "activity-card-danger"
                                            }
                                            return ""
                                          })()} set1 bg-supermild`}
                                          data_key={
                                            activity.pk_lesson_activity_key
                                          }
                                        >
                                          <span className="text-danger font-size-12 me-2">
                                            {activity && activity.roll_date
                                              ? "Rolled over from" +
                                                moment(
                                                  activity.roll_date,
                                                  moment.ISO_8601
                                                ).format("DD/MMM/YYYY")
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {activity.status == "0"
                                              ? "Incomplete"
                                              : activity.status == "1"
                                              ? "Completed"
                                              : null}
                                          </span>
                                          <span
                                            className={`${
                                              activity.status == "0"
                                                ? "text-danger"
                                                : activity.status == "1"
                                                ? "text-success"
                                                : ""
                                            } font-size-12 me-2`}
                                          >
                                            {count_answer != null &&
                                            total_question != null
                                              ? count_answer +
                                                "/" +
                                                total_question +
                                                " attempted"
                                              : null}
                                          </span>
                                          <span className="text-danger font-size-12 me-2">
                                            {sessionStudentData?.session
                                              ?.ses_is_completed === 1
                                              ? activity.mastryScore
                                                ? `Score: ${activity.mastryScore}`
                                                : `Score: 0`
                                              : null}
                                          </span>
                                          {["top"].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                  className="custom-tooltip"
                                                >
                                                  Comment:{" "}
                                                  {activity?.tutor_comments ||
                                                    "No comments available"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                variant="secondary"
                                                className="no-bdr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                            {activity.level_name &&
                                            activity.level_name != ""
                                              ? activity.level_name + " - "
                                              : null}
                                            {activity.topic_name &&
                                            activity.topic_name != ""
                                              ? activity.topic_name + " - "
                                              : null}
                                            {activity.activity_node[0] &&
                                            activity.activity_node[0]
                                              .node_name != ""
                                              ? activity.activity_node[0]
                                                  .node_name
                                              : null}
                                          </h6>

                                          <div className="d-flex justify-content-between mb-2">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={reel}
                                                  alt=""
                                                />
                                              </span>
                                              WorkSheet
                                            </div>
                                            <Dropdown
                                              isOpen={
                                                dropdownOpen[
                                                  activity
                                                    .pk_lesson_activity_key
                                                ]
                                              }
                                              toggle={() =>
                                                toggleDropdown(
                                                  activity.pk_lesson_activity_key
                                                )
                                              }
                                            >
                                              <DropdownToggle className="p-0 bg-white">
                                                <i className="mdi mdi-dots-horizontal"></i>{" "}
                                              </DropdownToggle>
                                              <DropdownMenu className="custom-position">
                                                {manual_mark_review === 0 &&
                                                activity.status == "1" ? (
                                                  <>
                                                    <DropdownItem
                                                      onClick={() =>
                                                        onManualMarkingActivity(
                                                          activity
                                                        )
                                                      }
                                                    >
                                                      <span>
                                                        <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                      </span>{" "}
                                                      Mark
                                                    </DropdownItem>
                                                  </>
                                                ) : null}
                                                <DropdownItem
                                                  onClick={() =>
                                                    onPreviewActivity(activity)
                                                  }
                                                >
                                                  Preview
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    handle_commend(activity)
                                                  }}
                                                >
                                                  Add Comment
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={
                                                    () =>
                                                      onActivityToHomework(
                                                        activity.pk_lesson_activity_key,
                                                        1
                                                      ) //homework move
                                                  }
                                                >
                                                  Move To Homework
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    onDelectActivityPopup(
                                                      activity.pk_lesson_activity_key
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>

                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              <span>
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={duration}
                                                  alt=""
                                                />
                                              </span>
                                              {activity.total_activity_time
                                                ? activity.total_activity_time
                                                : 0}{" "}
                                              mins
                                            </div>

                                            <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                              {" "}
                                              <img
                                                className="me-05"
                                                width={24}
                                                src={awicon}
                                                alt=""
                                              />
                                              {activity.coins_earned
                                                ? activity.coins_earned
                                                : 0}
                                              /{total_coins}{" "}
                                            </div>
                                          </div>

                                          <div className="question-count mt-2">
                                            <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                              {/* {activity.LessonActivitiesQuestions &&
                                              activity.LessonActivitiesQuestions
                                                .length > 0
                                                ? activity.LessonActivitiesQuestions.map(
                                                    (
                                                      activityQuestion,
                                                      index2
                                                    ) => {
                                                      let color = ""

                                                      if (
                                                        activityQuestion.question &&
                                                        activityQuestion
                                                          .question.length > 0
                                                      ) {
                                                        activityQuestion.question.forEach(
                                                          (
                                                            question,
                                                            index3
                                                          ) => {
                                                            if (
                                                              question
                                                                .StudentAnsAttempts
                                                                .length > 0
                                                            ) {
                                                              question.StudentAnsAttempts.forEach(
                                                                (
                                                                  answerattquestion,
                                                                  index4
                                                                ) => {
                                                                  color +=
                                                                    answerattquestion.points_obtained
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )
                                                      }

                                                      // console.log(color); // This logs the concatenated string, not each value separately

                                                      // Parse color to an integer
                                                      const colorInt =
                                                        parseInt(color)

                                                      return null
                                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                      // </li>
                                                    }
                                                  )
                                                : null} */}
                                            </ul>
                                          </div>
                                          {manual_mark_review === 0 ? (
                                            <div class="manual_mark_div">
                                              <span className="d-flex align-items-center gap-1">
                                                <i className="font-size-18 mdi mdi-alert-outline"></i>
                                                <p className="font-size-12 mb-0">
                                                  Manual marking required
                                                </p>
                                              </span>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              } else {
                                return null
                              }
                            })}
                            {/* //  }else {
                    //   return (null)
                    // }
                  // })} */}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>

              <div className="kb-child ehp-ch_height ehp-w bg-white p-2 border-0 state-bg">
                <div className="ss-details border-bottom1 mb-2">
                  <h5 className="mb-0 text-dark font-size-16 fw-700">
                    Homework
                  </h5>
                  <div className="">
                    <Label
                      className="mb-1 ms-0 font-size-15 fw-700"
                      style={{ color: "#4d4d4d" }}
                    >
                      Message From Tutor
                    </Label>

                    <Input
                      className="tp-height"
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachangeHome(e)
                      }}
                      value={HomeWorkNote}
                    />
                    {/* {textareabadge ? (
                      <span className="badgecount badge badge-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null} */}
                  </div>
                </div>

                <div className="lesson-plan-wrapper bg-white p-2">
                  {sessionStudentData &&
                    sessionStudentData.session &&
                    sessionStudentData.session.tbl_session_time &&
                    sessionStudentData.session.tbl_session_time.tbl_student_enrolment.map(
                      (enrolment, index) => {
                        return (
                          <div className="section-divider">
                            <div className="d-flex justify-content-between list-unstyled mb-2">
                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                {" "}
                                <img
                                  className="me-05"
                                  width={24}
                                  src={awicon}
                                  alt=""
                                />
                                {CountTotalCoinsForActivity(homeworkActivityArray) || "-"}{" "}
                              </div>
                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                <span>
                                  {" "}
                                  <img
                                    className="me-05"
                                    width={24}
                                    src={duration}
                                    alt=""
                                  />
                                </span>
                                {CountHoursForActivity(homeworkActivityArray) || "-"}
                              </div>
                            </div>

                            <div className="d-flex gap-5 mb-3 align-items-center justify-content-between">
                              <div className=" pe-3">
                                <h6 class="mb-0 font-size-14 fw-700 text-dark">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session
                                    .tbl_session_time &&
                                  sessionStudentData.session.tbl_session_time
                                    .sest_name
                                    ? sessionStudentData.session
                                        .tbl_session_time.sest_name
                                    : "-"}
                                </h6>
                                <p class="mb-0 fs-sans-s font-size-12">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session
                                    .tbl_session_time &&
                                  sessionStudentData.session.tbl_session_time
                                    .sest_type
                                    ? sessionStudentData.session
                                        .tbl_session_time.sest_type
                                    : "-"}
                                </p>
                              </div>
                              <div className="text-end">
                                <h6 class="mb-0 font-size-12 fw-700 text-dark">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.ses_date
                                    ? moment(
                                        sessionStudentData.session.ses_date,
                                        moment.ISO_8601
                                      ).format("DD MMM YYYY (ddd)")
                                    : "-"}
                                </h6>
                                <p class="mb-0 fs-sans-s font-size-12">
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.ses_start_time
                                    ? moment(
                                        sessionStudentData.session
                                          .ses_start_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")
                                    : "-"}{" "}
                                  -{" "}
                                  {sessionStudentData &&
                                  sessionStudentData.session &&
                                  sessionStudentData.session.ses_end_time
                                    ? moment(
                                        sessionStudentData.session
                                          .ses_end_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="switch-wrapper">
                                {!switchhome ? "Random" : "Sequential"}{" "}
                                <div className="sw-child">
                                  <Switch
                                    // uncheckedIcon={<Offsymbol />}
                                    // checkedIcon={<OnSymbol />}
                                    id="homeActivity"
                                    onColor="#a2a2a2"
                                    onChange={() => {
                                      setswitchhome(!switchhome)
                                    }}
                                    checked={switchhome}
                                  />
                                </div>
                              </div>
                              <div className="switch-wrapper">
                                {!switchhome ? (
                                  <i class="mdi mdi-shuffle-variant"></i>
                                ) : (
                                  <i class=" mdi mdi-sort-ascending"></i>
                                )}{" "}
                              </div>
                            </div>
                            <Link
                              to={"/session-newactivity"}
                              className="btn btn-secondary w-100"
                              onClick={() => handleSetTypeClick(2)}
                            >
                              Add New Activity
                            </Link>
                            <div className="d-flex justify-content-between ">
                              <div className="switch-wrapper">
                                {ActivityNavHomeSeleceted === 0 ? (
                                  ""
                                ) : (
                                  <span
                                    className="bg-white p-1 px-2 rounded-4 pe-auto"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleHomePrevious}
                                  >
                                    <i className="mdi mdi-chevron-left"></i>
                                  </span>
                                )}
                              </div>
                              <div className="switch-wrapper">
                                {homeworkActivityArray.length > 0 ? (
                                  ActivityNavHomeSeleceted ===
                                  homeworkActivityArray.length - 1 ? (
                                    ""
                                  ) : (
                                    <span
                                      className="bg-white p-1 px-2 rounded-4 pe-auto"
                                      style={{ cursor: "pointer" }}
                                      onClick={handleHomeNext}
                                    >
                                      <i className="mdi mdi-chevron-right"></i>
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      }
                    )}

                  {sessionStudentData &&
                    sessionStudentData.session &&
                    sessionStudentData.session.tbl_session_time &&
                    sessionStudentData.session.tbl_session_time
                      .tbl_student_enrolment.length === 0 && (
                      <div className="section-divider">
                        <div className="d-flex justify-content-between list-unstyled mb-2">
                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                            {" "}
                            <img
                              className="me-05"
                              width={24}
                              src={awicon}
                              alt=""
                            />
                            -{" "}
                          </div>
                          <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                            <span>
                              {" "}
                              <img
                                className="me-05"
                                width={24}
                                src={duration}
                                alt=""
                              />
                            </span>
                            -
                          </div>
                        </div>

                        <div className="d-flex gap-5 mb-3 align-items-center justify-content-between">
                          <div className=" pe-3">
                            <h6 class="mb-0 font-size-14 fw-700 text-dark">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.tbl_session_time &&
                              sessionStudentData.session.tbl_session_time
                                .sest_name
                                ? sessionStudentData.session.tbl_session_time
                                    .sest_name
                                : "-"}
                            </h6>
                            <p class="mb-0 fs-sans-s font-size-12">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.tbl_session_time &&
                              sessionStudentData.session.tbl_session_time
                                .sest_type
                                ? sessionStudentData.session.tbl_session_time
                                    .sest_type
                                : "-"}
                            </p>
                          </div>
                          <div className="text-end">
                            <h6 class="mb-0 font-size-14 fw-700 text-dark">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.ses_date
                                ? moment(
                                    sessionStudentData.session.ses_date,
                                    moment.ISO_8601
                                  ).format("DD MMM YYYY (ddd)")
                                : "-"}
                            </h6>
                            <p class="mb-0 fs-sans-s font-size-12">
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.ses_start_time
                                ? moment(
                                    sessionStudentData.session.ses_start_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                : "-"}{" "}
                              -{" "}
                              {sessionStudentData &&
                              sessionStudentData.session &&
                              sessionStudentData.session.ses_end_time
                                ? moment(
                                    sessionStudentData.session.ses_end_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")
                                : "-"}
                            </p>
                          </div>
                        </div>

                        {/* <div>
                          Random  <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#a2a2a2"
                            onChange={() => {
                              setswitch2(!switch2);
                            }}
                            checked={switch2}
                          />
                        </div> */}
                        <Link
                          to={"/session-newactivity"}
                          className="btn btn-secondary w-100"
                          onClick={() => handleSetTypeClick(2)}
                        >
                          Add New Activity
                        </Link>
                      </div>
                    )}
                  {/* <div className="events rm-heignt border-bottom1 pb-3 mb-3 mt-0"> */}
                  <div className="events border-bottom1 pb-3 mb-3 mt-0">
                    {/* {sessionHomeWorkData &&
                  sessionHomeWorkData.session &&
                  sessionHomeWorkData.session.tbl_session_time &&
                  sessionHomeWorkData.session.tbl_session_time.tbl_student_enrolment.map((enrolment, index) => {



                  
          
                    if (enrolment.tbl_student && enrolment.tbl_student.Lesson && enrolment.tbl_student.Lesson.LessonActivities.length > 0) { */}

                    <DragDropContext onDragEnd={handleHomeDragEnd}>
                      <Droppable droppableId="homeworkActivities">
                        {provided => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {
                              homeworkActivityArray.map((activity, index1) => {
                                if (
                                  activity.activity_type === 0 &&
                                  activity.fk_sub_id === 2
                                ) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0

                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0
                                  //let percentage = 0;
                                  //let manual_mark = 0;

                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []

                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          // if(filteredManualMark.length > 0){
                                          //   manual_mark += 1;
                                          // }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              question.question_parts &&
                                                question.question_parts.length >
                                                  0 &&
                                                question.question_parts.forEach(
                                                  (questionpat, index4) => {
                                                    total_question += 1

                                                    total_coins += 2
                                                    if (
                                                      questionpat.StudentAnsAttempts &&
                                                      questionpat
                                                        .StudentAnsAttempts
                                                        .length > 0
                                                    ) {
                                                      questionpat.StudentAnsAttempts.forEach(
                                                        stuAnswer => {
                                                          total_time +=
                                                            parseInt(
                                                              stuAnswer.submitted_on
                                                            )
                                                        }
                                                      )
                                                      count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                    }
                                                  }
                                                )
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0]
                                                .node_name != ""
                                                ? activity.activity_node[0]
                                                    .node_name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Assessment
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      tog_Assessment()
                                                      fetchNextAssessmentActivityData(
                                                        activity.fk_sub_id,
                                                        activity.pk_lesson_activity_key,
                                                        activity.marked_for_homework
                                                      )
                                                    }}
                                                  >
                                                    View Activities
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""
                                                        let attempt = ""
                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .question_parts
                                                                  .length > 0
                                                              ) {
                                                                question.question_parts.forEach(
                                                                  (
                                                                    partQuestion,
                                                                    index4
                                                                  ) => {
                                                                    if (
                                                                      partQuestion
                                                                        .StudentAnsAttempts
                                                                        .length >
                                                                      0
                                                                    ) {
                                                                      partQuestion.StudentAnsAttempts.forEach(
                                                                        (
                                                                          answerattquestion,
                                                                          index5
                                                                        ) => {
                                                                          attempt += 1
                                                                          color +=
                                                                            answerattquestion.points_obtained
                                                                        }
                                                                      )
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        //console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)
                                                        const attemptInt =
                                                          parseInt(attempt)
                                                        return (
                                                          <li
                                                            key={index2}
                                                            className={`${
                                                              attemptInt >= 2 &&
                                                              colorInt === 1
                                                                ? "attempt2 text-warning bg-success-subtle"
                                                                : attemptInt >=
                                                                    1 &&
                                                                  colorInt === 2
                                                                ? "attempt1 custom-success bg-success-subtle"
                                                                : attemptInt >=
                                                                    2 &&
                                                                  colorInt === 0
                                                                ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                                : ""
                                                            }`}
                                                          >
                                                            {attemptInt >= 2 &&
                                                            colorInt === 1 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : attemptInt >=
                                                                1 &&
                                                              colorInt === 2 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : attemptInt >=
                                                                2 &&
                                                              colorInt === 0 ? (
                                                              <i className="mdi mdi-window-close"></i>
                                                            ) : (
                                                              index2 + 1
                                                            )}
                                                          </li>
                                                        )
                                                      }
                                                    )
                                                  : null}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (
                                  activity.activity_type === 0 &&
                                  activity.fk_sub_id === 1
                                ) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0
                                  //let percentage = 0;
                                  let manual_mark = 0
                                  let manual_mark_review = 0
                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        manual_mark_review += parseInt(
                                          activityQuestion.marked_for_manual_marking
                                        )
                                        if (
                                          activityQuestion.english_question &&
                                          activityQuestion.english_question
                                            .length > 0
                                        ) {
                                          activityQuestion.english_question.forEach(
                                            (question, index3) => {
                                              let filteredManualMark =
                                                question.english_question_parts.filter(
                                                  manualMark =>
                                                    parseInt(
                                                      manualMark.english_question_part_automark
                                                    ) === 0
                                                )
                                              if (
                                                filteredManualMark.length > 0
                                              ) {
                                                manual_mark += 1
                                              }

                                              question.english_question_parts.forEach(
                                                (questionpart, index4) => {
                                                  if (
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 10 ||
                                                    parseInt(
                                                      question.english_question_category_id
                                                    ) === 13
                                                  ) {
                                                    questionpart
                                                      .english_question_answers
                                                      .length > 0 &&
                                                    questionpart
                                                      .english_question_answers[0]
                                                      .english_answer_text &&
                                                    questionpart
                                                      .english_question_answers[0]
                                                      .english_answer_text != ""
                                                      ? ((total_question += 1),
                                                        (total_coins += 2))
                                                      : null
                                                  } else {
                                                    total_question += 1
                                                    total_coins += 2
                                                  }
                                                  // total_question += 1;
                                                  // total_coins +=2;
                                                  if (
                                                    questionpart.StudentAnsAttempts &&
                                                    questionpart
                                                      .StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    questionpart.StudentAnsAttempts.forEach(
                                                      stuAnswer => {
                                                        total_time += parseInt(
                                                          stuAnswer.submitted_on
                                                        )
                                                      }
                                                    )
                                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                  }
                                                }
                                              )
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0]
                                                .node_name != ""
                                                ? activity.activity_node[0]
                                                    .node_name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Assessment
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  {manual_mark &&
                                                  manual_mark > 0 &&
                                                  manual_mark_review === 0 &&
                                                  activity.status == "1" ? (
                                                    <>
                                                      <DropdownItem
                                                        onClick={() =>
                                                          onManualMarkingActivity(
                                                            activity
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                        </span>{" "}
                                                        Mark
                                                      </DropdownItem>
                                                    </>
                                                  ) : null}
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      tog_Assessment()
                                                      fetchNextAssessmentActivityData(
                                                        activity.fk_sub_id,
                                                        activity.pk_lesson_activity_key,
                                                        activity.marked_for_homework
                                                      )
                                                    }}
                                                  >
                                                    View Activities
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""
                                                        let attempt = ""
                                                        let manual_mark = 0
                                                        if (
                                                          activityQuestion.english_question &&
                                                          activityQuestion
                                                            .english_question
                                                            .length > 0
                                                        ) {
                                                          activityQuestion.english_question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              let filteredManualMark =
                                                                question.english_question_parts.filter(
                                                                  manualMark =>
                                                                    parseInt(
                                                                      manualMark.english_question_part_automark
                                                                    ) === 0
                                                                )
                                                              if (
                                                                filteredManualMark.length >
                                                                0
                                                              ) {
                                                                manual_mark += 1
                                                              }
                                                              if (
                                                                question
                                                                  .english_question_parts
                                                                  .length > 0
                                                              ) {
                                                                question.english_question_parts.forEach(
                                                                  (
                                                                    questionpart,
                                                                    index4
                                                                  ) => {
                                                                    if (
                                                                      questionpart
                                                                        .StudentAnsAttempts
                                                                        .length >
                                                                      0
                                                                    ) {
                                                                      questionpart.StudentAnsAttempts.forEach(
                                                                        (
                                                                          answerattquestion,
                                                                          index5
                                                                        ) => {
                                                                          attempt += 1
                                                                          color +=
                                                                            answerattquestion.points_obtained
                                                                        }
                                                                      )
                                                                    }
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)
                                                        const attemptInt =
                                                          parseInt(attempt)
                                                        return (
                                                          <li
                                                            key={index2}
                                                            className={`${
                                                              manual_mark > 0
                                                                ? ""
                                                                : attemptInt >=
                                                                    2 &&
                                                                  colorInt === 1
                                                                ? "attempt2 text-warning bg-success-subtle"
                                                                : attemptInt >=
                                                                    1 &&
                                                                  colorInt >= 2
                                                                ? "attempt1 custom-success bg-success-subtle"
                                                                : attemptInt >=
                                                                    2 &&
                                                                  colorInt === 0
                                                                ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                                : ""
                                                            }`}
                                                          >
                                                            {manual_mark > 0 ? (
                                                              "M"
                                                            ) : attemptInt >=
                                                                2 &&
                                                              colorInt === 1 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : attemptInt >=
                                                                1 &&
                                                              colorInt >= 2 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : attemptInt >=
                                                                2 &&
                                                              colorInt === 0 ? (
                                                              <i className="mdi mdi-window-close"></i>
                                                            ) : (
                                                              index2 + 1
                                                            )}
                                                          </li>
                                                        )
                                                      }
                                                    )
                                                  : null}
                                              </ul>
                                            </div>
                                            {manual_mark &&
                                            manual_mark > 0 &&
                                            manual_mark_review === 0 ? (
                                              <div class="manual_mark_div">
                                                <span className="d-flex align-items-center gap-1">
                                                  <i className="font-size-18 mdi mdi-alert-outline"></i>
                                                  <p className="font-size-12 mb-0">
                                                    Manual marking required
                                                  </p>
                                                </span>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (activity.activity_type === 1) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0

                                  //let manual_mark = 0;

                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []

                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          // if(filteredManualMark.length > 0){
                                          //   manual_mark += 1;
                                          // }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              total_question += 1
                                              total_coins += 2
                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0].name !=
                                                ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Video
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handleRowEditActivityClick(
                                                        activity
                                                      )
                                                      tog_largeEdit()
                                                    }}
                                                  >
                                                    Edit
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      true
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {/* {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return null
                                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                        // </li>
                                                      }
                                                    )
                                                  : null} */}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (
                                  activity.activity_type === 2 &&
                                  activity.fk_sub_id === 1
                                ) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0
                                  let manual_mark = 0
                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        if (
                                          activityQuestion.english_question &&
                                          activityQuestion.english_question
                                            .length > 0
                                        ) {
                                          let filteredManualMark = []
                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          //   if(filteredManualMark.length > 0){
                                          //     manual_mark += 1;
                                          //   }
                                          activityQuestion.english_question.forEach(
                                            (question, index3) => {
                                              total_question += 1
                                              total_coins += 2
                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topics &&
                                              activity.topics.length > 0
                                                ? getUniqueTopicsNames(
                                                    activity.topics
                                                  )
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Diagnostics
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      tog_Diagnostics()
                                                      fetchNextDiagnosticsActivityData(
                                                        activity
                                                      )
                                                    }}
                                                  >
                                                    View Activities
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.english_question &&
                                                          activityQuestion
                                                            .english_question
                                                            .length > 0
                                                        ) {
                                                          activityQuestion.english_question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return (
                                                          <li
                                                            key={index2}
                                                            className={`${
                                                              colorInt === 1
                                                                ? "attempt2 text-warning bg-success-subtle"
                                                                : colorInt === 2
                                                                ? "attempt1 custom-success bg-success-subtle"
                                                                : colorInt === 0
                                                                ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                                : ""
                                                            }`}
                                                          >
                                                            {colorInt === 1 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : colorInt ===
                                                              2 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : colorInt ===
                                                              0 ? (
                                                              <i className="mdi mdi-window-close"></i>
                                                            ) : (
                                                              index2 + 1
                                                            )}
                                                          </li>
                                                        )
                                                      }
                                                    )
                                                  : null}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (
                                  activity.activity_type === 2 &&
                                  activity.fk_sub_id === 2
                                ) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0

                                  let manual_mark = 0
                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []
                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          //   if(filteredManualMark.length > 0){
                                          //     manual_mark += 1;
                                          //   }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              total_question += 1
                                              total_coins += 2
                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topics &&
                                              activity.topics.length > 0
                                                ? getUniqueTopicsNames(
                                                    activity.topics
                                                  )
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Diagnostics
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      tog_Diagnostics()
                                                      fetchNextDiagnosticsActivityData(
                                                        activity
                                                      )
                                                    }}
                                                  >
                                                    View Activities
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return (
                                                          <li
                                                            key={index2}
                                                            className={`${
                                                              colorInt === 1
                                                                ? "attempt2 text-warning bg-success-subtle"
                                                                : colorInt === 2
                                                                ? "attempt1 custom-success bg-success-subtle"
                                                                : colorInt === 0
                                                                ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                                : ""
                                                            }`}
                                                          >
                                                            {colorInt === 1 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : colorInt ===
                                                              2 ? (
                                                              <i className="fas fa-check"></i>
                                                            ) : colorInt ===
                                                              0 ? (
                                                              <i className="mdi mdi-window-close"></i>
                                                            ) : (
                                                              index2 + 1
                                                            )}
                                                          </li>
                                                        )
                                                      }
                                                    )
                                                  : null}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (activity.activity_type === 3) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0

                                  //let manual_mark = 0;

                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []

                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          // if(filteredManualMark.length > 0){
                                          //   manual_mark += 1;
                                          // }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              total_question += 1
                                              total_coins += 2
                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0].name !=
                                                ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Link
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handleRowEditActivityClick(
                                                        activity
                                                      )
                                                      tog_largeEdit()
                                                    }}
                                                  >
                                                    Edit
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      true
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {/* {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return null
                                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                        // </li>
                                                      }
                                                    )
                                                  : null} */}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (activity.activity_type === 4) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0
                                  //let percentage = 0;
                                  //let manual_mark = 0;

                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []

                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          // if(filteredManualMark.length > 0){
                                          //   manual_mark += 1;
                                          // }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              total_question += 1
                                              total_coins += 2
                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0].name !=
                                                ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Task
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handleRowEditActivityClick(
                                                        activity
                                                      )
                                                      tog_largeEdit()
                                                    }}
                                                  >
                                                    Edit
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      true
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {/* {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return null
                                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                        // </li>
                                                      }
                                                    )
                                                  : null} */}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (activity.activity_type === 5) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0
                                  //let percentage = 0;
                                  //let manual_mark = 0;
                                  let manual_mark_review = 0
                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        total_question += 1
                                        manual_mark_review += parseInt(
                                          activityQuestion.marked_for_manual_marking
                                        )
                                        if (
                                          activityQuestion.StudentAnsAttempts &&
                                          activityQuestion.StudentAnsAttempts
                                            .length > 0
                                        ) {
                                          activityQuestion.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []

                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          // if(filteredManualMark.length > 0){
                                          //   manual_mark += 1;
                                          // }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              total_coins += 2
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0].name !=
                                                ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                WorkSheet
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  {manual_mark_review === 0 &&
                                                  activity.status == "1" ? (
                                                    <>
                                                      <DropdownItem
                                                        onClick={() =>
                                                          onManualMarkingActivity(
                                                            activity
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                        </span>{" "}
                                                        Mark
                                                      </DropdownItem>
                                                    </>
                                                  ) : null}
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handleRowEditActivityClick(
                                                        activity
                                                      )
                                                      tog_largeEdit()
                                                    }}
                                                  >
                                                    Edit
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      true
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {/* {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return null
                                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                        // </li>
                                                      }
                                                    )
                                                  : null} */}
                                              </ul>
                                            </div>
                                            {manual_mark_review === 0 ? (
                                              <div class="manual_mark_div">
                                                <span className="d-flex align-items-center gap-1">
                                                  <i className="font-size-18 mdi mdi-alert-outline"></i>
                                                  <p className="font-size-12 mb-0">
                                                    Manual marking required
                                                  </p>
                                                </span>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (
                                  activity.activity_type === 6 &&
                                  (activity.fk_sub_id === 1 ||
                                    activity.fk_sub_id === 2)
                                ) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0
                                  //let percentage = 0;
                                  //let manual_mark = 0;

                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []

                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          // if(filteredManualMark.length > 0){
                                          //   manual_mark += 1;
                                          // }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              total_question += 1
                                              total_coins += 2
                                              if (
                                                question.StudentAnsAttempts &&
                                                question.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0]
                                                .video_name != ""
                                                ? activity.activity_node[0]
                                                    .video_name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                Video
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {/* {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return null
                                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                        // </li>
                                                      }
                                                    )
                                                  : null} */}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else if (
                                  activity.activity_type === 7 &&
                                  (activity.fk_sub_id === 1 ||
                                    activity.fk_sub_id === 2)
                                ) {
                                  // var total_coins =
                                  //   activity.LessonActivitiesQuestions.length >
                                  //   0
                                  //     ? activity.LessonActivitiesQuestions
                                  //         .length * 2
                                  //     : 0
                                  // console.log(activity.activity_node[0].);
                                  let total_coins = 0
                                  let total_time = 0

                                  let total_question = 0
                                  let count_answer = 0
                                  //let percentage = 0;
                                  //let manual_mark = 0;
                                  let manual_mark_review = 0
                                  if (
                                    activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                  ) {
                                    //total_question += activity.LessonActivitiesQuestions.length;

                                    activity.LessonActivitiesQuestions.forEach(
                                      (activityQuestion, index2) => {
                                        total_question += 1
                                        manual_mark_review += parseInt(
                                          activityQuestion.marked_for_manual_marking
                                        )
                                        if (
                                          activityQuestion.StudentAnsAttempts &&
                                          activityQuestion.StudentAnsAttempts
                                            .length > 0
                                        ) {
                                          activityQuestion.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }

                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question.length > 0
                                        ) {
                                          let filteredManualMark = []

                                          // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                          // if(filteredManualMark.length > 0){
                                          //   manual_mark += 1;
                                          // }
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              total_coins += 2
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                  return (
                                    <Draggable
                                      isDragDisabled={switchhome}
                                      key={activity.pk_lesson_activity_id}
                                      draggableId={activity.pk_lesson_activity_id.toString()}
                                      index={index1}
                                    >
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() =>
                                            setActivityNavHomeSeleceted(index1)
                                          }
                                          className={
                                            ActivityNavHomeSeleceted === index1
                                              ? "active card-home-activity"
                                              : "card-home-activity"
                                          }
                                        >
                                          <div
                                            className={`${(() => {
                                              if (
                                                sessionStudentData &&
                                                sessionStudentData.session &&
                                                sessionStudentData.session
                                                  .ses_is_completed === 1
                                              ) {
                                                if (
                                                  activity.status == "1" &&
                                                  activity.mastryScore
                                                ) {
                                                  const score = parseInt(
                                                    activity.mastryScore,
                                                    10
                                                  )
                                                  if (score > 75)
                                                    return "activity-card-success"
                                                  if (score < 75)
                                                    return "activity-card-blue"
                                                } else if (
                                                  activity.status == "1"
                                                ) {
                                                  return "activity-card-blue"
                                                }
                                                return "activity-card-danger"
                                              }
                                              return ""
                                            })()} set1 bg-supermild`}
                                          >
                                            <span className="text-danger font-size-12 me-2">
                                              {activity && activity.roll_date
                                                ? "Rolled over from" +
                                                  moment(
                                                    activity.roll_date,
                                                    moment.ISO_8601
                                                  ).format("DD/MMM/YYYY")
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {activity.status == "0"
                                                ? "Incomplete"
                                                : activity.status == "1"
                                                ? "Completed"
                                                : null}
                                            </span>
                                            <span
                                              className={`${
                                                activity.status == "0"
                                                  ? "text-danger"
                                                  : activity.status == "1"
                                                  ? "text-success"
                                                  : ""
                                              } font-size-12 me-2`}
                                            >
                                              {count_answer != null &&
                                              total_question != null
                                                ? count_answer +
                                                  "/" +
                                                  total_question +
                                                  " attempted"
                                                : null}
                                            </span>
                                            <span className="text-danger font-size-12 me-2">
                                              {sessionStudentData?.session
                                                ?.ses_is_completed === 1
                                                ? activity.mastryScore
                                                  ? `Score: ${activity.mastryScore}`
                                                  : `Score: 0`
                                                : null}
                                            </span>
                                            {["top"].map(placement => (
                                              <OverlayTrigger
                                                key={placement}
                                                placement={placement}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-${placement}`}
                                                    className="custom-tooltip"
                                                  >
                                                    Comment:{" "}
                                                    {activity?.tutor_comments ||
                                                      "No comments available"}
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  variant="secondary"
                                                  className="no-bdr"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="fas fa-info-circle"></i>
                                                </span>
                                              </OverlayTrigger>
                                            ))}
                                            <h6 className="text-dark border-bottom pb-2 mb-2 mt-2 font-size-13 lh-1">
                                              {activity.level_name &&
                                              activity.level_name != ""
                                                ? activity.level_name + " - "
                                                : null}
                                              {activity.topic_name &&
                                              activity.topic_name != ""
                                                ? activity.topic_name + " - "
                                                : null}
                                              {activity.activity_node[0] &&
                                              activity.activity_node[0]
                                                .node_name != ""
                                                ? activity.activity_node[0]
                                                    .node_name
                                                : null}
                                            </h6>

                                            <div className="d-flex justify-content-between mb-2">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={reel}
                                                    alt=""
                                                  />
                                                </span>
                                                WorkSheet
                                              </div>
                                              <Dropdown
                                                isOpen={
                                                  dropdownOpen[
                                                    activity
                                                      .pk_lesson_activity_key
                                                  ]
                                                }
                                                toggle={() =>
                                                  toggleDropdown(
                                                    activity.pk_lesson_activity_key
                                                  )
                                                }
                                              >
                                                <DropdownToggle className="p-0 bg-white">
                                                  <i className="mdi mdi-dots-horizontal"></i>{" "}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-position">
                                                  {manual_mark_review === 0 &&
                                                  activity.status == "1" ? (
                                                    <>
                                                      <DropdownItem
                                                        onClick={() =>
                                                          onManualMarkingActivity(
                                                            activity
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                                        </span>{" "}
                                                        Mark
                                                      </DropdownItem>
                                                    </>
                                                  ) : null}
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onPreviewActivity(
                                                        activity
                                                      )
                                                    }
                                                  >
                                                    Preview
                                                  </DropdownItem>
                                                  <DropdownItem
                                                  onClick={() => {
                                                    tog_large()
                                                    setnewactivityhomework(
                                                      false
                                                    )
                                                  }}
                                                >
                                                  Add New Task
                                                </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() => {
                                                      handle_commend(activity)
                                                    }}
                                                  >
                                                    Add Comment
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={
                                                      () =>
                                                        onActivityToHomework(
                                                          activity.pk_lesson_activity_key,
                                                          0
                                                        ) //lesson move
                                                    }
                                                  >
                                                    Move To Lesson Plan
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    onClick={() =>
                                                      onDelectActivityPopup(
                                                        activity.pk_lesson_activity_key
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                <span>
                                                  {" "}
                                                  <img
                                                    className="me-05"
                                                    width={24}
                                                    src={duration}
                                                    alt=""
                                                  />
                                                </span>
                                                {activity.total_activity_time
                                                  ? activity.total_activity_time
                                                  : 0}{" "}
                                                mins
                                              </div>

                                              <div className="d-flex align-items-center fw-400 font-size-12 text-dark">
                                                {" "}
                                                <img
                                                  className="me-05"
                                                  width={24}
                                                  src={awicon}
                                                  alt=""
                                                />
                                                {activity.coins_earned
                                                  ? activity.coins_earned
                                                  : 0}
                                                /{total_coins}{" "}
                                              </div>
                                            </div>

                                            <div className="question-count mt-2">
                                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-1 mt-1">
                                                {/* {activity.LessonActivitiesQuestions &&
                                                activity
                                                  .LessonActivitiesQuestions
                                                  .length > 0
                                                  ? activity.LessonActivitiesQuestions.map(
                                                      (
                                                        activityQuestion,
                                                        index2
                                                      ) => {
                                                        let color = ""

                                                        if (
                                                          activityQuestion.question &&
                                                          activityQuestion
                                                            .question.length > 0
                                                        ) {
                                                          activityQuestion.question.forEach(
                                                            (
                                                              question,
                                                              index3
                                                            ) => {
                                                              if (
                                                                question
                                                                  .StudentAnsAttempts
                                                                  .length > 0
                                                              ) {
                                                                question.StudentAnsAttempts.forEach(
                                                                  (
                                                                    answerattquestion,
                                                                    index4
                                                                  ) => {
                                                                    color +=
                                                                      answerattquestion.points_obtained
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          )
                                                        }

                                                        // console.log(color); // This logs the concatenated string, not each value separately

                                                        // Parse color to an integer
                                                        const colorInt =
                                                          parseInt(color)

                                                        return null
                                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 text-danger bg-danger-subtle' : ''}`}>
                                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                                        // </li>
                                                      }
                                                    )
                                                  : null} */}
                                              </ul>
                                            </div>
                                            {manual_mark_review === 0 ? (
                                              <div class="manual_mark_div">
                                                <span className="d-flex align-items-center gap-1">
                                                  <i className="font-size-18 mdi mdi-alert-outline"></i>
                                                  <p className="font-size-12 mb-0">
                                                    Manual marking required
                                                  </p>
                                                </span>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                } else {
                                  return null
                                }
                              })
                              //    }else {
                              //     return (null)
                              //   }
                              // })}
                            }

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>
            </div>

            <div className="btn-group mt-3">
              <Button
                className="bt-pg-bk-btn"
                onClick={() => window.history.back()}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                className="bt-pg-sb-btn"
                disabled={isNotesSubmitting}
                onClick={() => onUpdateNotes()}
              >
                {" "}
                Update{" "}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateLesson.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CreateLesson)
