import PropTypes from "prop-types"
import React, { useState, useCallback, useRef, useEffect } from "react"

import {
  Container,
  Table,
  Card,
  CardBody,
  Collapse,
  Button,
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Label,
  Modal,
  Form,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import ReactPlayer from 'react-player'
// import PDFViewer from 'pdf-viewer-reactjs'
//import { PDFEditor } from 'react-pdf-editor';

//import "react-pdf-editor/dist/style.css"
//import WebViewer from '@pdftron/webviewer';

import Loader from "react-spinner-loader"
import { useFormik } from "formik"
import * as yup from "yup"
import { ToastContainer, toast } from "react-toastify"

import Dropzone from "react-dropzone"

import { useNavigate, useLocation, Link } from "react-router-dom"

import Select from "react-select"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
//class

import classnames from "classnames"

import reel from "../../assets/images/services-icon/reel.png"
import awicon from "../../assets/images/services-icon/coin.png"
import st2 from "../../assets/images/services-icon/st2.png"

import moment from "moment"


import { fabric } from 'fabric';
import { pdfjs } from 'react-pdf';
import jsPDF from 'jspdf';

//popup
import axios from "axios"
import { SECURITY_KEY, BASE_URL } from "../../global";
const ManualMarkLCMSMark = props => {
  const navigate = useNavigate()

  const viewer = useRef(null);


  const [modal_large, setmodal_large] = useState(false)
  const tog_large = () => {
    setmodal_large(!modal_large)
  }
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [customActiveTab1, setcustomActiveTab1] = useState("0")
  const [searchData, setSearchData] = useState('')

  const [subjectIdData, setSubjectIdData] = useState(1)
  const [yearlevelData, setYearlevelIdData] = useState(0)
  const [statusIdData, setStatusIdData] = useState(0)
  const [loader, setLoader] = useState(true)

  const [showPrevPopup, setShowPrevPopup] = useState(false)
  const [popupPreviewAssessData, setPopupPreviewAssessData] = useState([])
  const [popupPreviewDiagnosData, setPopupPreviewDiagnosData] = useState([])
  const [popupPreviewLibraryData, setPopupPreviewLibraryData] = useState([])
  const [popupPreviewVideoData, setPopupPreviewVideoData] = useState([])
  const [popupPreviewWorksheetData, setPopupPreviewWorksheetData] = useState([])

  const tog_large1 = () => {
    setPopupPreviewAssessData([]);
    setPopupPreviewDiagnosData([]);
    setPopupPreviewLibraryData([]);
    setPopupPreviewVideoData([]);
    setPopupPreviewWorksheetData([]);
    setShowPrevPopup(!showPrevPopup)

  }



  function toggleCustom1(tab) {
    if (customActiveTab1 !== tab) {
      setcustomActiveTab1(tab)
    }
  }
  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
    if (tab == 1) {
      fetchAssessmentActivitiesData(searchData, subjectIdData, yearlevelData, statusIdData)
    } else if (tab == 2) {
      fetchDiagnosticsActivitiesData(searchData, subjectIdData, yearlevelData, statusIdData)
    } else if (tab == 3) {
      fetchlistLibraryData(searchData, subjectIdData, yearlevelData, statusIdData)
    } else if (tab == 4) {
      fetchVideoActivitiesData(searchData, subjectIdData, yearlevelData, statusIdData)
    } else if (tab == 5) {
      fetchWorksheetActivitiesData(searchData, subjectIdData, yearlevelData, statusIdData)
    }
  }

  const handleOnchangeGetSubject = event => {
    setSubjectIdData(event.value)
    setselectedGroupSubject(event)
    if (customActiveTab == 1) {
      fetchAssessmentActivitiesData(searchData, event.value, yearlevelData, statusIdData)
    } else if (customActiveTab == 2) {
      fetchDiagnosticsActivitiesData(searchData, event.value, yearlevelData, statusIdData)
    } else if (customActiveTab == 3) {
      fetchlistLibraryData(searchData, event.value, yearlevelData, statusIdData)
    } else if (customActiveTab == 4) {
      fetchVideoActivitiesData(searchData, event.value, yearlevelData, statusIdData)
    } else if (customActiveTab == 5) {
      fetchWorksheetActivitiesData(searchData, event.value, yearlevelData, statusIdData)
    }
  }

  const handleOnchangeGetYearlevel = event => {
    setYearlevelIdData(event.value)
    setselectedGroupYearlevel(event)
    if (customActiveTab == 1) {
      fetchAssessmentActivitiesData(searchData, subjectIdData, event.value, statusIdData)
    } else if (customActiveTab == 2) {
      fetchDiagnosticsActivitiesData(searchData, subjectIdData, event.value, statusIdData)
    } else if (customActiveTab == 3) {
      fetchlistLibraryData(searchData, subjectIdData, event.value, statusIdData)
    } else if (customActiveTab == 4) {
      fetchVideoActivitiesData(searchData, subjectIdData, event.value, statusIdData)
    } else if (customActiveTab == 5) {
      fetchWorksheetActivitiesData(searchData, subjectIdData, event.value, statusIdData)
    }
  }

  const handleOnchangeGetStatus = event => {
    setStatusIdData(event.value)
    setselectedGroupStatus(event)
    if (customActiveTab == 1) {
      fetchAssessmentActivitiesData(searchData, subjectIdData, yearlevelData, event.value)
    } else if (customActiveTab == 2) {
      fetchDiagnosticsActivitiesData(searchData, subjectIdData, yearlevelData, event.value)
    } else if (customActiveTab == 3) {
      fetchlistLibraryData(searchData, subjectIdData, yearlevelData, event.value)
    } else if (customActiveTab == 4) {
      fetchVideoActivitiesData(searchData, subjectIdData, yearlevelData, event.value)
    } else if (customActiveTab == 5) {
      fetchWorksheetActivitiesData(searchData, subjectIdData, yearlevelData, event.value)
    }
  }

  const handleOnchangeGetSearch = (event) => {

    const { value } = event.target;
    setSearchData(value);
    setselectedGroup(value);
    if (customActiveTab == 1) {
      fetchAssessmentActivitiesData(value, subjectIdData, yearlevelData, statusIdData)
    } else if (customActiveTab == 2) {
      fetchDiagnosticsActivitiesData(value, subjectIdData, yearlevelData, statusIdData)
    } else if (customActiveTab == 3) {
      fetchlistLibraryData(value, subjectIdData, yearlevelData, statusIdData)
    } else if (customActiveTab == 4) {
      fetchVideoActivitiesData(value, subjectIdData, yearlevelData, statusIdData)
    } else if (customActiveTab == 5) {
      fetchWorksheetActivitiesData(value, subjectIdData, yearlevelData, statusIdData)
    }
  }
  const [selectedGroup, setselectedGroup] = useState('')

  const [selectedGroupSubject, setselectedGroupSubject] = useState({
    value: 1,
    label: "English",
  })

  const [selectedGroupYearlevel, setselectedGroupYearlevel] = useState(0)
  const [selectedGroupStatus, setselectedGroupStatus] = useState(0)

  const [subjectOptions, setSubjectOptions] = useState([])
  const [yearlevelOptions, setYearlevelOptions] = useState([])

  const [assignActivity, setAssignActivity] = useState([])
  const [assignmylibraryActivity, setAssignMylibraryActivity] = useState([])
  const [assignDiagnosticsActivity, setAssignDiagnosticsActivity] = useState([])
  const [assignVideoActivity, setAssignVideoActivity] = useState([])
  const [assignWorksheetActivity, setAssignWorkSheetActivity] = useState([])
  const [assactivitiyList, setAssactivitiyList] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [isSubmitting1, setIsSubmitting1] = useState(false)
  const formRef = useRef(null)
  const [inputValue, setInputValue] = useState("")
  const [selectedOption3, setSelectedOption] = useState("")

  const [sessionStudentData, setSessionStudentData] = useState([])
  const session_key = localStorage.getItem("session_key") || null
  const student_key = localStorage.getItem("student_key") || null
  const typeAct = localStorage.getItem("typeAct") || 0

  const [existingData, setExistingValue] = useState(
    JSON.parse(localStorage.getItem("selectedData")) || []
  )

  // function handleSelectGroup(selectedGroup) {
  //   setselectedGroup(selectedGroup)
  // }

  const getUniqueSubjectNames = jsonData => {
    const uniqueSubjectNames = new Set()

    // Check if the necessary data is available
    if (
      jsonData &&
      jsonData.session &&
      jsonData.session.tbl_session_time &&
      jsonData.session.tbl_session_time.tbl_student_enrolment
    ) {
      // Iterate through student enrollments
      jsonData.session.tbl_session_time.tbl_student_enrolment.forEach(
        enrollment => {
          // Check if the necessary data is available and is an array
          if (
            enrollment &&
            enrollment.tbl_enrolment_subject &&
            Array.isArray(enrollment.tbl_enrolment_subject)
          ) {
            // Iterate through enrollment subjects
            enrollment.tbl_enrolment_subject.forEach(subject => {
              // Assuming subject_name is a property of the subject object and is not null/undefined
              if (subject && subject.subject_name != null) {
                uniqueSubjectNames.add(subject.subject_name)
              }
            })
          }
        }
      )
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectNames)
  }
  const getUniqueSubjectId = jsonData => {
    const uniqueSubjectNames = new Set()

    // Check if the necessary data is available
    if (
      jsonData &&
      jsonData.session &&
      jsonData.session.tbl_session_time &&
      jsonData.session.tbl_session_time.tbl_student_enrolment
    ) {
      // Iterate through student enrollments
      jsonData.session.tbl_session_time.tbl_student_enrolment.forEach(
        enrollment => {
          // Check if the necessary data is available and is an array
          if (
            enrollment &&
            enrollment.tbl_enrolment_subject &&
            Array.isArray(enrollment.tbl_enrolment_subject)
          ) {
            // Iterate through enrollment subjects
            enrollment.tbl_enrolment_subject.forEach(subject => {
              // Assuming subject_name is a property of the subject object and is not null/undefined
              if (subject && subject.subject_id != null) {
                uniqueSubjectNames.add(subject.subject_id)
              }
            })
          }
        }
      )
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectNames)
  }

  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  // const fetchSubjectData = async () => {
  //   try {
  //     setLoader(true)
  //     if (authUser && authUser != "" && authUser != null) {
  //       // setLoading(true);
  //       axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
  //       const response = await axios
  //         .post(BASE_URL+"master/getSubject", {
  //           session_key: session_key,
  //           student_key: student_key,
  //         })
  //         .then(response_subject => {
  //           // Assuming the API response is an array of objects with 'id' and 'value' properties

  //           const formattedSubjectOptions = [
  //             {
  //               options: [

  //                 ...response_subject.data.map(option => ({
  //                   value: option.subject_id,
  //                   label: option.sub_name,
  //                 })),
  //               ],
  //             },
  //           ];
  //           setSubjectOptions(formattedSubjectOptions)

  //           setLoader(false)
  //         })
  //         .catch(error => {
  //           console.error("Error fetching options from API: ", error)

  //           setLoader(false)
  //         })
  //     } else {
  //       navigate("/login")
  //     } /*  */
  //     // setAllData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error)
  //   }
  // }
  const fetchYearlevelData = async () => {
    try {
      setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        // setLoading(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios
          .post(BASE_URL+"master/getYearLevel", {
            student_key: localStorage.getItem("student_key"),
            subject_id: subjectIdData,
          })
          .then(response_yearlevel => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            // const formattedYearlevelOptions =[];
            // formattedYearlevelOptions.push({ value: 0, label: "All" });

            //  formattedYearlevelOptions = response_yearlevel.data.map(option => ({
            //   value: option.activity_level_id,
            //   label: option.level_name
            // }));
            const formattedYearlevelOptions = [
              {
                options: [
                  { value: 0, label: "All", selected: true },
                  ...response_yearlevel.data.map(option => ({
                    value: option.activity_level_id,
                    label: option.level_name,
                  })),
                ],
              },
            ]
            setYearlevelOptions(formattedYearlevelOptions)

            //setLoader(false)
          })
          .catch(error => {
            console.error("Error fetching options from API: ", error)
            // setLoader(false)
          })
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const fetchAssessmentActivitiesData = async (
    searchDataval,
    subjectIdDataval,
    yearlevelDataval,
    statusIdDataval
  ) => {
    setAssignActivity([])

    try {
      setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          const response = await axios
            .post(
              BASE_URL+"activity/getAssessmentActivities",
              {
                is_homework: typeAct && typeAct == 1 ? 0 : 1,
                search: searchDataval,
                session_key: session_key,
                student_key: student_key,
                subject_id: subjectIdDataval,
                activity_level_id: yearlevelDataval,
                attended: statusIdDataval,
              }
            )
            .then(responseAssignActivity => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              const activitiesData = responseAssignActivity.data

              setAssignActivity([activitiesData])

              setLoader(false)
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
              setLoader(false)
            })
        }
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const fetchDiagnosticsActivitiesData = async (
    searchDataval,
    subjectIdDataval,
    yearlevelDataval,
    statusIdDataval
  ) => {
    setAssignDiagnosticsActivity([])

    try {
      setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          const response = await axios
            .post(
              BASE_URL+"activity/getDiagnosticsActivities",
              {
                is_homework: typeAct && typeAct == 1 ? 0 : 1,
                search: searchDataval,
                session_key: session_key,
                student_key: student_key,
                subject_id: subjectIdDataval,
                activity_level_id: yearlevelDataval,
                attended: statusIdDataval,
              }
            )

            .then(responseAssignActivity => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              const activitiesData = responseAssignActivity.data

              setAssignDiagnosticsActivity([activitiesData])

              setLoader(false)
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
              setLoader(false)
            })
        }
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const fetchVideoActivitiesData = async (
    searchDataval,
    subjectIdDataval,
    yearlevelDataval,
    statusIdDataval
  ) => {
    setAssignVideoActivity([])

    try {
      setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          const response = await axios
            .post(
              BASE_URL+"activity/getVideoActivities",
              {
                is_homework: typeAct && typeAct == 1 ? 0 : 1,
                search: searchDataval,
                session_key: session_key,
                student_key: student_key,
                subject_id: subjectIdDataval,
                activity_level_id: yearlevelDataval,
                attended: statusIdDataval,
              }
            )

            .then(responseAssignActivity => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              const activitiesData = responseAssignActivity.data

              setAssignVideoActivity([activitiesData])

              setLoader(false)
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
              setLoader(false)
            })
        }
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const fetchWorksheetActivitiesData = async (
    searchDataval,
    subjectIdDataval,
    yearlevelDataval,
    statusIdDataval
  ) => {
    setAssignWorkSheetActivity([])

    try {
      setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          const response = await axios
            .post(
              BASE_URL+"activity/getWorkSheetActivities",
              {
                is_homework: typeAct && typeAct == 1 ? 0 : 1,
                search: searchDataval,
                session_key: session_key,
                student_key: student_key,
                subject_id: subjectIdDataval,
                activity_level_id: yearlevelDataval,
                attended: statusIdDataval,
              }
            )

            .then(responseAssignActivity => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              const activitiesData = responseAssignActivity.data

              setAssignWorkSheetActivity([activitiesData])

              setLoader(false)
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
              setLoader(false)
            })
        }
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const fetchselectActivityData = async activity_node_id => {
    try {
      setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        // setLoading(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios
          .post(BASE_URL+"activity/selectActivity", {
            activity_node_id: activity_node_id,
            subject_id: subjectIdData,
          })
          .then(responseAssignActivity => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            const activitiesData = responseAssignActivity.data

            // setAssignActivity([activitiesData]);

            setLoader(false)
          })
          .catch(error => {
            console.error("Error fetching options from API: ", error)
            setLoader(false)
          })
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const fetchlistLibraryData = async (searchdataval,
    subjectIdDataval,
    yearlevelDataval,
    statusIdDataval
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        setLoader(true)

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL+"activity/listOfActivities",
          {
            search: searchdataval,
            fk_sub_id: subjectIdDataval,
            session_key: session_key,
            student_key: student_key,
          }
        )
        const activitiesmylibData = responseListLibrary.data

        setAssignMylibraryActivity(activitiesmylibData)

        setLoader(false)

        //    if(responseListLibrary.data.data.data !=''){
        //      setstudentDetail(responseListLibrary.data.data.data);
        //    }else{
        //      setstudentDetail([]);
        //    }
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)
        setLoader(false)
      }
    } else {
      setLoading(false)
    }
  }
  const fetchpreviewAssessData = async (subId, nodeId) => {
    console.log(nodeId);
    if (authUser && authUser != "" && authUser != null) {
      try {
        setLoader(true)

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL+"activity/previewAssessmentDetails",
          {
            activity_node_id: nodeId,
            subject_id: subId,
            session_key: session_key,
            student_key: student_key,
          }
        )
        const activitiesmylibData = responseListLibrary.data.data;


        setSubjectIdData(subId);

        setPopupPreviewAssessData(activitiesmylibData)

        setShowPrevPopup(true)

        setLoader(false)



        //    if(responseListLibrary.data.data.data !=''){
        //      setstudentDetail(responseListLibrary.data.data.data);
        //    }else{
        //      setstudentDetail([]);
        //    }
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)
        setLoader(false)
      }
    } else {
      setLoading(false)
    }
  }
  const fetchpreviewDiagnosData = async (subId, nodeId) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        setLoader(true)

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL+"activity/previewDiagnosticsDetails",
          {
            activity_node_id: nodeId,
            subject_id: subId,
            session_key: session_key,
            student_key: student_key,
          }
        )
        const activitiesmylibData = responseListLibrary.data.data

        setSubjectIdData(subId);


        setPopupPreviewDiagnosData(activitiesmylibData)

        setShowPrevPopup(true)

        setLoader(false)

        //    if(responseListLibrary.data.data.data !=''){
        //      setstudentDetail(responseListLibrary.data.data.data);
        //    }else{
        //      setstudentDetail([]);
        //    }
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)
        setLoader(false)
      }
    } else {
      setLoading(false)
    }
  }
  const fetchpreviewLibraryData = async (subId, nodeId) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        setLoader(true)

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL+"activity/previewLibraryDetails",
          {
            tutor_library_item_key: nodeId,

          }
        )
        const activitiesmylibData = responseListLibrary.data.data
        setSubjectIdData(subId);

        setPopupPreviewLibraryData(activitiesmylibData)

        setShowPrevPopup(true)

        setLoader(false)

        //    if(responseListLibrary.data.data.data !=''){
        //      setstudentDetail(responseListLibrary.data.data.data);
        //    }else{
        //      setstudentDetail([]);
        //    }
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)
        setLoader(false)
      }
    } else {
      setLoading(false)
    }
  }
  const fetchpreviewVideoData = async (subId, nodeId, topic_id) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        setLoader(true)

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL+"activity/previewVideoDetails",
          {
            video_id: nodeId,
            subject_id: subId,
            topic_id: topic_id,
          }
        )
        const activitiesmylibData = responseListLibrary.data.data
        setSubjectIdData(subId);

        setPopupPreviewVideoData(activitiesmylibData)

        setShowPrevPopup(true)

        setLoader(false)

        //    if(responseListLibrary.data.data.data !=''){
        //      setstudentDetail(responseListLibrary.data.data.data);
        //    }else{
        //      setstudentDetail([]);
        //    }
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)
        setLoader(false)
      }
    } else {
      setLoading(false)
    }
  }
  const fetchpreviewWorksheetData = async (subId, nodeId, topic_id) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        setLoader(true)

        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseListLibrary = await axios.post(
          BASE_URL+"activity/previewWorksheetDetails",
          {
            pdf_id: nodeId,
            subject_id: subId,
            topic_id: topic_id,
          }
        )
        const activitiesmylibData = responseListLibrary.data.data
        setSubjectIdData(subId);

        setPopupPreviewWorksheetData(activitiesmylibData)

        setShowPrevPopup(true)

        setLoader(false)

        initPreviewWorksheet(activitiesmylibData.file_name);

        //    if(responseListLibrary.data.data.data !=''){
        //      setstudentDetail(responseListLibrary.data.data.data);
        //    }else{
        //      setstudentDetail([]);
        //    }
      } catch (error) {
        console.error("Error in fetching List Library deatails:", error)
        setLoader(false)
      }
    } else {
      setLoading(false)
    }
  }


  // const fetchOPHData = async () => {

  // const assactivitiyListdetails = Array.isArray(existingData) && existingData.length > 0
  //   ? existingData.map((Assessmentlistdata) => {
  //     return (
  //       <div className="bg-supermild p-2 position-relative">
  //         <div className="close-icon">
  //           <i className="mdi mdi-close-thick" onClick={() => removeLocalStorageAssignItem(Assessmentlistdata.id)}></i>
  //         </div>
  //         <h6 className="two_line-ellipse font-size-14 fw-bold text-dark">
  //           {Assessmentlistdata.name}
  //         </h6>

  //         <div className="act-info mt-3">
  //           <ul className="list-unstyled mb-0 d-flex align-items-center gap-2">
  //             <li>
  //               {" "}
  //               <img className="" width={16} src={reel} alt="" />{" "}
  //               {Assessmentlistdata.is_homework}
  //             </li>
  //             <span className="circle"></span>
  //             <li>{Assessmentlistdata.duration}</li>
  //             <span className="circle"></span>
  //             <li>
  //               {" "}
  //               <img className="" width={16} src={awicon} alt="" />{" "}
  //               {Assessmentlistdata.coin}
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     );
  //   })
  //   : '';

  //   setAssactivitiyList(assactivitiyListdetails);

  //}

  useEffect(() => {
    //fetchSubjectData()
    fetchYearlevelData()
    fetchsessDetailData()
    //fetchpreviewData()



    // fetchVideoActivitiesData(subjectIdData, yearlevelData, statusIdData);
    // fetchYearData();
    //fetchOPHData();
  }, [])

  const optionGroup = [
    {
      options: [
        { value: 0, label: "All", selected: true },
        { label: "Attempted", value: "1" },
        { label: "Unattempted", value: "2" },
      ],
    },
  ]

  const optionGroup3 = [
    {
      label: "Select Activity",
      options: [
        { label: "Video", value: "1" },
        { label: "Link", value: "3" },
        { label: "Task", value: "4" },
        { label: "Work sheet", value: "5" },
      ],
    },
  ]

  const optionGroup4 = [
    {
      label: "Select Duration",
      options: [
        { label: "Less than 2 minutes", value: "AlLess than 2 minutesl" },
        { label: "2-5 minutes", value: "2-5 minutes" },
        { label: "5-10 minutes", value: "5-10 minutes" },
        { label: "10-15 minutes", value: "10-15 minutes" },
        { label: "15-20 minutes", value: "15-20 minutes" },
        { label: "20-30 minutes", value: "20-30 minutes" },
        { label: "5-10 minutes", value: "5-10 minutes" },
        { label: "30-60 minutes", value: "30-60 minutes" },
      ],
    },
  ]

  //accordion
  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  // const [col1, setCol1] = useState(true)
  const [col2, setCol2] = useState(false)
  const [col3, setCol3] = useState(false)

  // function t_col1() {
  //   setCol1(!col1)
  //   //setCol1(true)
  //   setCol2(false)
  //   setCol3(false)
  // }
  function t_col2() {
    setCol2(!col2)
    //setCol1(false)
    //setCol2(true)
    setCol3(false)
  }
  function t_col3() {
    setCol1(false)
    setCol2(false)
    //setCol3(true)
    setCol3(!col3)
  }

  // const [customActiveTab, setCustomActiveTab] = useState('1');
  const [activeIndex, setActiveIndex] = useState(null)
  const [activeIndex1, setActiveIndex1] = useState(null)

  const toggleAccordion = index => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const toggleAccordion1 = index => {
    setActiveIndex1(activeIndex1 === index ? null : index)
  }
  //modal add

  //modal edit

  //delete modal
  const [modal_center, setmodal_center] = useState(false)
  const tog_center = () => {
    setmodal_center(!modal_center)
  }

  //tooltip
  const [ttop, setttop] = useState(false)

  const [ttop1, setttop1] = useState(false)

  // const handleCookieAssignClick = (selectedSessdata) => {

  //   const value = selectedSessdata.push(selectedSessdata);
  //   Cookies.set('selectedData', value);
  //   setCookieValue(value);
  // };

  const goToNextTab = () => {
    const currentIndex = parseInt(customActiveTab1);
    if (subjectIdData === 1) {
      if (currentIndex < popupPreviewAssessData.english_question_activities.length - 1) {
        toggleCustom1((currentIndex + 1).toString());
      }
    }
    else if (subjectIdData === 2) {
      if (currentIndex < popupPreviewAssessData.question_activities.length - 1) {
        toggleCustom1((currentIndex + 1).toString());
      }
    }

  }
  const goToNextTabDiag = () => {
    const currentIndex = parseInt(customActiveTab1);
    if (subjectIdData === 1) {
      if (currentIndex < popupPreviewDiagnosData.questionData.length - 1) {
        toggleCustom1((currentIndex + 1).toString());
      }
    }
    else if (subjectIdData === 2) {
      if (currentIndex < popupPreviewDiagnosData.questionData.length - 1) {
        toggleCustom1((currentIndex + 1).toString());
      }
    }

  }
  const goToNextTabLib = () => {
    const currentIndex = parseInt(customActiveTab1);
    if (subjectIdData === 1) {
      if (currentIndex < popupPreviewAssessData.english_question_activities.length - 1) {
        toggleCustom1((currentIndex + 1).toString());
      }
    }
    else if (subjectIdData === 2) {
      if (currentIndex < popupPreviewAssessData.question_activities.length - 1) {
        toggleCustom1((currentIndex + 1).toString());
      }
    }

  }

  const goToPrevTab = () => {
    const currentIndex = parseInt(customActiveTab1);
    if (currentIndex > 0) {
      toggleCustom1((currentIndex - 1).toString());
    }
  }
  const handleLocalStorageAssignClick = (
    topicTitle,
    topic,
    activity,
    acttype
  ) => {
    // console.log(topicTitle)
    // console.log(topic)

    let mergedData = {}

    if (acttype == 0) {
      /* Assessment */


      if (topicTitle.activity_node_id) {
        toast.error("Selected activity does not have any question.", {
          autoClose: 3000, // 3 seconds in this example
        })
      } else {


        mergedData = {
          id: topicTitle.activity_node_id,
          name: topicTitle.node_name,
          subject_id: topic.subject_id,
          topicID: topic.activity_topic_id,
          coin: topicTitle.coin,
          duration: "10 mins",
          is_homework: typeAct && typeAct == 1 ? 0 : 1,
          type: "Assessment",

          activity_node_id: topicTitle.activity_node_id,
          activity_type: 0,
          level_id: activity.activity_level_id,
          level_name: activity.level_name,

        }
      }
    } else if (acttype == 2) {
      /* Diagnostics */
      let uniqueTopicNodes = [];


      const topicNodes = topicTitle.activity_master_topic.activity_level_has_topic_nodes
        .map(topicdata => {
          if (topic.subject_id == 1) {
            if (topicdata && topicdata.activity_node && topicdata.activity_node.english_diagnostic_question_activities) {
              // Ensure topic and necessary properties exist before accessing them
              return topicdata.activity_node.english_diagnostic_question_activities.map(
                node => node.question_id
              );
            }
            else {
              return []; // Return an empty array if any necessary properties are missing
            }
          }
          else if (topic.subject_id == 2) {
            if (topicdata && topicdata.activity_node && topicdata.activity_node.diagnostic_question_activities) {
              // Ensure topic and necessary properties exist before accessing them
              return topicdata.activity_node.diagnostic_question_activities.map(
                node => node.question_id
              );
            }
            else {
              return []; // Return an empty array if any necessary properties are missing
            }

          }
          else {
            return []; // Return an empty array if any necessary properties are missing
          }

        })
        .flat(); // Flatten the array of arrays

      uniqueTopicNodes = [...new Set(topicNodes)].filter(nodeId => nodeId !== 0);
      mergedData = {
        id: uniqueTopicNodes,
        name: topicTitle.activity_master_topic.topic_name,
        subject_id: topic.subject_id,
        topicID: topicTitle.activity_master_topic.activity_topic_id,
        coin: 2,
        duration: "10 mins",
        is_homework: typeAct && typeAct == 1 ? 0 : 1,
        type: acttype && acttype == 2 ? "Diagnostics" : "",
        activity_node_id: uniqueTopicNodes,
        activity_type: 2,
        level_id: topic.activity_level_id,
        level_name: topic.level_name,
      }
    } else if (acttype == 4) {
      /* Mylibrary */

      // id
      // name
      // subject_id
      // topicID
      // coin
      // duration
      // is_homework
      // type

      mergedData = {
        id: topicTitle.pk_tutor_library_item_id,
        name: topicTitle.name,
        subject_id: topicTitle.fk_sub_id,
        topicID: 0,
        coin: 2,
        duration: topicTitle.duration,
        is_homework: typeAct && typeAct == 1 ? 0 : 1,
        type:
          topicTitle.activity_type && topicTitle.activity_type == 1
            ? "Video"
            : topicTitle.activity_type == 3
              ? "Link"
              : topicTitle.activity_type == 4
                ? "Task"
                : topicTitle.activity_type == 5
                  ? "Worksheet"
                  : "",
        activity_node_id: topicTitle.pk_tutor_library_item_id,
        activity_type: topicTitle.activity_type,
        level_id: "",
        level_name: "",
      }
    } else if (acttype == 6) {
      /* Video */

      mergedData = {
        id: topicTitle.id,
        name: topicTitle.video_name,
        subject_id: topic.subject_id,
        topicID: topic.activity_topic_id,
        coin: 2,
        duration: "10 mins",
        is_homework: typeAct && typeAct == 1 ? 0 : 1,
        type: acttype && acttype == 6 ? "Video" : "",
        activity_node_id: topicTitle.activity_node_id,
        activity_type: 6,
        level_id: activity.activity_level_id,
        level_name: activity.level_name,
      }
    } else if (acttype == 7) {
      /* Worksheet */
      mergedData = {
        id: topicTitle.id,
        name: topicTitle.activity_node.node_name,
        subject_id: topic.subject_id,
        topicID: topic.activity_topic_id,
        coin: 2,
        duration: "10 mins",
        is_homework: typeAct && typeAct == 1 ? 0 : 1,
        type: acttype && acttype == 7 ? "Worksheet" : "",
        activity_node_id: topicTitle.activity_node.activity_node_id,
        activity_type: 7,
        level_id: activity.activity_level_id,
        level_name: activity.level_name,
      }
    } else {
      /* Else */
      mergedData = {
        id: "",
        name: "",
        subject_id: 0,
        topicID: 0,
        coin: 0,
        duration: "",
        is_homework: 0,
        type: "",
        activity_node_id: "",
        activity_type: "",
        level_id: "",
        level_name: "",
      }
    }

    // Creating a new object by merging data from topicTitle and topic
    // const mergedData = {
    //   id: topicTitle.activity_node_id,
    //   name: topicTitle.node_name,
    //   subject_id: topic.subject_id,
    //   topicID: topic.activity_topic_id,
    //   coin: 2,
    //   duration: '10 mins',
    //   is_homework: typeAct,
    //   type: (acttype && acttype == 0) ? 'Assessment' : (acttype && acttype == 2) ? 'Diagnostics' : (acttype && acttype == 4) ? 'Task' : (acttype && acttype == 6) ? 'Video' : (acttype && acttype == 7) ? 'Worksheet' : '',
    //   activity_node_id: topicTitle.activity_node_id,
    //   activity_type: acttype
    // };

    //fetchselectActivityData(topicTitle.activity_node_id);

    // Get existing data from local storage or initialize an empty array
    // const existingValue = localStorage.getItem('selectedData') || '[]';
    // const existingData = JSON.parse(existingValue);

    // Concatenate the new merged data with existing data
    const newValue = existingData.concat(mergedData)

    // Convert the concatenated data into JSON format
    const jsonValue = JSON.stringify(newValue)

    // Update local storage with the new concatenated data
    localStorage.setItem("selectedData", jsonValue)

    // Optionally, update some state or perform other actions
    // setCookieValue(jsonValue);

    setExistingValue(JSON.parse(localStorage.getItem("selectedData")) || [])

    //fetchOPHData();
  }

  const fetchsessDetailData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        session_key &&
        student_key &&
        student_key != null &&
        session_key != null
      ) {
        setLoader(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(
            BASE_URL+"sessionActivity/SessionStudentDetails",
            {
              pk_ses_key: session_key,
              pk_student_key: student_key,
            }
          )
          .then(responseSession => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            if (
              responseSession.data.session != null &&
              responseSession.data.session != ""
            ) {
              //setLoader(false);
              setSessionStudentData(responseSession.data)
              const uniqueSubjects = getUniqueSubjectId(responseSession.data)

              const resultSuboption =
                uniqueSubjects.includes(1) && !uniqueSubjects.includes(2)
                  ? { options: [{ value: 1, label: "English", selected: true }] }
                  : !uniqueSubjects.includes(1) && uniqueSubjects.includes(2)
                    ? { options: [{ value: 2, label: "Mathematics", selected: true }] }
                    : {
                      options: [
                        { value: 1, label: "English", selected: true },
                        { value: 2, label: "Mathematics" },
                      ],
                    }

              setSubjectOptions([resultSuboption])
              const resultSuboptionSubId = uniqueSubjects.includes(1) && !uniqueSubjects.includes(2) ? 1 : !uniqueSubjects.includes(1) && uniqueSubjects.includes(2) ? 2 : 1;

              setSubjectIdData(resultSuboptionSubId);
              fetchAssessmentActivitiesData(searchData, resultSuboptionSubId, yearlevelData, statusIdData)
              const resultSub =
                uniqueSubjects.includes(1) && !uniqueSubjects.includes(2)
                  ? { value: 1, label: "English" }
                  : !uniqueSubjects.includes(1) && uniqueSubjects.includes(2)
                    ? { value: 2, label: "Mathematics" }
                    : { value: 1, label: "English" }

              // const dataofsub = getUniqueSubjectId(sessionStudentData).includes(1) && !getUniqueSubjectId(sessionStudentData).includes(2) ? { value: 1, label: "English" } : !getUniqueSubjectId(sessionStudentData).includes(1) && getUniqueSubjectId(sessionStudentData).includes(2) ? { value: 2, label: "Mathematics" } : { value: 1, label: "English" }

              setselectedGroupSubject(resultSub)
              //   if (
              //     responseSession.data &&
              //     responseSession.data.session &&
              //     responseSession.data.session.tbl_session_time &&
              //     responseSession.data.session.tbl_session_time.tbl_student_enrolment &&
              //     responseSession.data.session.tbl_session_time.tbl_student_enrolment.length > 0
              //   ) {

              //     //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
              //     const formattedOptions = responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(enroll => ({
              //       value: enroll.tbl_student.pk_student_key,
              //       label: enroll.tbl_student.st_first_name != null && enroll.tbl_student.st_first_name !== '' ? enroll.tbl_student.st_first_name + ' ' + enroll.tbl_student.st_surname : null
              //     }));

              //     setStudentOption(formattedOptions);

              //   } else {
              //     setStudentOption([]);
              // setLoader(false);
              //   }
              //   // Get unique subject names from the provided JSON data
              //   //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
            } else {
              //console.error("Home card details not get from database.");
              setSessionStudentData([])
              // setLoader(false);
            }
          })
          .catch(error => {
            // setLoader(false);
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        // setLoader(false);
        navigate("/session")
      }
    } else {
      // setLoader(false);
      navigate("/login")
    }
  }

  const removeLocalStorageAssignItem = id => {
    const updatedItems = existingData.filter(item => item.id !== id)
    //setItems(updatedItems);
    localStorage.setItem("selectedData", JSON.stringify(updatedItems))

    setExistingValue(JSON.parse(localStorage.getItem("selectedData")) || [])

    // fetchOPHData();
  }

  const OnSubmitActivityData = async () => {
    try {
      setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        if (
          session_key &&
          student_key &&
          student_key != null &&
          session_key != null
        ) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

          const response = await axios
            .post(
              BASE_URL+"activity/createActivities",
              {
                activities:
                  JSON.parse(localStorage.getItem("selectedData")) || [],
                is_homework: typeAct && typeAct == 1 ? 0 : 1,
                lesson_key:
                  localStorage.getItem("lesson_key") &&
                    localStorage.getItem("lesson_key") != null
                    ? localStorage.getItem("lesson_key")
                    : "",
                session_key: session_key || "",
                student_key: student_key || "",
              }
            )
            .then(responseActivity => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              const activitiesData = responseActivity.data

              if (activitiesData == 1) {
                // Reset the form
                //formRef.current.reset();

                toast.success(" Activities created successfully", {
                  autoClose: 3000, // 3 seconds in this example
                  onClose: () => {
                    window.history.back()
                    // After 3 seconds, navigate back one step in the browser's history
                    //  window.location.reload();
                  },
                })

                setIsSubmitting(false) // Re-enable the submit button
                localStorage.removeItem("selectedData")
                setLoader(false)
              } else {
                toast.success(" Activities update successfully. ", {
                  autoClose: 3000, // 3 seconds in this example
                  onClose: () => {
                    window.history.back()
                    // After 3 seconds, navigate back one step in the browser's history
                    // window.location.reload();
                  },
                })

                setIsSubmitting(false) // Re-enable the submit button
                localStorage.removeItem("selectedData")
                setLoader(false)
              }
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
              setLoader(false)
            })
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      toast.error("Failure! Unable to create notes. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      })

      setIsSubmitting(false) // Re-enable the submit button
    } finally {
      setIsSubmitting(false) // Re-enable the submit button
    }
  }

  //yogii start
  const basicSchema = yup.object().shape({
    act_subject: yup.string().required("Subject name is required."),
  })

  const initialValues = {
    /*subjects*/

    act_subject: "",
    act_type: "",
    act_name: "",
    act_source: "",
    act_duration: "",
  }
  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    //  onSubmit,
    onSubmit: async formValues => {
      // setIsSubmitting1(true);

      try {
        if (authUser && authUser != "" && authUser != null) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`


          if(values.act_file)
          {
          const formData = new FormData();
          formData.append('act_subject', values.act_subject);
          formData.append('act_type', values.act_type);
          formData.append('act_name', values.act_name);
          formData.append('act_source', values.act_source);
          formData.append('act_duration', values.act_duration);
          formData.append('act_file', values.act_file);

          const response = await axios.post(BASE_URL+'mylibrary/createMyLibrary1', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status == true) {
            // Reset the form
            //formRef.current.reset();
            fetchData();
            toast.success(" Your My Library was submitted", {
              autoClose: 3000, // 3 seconds in this example

            });
            setmodal_large(false);

            // After 3 seconds, redirect to another page
            // setTimeout(function () {
            //   navigate("/Subject");
            // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button

          } else {

            toast.error("Failure! Unable to create my library. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button

          }

        }else{
          const  response = await axios.post(BASE_URL+'mylibrary/createMyLibrary', {
           ...formValues,           
           
          });

          if (response.status == true) {
            // Reset the form
            //formRef.current.reset();
            fetchData();
            toast.success(" Your My Library was submitted", {
              autoClose: 3000, // 3 seconds in this example

            });
            setmodal_large(false);

            // After 3 seconds, redirect to another page
            // setTimeout(function () {
            //   navigate("/Subject");
            // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button

          } else {

            toast.error("Failure! Unable to create my library. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button

          }
        }
          }
      } catch (error) {
        console.error("Error fetching data:", error)
        toast.error("Failure! Unable to create my library. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        })

        setIsSubmitting1(false) // Re-enable the submit button
      } finally {
        setIsSubmitting1(false) // Re-enable the submit button
      }
    },
  })
  // Now, you can set variables as needed:
  const values = formik.values
  const handleBlur = formik.handleBlur
  const touched = formik.touched
  const handleChange = formik.handleChange
  const errors = formik.errors
  const handleSubmit = formik.handleSubmit
  const setTouched = formik.setTouched
  const handleFileChange = formik.handleChange //check here surya broo
  const setValues = formik.setValues
  const handleReset = formik.handleReset

  const handleInputChange = event => {
    setSelectedOption(event.target.value)
    setInputValue("")
  }
  const handleInputValueChange = event => {
    setInputValue(event.target.value)
  }





  /** Start Worksheet PDF**/
  //worksheet


  const [imageUrls, setImageUrls] = useState([]);
  const [canvasList, setCanvasList] = useState([]);
  const [pdfInstance, setPdfInstance] = useState(null);

  // const [historyIndex, setHistoryIndex] = useState(-1);
  // const [canvasHistory, setCanvasHistory] = useState([]);
  const [currentColor, setCurrentColor] = useState('black');

  const colorRef = useRef();
  colorRef.current = currentColor;

  const [drawMode, setDrawMode] = useState(false); // State for text mode
  const drawModeRef = useRef();
  drawModeRef.current = drawMode;



  const [textMode, setTextMode] = useState(false); // State for text mode
  const textModeRef = useRef();
  textModeRef.current = textMode;



  const [selectedShape, setSelectedShape] = useState('circle'); // State for selected shape
  const [shapMode, setShapMode] = useState(false);
  const shapModeRef = useRef();
  shapModeRef.current = shapMode;

  const shapDataRef = useRef();
  shapDataRef.current = selectedShape;


  const [zoomLevel, setZoomLevel] = useState(1);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard1, setmodal_standard1] = useState(false);
  const tog_standard1 = () => {
    setmodal_standard1(!modal_standard1)
  }



  const initPreviewWorksheet = async (filepdf) => {
    console.log('helllloo11');

    // not conver to url
    // https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/english_activity_pdf/Year_5_Vocabulary_S__Year_5_Vocabulary_S_1710151837_Q.pdf

    //DIRECT URL
    //https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/tutor/library/1707203418570_PdfForm.pdf


    //student direct URL
    //https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/student/submissions/worksheet_submission_1711431708116.pdf
    //if(ActivityData && ActivityData !=''){
    // let pdfUrl = '';
    // if(ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 && ActivityData.LessonActivitiesQuestions[0] &&  ActivityData.LessonActivitiesQuestions[0].StudentAnsAttempts.length > 0 && ActivityData.LessonActivitiesQuestions[0].StudentAnsAttempts[0].student_answer){
    //     pdfUrl=ActivityData.LessonActivitiesQuestions[0].StudentAnsAttempts[0].student_answer;
    // }else if(ActivityData && ActivityData.fk_sub_id && ActivityData.activity_type && parseInt(ActivityData.activity_type) === 7 && ActivityData.activity_node && ActivityData.activity_node.length > 0){
    //   if(parseInt(ActivityData.fk_sub_id) === 1){
    //     pdfUrl='https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/english_activity_pdf/'+ActivityData.activity_node[0].file_name;
    //   }else if(parseInt(ActivityData.fk_sub_id) === 2){
    //     pdfUrl='https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/math_activity_pdf/'+ActivityData.activity_node[0].file_name;
    //   }
    // }else if(ActivityData && ActivityData.fk_sub_id && ActivityData.activity_type && parseInt(ActivityData.activity_type) === 5 && ActivityData.activity_node && ActivityData.activity_node.length > 0){
    //   if(parseInt(ActivityData.fk_sub_id) === 1){
    //     pdfUrl=ActivityData.activity_node[0].link ? ActivityData.activity_node[0].link : '';
    //   }else if(parseInt(ActivityData.fk_sub_id) === 2){
    //     pdfUrl=ActivityData.activity_node[0].link ? ActivityData.activity_node[0].link : '';
    //   }
    // }
    let pdfUrl = '';

    if (parseInt(subjectIdData) === 1) {
      pdfUrl = 'https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/english_activity_pdf/' + filepdf;

    }
    else if (parseInt(subjectIdData) === 2) {
      pdfUrl = 'https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/math_activity_pdf/' + filepdf;

    }

    // const pdfUrl1 = 'https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/math_activity_pdf/Angles_Triangles_an_Angles_Triangles_an_1702259819_Q.pdf'; // Update with your PDF URL
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const fetchPdfAndConvert = async () => {
      try {
        const response = await fetch(pdfUrl);
        console.log(response);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = async (e) => {
          const data = atob(e.target.result.replace(/.*base64,/, ''));
          renderPages(data);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching or converting PDF:', error);
      }
    };
    const renderPages = async (data) => {
      const imagesList = [];
      const canvases = [];
      const pdf = await pdfjs.getDocument({ data }).promise;

      const canvasContainer = document.getElementById('Canvas_list');

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 1.5 });

        // Create a div for the canvas and page number display
        const pageDiv = document.createElement('div');
        pageDiv.classList.add('canvas-page-container');

        const pageNumberDiv = document.createElement('div');
        pageNumberDiv.classList.add('d-flex', 'justify-content-center');

        const pageNumberParagraph = document.createElement('p');
        pageNumberParagraph.textContent = `${i}/${pdf.numPages}`;

        pageNumberDiv.appendChild(pageNumberParagraph);
        pageDiv.appendChild(pageNumberDiv);

        const canvasContainerDiv = document.createElement('div');
        canvasContainerDiv.classList.add('d-flex', 'justify-content-center');

        const canvasElem = document.createElement('canvas');
        canvasElem.id = `fabric-canvas-${i}`; // Unique ID for each canvas
        canvasElem.height = viewport.height;
        canvasElem.width = viewport.width;

        canvasContainerDiv.appendChild(canvasElem);
        pageDiv.appendChild(canvasContainerDiv);

        canvasContainer.appendChild(pageDiv);

        const fabricCanvas = new fabric.Canvas(canvasElem);
        // Add click event listener to canvas
        fabricCanvas.on('mouse:down', function (event) {
          handleCanvasClick(event, fabricCanvas);
          handleShapeCreation(event, fabricCanvas);
        });

        canvases.push(fabricCanvas);

        const renderContext = {
          canvasContext: fabricCanvas.getContext('2d'),
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        const img = canvasElem.toDataURL('image/png');
        imagesList.push(img);
      }

      setImageUrls(imagesList);
      setCanvasList(canvases);
      setPdfInstance(pdf);
    };

    console.log('helllloo');
    fetchPdfAndConvert();
    //}
  };

  useEffect(() => {
    //enable draw option
    canvasList.forEach((canvas) => {
      canvas.isDrawingMode = drawMode; // Enable drawing mode
    });
  }, [drawMode]);


  const handleCanvasClick = (event, canvas) => {
    if (textModeRef.current) {
      const { x, y } = event.pointer;
      const textbox = new fabric.Textbox('Enter text', {
        left: x,
        top: y,
        width: 150,
        fill: colorRef.current, // Set text color
      });
      canvas.add(textbox);
      canvas.setActiveObject(textbox);
      canvas.renderAll();
    }
  };

  const handleShapeCreation = (event, canvas) => {
    if (shapModeRef.current) {
      // Handle shape creation
      const { x, y } = event.pointer;

      switch (shapDataRef.current) {
        case 'circle':
          const circle = new fabric.Circle({
            radius: 50,
            left: x,
            top: y,
            fill: 'transparent', // Set fill color to transparent
            stroke: colorRef.current,
          });
          canvas.add(circle);
          break;
        case 'rectangle':
          const rectangle = new fabric.Rect({
            width: 100,
            height: 50,
            left: x,
            top: y,
            fill: 'transparent', // Set fill color to transparent
            stroke: colorRef.current,
          });
          canvas.add(rectangle);
          break;
        case 'square':
          const square = new fabric.Rect({
            width: 50,
            height: 50,
            left: x,
            top: y,
            fill: 'transparent', // Set fill color to transparent
            stroke: colorRef.current,
          });
          canvas.add(square);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    imageUrls.forEach((imageUrl, index) => {
      const canvas = canvasList[index];

      fabric.Image.fromURL(imageUrl, (img) => {
        img.set({
          left: 0,
          top: 0,
          selectable: false, // Disable object selection/movement
          evented: false,
        });

        img.scaleToWidth(canvas.width);
        canvas.add(img);
        canvas.renderAll();
      });
    });
  }, [imageUrls, canvasList]);


  //Toggles
  const toggleTextMode = () => {
    setDrawMode(false);
    setShapMode(false);
    setTextMode(!textMode); // Toggle text mode state
  };

  const toggleDrawMode = () => {
    setTextMode(false);
    setShapMode(false);
    setDrawMode(!drawMode); // Toggle text mode state
    // Update brush color when switching draw mode
    if (!drawMode) {
      canvasList.forEach((canvas) => {
        canvas.freeDrawingBrush.color = currentColor;
      });
    }
  };

  const toggleShapeMode = (value) => {
    setTextMode(false);
    setDrawMode(false); // Toggle text mode state
    setShapMode(true); // Toggle text mode state
    setSelectedShape(value);
  };

  const ClickShapeMode = () => {
    setTextMode(false);
    setDrawMode(false); // Toggle text mode state
    setShapMode(!shapMode); // Toggle text mode state
  };


  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
    adjustZoom(1.1);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => (prevZoom > 0.1 ? prevZoom - 0.1 : prevZoom));
    adjustZoom(0.9);
  };

  const resetZoom = () => {
    setZoomLevel(1);
    adjustZoom(1 / zoomLevel); // Reset to original zoom level (1)
  };



  const adjustZoom = (factor) => {
    canvasList.forEach((canvas) => {
      const zoom = canvas.getZoom() * factor;
      canvas.setZoom(zoom);
      canvas.requestRenderAll();
    });
  };


  //  // Function to save canvas state to history
  //  const saveCanvasToHistory = (canvas) => {
  //   const newHistory = canvasHistory.slice(0, historyIndex + 1);
  //   newHistory.push(canvas.toJSON());
  //   setCanvasHistory(newHistory);
  //   setHistoryIndex(historyIndex + 1);
  // };

  // // Function to handle undo action
  // const undoAction = () => {
  //   if (historyIndex > 0) {
  //     const newIndex = historyIndex - 1;
  //     setHistoryIndex(newIndex);
  //     const canvasData = canvasHistory[newIndex];
  //     canvasList.forEach((canvas) => {
  //       canvas.loadFromJSON(canvasData, () => {
  //         canvas.renderAll();
  //       });
  //     });
  //   }
  // };

  // // Function to handle redo action
  // const redoAction = () => {
  //   if (historyIndex < canvasHistory.length - 1) {
  //     const newIndex = historyIndex + 1;
  //     setHistoryIndex(newIndex);
  //     const canvasData = canvasHistory[newIndex];
  //     canvasList.forEach((canvas) => {
  //       canvas.loadFromJSON(canvasData, () => {
  //         canvas.renderAll();
  //       });
  //     });
  //   }
  // };


  // Function to remove selected object from canvas
  const removeSelectedObject = () => {
    canvasList.forEach((canvas) => {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        //saveCanvasToHistory(canvas);
      }
    });
  };

  const changeColor = (color) => {
    setCurrentColor(color);
    if (drawMode) {
      // Set stroke color for drawing objects
      canvasList.forEach((canvas) => {
        canvas.freeDrawingBrush.color = color;
      });
    } else {
      // Set fill color for shape creation or text color
      canvasList.forEach((canvas) => {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          if (activeObject.type === 'textbox') {
            activeObject.set('fill', color);
          } else {
            activeObject.set('stroke', color);
          }
          canvas.renderAll();
          //saveCanvasToHistory(canvas);
        }
      });
    }
  };

  const downloadPdf = () => {
    resetZoom();
    canvasList.forEach((canvas) => {
      canvas.setZoom(canvas.getZoom() / 1.1);
      canvas.requestRenderAll();
    });
    const doc = new jsPDF();

    canvasList.forEach((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      doc.addImage(imgData, 'JPEG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
      if (canvas !== canvasList[canvasList.length - 1]) {
        doc.addPage();
      }
    });

    doc.save('worksheet_submission_' + generateRandomFileName() + '.pdf');
  };
  // Function to generate a random 13-digit number
  const generateRandomFileName = () => {
    const min = 1000000000000; // Minimum 13-digit number
    const max = 9999999999999; // Maximum 13-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  /**End Worksheet PDF **/
  return (
    <React.Fragment>
      <ToastContainer />
      <Loader
        show={loader}
        type="body"
      // stack="vertical"
      // message="Loading Data"
      />
      <Container fluid className="pe-0">
        <div className="page-content fo-activity pe-0 ps-0">
          <div className="activity-wrapper d-flex">
            <div className="fo-left">
              <div className="page-title-box mb-3 mt-3">
                <div className="" style={{ paddingTop: "18px" }}>

                  <div>
                    <h6
                      className="page-title mb-2"
                      style={{ lineHeight: "32px" }}
                    >
                      <Button color="light" className='bck-btn' onClick={() => window.history.back()}>
                        <i className='fas fa-chevron-left'></i>
                      </Button>
                      {" "}
                      Add{" "}
                      {typeAct && typeAct == 1
                        ? "assessment"
                        : typeAct && typeAct == 2
                          ? "homework"
                          : "-"}{" "}
                      activity
                    </h6>
                    {/* <h6 className="page-title inner-pg-title mb-2"> Add {typeAct &&  typeAct == 1  ? 'Assessment' : typeAct &&  typeAct == 2 ? 'Homework' : '-'} Activity</h6> */}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2 bg-white mb-0">
                <div className="d-flex gap-5 align-items-center">
                  <div className=" pe-3">
                    <h6 class="mb-0 font-size-14 text-dark">
                      {sessionStudentData &&
                        sessionStudentData.session &&
                        sessionStudentData.session.tbl_session_time &&
                        sessionStudentData.session.tbl_session_time.sest_name
                        ? sessionStudentData.session.tbl_session_time.sest_name
                        : "-"}
                    </h6>
                    <p class="mb-0 font-size-12">
                      {sessionStudentData &&
                        sessionStudentData.session &&
                        sessionStudentData.session.tbl_session_time &&
                        sessionStudentData.session.tbl_session_time.sest_type
                        ? sessionStudentData.session.tbl_session_time.sest_type
                        : "-"}
                    </p>
                  </div>
                  <div className="text-end pe-3">
                    <h6 class="mb-0 font-size-14 text-dark">
                      {sessionStudentData &&
                        sessionStudentData.session &&
                        sessionStudentData.session.ses_date
                        ? moment(
                          sessionStudentData.session.ses_date,
                          moment.ISO_8601
                        ).format("DD MMM YYYY (ddd)")
                        : "-"}
                    </h6>
                    <p class="mb-0 font-size-12">
                      {sessionStudentData &&
                        sessionStudentData.session &&
                        sessionStudentData.session.ses_start_time
                        ? moment(
                          sessionStudentData.session.ses_start_time,
                          "HH:mm:ss"
                        ).format("hh:mm A")
                        : "-"}{" "}
                      -{" "}
                      {sessionStudentData &&
                        sessionStudentData.session &&
                        sessionStudentData.session.ses_end_time
                        ? moment(
                          sessionStudentData.session.ses_end_time,
                          "HH:mm:ss"
                        ).format("hh:mm A")
                        : "-"}
                    </p>
                  </div>
                  <div className=" pe-3">
                    <h6 class="mb-0 font-size-14 text-dark">Subjects</h6>
                    <p class="mb-0 font-size-12">
                      {sessionStudentData && sessionStudentData != ""
                        ? getUniqueSubjectNames(sessionStudentData).join(
                          "& "
                        ) || "-"
                        : "-"}
                    </p>
                  </div>

                  <div className="text-end pe-3">
                    <h6 class="mb-0 font-size-14 text-dark">
                      {typeAct && typeAct == 1
                        ? "Assessment"
                        : typeAct && typeAct == 2
                          ? "Homework"
                          : "-"}
                    </h6>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <div>
                    <img
                      className="rounded-circle avatar-xs"
                      src={st2}
                      alt=""
                    />
                  </div>
                  <div>
                    {/* { sessionStudentData.session &&
                  sessionStudentData.session.tbl_session_time &&
                  sessionStudentData.session.tbl_session_time.tbl_student_enrolment ? console.log(sessionStudentData.session.tbl_session_time.tbl_student_enrolment) : ''} */}
                    <h6 class="mb-0 font-size-16">
                      {sessionStudentData &&
                        sessionStudentData.session &&
                        sessionStudentData.session.tbl_session_time &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0] &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_student &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_student.st_first_name
                        ? sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_student
                          .st_first_name +
                        " " +
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_student.st_surname
                        : "-"}
                    </h6>
                    <p class="mb-0">
                      {sessionStudentData &&
                        sessionStudentData.session &&
                        sessionStudentData.session.tbl_session_time &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0] &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_student &&
                        sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_student.fk_sc_id
                        ? sessionStudentData.session.tbl_session_time
                          .tbl_student_enrolment[0].tbl_student.fk_sc_id
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="page-title-box">
                <div className="custom-pg_header justify-content-between mb-3">
                  <form className="app-search page-search">
                    <div className="position-relative">
                      <span className="fa fa-search"></span>
                      <input
                        type="text"

                        value={selectedGroup}
                        className="form-control"
                        name="searchActivity"
                        id="searchActivity"
                        onChange={handleOnchangeGetSearch}
                        placeholder={
                          props.t("Search for topic or activity name") + "..."
                        }
                      />
                    </div>
                  </form>
                </div>
              </div>

              <div
                className="act-filters d-flex align-items-center mt-4"
                style={{ gap: "12px" }}
              >
                <div className="my-ss">
                  <Label className="font-size-15 fw-700 mb-1">
                    {" "}
                    Select Subject
                  </Label>

                  <Select
                    // {sessionStudentData && sessionStudentData != ""
                    // ? getUniqueSubjectId(sessionStudentData).join("& ") || "-"
                    // : "-"}
                    // {sessionStudentData && sessionStudentData != ""
                    //  ? 'value='{selectedGroupSubject ==  getUniqueSubjectId(sessionStudentData).join("&") ? 1  : "-"}

                    //                    : value={selectedGroupSubject}
                    //               }

                    value={selectedGroupSubject}
                    // onChange={() => {
                    //   handleSelectGroup()

                    // }}
                    onChange={handleOnchangeGetSubject}
                    options={subjectOptions}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div className="my-ss">
                  <Label className="font-size-15 fw-700 mb-1">Levels</Label>
                  <Select
                    // value={yearlevelOptions && yearlevelOptions[0].options.find(option => option.value === selectedGroupYearlevel)}
                    value={
                      yearlevelOptions &&
                      yearlevelOptions[0] &&
                      yearlevelOptions[0].options &&
                      yearlevelOptions[0].options.find(
                        option => option.value === selectedGroupYearlevel
                      )
                    }
                    onChange={handleOnchangeGetYearlevel}
                    options={yearlevelOptions}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div className="my-ss">
                  <Label className="font-size-15 fw-700 mb-1">Status</Label>
                  <Select
                    // value={selectedGroupStatus}
                    value={optionGroup[0].options.find(
                      option => option.value === selectedGroupStatus
                    )}
                    onChange={handleOnchangeGetStatus}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>

              <div className="position-relative" style={{ marginTop: "36px" }}>
                {customActiveTab === "3" && (
                  <Button
                    className="btn-cstm-float"
                    color="danger"
                    onClick={tog_large}
                  >
                    Add New
                  </Button>
                )}
                <Nav
                  tabs
                  className="nav-tabs-custom activitytb"
                  style={{ marginBottom: "39px" }}
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1")
                      }}
                    >
                      <span className="d-none d-sm-block">Assessment</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2")
                      }}
                    >
                      <span className="d-none d-sm-block">Diagnostics</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3")
                      }}
                    >
                      <span className="d-none d-sm-block">My Library</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "4",
                      })}
                      onClick={() => {
                        toggleCustom("4")
                      }}
                    >
                      <span className="d-none d-sm-block">Video</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "5",
                      })}
                      onClick={() => {
                        toggleCustom("5")
                      }}
                    >
                      <span className="d-none d-sm-block">Worksheet</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <Modal isOpen={modal_large} toggle={tog_large} size="lg">
                <ModalHeader className="mt-0" toggle={tog_large}>
                  Add New Activity
                </ModalHeader>
                <ModalBody>
                  <Form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    ref={formRef}
                  >
                    <Row className="mb-3 g-3">
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Subject
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={subjectOptions}
                              name="act_subject"
                              value={subjectOptions.find(
                                option =>
                                  option.value &&
                                  values.act_subject &&
                                  option.value.toString() ===
                                  values.act_subject.toString()
                              )}
                              id="act_subject"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_subject",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange(event)
                              }}
                              onBlur={event => {
                                handleBlur(event)
                                setTouched({ ...touched, act_subject: true })
                              }}
                              className={
                                errors.act_subject && touched.act_subject
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors.act_subject && touched.act_subject && (
                              <p className="error">{errors.act_subject}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Activity type
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={optionGroup3}
                              name="act_type"
                              value={optionGroup3.find(
                                option =>
                                  option.value &&
                                  values.act_type &&
                                  option.value.toString() ===
                                  values.act_type.toString()
                              )}
                              id="act_type"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_type",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange(event)
                                handleInputChange(event)
                              }}
                              onBlur={event => {
                                handleBlur(event)
                                setTouched({ ...touched, act_type: true })
                              }}
                              className={
                                errors.act_type && touched.act_type
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors.act_type && touched.act_type && (
                              <p className="error">{errors.act_type}</p>
                            )}
                          </div>
                          {/* <div className="">

                        
                      <Select
                              // onChange={handleInputChange}
                              menuPlacement="auto" options={optionGroup3}
                              name="act_type"
                              value={optionGroup3.find(option => option.value && values.act_type && option.value.toString() === values.act_type.toString())}
                              id="act_type"
                              onChange={(selectedOption) => {
                                const event = {
                                  target: {
                                    name: "act_type",
                                    value: selectedOption["value"],
                                  },
                                };
                                handleInputChange(event);
                              }}
                              onBlur={(event) => {
                                handleBlur(event);
                                setTouched({ ...touched, act_type: true });
                              }}
                              className={errors.act_type && touched.act_type ? "input-error" : ""}
                            />
                            {errors.act_type && touched.act_type && <p className="error">{errors.act_type}</p>}
                      </div> */}
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Additional Learning Resources Name
                          </label>
                          <div className="">
                            <Input
                              type="text"
                              value={values.act_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="act_name"
                              className={
                                errors.act_name && touched.act_name
                                  ? "form-control input-error"
                                  : ""
                              }
                            />
                            {errors.act_name && touched.act_name && (
                              <p className="error">{errors.act_name}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Source
                          </label>
                          <div className="">
                            <Input
                              type="text"
                              value={values.act_source}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="act_source"
                              className={
                                errors.act_source && touched.act_source
                                  ? "form-control input-error"
                                  : ""
                              }
                            />
                            {errors.act_source && touched.act_source && (
                              <p className="error">{errors.act_source}</p>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Duration
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={optionGroup4}
                              name="act_duration"
                              value={optionGroup4.find(
                                option =>
                                  option.value &&
                                  values.act_duration &&
                                  option.value.toString() ===
                                  values.act_duration.toString()
                              )}
                              id="act_duration"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_duration",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange(event)
                              }}
                              onBlur={event => {
                                handleBlur(event)
                                setTouched({ ...touched, act_duration: true })
                              }}
                              className={
                                errors.act_duration && touched.act_duration
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors.act_duration && touched.act_duration && (
                              <p className="error">{errors.act_duration}</p>
                            )}
                          </div>
                        </div>
                      </Col>

                      {selectedOption3 === "1" && (
                        <Col className="col-6">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Video
                            </label>
                            <div className="">
                              <Input
                                type="text"
                                value={values.act_video}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="URL"
                                id="act_video"
                                className={
                                  errors.act_video && touched.act_video
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                              {errors.act_video && touched.act_video && (
                                <p className="error">{errors.act_video}</p>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {selectedOption3 === "3" && (
                        <Col className="col-6">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Link
                            </label>
                            <div className="">
                              <Input
                                type="text"
                                value={values.act_link}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="link"
                                id="act_link"
                                className={
                                  errors.act_link && touched.act_link
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                              {errors.act_link && touched.act_link && (
                                <p className="error">{errors.act_link}</p>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {selectedOption3 === "5" && (
                        <Col className="col-12">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Upload PDF
                            </label>

                            <span> File size should not exceed 2mb</span>
                            <div className="">

                              <Input
                                type="file"
                                accept=".pdf"
                                onChange={(event) => {
                                  formik.setFieldValue('act_file', event.currentTarget.files[0]);
                                }}
                                onBlur={handleBlur}
                                id="act_file"
                                name="act_file"

                                className={errors.act_file && touched.act_file ? "form-control input-error" : ""}
                              />
                              {/* <Input
                                type="file"
                                accept=".pdf"
                                value={values.act_file}
                                onChange={handleFileChange}
                                onBlur={handleBlur}
                                id="act_file"
                                className={
                                  errors.act_file && touched.act_file
                                    ? "form-control input-error"
                                    : ""
                                }
                              /> */}
                            </div>
                          </div>
                        </Col>
                      )}
                      {/* {selectedOption === 'Date' && (
   <input
     type="date"
     value={inputValue}
     onChange={handleInputValueChange}
     placeholder="Select date..."
   />
 )} */}
                    </Row>
                    <div className="text-end mt-4">
                      {/* <Button type="submit" color="danger">
                    Add
                  </Button> */}
                      <Button
                        type="submit"
                        variant="contained"
                        color="danger"
                        disabled={isSubmitting1}
                      >
                        Add
                      </Button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
              <TabContent
                activeTab={customActiveTab}
                className="activity-scroll"
              >
                <TabPane tabId="1">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="table mb-3">
                        <thead>
                          <tr>
                            <th width="380">
                              <h6 className="text-dark">
                                Year/Topic / Activity Name
                              </h6>
                            </th>
                            <th width="230">
                              <h6 className="text-dark">Status</h6>
                            </th>
                            <th width="100">
                              <h6 className="text-dark">Score (%)</h6>
                            </th>
                            <th width="666"></th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    {assignActivity.length > 0 ? (
                      // Render if activities array is not empty
                      assignActivity[0].activities.map((activity, index) => (
                        <div
                          className="accordion"
                          id={`accordion${index}`}
                          key={index}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`heading${index}`}
                            >
                              <button
                                className={`accordion-button bg-white fw-semibold ${activeIndex === index ? "" : "collapsed"
                                  }`}
                                type="button"
                                onClick={() => toggleAccordion(index)}
                                style={{ cursor: "pointer" }}
                              >
                                {activity.level_name}
                              </button>
                            </h2>
                            <Collapse
                              isOpen={activeIndex === index}
                              className="accordion-collapse"
                              aria-labelledby={`heading${index}`}
                              data-bs-parent={`#accordion${index}`}
                            >
                              <div className="accordion-body p-0">
                                {activity.LevelTopics &&
                                  activity.LevelTopics.length > 0 ? (
                                  activity.LevelTopics.map(
                                    (topic, topicIndex) => (
                                      <div
                                        className="accordion"
                                        id={`accordionInner${topicIndex}`}
                                        key={topicIndex}
                                      >
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id={`headingInner${topicIndex}`}
                                          >
                                            <button
                                              className={`accordion-button bg-white font-size-14 fw-semibold ${activeIndex1 === topicIndex
                                                ? ""
                                                : "collapsed"
                                                }`}
                                              type="button"
                                              onClick={() =>
                                                toggleAccordion1(topicIndex)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              {topic.topic_name}
                                              <span className="score-float">
                                                Mastery Score: 0
                                              </span>
                                            </button>
                                          </h2>
                                          {topic.TopicActivities &&
                                            topic.TopicActivities.length > 0 ? (
                                            topic.TopicActivities.map(
                                              (topicTitle, topicTitleIndex) => (
                                                <Collapse
                                                  isOpen={
                                                    activeIndex1 === topicIndex
                                                  } // Use col2 state here
                                                  className="accordion-collapse"
                                                  key={topicTitleIndex}
                                                >


                                                  <div className="accordion-body p-0">
                                                    <div className="table-responsive">
                                                      <Table className="table mb-0">
                                                        <tbody>
                                                          <tr>
                                                            <th
                                                              scope="row"
                                                              width="380"

                                                            >
                                                              {
                                                                topicTitle.node_name
                                                              }
                                                            </th>
                                                            <td width="230">
                                                              <div className="status-ac unattempted">
                                                                UNATTEMPTED
                                                              </div>
                                                            </td>
                                                            <td width="100">
                                                              -
                                                            </td>
                                                            <td
                                                              width="666"
                                                              className="text-end"
                                                            >
                                                              <div className="btn-group">
                                                                <Button
                                                                  color="danger"
                                                                  className={topicTitle.data_exists == 1 ? 'selector-display-parttype-2' : ''}
                                                                  disabled={
                                                                    (Array.isArray(existingData) && existingData.length > 0 &&
                                                                      existingData.some(item => item.id === topicTitle.activity_node_id) &&
                                                                      existingData.some(item => item.activity_type === 0)) ||
                                                                    topicTitle.data_exists == 1
                                                                  }
                                                                  onClick={() => handleLocalStorageAssignClick(topicTitle, topic, activity, 0)}
                                                                >
                                                                  {topicTitle.data_exists == 1 ? (
                                                                    <>
                                                                      <i className="fa fa-check green-check"></i>
                                                                      {' Selected'}
                                                                    </>
                                                                  ) : (
                                                                    'Select'
                                                                  )}
                                                                </Button>

                                                                <Button onClick={() =>
                                                                  fetchpreviewAssessData(topic.subject_id, topicTitle.activity_node_id)} className="btn btn-outline-danger bg-white text-danger">
                                                                  Preview
                                                                </Button>
                                                                {/* <Button
                                                                  onClick={() =>
                                                                    handleShowPrevPopup()
                                                                  }
                                                                  className="btn btn-outline-danger bg-white text-danger"
                                                                > 
                                                                  Preview
                                                                </Button> */}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          {/* Additional table rows */}
                                                        </tbody>
                                                      </Table>
                                                    </div>
                                                  </div>
                                                </Collapse>
                                              )
                                            )
                                          ) : (
                                            // Render if TopicActivities array is empty
                                            <p className="text-center p-2 text-dark fw-500">
                                              No activities found for this topic
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )
                                ) : (
                                  // Render if LevelTopics array is empty
                                  <p className="text-center p-2 text-dark fw-500">
                                    No topics found for this activity
                                  </p>
                                )}
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      ))
                    ) : (
                      // Render if activities array is empty
                      <p className="text-center p-2 text-dark fw-500">
                        No activities found
                      </p>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="table mb-3">
                        <thead>
                          <tr>
                            <th width="280">
                              <h6 className="text-dark">Question</h6>
                            </th>
                            <th width="230">
                              <h6 className="text-dark">Status</h6>
                            </th>

                            <th width="966"></th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    {assignDiagnosticsActivity.length > 0 ? (
                      // Render if activities array is not empty
                      assignDiagnosticsActivity[0].activities.map(
                        (activity, index) => (
                          <div
                            className="accordion"
                            id={`accordion${index}`}
                            key={index}
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id={`heading${index}`}
                              >
                                <button
                                  className={`accordion-button bg-white fw-semibold ${activeIndex === index ? "" : "collapsed"
                                    }`}
                                  type="button"
                                  onClick={() => toggleAccordion(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {activity.level_name}
                                </button>
                              </h2>

                              <div className="accordion-body p-0">
                                {activity.topics &&
                                  activity.topics.length > 0 ? (
                                  activity.topics.map((topic, index1) => (
                                    <Collapse
                                      isOpen={activeIndex === index} // Use col2 state here
                                      className="accordion-collapse"
                                      key={index1}
                                    >
                                      {topic.activity_master_topic &&
                                        topic.activity_master_topic.topic_name ? (
                                        <div
                                          className="accordion"
                                          id={`accordionInner${index1}`}
                                          key={index1}
                                        >
                                          <div className="table-responsive">
                                            <Table className="table mb-0">
                                              <tbody>
                                                <tr>
                                                  <th scope="row" width="400">
                                                    {topic.activity_master_topic &&
                                                      topic
                                                        .activity_master_topic
                                                        .topic_name}
                                                  </th>
                                                  <td width="380">
                                                    <div className="status-ac unattempted">
                                                      UNATTEMPTED
                                                    </div>
                                                  </td>
                                                  <td
                                                    width="1196"
                                                    className="text-end"
                                                  >
                                                    <div className="btn-group">
                                                      <Button
                                                        color="danger"
                                                        //className="me-2"
                                                        className={topic.activity_master_topic.data_exists == 1 ? 'selector-display-parttype-2 me-2' : 'me-2'}

                                                        disabled={
                                                          Array.isArray(
                                                            existingData
                                                          ) &&
                                                            existingData.length >
                                                            0 &&
                                                            existingData.some(
                                                              item =>
                                                                item.topicID ===
                                                                topic
                                                                  .activity_master_topic.activity_topic_id
                                                            ) &&
                                                            existingData.some(
                                                              item =>
                                                                item.activity_type ===
                                                                2
                                                            ) ||
                                                            topic.activity_master_topic.data_exists == 1
                                                            ? true
                                                            : false
                                                        }
                                                        //   disabled={


                                                        //     Array.isArray(existingData) &&
                                                        //     existingData.length > 0 &&
                                                        //     topic.activity_master_topic.activity_level_has_topic_nodes.some(topicval => {
                                                        //         let conditionMet = false; // Variable to keep track of whether the condition is met
                                                        //         if (topicval.subject_id === 1 && topicval.activity_node && topicval.activity_node.english_diagnostic_question_activities) {
                                                        //             conditionMet = topicval.activity_node.english_diagnostic_question_activities.some(node =>
                                                        //                 existingData.some(item =>
                                                        //                     item.id.includes(node.question_id)
                                                        //                 )
                                                        //             );
                                                        //         } else if (topicval.subject_id === 2 && topicval.activity_node && topicval.activity_node.diagnostic_question_activities) {
                                                        //             conditionMet = topicval.activity_node.diagnostic_question_activities.some(node =>
                                                        //                 existingData.some(item =>
                                                        //                     item.id.includes(node.question_id)
                                                        //                 )
                                                        //             );
                                                        //         }
                                                        //         return conditionMet;
                                                        //     }) &&
                                                        //     existingData.some(item => item.activity_type === 2)
                                                        //     ? true : false
                                                        // }


                                                        onClick={() =>
                                                          handleLocalStorageAssignClick(
                                                            topic,
                                                            activity,
                                                            [],
                                                            2
                                                          )
                                                        }
                                                      >

                                                        {topic.activity_master_topic.data_exists == 1 ? (
                                                          <>
                                                            <i className="fa fa-check green-check"></i>
                                                            {' Selected'}
                                                          </>
                                                        ) : (
                                                          'Select'
                                                        )}

                                                      </Button>
                                                      {/* <Button className="btn btn-outline-danger bg-white text-danger">
                                                        Preview
                                                      </Button> */}

                                                      <Button onClick={() =>
                                                        fetchpreviewDiagnosData(topic.subject_id, topic
                                                          .activity_master_topic.activity_topic_id)} className="btn btn-outline-danger bg-white text-danger">
                                                        Preview
                                                      </Button>
                                                    </div>
                                                  </td>
                                                </tr>
                                                {/* Additional table rows */}
                                              </tbody>
                                            </Table>
                                          </div>
                                        </div>
                                      ) : (
                                        <p className="text-center p-2 text-dark fw-500">
                                          No topics found for this activity
                                        </p>
                                      )}
                                    </Collapse>
                                  ))
                                ) : (
                                  // Render if LevelTopics array is empty
                                  <p className="text-center p-2 text-dark fw-500">
                                    No topics found for this activity
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      // Render if activities array is empty
                      <p className="text-center p-2 text-dark fw-500">
                        No activities found
                      </p>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="table mb-3">
                        <thead>
                          <tr>
                            <th width="30%">
                              <h6 className="text-dark">Additional Learning Resources Name</h6>
                            </th>
                            <th width="20%">
                              <h6 className="text-dark">Source</h6>
                            </th>
                            <th width="10%">
                              <h6 className="text-dark">Type</h6>
                            </th>
                            <th width="15%">
                              <h6 className="text-dark">Status </h6>
                            </th>
                            <th width="25%"></th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    {assignmylibraryActivity.length > 0 ? (
                      assignmylibraryActivity.map((activity, index) => (
                        // <Collapse
                        //   isOpen={activeIndex1 === index} // Use col2 state here
                        //   className="accordion-collapse"
                        //   key={index}
                        // >
                        <div className="accordion-body p-0">
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row" width="30">
                                    {activity.name && activity.name != ""
                                      ? activity.name
                                      : "-"}
                                  </th>
                                  <td width="20%">
                                    {activity.source && activity.source != ""
                                      ? activity.source
                                      : "-"}
                                  </td>
                                  <td width="10%">
                                    {activity.activity_type &&
                                      activity.activity_type != "" &&
                                      activity.activity_type == 1
                                      ? "Video"
                                      : activity.activity_type == 3
                                        ? "Link"
                                        : activity.activity_type == 4
                                          ? "Task"
                                          : activity.activity_type == 5
                                            ? "Work sheet"
                                            : "-"}{" "}
                                  </td>

                                  <td width="15%">
                                    <div className="status-ac unattempted">
                                      UNATTEMPTED
                                    </div>
                                  </td>
                                  <td width="25%" className="text-end">
                                    <div className="btn-group">
                                      <Button
                                        color="danger"
                                        //className="me-2"

                                        className={activity.data_exists == 1 ? 'selector-display-parttype-2 me-2' : 'me-2'}


                                        disabled={
                                          Array.isArray(existingData) &&
                                            existingData.length > 0 &&
                                            existingData.some(
                                              item =>
                                                item.id ===
                                                activity.pk_tutor_library_item_id
                                            ) &&
                                            existingData.some(
                                              item =>
                                                item.activity_type === 4 ||
                                                item.activity_type === 3 ||
                                                item.activity_type === 1 ||
                                                item.activity_type === 5
                                            ) ||
                                            activity.data_exists == 1
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          handleLocalStorageAssignClick(
                                            activity,
                                            [],
                                            [],
                                            4
                                          )
                                        }
                                      >
                                        {activity.data_exists == 1 ? (
                                          <>
                                            <i className="fa fa-check green-check"></i>
                                            {' Selected'}
                                          </>
                                        ) : (
                                          'Select'
                                        )}
                                      </Button>

                                      <Button onClick={() =>
                                        fetchpreviewLibraryData(activity.fk_sub_id, activity
                                          .pk_tutor_library_item_key)} className="btn btn-outline-danger bg-white text-danger">
                                        Preview
                                      </Button>

                                    </div>
                                  </td>
                                </tr>
                                {/* Additional table rows */}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        // </Collapse>
                      ))
                    ) : (
                      // Render if TopicActivities array is empty
                      <p className="text-center p-2 text-dark fw-500">
                        No activities found for this topic
                      </p>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="table mb-3">
                        <thead>
                          <tr>
                            <th width="400">
                              <h6 className="text-dark">Video</h6>
                            </th>
                            <th width="300">
                              <h6 className="text-dark">Status</h6>
                            </th>

                            <th width="300"></th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    {assignVideoActivity.length > 0 ? (
                      // Render if activities array is not empty
                      assignVideoActivity[0].activities.map(
                        (activity, index) => (
                          <div
                            className="accordion"
                            id={`accordion${index}`}
                            key={index}
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id={`heading${index}`}
                              >
                                <button
                                  className={`accordion-button bg-white fw-semibold ${activeIndex === index ? "" : "collapsed"
                                    }`}
                                  type="button"
                                  onClick={() => toggleAccordion(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {activity.level_name}
                                </button>
                              </h2>
                              <Collapse
                                isOpen={activeIndex === index}
                                className="accordion-collapse"
                                aria-labelledby={`heading${index}`}
                                data-bs-parent={`#accordion${index}`}
                              >
                                <div className="accordion-body p-0">
                                  {activity.topics &&
                                    activity.topics.length > 0 ? (
                                    activity.topics.map((topic, topicIndex) => (
                                      <div
                                        className="accordion"
                                        id={`accordionInner${topicIndex}`}
                                        key={topicIndex}
                                      >
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id={`headingInner${topicIndex}`}
                                          >
                                            <button
                                              className={`accordion-button bg-white font-size-14 fw-semibold ${activeIndex1 === topicIndex
                                                ? ""
                                                : "collapsed"
                                                }`}
                                              type="button"
                                              onClick={() =>
                                                toggleAccordion1(topicIndex)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              {topic.activity_master_topic.topic_name}
                                              <span className="score-float">
                                                Mastery Score: 0
                                              </span>
                                            </button>
                                          </h2>


                                          {console.log(topic.activity_master_topic.activity_level_has_topic_nodes[0].activity_node.math_activity_video_extra_data)}
                                          {



                                            topic.activity_master_topic &&
                                              topic.activity_master_topic.activity_level_has_topic_nodes &&
                                              topic.activity_master_topic.activity_level_has_topic_nodes.length > 0 ? (

                                              topic.activity_master_topic.activity_level_has_topic_nodes[0].activity_node &&
                                                topic.activity_master_topic.activity_level_has_topic_nodes[0].activity_node != 0 ? (
                                                topic.activity_master_topic.activity_level_has_topic_nodes[0].activity_node.math_activity_video_extra_data.map((topicTitle, topicTitleIndex) => (

                                                  // topic.activity_master_topic && topic.activity_master_topic.activity_level_has_topic_nodes && topic.activity_master_topic.activity_level_has_topic_nodes.activity_node &&
                                                  //   topic.activity_master_topic.activity_level_has_topic_nodes.activity_node.length > 0 ? (
                                                  //     activityvideo.map(
                                                  //     (topicTitle, topicTitleIndex) => (

                                                  <Collapse
                                                    isOpen={
                                                      activeIndex1 === topicIndex
                                                    } // Use col2 state here
                                                    className="accordion-collapse"
                                                    key={topicTitleIndex}
                                                  >

                                                    <div className="accordion-body p-0">
                                                      <div className="table-responsive">
                                                        <Table className="table mb-0">
                                                          <tbody>
                                                            <tr>
                                                              <th
                                                                scope="row"
                                                                width="400"
                                                              >
                                                                {
                                                                  topicTitle.video_name
                                                                }
                                                              </th>
                                                              <td width="300">
                                                                <div className="status-ac unattempted">
                                                                  UNATTEMPTED
                                                                </div>
                                                              </td>
                                                              <td
                                                                width="300"
                                                                className="text-end"
                                                              >
                                                                <div className="btn-group">
                                                                  <Button
                                                                    color="danger"
                                                                    //  className="me-2"

                                                                    className={activity.data_exists == 1 ? 'selector-display-parttype-2 me-2' : 'me-2'}


                                                                    disabled={
                                                                      Array.isArray(
                                                                        existingData
                                                                      ) &&
                                                                        existingData.length >
                                                                        0 &&
                                                                        existingData.some(
                                                                          item =>
                                                                            item.id ===
                                                                            topicTitle.id
                                                                        ) &&
                                                                        existingData.some(
                                                                          item =>
                                                                            item.activity_type ===
                                                                            6
                                                                        ) ||
                                                                        topicTitle.data_exists == 1
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onClick={() =>
                                                                      handleLocalStorageAssignClick(
                                                                        topicTitle,
                                                                        topic,
                                                                        activity,
                                                                        6
                                                                      )
                                                                    }
                                                                  >
                                                                    {topicTitle.data_exists == 1 ? (
                                                                      <>
                                                                        <i className="fa fa-check green-check"></i>
                                                                        {' Selected'}
                                                                      </>
                                                                    ) : (
                                                                      'Select'
                                                                    )}
                                                                  </Button>
                                                                  {/* <Button className="btn btn-outline-danger bg-white text-danger">
                                                                    Preview
                                                                  </Button> */}
                                                                  <Button onClick={() =>
                                                                    fetchpreviewVideoData(topic.subject_id, topicTitle.id, topic.activity_master_topic.activity_topic_id)} className="btn btn-outline-danger bg-white text-danger">
                                                                    Preview
                                                                  </Button>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                            {/* Additional table rows */}
                                                          </tbody>
                                                        </Table>
                                                      </div>
                                                    </div>
                                                  </Collapse>
                                                )
                                                )
                                              ) : (
                                                // Render if TopicActivities array is empty
                                                <p className="text-center p-2 text-dark fw-500">
                                                  No activities found for this topic
                                                </p>
                                              )
                                            ) : (
                                              // Render if TopicActivities array is empty
                                              <p className="text-center p-2 text-dark fw-500">
                                                No activities found for this topic
                                              </p>
                                            )

                                          }
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    // Render if LevelTopics array is empty
                                    <p className="text-center p-2 text-dark fw-500">
                                      No topics found for this activity
                                    </p>
                                  )}
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      // Render if activities array is empty
                      <p className="text-center p-2 text-dark fw-500">
                        No activities found
                      </p>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="table mb-3">
                        <thead>
                          <tr>
                            <th width="400">
                              <h6 className="text-dark">Worksheet</h6>
                            </th>
                            <th width="300">
                              <h6 className="text-dark">Status</h6>
                            </th>

                            <th width="300"></th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    {assignWorksheetActivity.length > 0 ? (
                      // Render if activities array is not empty
                      assignWorksheetActivity[0].activities.map((activity, index) => (
                        <div
                          className="accordion"
                          id={`accordion${index}`}
                          key={index}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`heading${index}`}
                            >
                              <button
                                className={`accordion-button bg-white fw-semibold ${activeIndex === index ? "" : "collapsed"
                                  }`}
                                type="button"
                                onClick={() => toggleAccordion(index)}
                                style={{ cursor: "pointer" }}
                              >
                                {activity.level_name}
                              </button>
                            </h2>
                            <Collapse
                              isOpen={activeIndex === index}
                              className="accordion-collapse"
                              aria-labelledby={`heading${index}`}
                              data-bs-parent={`#accordion${index}`}
                            >
                              <div className="accordion-body p-0">
                                {activity.topics &&
                                  activity.topics.length > 0 ? (
                                  activity.topics.map(
                                    (topic, topicIndex) => (
                                      <div
                                        className="accordion"
                                        id={`accordionInner${topicIndex}`}
                                        key={topicIndex}
                                      >
                                        <div className="accordion-item">
                                          <h2
                                            className="accordion-header"
                                            id={`headingInner${topicIndex}`}
                                          >
                                            <button
                                              className={`accordion-button bg-white font-size-14 fw-semibold ${activeIndex1 === topicIndex
                                                ? ""
                                                : "collapsed"
                                                }`}
                                              type="button"
                                              onClick={() =>
                                                toggleAccordion1(topicIndex)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              {topic.topic_name}
                                              <span className="score-float">
                                                Mastery Score: 0
                                              </span>
                                            </button>
                                          </h2>


                                          {topic.activity_level_has_topic_nodes &&
                                            topic.activity_level_has_topic_nodes.length > 0 ? (
                                            topic.activity_level_has_topic_nodes.map(
                                              (topicTitle, topicTitleIndex) => (
                                                topicTitle.activity_pdfs.map(
                                                  (topicactivitypdfsTitle, topicactivitypdfsTitleIndex) => (

                                                    <Collapse
                                                      isOpen={
                                                        activeIndex1 === topicIndex
                                                      } // Use col2 state here
                                                      className="accordion-collapse"
                                                      key={topicactivitypdfsTitleIndex}
                                                    >
                                                      <div className="accordion-body p-0">
                                                        <div className="table-responsive">
                                                          <Table className="table mb-0">
                                                            <tbody>
                                                              <tr>
                                                                <th
                                                                  scope="row"
                                                                  width="400"
                                                                >
                                                                  {
                                                                    topicactivitypdfsTitle.activity_node.node_name
                                                                  }
                                                                </th>
                                                                <td width="300">
                                                                  <div className="status-ac unattempted">
                                                                    UNATTEMPTED
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  width="300"
                                                                  className="text-end"
                                                                >
                                                                  <div className="btn-group">
                                                                    <Button
                                                                      color="danger"
                                                                      className={topicactivitypdfsTitle.activity_node.data_exists == 1 ? 'selector-display-parttype-2 me-2' : 'me-2'}



                                                                      disabled={
                                                                        Array.isArray(
                                                                          existingData
                                                                        ) &&
                                                                          existingData.length >
                                                                          0 &&
                                                                          existingData.some(
                                                                            item =>
                                                                              item.id ===
                                                                              topicactivitypdfsTitle.id
                                                                          ) &&
                                                                          existingData.some(
                                                                            item =>
                                                                              item.activity_type ===
                                                                              7
                                                                          ) ||
                                                                          topicactivitypdfsTitle.activity_node.data_exists == 1
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        handleLocalStorageAssignClick(
                                                                          topicactivitypdfsTitle,
                                                                          topic,
                                                                          activity,
                                                                          7
                                                                        )
                                                                      }
                                                                    >
                                                                      {topicactivitypdfsTitle.activity_node.data_exists == 1 ? (
                                                                        <>
                                                                          <i className="fa fa-check green-check"></i>
                                                                          {' Selected'}
                                                                        </>
                                                                      ) : (
                                                                        'Select'
                                                                      )}
                                                                    </Button>
                                                                    {/* <Button className="btn btn-outline-danger bg-white text-danger">
                                                                      Preview
                                                                    </Button> */}
                                                                    <Button onClick={() =>
                                                                      fetchpreviewWorksheetData(topic.subject_id, topicactivitypdfsTitle.id, topic.activity_topic_id)} className="btn btn-outline-danger bg-white text-danger">
                                                                      Preview
                                                                    </Button>
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              {/* Additional table rows */}
                                                            </tbody>
                                                          </Table>
                                                        </div>
                                                      </div>
                                                    </Collapse>

                                                  )
                                                )
                                              )
                                            )
                                          ) : (
                                            // Render if TopicActivities array is empty
                                            <p className="text-center p-2 text-dark fw-500">
                                              No activities found for this topic
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )
                                ) : (
                                  // Render if LevelTopics array is empty
                                  <p className="text-center p-2 text-dark fw-500">
                                    No topics found for this activity
                                  </p>
                                )}
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      ))
                    ) : (
                      // Render if activities array is empty
                      <p className="text-center p-2 text-dark fw-500">
                        No activities found
                      </p>
                    )}
                  </div>
                </TabPane>
              </TabContent>
            </div>

            <div className="fo-right">
              <Card className="border-top">
                <CardBody
                  className=""
                  style={{ padding: "12px", paddingTop: "0" }}
                >
                  <h6
                    className="mb-12 font-size-16 fw-500 text-dark"
                    style={{ paddingTop: "36px" }}
                  >
                    Selected Activities{" "}
                    <span>
                      (
                      {Array.isArray(existingData) && existingData.length > 0
                        ? existingData.length
                        : 0}
                      )
                    </span>{" "}
                  </h6>

                  <div className="activities-cts overflow-auto">
                    {Array.isArray(existingData) && existingData.length > 0
                      ? existingData.map(Assessmentlistdata => {
                        return (
                          <div
                            className="bg-supermild position-relative"
                            style={{ padding: "16px" }}
                          >
                            <div className="close-icon">
                              <i
                                className="mdi mdi-close-thick"
                                onClick={() =>
                                  removeLocalStorageAssignItem(
                                    Assessmentlistdata.id
                                  )
                                }
                              ></i>
                            </div>
                            <h6 className="two_line-ellipse font-size-15 fw-700 text-dark w-90">
                              {Assessmentlistdata.name}
                            </h6>

                            <div className="act-info mt-3">
                              <ul className="list-unstyled mb-0 d-flex align-items-center gap-2">
                                <li className="font-size-15">
                                  {" "}
                                  <img
                                    className=""
                                    width={10}
                                    src={reel}
                                    alt=""
                                  />{" "}
                                  {Assessmentlistdata.type}
                                </li>
                                <span className="circle"></span>
                                <li className="font-size-15">
                                  {Assessmentlistdata.duration}
                                </li>
                                <span className="circle"></span>
                                <li className="font-size-15">
                                  {" "}
                                  <img
                                    className=""
                                    width={12}
                                    src={awicon}
                                    alt=""
                                  />{" "}
                                  {Assessmentlistdata.coin}
                                </li>
                              </ul>
                            </div>
                          </div>
                        )
                      })
                      : ""}
                  </div>
                  <div className="btn-group w-100 flex-column py-4">
                    <Button
                      className="w-100 btn-lg cst-danger"
                      color="danger"
                      disabled={
                        !Array.isArray(existingData) || existingData.length == 0
                      }
                      onClick={() => OnSubmitActivityData()}
                    >
                      Add Activities
                    </Button>

                    <Button
                      className="w-100 cb-secondary"
                      onClick={() => window.history.back()}
                    >
                      Go Back
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>

        <div>
          <Modal
            isOpen={showPrevPopup}
            toggle={tog_large1}
            size="xl"
            className="remove-bd"
          >

            {Object.keys(popupPreviewAssessData).length > 0 ?
              (<>
                <ModalHeader
                  className="mt-0"
                  toggle={tog_large1}
                  style={{ backgroundColor: "#20365b" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mb-0 font-size-20 text-white w-60 fw-500">

                      {popupPreviewAssessData && popupPreviewAssessData.activity_node && popupPreviewAssessData.activity_node.node_name ? popupPreviewAssessData.activity_node.node_name : ''}

                    </h6>
                    <div className="d-flex align-items-center gap-4">
                      <p className="mb-0 text-uppercase luminous-wht">
                        {" "}
                        <span className="text-yellow me-1">
                          {sessionStudentData && sessionStudentData != ""
                            ? getUniqueSubjectNames(sessionStudentData).join(
                              "& "
                            ) || "-"
                            : "-"}
                        </span> Assessment{" "}
                      </p>
                      <div className="luminous-wht font-size-16">
                        <img width={24} className="" src={awicon} alt="" /> 0/{popupPreviewAssessData && popupPreviewAssessData.totalScore ? popupPreviewAssessData.totalScore + ' ' : ''}
                        coins earned
                      </div>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody>
                  {subjectIdData === 1 && (

                    <Container className="custom-wt">
                      <div className="assessement-section assessment-width-hold">

                        <Nav tabs className="md-prev-tab mb-3">
                          {


                            // popupPreviewAssessData && (subjectIdData === 1
                            //   ? popupPreviewAssessData.english_question_activities
                            //   : subjectIdData === 2
                            //     ? popupPreviewAssessData.question_activities
                            //     : []
                            // ).map((activity, popactivityindex) => (
                            popupPreviewAssessData && popupPreviewAssessData.english_question_activities && popupPreviewAssessData.english_question_activities.map((activity, popactivityindex) => (

                              <NavItem key={popactivityindex}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({ active: customActiveTab1 === (popactivityindex).toString() }) + " display-number"}
                                  onClick={() => toggleCustom1((popactivityindex).toString())}
                                >
                                  <span className="font-size-20 fw-800">{popactivityindex + 1}</span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab1}
                          className="text-muted"
                        >


                          {popupPreviewAssessData && popupPreviewAssessData.english_question_activities && popupPreviewAssessData.english_question_activities.map((activity, popactivityindex) => (
                            <TabPane tabId={popactivityindex.toString()} key={popactivityindex}>
                              {" "}
                              <div className="hw-list mt-4 pb-100">
                                <div class="QuestionBody jss13">
                                  <div>
                                    {activity.english_question &&
                                      activity.english_question.english_question_new_category &&
                                      activity.english_question.english_question_new_category.english_question_category_id === 1 && (
                                        activity.english_question.english_question_text ?
                                          <div dangerouslySetInnerHTML={{ __html: activity.english_question.english_question_text }} />
                                          : null
                                      )}
                                    {activity.english_question &&
                                      activity.english_question.english_question_new_category &&
                                      activity.english_question.english_question_new_category.english_question_category_id === 2 && (
                                        activity.english_question.english_question_text ?
                                          <div dangerouslySetInnerHTML={{ __html: activity.english_question.english_question_text }} />
                                          : null
                                      )}
                                    {activity.english_question &&
                                      activity.english_question.english_question_new_category &&
                                      activity.english_question.english_question_new_category.english_question_category_id === 8 && (
                                        activity.english_question.english_question_text ?
                                          <div dangerouslySetInnerHTML={{ __html: activity.english_question.english_question_text }} />
                                          : null
                                      )}
                                    {activity.english_question &&
                                      activity.english_question.english_question_new_category &&
                                      activity.english_question.english_question_new_category.english_question_category_id === 10 && (
                                        activity.english_question.english_question_text ?
                                          <div dangerouslySetInnerHTML={{ __html: activity.english_question.english_question_text }} />
                                          : null
                                      )}
                                    {activity.english_question &&
                                      activity.english_question.english_question_new_category &&
                                      activity.english_question.english_question_new_category.english_question_category_id === 13 && (
                                        activity.english_question.part_heading ?
                                          <div dangerouslySetInnerHTML={{ __html: activity.english_question.part_heading }} />
                                          : null
                                      )}
                                  

                                  </div>
                                </div>

                                {activity && activity.english_question && activity.english_question.english_question_parts && activity.english_question.english_question_parts && activity.english_question.english_question_parts.map((activityQuestion, indexactivityQuestion) => (
                                  <>

                                    {activity.english_question && activity.english_question.english_question_new_category && activity.english_question.english_question_new_category.english_question_category_id == 1 &&
                                      <ul className="dg-selector list-unstyled mb-0 pt-4">

                                        {activityQuestion && activityQuestion.english_question_answers && activityQuestion.english_question_answers.map((activityQuestionAns, indexQuestionAns) => (

                                          <li className={activityQuestionAns && activityQuestionAns.english_answer_is_correct == 1 ? 'selector-display' : ''}>
                                            <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns + 1}</div>
                                            <div className="p-2 px-3 dg-media border-start-display">
                                              {activityQuestionAns && activityQuestionAns.english_answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.english_answer_text }} /> : ''}

                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    }

                                    {activity.english_question && activity.english_question.english_question_new_category && activity.english_question.english_question_new_category.english_question_category_id == 2 &&

                                      <>
                                        {/* <p>
                                          {activity.english_question.english_question_text}
                                        </p> */}
                                        {/* <div class="border border-black grid grid-cols-5 p-2 pl-3 my-2 mt-5"><div class="flex gap-1"><div>(A)</div><div>will be inviting</div></div><div class="flex gap-1"><div>(B)</div><div>furthermore</div></div><div class="flex gap-1"><div>(C)</div><div>hope</div></div><div class="flex gap-1"><div>(D)</div><div>would require</div></div><div class="flex gap-1"><div>(E)</div><div>to appeal</div></div><div class="flex gap-1"><div>(F)</div><div>kindly fill</div></div><div class="flex gap-1"><div>(G)</div><div>should be</div></div><div class="flex gap-1"><div>(H)</div><div>beyond</div></div><div class="flex gap-1"><div>(J)</div><div>will ask</div></div><div class="flex gap-1"><div>(K)</div><div>want</div></div><div class="flex gap-1"><div>(L)</div><div>raise awareness</div></div><div class="flex gap-1"><div>(M)</div><div>below</div></div><div class="flex gap-1"><div>(N)</div><div>will be</div></div><div class="flex gap-1"><div>(P)</div><div>however</div></div><div class="flex gap-1"><div>(Q)</div><div>receive</div></div></div> */}
                                        <ul className="dg-selector list-unstyled mb-0 pt-4">

                                          {activityQuestion && activityQuestion.english_question_answers && activityQuestion.english_question_answers.map((activityQuestionAns2, indexQuestionAns2) => (

                                            <li className={''}>
                                              
                                              <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns2 + 1}</div>
                                              <div className="p-2 px-3 dg-media border-start-display">
                                                {activityQuestionAns2 && activityQuestionAns2.english_answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns2.english_answer_text }} /> : ''}

                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    }
                                    {activity.english_question && activity.english_question.english_question_new_category && activity.english_question.english_question_new_category.english_question_category_id == 8 &&
                                      <>
                                        
                                        <div className="p-2 px-3 px-3 font-size-18">
  {activityQuestion && activityQuestion.english_question_part_text ? (
    <div dangerouslySetInnerHTML={{ __html: `${indexactivityQuestion + 1}. ${activityQuestion.english_question_part_text}` }} />
  ) : (
    ''
  )}
</div>

                                          
                                       
                                        <ul className="dg-selector list-unstyled mb-0 pt-4">


                                          {activityQuestion && activityQuestion.english_question_answers && activityQuestion.english_question_answers.map((activityQuestionAns, indexQuestionAns) => (

                                            <li className={activityQuestionAns && activityQuestionAns.english_answer_is_correct == 1 ? 'selector-display' : ''}>
                                              <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns + 1}</div>
                                              <div className="p-2 px-3 dg-media border-start-display">
                                                {activityQuestionAns && activityQuestionAns.english_answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.english_answer_text }} /> : ''}

                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    }
                                    {activity.english_question && activity.english_question.english_question_new_category && activity.english_question.english_question_new_category.english_question_category_id == 10 &&
                                      <ul className="dg-selector list-unstyled mb-0 pt-4">

                                        {activityQuestion && activityQuestion.english_question_answers && activityQuestion.english_question_answers.map((activityQuestionAns, indexQuestionAns) => (

                                          <li className={activityQuestionAns && activityQuestionAns.english_answer_is_correct == 1 ? 'selector-display' : ''}>
                                            <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns + 1}</div>
                                            <div className="p-2 px-3 dg-media border-start-display">
                                              {activityQuestionAns && activityQuestionAns.english_answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.english_answer_text }} /> : ''}

                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    }
                                    {activity.english_question && activity.english_question.english_question_new_category && activity.english_question.english_question_new_category.english_question_category_id == 13 &&


                                      <div>


                                        {/* <ul className="dg-selector list-unstyled mb-0 pt-4"> */}
                                        <div class="q_a-preview pt-3">
                                        {activityQuestion && activityQuestion.english_question_part_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestion.english_question_part_text }} /> : ''}
                                            {/* <div className="">{activityQuestion.english_question_part_text} </div> */}
                                           
                                          {activityQuestion && activityQuestion.english_question_answers && activityQuestion.english_question_answers.map((activityQuestionAns, indexQuestionAns) => (
                                            
                                            <div className={activityQuestionAns && activityQuestionAns.english_answer_text  ? ' myresult ' : ''}>
                                              {/* <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns + 1}</div> */}
                                              <div className="p-2 px-3 dg-media ">
                                                {activityQuestionAns && activityQuestionAns.english_answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.english_answer_text }} /> : ''}

                                              </div>
                                            </div>
                                          ))}
                                         
                                      </div>
                                        {/* </ul> */}

                                      </div>
                                    }


                                  </>
                                ))}


                                <div className="btn-group d-flex justify-content-between mt-3">
                                  <div>
                                    {parseInt(customActiveTab1) !== 0 && (
                                      <Button className="btn d-inline-block cs-btn-danger mt-1" onClick={goToPrevTab}>
                                        Prev
                                      </Button>
                                    )}
                                  </div>
                                  <div>
                                    {parseInt(customActiveTab1) !== popupPreviewAssessData.english_question_activities.length - 1 && (
                                      <Button className="btn cs-btn-danger mt-1" onClick={goToNextTab}>
                                        Next
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div >
                            </TabPane>
                          ))}

                        </TabContent>

                      </div>
                    </Container>
                  )}
                  {subjectIdData === 2 && (
                    <Container className="custom-wt">
                      <div className="assessement-section assessment-width-hold">

                        <Nav tabs className="md-prev-tab mb-3">
                          {


                            // popupPreviewAssessData && (subjectIdData === 1
                            //   ? popupPreviewAssessData.english_question_activities
                            //   : subjectIdData === 2
                            //     ? popupPreviewAssessData.question_activities
                            //     : []
                            // ).map((activity, popactivityindex) => (
                            popupPreviewAssessData && popupPreviewAssessData.question_activities && popupPreviewAssessData.question_activities.map((activity, popactivityindex) => (

                              <NavItem key={popactivityindex}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({ active: customActiveTab1 === (popactivityindex).toString() }) + " display-number"}
                                  onClick={() => toggleCustom1((popactivityindex).toString())}
                                >
                                  <span className="font-size-20 fw-800">{popactivityindex + 1}</span>
                                </NavLink>
                              </NavItem>
                            ))}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab1}
                          className="text-muted"
                        >


                          {popupPreviewAssessData && popupPreviewAssessData.question_activities && popupPreviewAssessData.question_activities.map((activity, popactivityindex) => (
                            <TabPane tabId={popactivityindex.toString()} key={popactivityindex}>
                              {" "}
                              <div className="hw-list mt-4 pb-100">

                                {activity && activity.question && activity.question.question_parts && activity.question.question_parts && activity.question.question_parts.map(activityQuestion => (<>
                                  <div class="QuestionBody jss13">
                                    <div>
                                      {activityQuestion && activityQuestion.part_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestion.part_text }} /> : ''}


                                    </div>
                                  </div>
                                  {/* part_answer_type: 4 
                            activityQuestion.part_answer_type

                            part_answer_type: 2

                           */}
                                  {activityQuestion && activityQuestion.part_answer_type == 4 &&
                                    <ul className="dg-selector list-unstyled mb-0 pt-4 ">
                                      {activityQuestion && activityQuestion.question_answers && activityQuestion.question_answers.map((activityQuestionAns, indexQuestionAns) => (


                                        <li className="selector-display">
                                          <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns + 1}</div>
                                          <div className="p-2 px-3 dg-media border-start-display">
                                            {activityQuestionAns && activityQuestionAns.answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.answer_text }} /> : ''}

                                          </div>
                                        </li>
                                      ))}
                                    </ul>

                                  }
                                  {activityQuestion && activityQuestion.part_answer_type == 2 &&
                                    <ul className="dg-selector list-unstyled mb-0 pt-4 w-25 ">
                                      {activityQuestion && activityQuestion.question_answers && activityQuestion.question_answers.map((activityQuestionAns, indexQuestionAns) => (


                                        <li className="selector-display-parttype-2">
                                          <div className="p-2 px-3 dg-media ">
                                            {activityQuestionAns && activityQuestionAns.answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.answer_text }} /> : ''}

                                          </div>
                                        </li>
                                      ))}
                                    </ul>

                                  }



                                </>))}


                                <div className="btn-group d-flex justify-content-between mt-3">
                                  <div>
                                    {parseInt(customActiveTab1) !== 0 && (
                                      <Button className="btn d-inline-block cs-btn-danger mt-1" onClick={goToPrevTab}>
                                        Prev
                                      </Button>
                                    )}
                                  </div>
                                  <div>

                                    {parseInt(customActiveTab1) !== parseInt(popupPreviewAssessData.question_activities.length) - 1 && (
                                      <Button className="btn cs-btn-danger mt-1" onClick={goToNextTab}>
                                        Next
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                          ))}

                        </TabContent>
                      </div>
                    </Container>)}
                </ModalBody>
              </>) : ''}

            {console.log(popupPreviewDiagnosData)}

            {
              Object.keys(popupPreviewDiagnosData).length > 0 ?
                (<>
                  <ModalHeader
                    className="mt-0"
                    toggle={tog_large1}
                    style={{ backgroundColor: "#20365b" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">

                      <h6 className="mb-0 font-size-20 text-white w-60 fw-500">

                        {popupPreviewDiagnosData && popupPreviewDiagnosData.topic_name ? popupPreviewDiagnosData.topic_name : ''}

                      </h6>

                      <div className="d-flex align-items-center gap-4">
                        <p className="mb-0 text-uppercase luminous-wht">
                          {" "}
                          <span className="text-yellow me-1">
                            {sessionStudentData && sessionStudentData != ""
                              ? getUniqueSubjectNames(sessionStudentData).join(
                                "& "
                              ) || "-"
                              : "-"}
                          </span> Diagnostics{" "}
                        </p>
                        <div className="luminous-wht font-size-16">
                          <img width={24} className="" src={awicon} alt="" /> 0/{popupPreviewDiagnosData && popupPreviewDiagnosData.totalScore ? popupPreviewDiagnosData.totalScore + ' ' : ''}
                          coins earned
                        </div>
                      </div>
                    </div>
                  </ModalHeader>
                  <ModalBody>

                    {console.log(subjectIdData)}
                    {subjectIdData === 1 && (

                      <Container className="custom-wt">
                        <div className="assessement-section assessment-width-hold">

                          <Nav tabs className="md-prev-tab mb-3">
                            {


                              // popupPreviewAssessData && (subjectIdData === 1
                              //   ? popupPreviewAssessData.english_question_activities
                              //   : subjectIdData === 2
                              //     ? popupPreviewAssessData.question_activities
                              //     : []
                              // ).map((activity, popactivityindex) => (
                              popupPreviewDiagnosData && popupPreviewDiagnosData.questionData && popupPreviewDiagnosData.questionData.map((activitytitle, popactivityindex) => (

                                <NavItem key={popactivityindex}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: customActiveTab1 === (popactivityindex).toString() }) + " display-number"}
                                    onClick={() => toggleCustom1((popactivityindex).toString())}
                                  >
                                    <span className="font-size-20 fw-800">{popactivityindex + 1}</span>
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab1}
                            className="text-muted"
                          >
                            {popupPreviewDiagnosData && popupPreviewDiagnosData.questionData && popupPreviewDiagnosData.questionData.map((activity, popactivityindex) => (
                              <TabPane tabId={popactivityindex.toString()} key={popactivityindex}>
                                {" "}
                                <div className="hw-list mt-4 pb-100">
                                  <div class="QuestionBody jss13">
                                    <div>

                                      {activity && activity.english_diagnostics && activity.english_diagnostics.diagnostic_text ? <div dangerouslySetInnerHTML={{ __html: activity.english_diagnostics.diagnostic_text }} /> : ''}


                                    </div>
                                  </div>


                                  <ul className="dg-selector list-unstyled mb-0 pt-4">

                                    {activity && activity.english_diagnostics.english_diagnostic_answers && activity.english_diagnostics.english_diagnostic_answers.map((activityQuestionAns, indexQuestionAns) => (

                                      <li className={activityQuestionAns && activityQuestionAns.answer_is_correct == 1 ? 'selector-display' : ''}>
                                        <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns + 1}</div>
                                        <div className="p-2 px-3 dg-media border-start-display">
                                          {activityQuestionAns && activityQuestionAns.answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.answer_text }} /> : ''}

                                        </div>
                                      </li>
                                    ))}
                                  </ul>








                                  <div className="btn-group d-flex justify-content-between mt-3">
                                    <div>
                                      {parseInt(customActiveTab1) !== 0 && (
                                        <Button className="btn d-inline-block cs-btn-danger mt-1" onClick={goToPrevTab}>
                                          Prev
                                        </Button>
                                      )}
                                    </div>

                                    <div>
                                      {parseInt(customActiveTab1) !== popupPreviewDiagnosData.questionData.length - 1 && (
                                        <Button className="btn cs-btn-danger mt-1" onClick={goToNextTabDiag}>
                                          Next
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </TabPane>
                            ))}

                          </TabContent>

                        </div>
                      </Container>
                    )}
                    {subjectIdData === 2 && (


                      <Container className="custom-wt">
                        <div className="assessement-section assessment-width-hold">

                          <Nav tabs className="md-prev-tab mb-3">
                            {


                              // popupPreviewAssessData && (subjectIdData === 1
                              //   ? popupPreviewAssessData.english_question_activities
                              //   : subjectIdData === 2
                              //     ? popupPreviewAssessData.question_activities
                              //     : []
                              // ).map((activity, popactivityindex) => (
                              popupPreviewDiagnosData && popupPreviewDiagnosData.questionData && popupPreviewDiagnosData.questionData.map((activitytitle, popactivityindex) => (

                                <NavItem key={popactivityindex}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: customActiveTab1 === (popactivityindex).toString() }) + " display-number"}
                                    onClick={() => toggleCustom1((popactivityindex).toString())}
                                  >
                                    <span className="font-size-20 fw-800">{popactivityindex + 1}</span>
                                  </NavLink>
                                </NavItem>
                              ))}
                          </Nav>

                          <TabContent
                            activeTab={customActiveTab1}
                            className="text-muted"
                          >
                            {popupPreviewDiagnosData && popupPreviewDiagnosData.questionData && popupPreviewDiagnosData.questionData.map((activity, popactivityindex) => (
                              <TabPane tabId={popactivityindex.toString()} key={popactivityindex}>
                                {" "}
                                <div className="hw-list mt-4 pb-100">
                                  <div class="QuestionBody jss13">
                                    <div>

                                      {activity && activity.diagnostics && activity.diagnostics.diagnostic_text ? <div dangerouslySetInnerHTML={{ __html: activity.diagnostics.diagnostic_text }} /> : ''}


                                    </div>
                                  </div>


                                  <ul className="dg-selector list-unstyled mb-0 pt-4">

                                    {activity && activity.diagnostics.diagnostic_answers && activity.diagnostics.diagnostic_answers.map((activityQuestionAns, indexQuestionAns) => (

                                      <li className={activityQuestionAns && activityQuestionAns.answer_is_correct == 1 ? 'selector-display' : ''}>
                                        <div className="p-2 px-3 px-3 font-size-18">{indexQuestionAns + 1}</div>
                                        <div className="p-2 px-3 dg-media border-start-display">
                                          {activityQuestionAns && activityQuestionAns.answer_text ? <div dangerouslySetInnerHTML={{ __html: activityQuestionAns.answer_text }} /> : ''}

                                        </div>
                                      </li>
                                    ))}
                                  </ul>








                                  <div className="btn-group d-flex justify-content-between mt-3">
                                    <div>
                                      {parseInt(customActiveTab1) !== 0 && (
                                        <Button className="btn d-inline-block cs-btn-danger mt-1" onClick={goToPrevTab}>
                                          Prev
                                        </Button>
                                      )}
                                    </div>

                                    <div>
                                      {parseInt(customActiveTab1) !== popupPreviewDiagnosData.questionData.length - 1 && (
                                        <Button className="btn cs-btn-danger mt-1" onClick={goToNextTabDiag}>
                                          Next
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </TabPane>
                            ))}

                          </TabContent>

                        </div>
                      </Container>)}
                  </ModalBody>
                </>) : ''
            }
            {
              Object.keys(popupPreviewLibraryData).length > 0 ?
                (<>
                  <ModalHeader
                    className="mt-0"
                    toggle={tog_large1}
                    style={{ backgroundColor: "#20365b" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">

                      <h6 className="mb-0 font-size-20 text-white w-60 fw-500">

                        {popupPreviewLibraryData && popupPreviewLibraryData.name ? popupPreviewLibraryData.name : ''}

                      </h6>
                      <div className="d-flex align-items-center gap-4">
                        <p className="mb-0 text-uppercase luminous-wht">
                          {" "}
                          <span className="text-yellow me-1">
                            {sessionStudentData && sessionStudentData != ""
                              ? getUniqueSubjectNames(sessionStudentData).join(
                                "& "
                              ) || "-"
                              : "-"}
                          </span> My Library{" "}
                        </p>
                        <div className="luminous-wht font-size-16">
                          <img width={24} className="" src={awicon} alt="" /> 0/{popupPreviewLibraryData && popupPreviewLibraryData.totalScore ? popupPreviewLibraryData.totalScore + ' ' : ''}
                          coins earned
                        </div>
                      </div>
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <Container className="custom-wt">
                      <div className="assessement-section assessment-width-hold">

                        {popupPreviewLibraryData && popupPreviewLibraryData.activity_type == 5 && (
                          <div className="hw-list mt-4 pb-100">
                            <div class="QuestionBody jss13">
                              <div>
                                {popupPreviewLibraryData && popupPreviewLibraryData.link ? <video src={popupPreviewLibraryData.link} width="100%" height="400" controls /> : ''}
                              </div>
                            </div>
                          </div>
                        )}
                        {popupPreviewLibraryData && popupPreviewLibraryData.activity_type == 4 && (
                          <div className="hw-list mt-4 pb-100">
                            <div class="QuestionBody jss13">
                              <div>
                                {popupPreviewLibraryData && popupPreviewLibraryData.source ? <div dangerouslySetInnerHTML={{ __html: popupPreviewLibraryData.source }} /> : ''}
                              </div>
                            </div>
                          </div>
                        )}
                        {popupPreviewLibraryData && popupPreviewLibraryData.activity_type == 3 && (
                          <div className="hw-list mt-4 pb-100">
                            <div class="QuestionBody jss13">
                              <div>
                                {popupPreviewLibraryData && popupPreviewLibraryData.link ? <video src={popupPreviewLibraryData.link} width="100%" height="400" controls /> : ''}
                              </div>
                            </div>
                          </div>
                        )}

                        {popupPreviewLibraryData && popupPreviewLibraryData.activity_type == 1 && (
                          <div className="hw-list mt-4 pb-100">
                            <div class="QuestionBody jss13">
                              <div>
                                {popupPreviewLibraryData && popupPreviewLibraryData.link ?
                                  <ReactPlayer url={popupPreviewLibraryData.link} width='100%'
                                    height='400px' />
                                  : ''}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Container>
                  </ModalBody>
                </>) : ''
            }

            {
              Object.keys(popupPreviewVideoData).length > 0 ?
                (<>
                  <ModalHeader
                    className="mt-0"
                    toggle={tog_large1}
                    style={{ backgroundColor: "#20365b" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">

                      <h6 className="mb-0 font-size-20 text-white w-60 fw-500">

                        {popupPreviewVideoData && popupPreviewVideoData.video_name ? popupPreviewVideoData.video_name : ''}

                      </h6>
                      <div className="d-flex align-items-center gap-4">
                        <p className="mb-0 text-uppercase luminous-wht">
                          {" "}
                          <span className="text-yellow me-1">
                            {sessionStudentData && sessionStudentData != ""
                              ? getUniqueSubjectNames(sessionStudentData).join(
                                "& "
                              ) || "-"
                              : "-"}
                          </span> Video{" "}
                        </p>
                        <div className="luminous-wht font-size-16">
                          <img width={24} className="" src={awicon} alt="" /> 0/{popupPreviewVideoData && popupPreviewVideoData.totalScore ? popupPreviewVideoData.totalScore + ' ' : ''}
                          coins earned
                        </div>
                      </div>
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <Container className="custom-wt">
                      <div className="assessement-section assessment-width-hold">

                        {popupPreviewVideoData && (
                          <div className="hw-list mt-4 pb-100">
                            <div class="QuestionBody jss13">
                              Please watch the entire video to get the coins.
                              <div>
                                {popupPreviewVideoData && popupPreviewVideoData.video_url ? <ReactPlayer url={popupPreviewVideoData.video_url} width='100%'
                                  height='500px' /> : ''}
                              </div>
                            </div>
                          </div>
                        )}

                      </div>
                    </Container>
                  </ModalBody>
                </>) : ''
            }

            {
              Object.keys(popupPreviewWorksheetData).length > 0 ?
                (<>
                  <ModalHeader
                    className="mt-0"
                    toggle={tog_large1}
                    style={{ backgroundColor: "#20365b" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">

                      <h6 className="mb-0 font-size-20 text-white w-60 fw-500">

                        {popupPreviewWorksheetData && popupPreviewWorksheetData.activity_level_has_topic_node.activity_master_topic ? popupPreviewWorksheetData.activity_level_has_topic_node.activity_master_topic.topic_name : ''}

                      </h6>
                      <div className="d-flex align-items-center gap-4">
                        <p className="mb-0 text-uppercase luminous-wht">
                          {" "}
                          <span className="text-yellow me-1">
                            {sessionStudentData && sessionStudentData != ""
                              ? getUniqueSubjectNames(sessionStudentData).join(
                                "& "
                              ) || "-"
                              : "-"}
                          </span> Video{" "}
                        </p>
                        <div className="luminous-wht font-size-16">
                          <img width={24} className="" src={awicon} alt="" /> 0/{popupPreviewWorksheetData && popupPreviewWorksheetData.totalScore ? popupPreviewWorksheetData.totalScore + ' ' : ''}
                          coins earned
                        </div>
                      </div>
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <Container className="custom-wt">
                      <div className="assessement-section assessment-width-hold">

                        {popupPreviewWorksheetData && (
                          <div className="hw-list mt-4 pb-100">
                            <div class="QuestionBody jss13">
                              <div>


                                <div className="assessement-section summary-width-hold mt-4">
                                  <div className="hw-list mt-4 pb-100">
                                    <ul className="list-unstyled mb-0">
                                      <li className="justify-content-between">
                                        <div className="d-flex align-items-start gap-4 justify-content-end">
                                          <div className="mb-0 d-flex align-items-center gap-2">

                                            {/* <span onClick={() => tog_standard(questionActivity)}>
                      <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                    </span> */}

                                          </div>
                                        </div>

                                        <div className="cs-tools d-flex align-items-center justify-content-center mt-3 mb-3">
                                          {/* <h4 className="font-size-16 fw-semibold mb-4 mt-4">
                      Please watch the entire video to get the coins.
                    </h4> */}


                                          <button className={drawMode ? 'active' : ''} onClick={toggleDrawMode}> <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-brush-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04" />
                                          </svg></button>

                                          <button className={textMode ? 'active' : ''} onClick={toggleTextMode}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-fonts super-size"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479z" />
                                            </svg>
                                          </button>
                                          <button className={shapMode ? 'active' : ''} onClick={ClickShapeMode}>
                                            {selectedShape == "circle" ? (
                                              <div className="fs-circle"></div>
                                            ) : selectedShape == "rectangle" ? (
                                              <div className="fs-rectangle"></div>
                                            ) : selectedShape == "square" ? (
                                              <div className="fs-square"></div>
                                            ) : (
                                              "circle"
                                            )}
                                          </button>
                                          <select
                                            value={selectedShape}
                                            //onClick={(e) => ClickShapeMode(e.target.value)}
                                            onChange={(e) => toggleShapeMode(e.target.value)}
                                            style={{ width: '20px' }}
                                          >
                                            <option value="circle">Circle</option>
                                            <option value="rectangle">Rectangle</option>
                                            <option value="square">Square</option>
                                          </select>
                                          <button onClick={zoomIn}> <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-zoom-in"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
                                            />
                                            <path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"
                                            />
                                          </svg></button>
                                          <button onClick={zoomOut}> <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-zoom-out"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
                                            />
                                            <path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"
                                            />
                                          </svg></button>
                                          {/* <button onClick={undoAction}>Undo</button>
                  <button onClick={redoAction}>Redo</button> */}
                                          <button onClick={removeSelectedObject}><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-ban"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
                                          </svg></button>

                                          <input
                                            type="color"
                                            value={currentColor}
                                            onChange={(e) => changeColor(e.target.value)}
                                          />

                                          <button onClick={downloadPdf}> <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-printer-fill fx-printer"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                                            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                          </svg></button>


                                          {/* <Button className="btn btn-danger cs-btn-danger mt-1 text-uppercase" onClick={tog_standard1}>
                    SUBMIT
                  </Button> */}
                                        </div>
                                        {/* <div className="video-wrapper"> */}
                                        <div>
                                          <div>

                                          </div>


                                        </div>
                                        <div>
                                          <div id="Canvas_list"></div>
                                        </div>
                                        {/* </div> */}

                                      </li>
                                      {/* <div>
                    <span>Duration: {formatDuration(duration)}</span>
          <br />
          <span>Elapsed: {formatDuration(duration * played)}</span>
          <br />
          <span>Remaining: {remainingTime}</span>
                    </div> */}


                                    </ul>
                                  </div>
                                </div>
                                {/* 
                                {popupPreviewWorksheetData && popupPreviewWorksheetData.file_name && subjectIdData === 1 ? 1
                                  : popupPreviewWorksheetData && popupPreviewWorksheetData.file_name && subjectIdData === 2 ? 2 : ''} */}

                                {/*  {
                                  popupPreviewWorksheetData && popupPreviewWorksheetData.file_name && subjectIdData === 1 ? (
                                  <PDFEditor src={`https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/english_activity_pdf/${popupPreviewWorksheetData.file_name}`} />) : popupPreviewWorksheetData && popupPreviewWorksheetData.file_name && subjectIdData === 2 ?  (
                                  <PDFEditor src={`https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/math_activity_pdf/${popupPreviewWorksheetData.file_name}`} />) : ''} */}
                              </div>
                            </div>
                          </div>
                        )}

                      </div>



                    </Container>
                  </ModalBody>
                </>) : ''
            }

          </Modal>
        </div>


      </Container>
    </React.Fragment>
  )
}

ManualMarkLCMSMark.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ManualMarkLCMSMark)
