import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import UAParser from 'ua-parser-js';
// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import FileDropZone from './FileDropZone';
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-dark.png"
import error from "../../assets/images/services-icon/error.png";
import mail from "../../assets/images/services-icon/mail.svg";
import lock from "../../assets/images/services-icon/lock.svg";
import success_setup from "../../assets/images/services-icon/success_setup.png";

import moment from "moment"

import axios from "axios"
import { SECURITY_KEY, BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { useLocation } from "react-router-dom"

const Login = props => {
  document.title = "MyGooRoo Tutor App"
  // const [uploadedFiles, setUploadedFiles] = useState([]);

  // const handleFileUpload = files => {
  //   // Update the uploaded files state
  //   setUploadedFiles([...uploadedFiles, ...files]);
  // };
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUpload = file => {
    setUploadedFile(file);
  };
 
  // modal
  const [modal_center, setmodal_center] = useState(false)

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  // const dispatch = useDispatch();
  const [userToken, setUserToken] = useState([])
  const [isIpData, setIpData] = useState('');
  const [userDetails, setUserDeatils] = useState(null);
  const [tut_pref_Name, set_tut_pref_Name] = useState(null);
  const [tut_profile, set_tut_profile] = useState(null);

  const [isContentDiv, setIsContentDiv] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [IsMaintenance, setMaintenance] = useState([])
  const [IsContent, setContent] = useState([])
  const [IsContentFilter, setContentFilter] = useState([])

  const [userLogin, setUserLogin] = useState([])


  const [loadingPrelaod, setLoadingPrelaod] = useState(false);


  const location = useLocation()
  useEffect(() => {
    if (!localStorage.getItem("authUser")) {
      fetchMaintenance()
      fetchContentData()
      fetchIpData()
    } else {
      props.router.navigate("/dashboard")
    }
  }, [location])

  const fetchMaintenance = async () => {
    try {
      const response = await axios.post(
        BASE_URL+"tutorlogin/tutorMaintenance"
      )
      if (response.data.length > 0) {
        setMaintenance(response.data[0])
      } else {
        console.error("Error fetching data")
      }
      setTimeout(() => {
        setLoadingPrelaod(false);
      }, 2000);

    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const fetchContentData = async () => {
    try {
      const response = await axios.post(
        BASE_URL+"tutorlogin/tutorContent"
      )
      if (response.data.length > 0) {
        setContent(response.data)
      } else {
        console.error("Error fetching data")
      }
      setTimeout(() => {
        setLoadingPrelaod(false);
      }, 2000);

    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const fetchIpData = async () => {

     
    try {
      // Get the user's IP address
     const ipResponse = await axios.get('https://jsonip.com');
    // console.log(ipResponse);
      if (ipResponse && ipResponse.data && ipResponse.data.ip && ipResponse.data.ip!='') {
        const ipAddress = ipResponse.data.ip;
        //console.log(ipAddress);
        setIpData(ipAddress)
      } else {
        console.error("Error fetching ip data")
        //setTimeout(fetchIpData, 3000);
      }
    } catch (error) {
      //setTimeout(fetchIpData, 3000);
      console.error("Error fetching ip data:", error)
    }
  }

  // const { user } = useSelector(state => ({
  //   user: state.Account.user,
  // }));

  // useEffect(() => {
  //   if (user && user) {
  //     setUserLogin({
  //       username: user.username,
  //       password: user.password
  //     });
  //   }
  // }, [user]);
  const [IsTearmsCondition, setTearmsCondition] = useState([])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: userLogin.username || "",
      password: userLogin.password || "",
      keepLogin: false,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("Username is required")
        .min(2, "Minimum 2 characters is required")
        .max(30, "Maximum 30 characters is required")
        .matches(/^[^'"`\s’]+$/, "Username is required"),
      password: Yup.string()
        .required("Password is required")
        .min(5, "Minimum 5 characters is required")
        .max(30, "Maximum 30 characters is required")
        .required("Password is required")
        .matches(
          /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
          "Avoid start & end with a space or comma"
        ),
    }),
    onSubmit: async values => {
      // console.log(values);

      //disable login button
      setIsSubmitting(true);
      setLoadingPrelaod(true);
      const parser = new UAParser();
      const systemInfo = parser.getResult();

      try {
        const response = await axios.post(
          BASE_URL+"tutorlogin/loginTutor",
          {
            ...values,
            ip_address : isIpData,
            system_info : systemInfo
          }
        )
        if (response.data.length > 0) {
          if (response.data[0]["fk_tut_key"] && response.data[0]["fk_tut_key"] !== "") {
            setUserDeatils(response.data[0]);
            // toast.success(" Student slot assigned", {
            //   autoClose: 3000, // 3 seconds in this example
            // });
            // localStorage.setItem("jwt-token", JSON.stringify(response.data[0]['token']));
            localStorage.setItem( "country",  response.data[0]["cent_country"] )
            localStorage.setItem( "timezone",  response.data[0]["cent_timezone_identifier"])
            localStorage.setItem( "flexiDays",  response.data[0]["tut_flexi_days"])
            setUserToken(response.data[0]["token"])
            const filteredData = IsContent.filter(
              item => item.pk_content_key === "8C516253795442BA"
            )
            if (
              (parseInt(response.data[0]["logged_in_flag"]) === 0 || response.data[0]["logged_in_flag"] == null) &&
              filteredData.length > 0
            ) {
              setTearmsCondition(filteredData[0])
              setIsContentDiv(2)
            } else if (
              response.data[0]["tut_activie"] == null ||
              response.data[0]["tut_activie"] == "" ||
              parseInt(response.data[0]["tut_activie"]) === 0
            ) {
              setIsContentDiv(3);
            }else if (
              response.data[0]["tut_activie"] != null &&  
              parseInt(response.data[0]["tut_activie"]) === 1
            ) {
              setIsContentDiv(4);
            }else if (
              response.data[0]["tut_activie"] != null &&  
              parseInt(response.data[0]["tut_activie"]) === 2
            ) {
              setIsContentDiv(5);
            } else {
              localStorage.setItem(
                "authUser",
                JSON.stringify(response.data[0]["token"])
              )
              props.router.navigate("/dashboard")
             
            }


            setIsSubmitting(false)
            setTimeout(() => {
              setLoadingPrelaod(false);
            }, 2000);


          } else {

            if(response.data[0]["login_status"] && parseInt(response.data[0]["login_status"]) === 2){
              toast.error("Account Inactive - Please Contact Centre", {
                autoClose: 3000, // 3 seconds in this example
              })
            }else{
              toast.error("The entered username or password is wrong.", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
            setIsSubmitting(false)
            setTimeout(() => {
              setLoadingPrelaod(false);
            }, 2000);

          }
        } else {
          setUserDeatils(null);
          toast.error("The entered username or password is wrong.", {
            autoClose: 3000, // 3 seconds in this example
          })
          setIsSubmitting(false)
          setTimeout(() => {
            setLoadingPrelaod(false);
          }, 2000);

        }
      } catch (error) {
        setUserDeatils(null);
        toast.error("The entered username or password is wrong.", {
          autoClose: 3000, // 3 seconds in this example
        })
        console.error("Error fetching data:", error)

        setIsSubmitting(false) // Re-enable the submit button
        setTimeout(() => {
          setLoadingPrelaod(false);
        }, 2000);

      } finally {
        
        setIsSubmitting(false) // Re-enable the submit button
        setTimeout(() => {
          setLoadingPrelaod(false);
        }, 2000);

      }

      //dispatch(loginUser(values, props.router.navigate));
    },
  })

 // console.log(userDetails);
  const submitConditionDenide = async () => {
    localStorage.clear()
    fetchContentData()
    setIsContentDiv(1)
  }
  
  
  const submitCondition = async () => {
    if (userToken && userToken != "" && userToken != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`
      axios
        .post(BASE_URL+"tutorlogin/teamsCondition", {
          flag: 1,
        })
        .then(responsetearms => {
          if (responsetearms.data == "1") {
             if (
              userDetails.tut_activie == null ||
              userDetails.tut_activie == "" ||
              parseInt(userDetails.tut_activie) === 0
            ) {
              setIsContentDiv(3);
            }else if (
              userDetails.tut_activie != null &&  
              parseInt(userDetails.tut_activie) === 1
            ) {
              setIsContentDiv(4);
            }else if (
              userDetails.tut_activie != null &&  
              parseInt(userDetails.tut_activie) === 2
            ) {
              setIsContentDiv(5);
            }else{
              localStorage.setItem(
                  "authUser",
                  JSON.stringify(userToken)
                )
                props.router.navigate("/dashboard")
            }
            
          } else {
            toast.error("Not update Terms & Conditions accepted", {
              autoClose: 3000, // 3 seconds in this example
            })
          }
        })
        .catch(error => {
          toast.error("Not update Terms & Conditions accepted", {
            autoClose: 3000, // 3 seconds in this example
          })
        
        })
    } else {
      toast.error("Not update Terms & Conditions accepted", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

    // if(isAvathor && isAvathor !==null && parseInt(isAvathor)!== 0){
    // setIsContentDiv(3);
    // }else{
    //   localStorage.setItem(
    //     "authStudentUser",
    //     JSON.stringify(userToken)
    //   )
    //   props.router.navigate("/");
    // }
  }


  const UserContentSubmit = async () => {

    if (userToken && userToken != "" && userToken != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
      let tut_active = 1;
      if(isContentDiv === 3){
        tut_active = 1;
      }else if(isContentDiv === 4){
        tut_active = 2;
      }else if(isContentDiv === 5){
        tut_active = 3;
      }
      //console.log(tut_profile[0])
      if(isContentDiv === 5 && uploadedFile && uploadedFile.name && uploadedFile.name!=''){
        try {
            const formData = new FormData();
            formData.append('profileImage', uploadedFile); // Append the file to form data
      
            const responseUpdate = await axios.post(
              BASE_URL+`profile/updateTutorProfileImg`, formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
      
            if (responseUpdate.status === 200) {
              setIsContentDiv(isContentDiv + 1)
            } else {
              toast.error('Failed to update profile image', {
                autoClose: 3000,
              });
            }
          } catch (error) {
            toast.error('Error updating profile image', {
              autoClose: 3000,
            });
          }
      }else{
        axios
        .post(BASE_URL+"tutorlogin/updateTutorProfile", {
          tut_active: tut_active,
          tut_pref_Name: tut_pref_Name
        })
        .then(responsetearms => {
          if (responsetearms.data == "1") {
            setIsContentDiv(isContentDiv + 1)
            
          } else {
            toast.error("Not update tutor profile", {
              autoClose: 3000, // 3 seconds in this example
            })
          }
        })
        .catch(error => {
          toast.error("Not update tutor profile", {
            autoClose: 3000, // 3 seconds in this example
          })
         
        })
      }
      
    } else {
      toast.error("Not update tutor profile", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
    
  }

  const UserContentBack = async () => {
    setIsContentDiv(isContentDiv - 1)
  }
  const UserContentDashboard = async () => {
    localStorage.setItem(
      "authUser",
      JSON.stringify(userToken)
    )
    props.router.navigate("/dashboard")
  }
  

  const [passwordVisible, setPasswordVisible] = useState(false);


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
  return (
    <React.Fragment>

      <ToastContainer autoClose={3000} />


      {IsMaintenance ? (
        IsMaintenance.sm_narration &&
          IsMaintenance.sm_narration != "" &&
          parseInt(IsMaintenance.is_visible) == 0 ? (
          <div className="headerlogin">
            <div className="d-flex justify-content-between align-items-center">
              <span className="font-size-12 d-flex align-items-center gap-1">
                <img src={error} width={24} alt="logo" />
                <p className="font-size-12 mb-0 text-dark">Scheduled maintenance</p>
              </span>
              <span className="">
                {IsMaintenance
                  ? IsMaintenance.sm_narration &&
                    IsMaintenance.sm_narration != null &&
                    IsMaintenance.sm_narration != ""
                    ? IsMaintenance.sm_narration
                    : null
                  : null}
              </span>
            </div>
          </div>
        ) : null
      ) : null}
      {loadingPrelaod ? (
        <div className="container SplashLoader_splash" style={{
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          width: '100%'
        }}>
          <div className="SplashLoader_p20__OUCVf" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: '50px'
          }}>
            <img src={logoSm} alt="logo" style={{ width: '300px' }} />

          </div>
        </div>
      ) : (
        <><div className="account-pages position-y"  style={{ display: isContentDiv === 1 ? "block" : "none" }}>
          <Container>
            <div className="lp-wrapper">
              <div className="logo-wrapper text-center" style={{ marginTop: "95px", marginBottom: "40px" }}>
                <img src={logoSm} width={230} alt="logo" />
              </div>

              <div className="lg-form">
                <Form
                  className=""
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                  action="#"
                >
                  <div className="mb-4 position-relative">
                    {/* <Label className="form-label" htmlFor="username">
                    Username
                  </Label> */}
                    <div className="ic-input">
                      <img src={mail} width={25} alt="logo" />
                    </div>
                    <Input
                      name="username"
                      className="form-control"
                      placeholder="Enter Username"
                      type="username"
                      id="username"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={
                        validation.touched.username && validation.errors.username
                          ? true
                          : false
                      }
                    />

                    {validation.touched.username && validation.errors.username ? (
                      <FormFeedback type="invalid">
                        {validation.errors.username}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-4 position-relative">
                    {/* <Label className="form-label" htmlFor="userpassword">
                    Password
                  </Label> */}
                    <div className="ic-input">
                      <img src={lock} width={25} alt="logo" />
                    </div>
                    <Input
                      name="password"
                      value={validation.values.password || ""}
                      type={passwordVisible ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password && validation.errors.password
                          ? true
                          : false
                      }
                    />

                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}

                    <div className="password-hider position-absolute" onClick={togglePasswordVisibility}>
                      {passwordVisible ? <i className="mdi mdi-eye font-size-24"></i> : <i className="mdi mdi-eye-off font-size-24"></i>}
                    
                    {/* <i className="mdi mdi-eye-off font-size-24"></i> */}
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-check mt-4">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="keepLogin"
                        checked={validation.values.keepLogin}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      />
                      {/* <input type="checkbox" className="form-check-input" id="customControlInline" checked={validation.values.keepLogin} onChange={validation.handleChange}onBlur={validation.handleBlur}/> */}
                      <label
                        className="form-check-label mb-0 fw-medium"
                        htmlFor="keepLogin"
                      >
                        Keep me logged in
                      </label>
                    </div>
                  </div>

                  <div className="">
                    <button
                      className="btn btn-primary w-100 waves-effect waves-light btn-md py-3"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Login
                    </button>
                  </div>
                </Form>
              </div>

              <div className="lg-ess text-center d-flex gap-3 align-items-center justify-content-center font-size-15">
                {IsContent.length > 0 ? (
                  <>
                    {IsContent.map((item, index) =>
                      item.content_title === "Terms & Conditions" ||
                        item.content_title === "Privacy Policy" ? (
                        <>
                          {index > 0 && (
                            <span className="text-primary mx-1">|</span>
                          )}
                          <h6
                            className="text-primary fw-medium mb-0"
                            key={index}
                            onClick={async e => {
                              e.preventDefault()
                              setContentFilter(item)
                              tog_center() // Fix: Call the function
                            }}
                          >
                            {item.content_title}
                          </h6>
                          {/* {index < IsContent.length - 1 && (
                        <span className="text-primary mx-1">|</span>
                      )} */}
                        </>
                      ) : null
                    )}
                  </>
                ) : null}
                {/* // <h6 className="text-primary mb-0"> Privacy Policy </h6> */}
              </div>
              <Modal isOpen={modal_center} toggle={tog_center} centered className="cs-lp-width ">
                <ModalHeader className="mt-0 p-1 pb-3 pt-0" toggle={tog_center}>
                  <div className="d-flex align-items-center">
                    <div className="ic-wrapper">
                      <i className="mdi mdi-file"></i>
                    </div>
                    <div className="px-20">
                      <h4 className="mb-0 font-size-18 fw-700 text-dark">
                        {IsContentFilter && IsContentFilter.content_title != ""
                          ? IsContentFilter.content_title
                          : ""}
                      </h4>
                      <p className="mb-0 font-size-15 fw-400 mb-02">Last modified January 2024</p>
                      {/* <small>Last Modified December 2024</small> */}
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody className="p-0">
                  {IsContentFilter.content_details !== "" ? (
                    <div>
                      <h6 className="text-center font-size-24 fw-500 my-2 text-dark">
                        {IsContentFilter.content_subject}
                      </h6>
                      <p className="font-size-16 text-dark my-terms"
                        dangerouslySetInnerHTML={{
                          __html: IsContentFilter.content_details,
                        }}
                      />
                    </div>
                  ) : (
                    <div>No content available</div>
                  )}
                </ModalBody>
              </Modal>
            </div>
          </Container>
        </div>
        <div
        className="account-pages mt-5 pt-2"
        style={{ display: isContentDiv === 2 ? "block" : "none" }}
      >
        <Container>
          <div className="lg-wrapper">
            <div className="text-center mb-2">
              <Modal isOpen={isContentDiv === 2 ? true : false} centered>
                <ModalHeader className="mt-0">
                  <div className="d-flex align-items-center gap-4">
                  <div className="ic-wrapper">
                      <i className="mdi mdi-file"></i>
                    </div>
                    <div>
                      <h4 className="mb-0 font-size-18">
                        {IsTearmsCondition &&
                        IsTearmsCondition.content_title != ""
                          ? IsTearmsCondition.content_title
                          : ""}
                      </h4>
                      <p className="mb-0 font-size-15">
                        Last Modified{" "}
                        {IsTearmsCondition && IsTearmsCondition.updated_on != ""
                          ? moment(
                              IsTearmsCondition.updated_on,
                              "YYYY-MM-DDTHH:mm:ssZ"
                            ).format("MMM YYYY")
                          : ""}
                      </p>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody>
                  {IsTearmsCondition.content_details !== "" ? (
                    <div>
                      <h6>
                        <strong>{IsTearmsCondition.content_subject}</strong>
                      </h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: IsTearmsCondition.content_details,
                        }}
                      />
                    </div>
                  ) : (
                    <div>No content available</div>
                  )}
                  <div className="text-end mt-2">
                    <button
                      className="btn btn-danger me-2"
                      type="button"
                      onClick={() => submitConditionDenide()}
                    >
                      DECLINE
                    </button>
                    <button
                      className="btn btn-success "
                      type="button"
                      onClick={() => submitCondition()}
                    >
                      ACCEPT
                    </button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="account-pages position-y"
        style={{ display: isContentDiv === 3 ? "block" : "none" }}
      >
        <Container>
          <div className="w-70 m-auto">
          <div className="lg-userDetails">
            <div className="mb-2">
              
                <div className="mt-4">
                  <div className="text-center ">
                    <h4>Before we proceed, please check your details</h4>
                   </div>
                    <p className="mt-3">Please notify your admin immediately if the information is not accurate.</p>
                    <div>
                      <div class="profile_detail_title pd-12">
                      <h5 className="font-size-14 fw-700 text-dark mb-10">
                        Personal Information
                      </h5>

                      <div className="row mt-2">
                        {/* <div className="col-5  me-5 mb-3">
                          <p className="Profile_subtitle">Salutation</p>
                          <p className="profile_info">{userDetails && userDetails.tut_salutation && userDetails.tut_salutation != ''? userDetails.tut_salutation : '-'}</p>
                        </div> */}

                        <div className="col-4  me-5 mb-3">
                          <p className="Profile_subtitle">First Name</p>
                          <p className="profile_info">
                          {userDetails && userDetails.tut_fname && userDetails.tut_fname != ''? userDetails.tut_fname : '-'}
                          </p>
                        </div>
                        <div className="col-5  me-5">
                          <p className="Profile_subtitle">Last Name</p>
                          <p className="profile_info">
                          {userDetails && userDetails.tut_surname && userDetails.tut_surname != ''? userDetails.tut_surname : '-'}
                          </p>
                        </div>

                        <div className="col-4  me-5">
                          <p className="Profile_subtitle">Birth date</p>
                          <p className="profile_info">{userDetails && userDetails.tut_dob && userDetails.tut_dob != ''? moment(
                              userDetails.tut_dob,
                              "YYYY-MM-DDTHH:mm:ssZ"
                            ).format("DD MMM YYYY") : '-'}</p>
                        </div>
                      </div>
                    </div>

                    <div className="pd-12">
                      <div class="profile_detail_title">
                        Address Information
                      </div>
                      <div className="col-12 mt-2">
                        <p className="Profile_subtitle">Address</p>
                        <p className="profile_info text-dark mb-3">
                        {userDetails && userDetails.tut_address && userDetails.tut_address != ''? userDetails.tut_address : '-'}
                        </p>
                      </div>

                      <div className="row mt-2">
                        <div className="col-5  me-5 mb-3">
                          <p className="Profile_subtitle">Country</p>
                          <p className="profile_info">
                            {userDetails && userDetails.country_name && userDetails.country_name != ''? userDetails.country_name : '-'}
                          </p>
                        </div>

                        <div className="col-4  me-5 mb-3">
                          <p className="Profile_subtitle">State</p>
                          <p className="profile_info">
                            {userDetails && userDetails.tut_state && userDetails.tut_state != ''? userDetails.tut_state : '-'}
                           
                          </p>
                        </div>
                        <div className="col-5  me-5">
                          <p className="Profile_subtitle">City</p>
                          <p className="profile_info">{userDetails && userDetails.tut_city && userDetails.tut_city != ''? userDetails.tut_city : '-'}</p>
                        </div>

                        <div className="col-4  me-5">
                          <p className="Profile_subtitle">Postcode</p>
                          <p className="profile_info">{userDetails && userDetails.tut_zip && userDetails.tut_zip != ''? userDetails.tut_zip : '-'}</p>
                        </div>
                      </div>
                    </div>

                    <div className="pd-12">
                      <div class="profile_detail_title">
                        Contact Information
                      </div>

                      <div className="row">
                        <div className="col-5 me-5 mb-3 mt-2">
                          <p className="Profile_subtitle">Home Phone</p>
                          <p className="profile_info">
                            {userDetails && userDetails.tut_home_phone && userDetails.tut_home_phone != ''? userDetails.tut_home_phone : '-'}
                          </p>
                        </div>

                        <div className="col-4  me-5 mb-3">
                          <p className="Profile_subtitle">Work Phone</p>
                          <p className="profile_info">
                          {userDetails && userDetails.tut_work_phone && userDetails.tut_work_phone != ''? userDetails.tut_work_phone : '-'}
                          
                          </p>
                        </div>
                        {/* <div className="col-5  me-4">
                          <p className="Profile_subtitle">Mobile</p>
                          <p className="profile_info">
                          {userDetails && userDetails.tut_mobile && userDetails.tut_mobile != ''? userDetails.tut_mobile : '-'}
                          
                          </p>
                        </div> */}

                        <div className="col-4  me-5">
                          <p className="Profile_subtitle">Email</p>
                          <p className="profile_info">
                            {userDetails && userDetails.tut_emailid && userDetails.tut_emailid != ''? userDetails.tut_emailid : '-'}
                            
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pd-12">
                      <div class="profile_detail_title">About Tutor</div>
                      <div className="col-12  me-5 mt-2">
                        <p className="Profile_subtitle">
                          {userDetails && userDetails.tut_notes && userDetails.tut_notes != ''? userDetails.tut_notes : '-'}
                        </p>
                      </div>
                    </div>
                    </div>
                  
                </div>
                <div className="text-center mt-2">
                  <button
                    className="btn btn-danger me-2"
                    type="button"
                    onClick={() => UserContentSubmit()}
                  >
                    Next
                  </button>
                </div>
              
            </div>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="account-pages position-y"
        style={{ display: isContentDiv === 4 ? "block" : "none" }}
      >
        <Container>
          <div className="w-70 m-auto">
          <div className="lg-userDetails">
            <div className="mb-2">
              
                <div className="mt-4">
                  <div className="text-center ">
                    <h4>Let us know your preferred name (optional)</h4>
                   </div>
                    <p className="mt-3">Your preferred name will be displayed on the student and parent platforms.</p>
                    <div>
                    <Input
                      name="tut_pref_Name"
                      className="form-control mt-4"
                      placeholder="Enter preferred name"
                      type="text"
                      id="tut_pref_Name"
                      onChange={(e)=> set_tut_pref_Name(e.target.value)}
                      value={tut_pref_Name || ""}
                      
                    />
                    </div>
                  
                </div>
                <div className="text-center mt-4 mb-5">
                <button
                      className="btn btn-danger me-2"
                      type="button"
                      onClick={() => UserContentBack()}
                    >
                      BACK
                    </button>
                    <button
                      className="btn btn-danger "
                      type="button"
                      onClick={() => UserContentSubmit()}
                    >
                      NEXT
                    </button>
                </div>
              
            </div>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="account-pages position-y"
        style={{ display: isContentDiv === 5 ? "block" : "none" }}
      >
       <Container>
          <div className="w-70 m-auto">
          <div className="lg-userDetails">
            <div className="mb-2">
              
                <div className="mt-4">
                  <div className="text-center ">
                    <h4>Upload a photo of yourself (Optional)</h4>
                   </div>
                    <p className="mt-3">Your photo will be displayed on the student and parent platforms.</p>
                    {/* <div>
                    <Input
                      name="tut_profile"
                      className="form-control mt-4"
                      placeholder=""
                      type="file"
                      id="tut_profile"
                      onChange={(e)=> set_tut_profile(e.target.files)}
                      //value={tut_profile || ""}
                      
                    />
                    </div> */}
                    <div>
                    <FileDropZone onFileUpload={handleFileUpload} />
                    </div>
                  
                </div>
                <div className="text-center mt-4 mb-5">
                <button
                      className="btn btn-danger me-2"
                      type="button"
                      onClick={() => UserContentBack()}
                    >
                      BACK
                    </button>
                    <button
                      className="btn btn-danger "
                      type="button"
                      onClick={() => UserContentSubmit()}
                    >
                      NEXT
                    </button>
                </div>
              
            </div>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="account-pages account-pages position-y"
        style={{ display: isContentDiv === 6 ? "block" : "none" }}
      >
        <Container>
          <div className="lp-wrapper">
            <div className="logo-wrapper text-center" style={{ marginTop: "95px", marginBottom: "40px" }}>
                <img src={success_setup} width={230} alt="logo" />
            </div>
            <div>
            {/* <h5 className="text-center">You are all set up!</h5> */}
            </div>
                <div className="text-center mt-4 mb-5">
                <button
                      className="btn btn-danger me-2"
                      type="button"
                      onClick={() => UserContentBack()}
                    >
                      BACK
                    </button>
                    <button
                      className="btn btn-danger "
                      type="button"
                      onClick={() => UserContentDashboard()}
                    >
                      GET STARTED
                    </button>
                </div>
              
           
            
          </div>
        </Container>
       
      </div></>)
      }
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
