import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback, useRef } from "react"
import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Input,
} from "reactstrap"
import ReactPlayer from "react-player"
import "react-multi-carousel/lib/styles.css"

import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"

import awicon from "../../assets/images/services-icon/coin.png"
import duration from "../../assets/images/services-icon/duration.png"

// import circle from "../../assets/images/services-icon/circle.png"
// import rectangle from "../../assets/images/services-icon/rectangle.png"
// import square from "../../assets/images/services-icon/square.png"

import Select from "react-select"
// import images

import fr1 from "../../assets/images/services-icon/fr1.png"
import dsmile from "../../assets/images/services-icon/dog_smile.png"
import BackwardIcon from "../../assets/images/services-icon/backward.png"
import PauseIcon from "../../assets/images/services-icon/pause.png"

//i18n
import { withTranslation } from "react-i18next"
import { sample } from "lodash"
// import Duration from './Duration';
import Iframe from "react-iframe"
import axios from "axios"
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import moment from "moment"

import Loader from "react-spinner-loader"

import { fabric } from "fabric"
import { pdfjs } from "react-pdf"
//import { PDFDocument } from 'pdf-lib';
import jsPDF from "jspdf"
import { SECURITY_KEY, BASE_URL } from "../../global";
const ManualLCMSWorksheet = props => {
  document.title = "MyGooRoo Tutor App"
  const { key } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // let pk_activity_key1 = null;
  // let IsType = null;
  // //let IsHomework = null;
  // let IsQuestionNo = 1;
  // let IsStKey = null;
  const [pk_activity_key, set_pk_activity_key] = useState(null);
  const [IsType, setIsType] = useState(null);
  const [IsQuestionNo, setIsQuestionNo] = useState(1);
  const [IsStKey, setIsStKey] = useState(null);
  useEffect(() => {
      
  const { type, st_key } = location.state || {};
 
      if (key && key!='' && type && type!=null && st_key && st_key!='') {
        set_pk_activity_key(key);
        setIsType(type);
        setIsQuestionNo(1);
        setIsStKey(st_key);
        // pk_activity_key1 = activity_key;
        // IsType = type;
        // //IsHomework = homework;
        // IsQuestionNo = key;
        // IsStKey = st_key;
      }else{
          toast.error("Sorry, Key missing!", {
            autoClose: 3000, // 3 seconds in this example
            onClose: () => {window.history.back()},
          });
      }
  }, [location]);


  const [loader, setLoader] = useState(false)
  const [ActivityData, setActivityData] = useState([])

  //Timmere start

  // const [seconds, setSeconds] = useState(0)
  // const [isRunning, setIsRunning] = useState(false)

  // useEffect(() => {
  //   let timer
  //   if (isRunning) {
  //     timer = setInterval(() => {
  //       setSeconds(prevSeconds => prevSeconds + 1)
  //     }, 1000)
  //   }

  //   return () => clearInterval(timer)
  // }, [isRunning])

  // const formatTime = time => {
  //   const minutes = Math.floor(time / 60)
  //   const remainingSeconds = time % 60
  //   return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
  //     .toString()
  //     .padStart(2, "0")}`
  // }

  // const handleToggleTimer = () => {
  //   setIsRunning(prevState => !prevState)
  // }

  // const handleResetTimer = () => {
  //   setSeconds(0)
  //   setIsRunning(true)
  // }

  //Timer end
  //worksheet

  const [imageUrls, setImageUrls] = useState([])
  const [canvasList, setCanvasList] = useState([])
  const [pdfInstance, setPdfInstance] = useState(null)

  // const [historyIndex, setHistoryIndex] = useState(-1);
  // const [canvasHistory, setCanvasHistory] = useState([]);
  const [currentColor, setCurrentColor] = useState("black")

  const colorRef = useRef()
  colorRef.current = currentColor

  const [drawMode, setDrawMode] = useState(false) // State for text mode
  const drawModeRef = useRef()
  drawModeRef.current = drawMode

  const [textMode, setTextMode] = useState(false) // State for text mode
  const textModeRef = useRef()
  textModeRef.current = textMode

  const [selectedShape, setSelectedShape] = useState("circle") // State for selected shape
  const [shapMode, setShapMode] = useState(false)
  const shapModeRef = useRef()
  shapModeRef.current = shapMode

  const shapDataRef = useRef()
  shapDataRef.current = selectedShape

  const [zoomLevel, setZoomLevel] = useState(1)

  const authUser = JSON.parse(localStorage.getItem("authUser")) || null
  const navigate = useNavigate()
  useEffect(() => {
    fetchData()
  }, [pk_activity_key])

  
  const fetchData = async () => {
     if (authUser && authUser != "" && authUser != null) {
    if (pk_activity_key && pk_activity_key != null && pk_activity_key != "") {
      setLoader(true)
      setActivityData([])
       axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(
          BASE_URL+"session/StudentQuestions",
          {
            pk_activity_key: pk_activity_key,
            subject_id: IsType,
            pk_student_key: IsStKey,
          }
        )
        .then(responseActivity => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties
          setLoader(false)

          if (responseActivity.data.LessonActivitiesQuestions.length > 0) {
            setActivityData(responseActivity.data)

            responseActivity.data.LessonActivitiesQuestions.forEach(
              (activityQuestion, index2) => {
                //if (activityQuestion.question && activityQuestion.question.length > 0) {
                let answerLength = activityQuestion.StudentAnsAttempts.length

                if (parseInt(IsHomework) === 1) {
                  if (answerLength <= 0) {
                  }
                } else {
                  if (answerLength <= 0) {
                    //console.log('attented');
                  }
                }

                // }
              }
            )
          } else {
            toast.error("Question data not found", {
              autoClose: 3000, // 3 seconds
              onClose: () => {
                history.back() // Navigate back in history after toast is closed
              },
            })
            setActivityData([])
          }
        })
        .catch(error => {
          setLoader(false)
          console.log(error)
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setLoader(false)
      
    }
    } else {
       setLoader(false);
      navigate("/login")
    }
  }

  useEffect(() => {
    // not conver to url
    // https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/english_activity_pdf/Year_5_Vocabulary_S__Year_5_Vocabulary_S_1710151837_Q.pdf

    //DIRECT URL
    //https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/tutor/library/1707203418570_PdfForm.pdf

    //student direct URL
    //https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/student/submissions/worksheet_submission_1711431708116.pdf
    if (ActivityData && ActivityData != "") {
      let pdfUrl = ""
      if (
        ActivityData &&
        ActivityData.LessonActivitiesQuestions &&
        ActivityData.LessonActivitiesQuestions.length > 0 &&
        ActivityData.LessonActivitiesQuestions[0] &&
        ActivityData.LessonActivitiesQuestions[0].StudentAnsAttempts.length >
          0 &&
        ActivityData.LessonActivitiesQuestions[0].StudentAnsAttempts[0]
          .student_answer
      ) {
        pdfUrl =
          ActivityData.LessonActivitiesQuestions[0].StudentAnsAttempts[0]
            .student_answer
      } else if (
        ActivityData &&
        ActivityData.fk_sub_id &&
        ActivityData.activity_type &&
        parseInt(ActivityData.activity_type) === 7 &&
        ActivityData.activity_node &&
        ActivityData.activity_node.length > 0
      ) {
        if (parseInt(ActivityData.fk_sub_id) === 1) {
          pdfUrl =
            "https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/english_activity_pdf/" +
            ActivityData.activity_node[0].file_name
        } else if (parseInt(ActivityData.fk_sub_id) === 2) {
          pdfUrl =
            "https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/math_activity_pdf/" +
            ActivityData.activity_node[0].file_name
        }
      } else if (
        ActivityData &&
        ActivityData.fk_sub_id &&
        ActivityData.activity_type &&
        parseInt(ActivityData.activity_type) === 5 &&
        ActivityData.activity_node &&
        ActivityData.activity_node.length > 0
      ) {
        if (parseInt(ActivityData.fk_sub_id) === 1) {
          pdfUrl = ActivityData.activity_node[0].link
            ? ActivityData.activity_node[0].link
            : ""
        } else if (parseInt(ActivityData.fk_sub_id) === 2) {
          pdfUrl = ActivityData.activity_node[0].link
            ? ActivityData.activity_node[0].link
            : ""
        }
      }

      // const pdfUrl1 = 'https://s3.ap-southeast-1.amazonaws.com/assets-dev.mygooroo.io/activity_pdf/math_activity_pdf/Angles_Triangles_an_Angles_Triangles_an_1702259819_Q.pdf'; // Update with your PDF URL
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

      const fetchPdfAndConvert = async () => {
        try {
          const response = await fetch(pdfUrl)
          const blob = await response.blob()
          const reader = new FileReader()

          reader.onload = async e => {
            const data = atob(e.target.result.replace(/.*base64,/, ""))
            renderPages(data)
          }

          reader.readAsDataURL(blob)
        } catch (error) {
          console.error("Error fetching or converting PDF:", error)
        }
      }
      const renderPages = async data => {
        const imagesList = []
        const canvases = []
        const pdf = await pdfjs.getDocument({ data }).promise

        const canvasContainer = document.getElementById("Canvas_list")

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i)
          const viewport = page.getViewport({ scale: 1.5 })

          // Create a div for the canvas and page number display
          const pageDiv = document.createElement("div")
          pageDiv.classList.add("canvas-page-container")

          const pageNumberDiv = document.createElement("div")
          pageNumberDiv.classList.add("d-flex", "justify-content-center")

          const pageNumberParagraph = document.createElement("p")
          pageNumberParagraph.textContent = `${i}/${pdf.numPages}`

          pageNumberDiv.appendChild(pageNumberParagraph)
          pageDiv.appendChild(pageNumberDiv)

          const canvasContainerDiv = document.createElement("div")
          canvasContainerDiv.classList.add("d-flex", "justify-content-center")

          const canvasElem = document.createElement("canvas")
          canvasElem.id = `fabric-canvas-${i}` // Unique ID for each canvas
          canvasElem.height = viewport.height
          canvasElem.width = viewport.width

          canvasContainerDiv.appendChild(canvasElem)
          pageDiv.appendChild(canvasContainerDiv)
          canvasContainer.appendChild(pageDiv)

          const fabricCanvas = new fabric.Canvas(canvasElem)
          // Add click event listener to canvas
          fabricCanvas.on("mouse:down", function (event) {
            handleCanvasClick(event, fabricCanvas)
            handleShapeCreation(event, fabricCanvas)
          })

          canvases.push(fabricCanvas)

          const renderContext = {
            canvasContext: fabricCanvas.getContext("2d"),
            viewport: viewport,
          }

          await page.render(renderContext).promise
          const img = canvasElem.toDataURL("image/png")
          imagesList.push(img)
        }

        setImageUrls(imagesList)
        setCanvasList(canvases)
        setPdfInstance(pdf)
      }
      fetchPdfAndConvert()
    }
  }, [ActivityData])
  //console.log(ActivityData)

  useEffect(() => {
    //enable draw option
    canvasList.forEach(canvas => {
      canvas.isDrawingMode = drawMode // Enable drawing mode
    })
  }, [drawMode])

  const handleCanvasClick = (event, canvas) => {
    if (textModeRef.current) {
      const { x, y } = event.pointer
      const textbox = new fabric.Textbox("Enter text", {
        left: x,
        top: y,
        width: 150,
        fill: colorRef.current, // Set text color
      })
      canvas.add(textbox)
      canvas.setActiveObject(textbox)
      canvas.renderAll()
    }
  }

  const handleShapeCreation = (event, canvas) => {
    if (shapModeRef.current) {
      // Handle shape creation
      const { x, y } = event.pointer

      switch (shapDataRef.current) {
        case "circle":
          const circle = new fabric.Circle({
            radius: 50,
            left: x,
            top: y,
            fill: "transparent", // Set fill color to transparent
            stroke: colorRef.current,
          })
          canvas.add(circle)
          break
        case "rectangle":
          const rectangle = new fabric.Rect({
            width: 100,
            height: 50,
            left: x,
            top: y,
            fill: "transparent", // Set fill color to transparent
            stroke: colorRef.current,
          })
          canvas.add(rectangle)
          break
        case "square":
          const square = new fabric.Rect({
            width: 50,
            height: 50,
            left: x,
            top: y,
            fill: "transparent", // Set fill color to transparent
            stroke: colorRef.current,
          })
          canvas.add(square)
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    imageUrls.forEach((imageUrl, index) => {
      const canvas = canvasList[index]

      fabric.Image.fromURL(imageUrl, img => {
        img.set({
          left: 0,
          top: 0,
          selectable: false, // Disable object selection/movement
          evented: false,
        })

        img.scaleToWidth(canvas.width)
        canvas.add(img)
        canvas.renderAll()
      })
    })
  }, [imageUrls, canvasList])

  //Toggles
  const toggleTextMode = () => {
    setDrawMode(false)
    setShapMode(false)
    setTextMode(!textMode) // Toggle text mode state
  }

  const toggleDrawMode = () => {
    setTextMode(false)
    setShapMode(false)
    setDrawMode(!drawMode) // Toggle text mode state
    // Update brush color when switching draw mode
    if (!drawMode) {
      canvasList.forEach(canvas => {
        canvas.freeDrawingBrush.color = currentColor
      })
    }
  }

  const toggleShapeMode = value => {
    setTextMode(false)
    setDrawMode(false) // Toggle text mode state
    setShapMode(true) // Toggle text mode state
    setSelectedShape(value)
  }

  const ClickShapeMode = () => {
    setTextMode(false)
    setDrawMode(false) // Toggle text mode state
    setShapMode(!shapMode) // Toggle text mode state
  }

  const zoomIn = () => {
    setZoomLevel(prevZoom => prevZoom + 0.1)
    adjustZoom(1.1)
  }

  const zoomOut = () => {
    setZoomLevel(prevZoom => (prevZoom > 0.1 ? prevZoom - 0.1 : prevZoom))
    adjustZoom(0.9)
  }

  const resetZoom = () => {
    setZoomLevel(1)
    adjustZoom(1 / zoomLevel) // Reset to original zoom level (1)
  }

  const adjustZoom = factor => {
    canvasList.forEach(canvas => {
      const zoom = canvas.getZoom() * factor
      canvas.setZoom(zoom)
      canvas.requestRenderAll()
    })
  }

  //  // Function to save canvas state to history
  //  const saveCanvasToHistory = (canvas) => {
  //   const newHistory = canvasHistory.slice(0, historyIndex + 1);
  //   newHistory.push(canvas.toJSON());
  //   setCanvasHistory(newHistory);
  //   setHistoryIndex(historyIndex + 1);
  // };

  // // Function to handle undo action
  // const undoAction = () => {
  //   if (historyIndex > 0) {
  //     const newIndex = historyIndex - 1;
  //     setHistoryIndex(newIndex);
  //     const canvasData = canvasHistory[newIndex];
  //     canvasList.forEach((canvas) => {
  //       canvas.loadFromJSON(canvasData, () => {
  //         canvas.renderAll();
  //       });
  //     });
  //   }
  // };

  // // Function to handle redo action
  // const redoAction = () => {
  //   if (historyIndex < canvasHistory.length - 1) {
  //     const newIndex = historyIndex + 1;
  //     setHistoryIndex(newIndex);
  //     const canvasData = canvasHistory[newIndex];
  //     canvasList.forEach((canvas) => {
  //       canvas.loadFromJSON(canvasData, () => {
  //         canvas.renderAll();
  //       });
  //     });
  //   }
  // };

  // Function to remove selected object from canvas
  const removeSelectedObject = () => {
    canvasList.forEach(canvas => {
      const activeObject = canvas.getActiveObject()
      if (activeObject) {
        canvas.remove(activeObject)
        //saveCanvasToHistory(canvas);
      }
    })
  }

  const changeColor = color => {
    setCurrentColor(color)
    if (drawMode) {
      // Set stroke color for drawing objects
      canvasList.forEach(canvas => {
        canvas.freeDrawingBrush.color = color
      })
    } else {
      // Set fill color for shape creation or text color
      canvasList.forEach(canvas => {
        const activeObject = canvas.getActiveObject()
        if (activeObject) {
          if (activeObject.type === "textbox") {
            activeObject.set("fill", color)
          } else {
            activeObject.set("stroke", color)
          }
          canvas.renderAll()
          //saveCanvasToHistory(canvas);
        }
      })
    }
  }

  const downloadPdf = () => {
    resetZoom()
    canvasList.forEach(canvas => {
      canvas.setZoom(canvas.getZoom() / 1.1)
      canvas.requestRenderAll()
    })
    const doc = new jsPDF()

    canvasList.forEach(canvas => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0)
      doc.addImage(
        imgData,
        "JPEG",
        0,
        0,
        doc.internal.pageSize.getWidth(),
        doc.internal.pageSize.getHeight()
      )
      if (canvas !== canvasList[canvasList.length - 1]) {
        doc.addPage()
      }
    })

    doc.save("worksheet_submission_" + generateRandomFileName() + ".pdf")
  }
  // Function to generate a random 13-digit number
  const generateRandomFileName = () => {
    const min = 1000000000000 // Minimum 13-digit number
    const max = 9999999999999 // Maximum 13-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  // const [isLinkSubmit, setLinkSubmit] = useState(false)

  // const convertAndSendPdf = async () => {
  //   if (authUser && authUser != "" && authUser != null) {
  //     try {
  //       resetZoom() // Reset zoom before converting to PDF
  //       setLinkSubmit(true)
  //       const doc = new jsPDF()

  //       canvasList.forEach((canvas, index) => {
  //         const imgData = canvas.toDataURL("image/jpeg", 1.0)
  //         doc.addImage(
  //           imgData,
  //           "JPEG",
  //           0,
  //           0,
  //           doc.internal.pageSize.getWidth(),
  //           doc.internal.pageSize.getHeight()
  //         )
  //         if (index !== canvasList.length - 1) {
  //           doc.addPage()
  //         }
  //       })

  //       // Save the PDF as a Blob
  //       const pdfBlob = doc.output("blob")

  //       // Create FormData object to send the Blob
  //       const formData = new FormData()
  //       formData.append(
  //         "pdfFile",
  //         pdfBlob,
  //         "worksheet_submission_" + generateRandomFileName() + ".pdf"
  //       )
  //       formData.append(
  //         "pk_lesson_activity_key",
  //         ActivityData.pk_lesson_activity_key
  //       ) // Append additional form data
  //       formData.append("fk_lesson_key", ActivityData.fk_lesson_key) // Append additional form data
  //       formData.append("activity_type", ActivityData.activity_type) // Append additional form data
  //       formData.append("fk_sub_id", ActivityData.fk_sub_id) // Append additional form data
  //       formData.append(
  //         "question_id",
  //         ActivityData.LessonActivitiesQuestions[0].fk_question_id
  //       ) // Append additional form data
  //       formData.append("submitted_on", seconds) // Append additional form data
  //       formData.append("fk_ses_key", ActivityData.fk_sest_key) // Append additional form data
  //       //console.log('worksheet_submission_'+generateRandomFileName()+'.pdf');
  //       // Send the FormData to the backend using Axios
  //       axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
  //       const response = await axios.post(
  //         BASE_URL+"studentAnswer/StudentAnswerWorkSheet",
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       )
  //       if (parseInt(response.data) === 1) {
  //         setLinkSubmit(true)
  //         window.location.reload() // Reloads the current page
  //         //console.log("PDF sent to backend:", response.data)
  //       } else {
  //         setLinkSubmit(true)
  //         toast.error("Connection to server lost! Kindly inform admin", {
  //           autoClose: 3000, // 3 seconds in this example
  //         })
  //       }

  //       // Add any additional handling after sending the PDF to the backend
  //     } catch (error) {
  //       setLinkSubmit(true)
  //       console.error("Error converting or sending PDF:", error)
  //       // Add error handling as needed
  //     }
  //   } else {
  //     setLinkSubmit(true)
  //     navigate("/login")
  //   }
  // }

  //link

  const [ResonTextData, setResonText] = useState("")
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_standard1, setmodal_standard1] = useState(false)

  const [isFlagReport, setFlagReport] = useState(false)

  const [selectedGroup, setselectedGroup] = useState(null)

  const [selectQuestion, setselectQuestion] = useState(null)

  const tog_standard1 = () => {
    setmodal_standard1(!modal_standard1)
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    // {
    // label: "Select Subject",
    // options: [
    { label: "Spelling error / Typo", value: "Spelling error / Typo" },
    { label: "The solution is wrong", value: "The solution is wrong" },
    {
      label: "The question is not relevant to the topic",
      value: "The question is not relevant to the topic",
    },
    {
      label: "The question cannot be solved",
      value: "The question cannot be solved",
    },
    {
      label: "The question is incomplete",
      value: "The question is incomplete",
    },
    { label: "Others", value: "Others" },
    // ],
    // },
  ]

  function textareachange(event) {
    const text = event.target.value
    // if (count > 0) {
    setResonText(text)
    // }
    //settextcount(event.target.value.length)
  }
  const tog_standard = data => {
    //console.log(data);
    setmodal_standard(!modal_standard)
    setselectedGroup(null)
    setResonText("")
    setselectQuestion(data ? data : null)
  }

  function submitFlagReport() {
    // if (authUser && authUser != "" && authUser != null) {
    setFlagReport(true)
    //axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
    axios
      .post(BASE_URL+"studentSession/flagReport", {
        fk_lesson_activity_key: selectQuestion.fk_lesson_activity_key,
        fk_lesson_activity_question_key:
          selectQuestion.pk_lesson_activity_question_key,
        fk_sub_id: selectQuestion.fk_sub_id,
        question_id: selectQuestion.fk_question_id,
        reson_text: ResonTextData,
        reson: selectedGroup,
      })
      .then(responseFlag => {
        //console.log(responseSessionEnd.data);
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (parseInt(responseFlag.data) === 1) {
          tog_standard()
          setFlagReport(false)
          toast.success("Report submitted successfully.", {
            autoClose: 3000, // 3 seconds in this example
          })
        } else {
          tog_standard()
          setFlagReport(false)
          toast.error("Failure! Unable to insert data. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          })
        }
      })
      .catch(error => {
        //console.log(error)
        tog_standard()
        setFlagReport(false)
        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
      })
    // } else {
    //   setFlagReport(false)
    //   navigate("/login")
    // }
  }

  // function submitLinkAnswer(){
  //   if (authUser && authUser != "" && authUser != null) {

  //     setLinkSubmit(true);
  //       axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
  //       axios.post(BASE_URL+'studentAnswer/submitQuestionLink', {
  //         activity_type: ActivityData.activity_type,
  //         fk_lesson_key: ActivityData.fk_lesson_key,
  //         pk_lesson_activity_key : ActivityData.pk_lesson_activity_key,
  //         fk_sub_id: ActivityData.fk_sub_id,
  //         question_id: ActivityData.LessonActivitiesQuestions[0].fk_question_id,
  //         submitted_on: seconds,
  //         fk_ses_key: ActivityData.fk_sest_key
  //       }).then(responseVideo => {
  //         //console.log(responseSessionEnd.data);
  //         // Assuming the API response is an array of objects with 'id' and 'value' properties
  //         if (parseInt(responseVideo.data) === 1) {
  //           setLinkSubmit(false);
  //           fetchData();
  //         } else {
  //           setLinkSubmit(false);
  //           fetchData();
  //         }

  //       })
  //         .catch(error => {
  //           //console.log(error)

  //           setLinkSubmit(false);
  //           if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
  //             localStorage.clear();
  //             navigate("/login");
  //           }
  //         });

  //   }
  //   else {
  //     setFlagReport(false);
  //     navigate("/login");
  //   }

  // }

  // console.log(ActivityData);
  // console.log(selectQuestion);


  
const LessonActivitiesQuestions = ActivityData.LessonActivitiesQuestions || [];

const validationSchema = Yup.object().shape({
  selectedOptions: Yup.object().shape(
    LessonActivitiesQuestions.reduce((shape, activity) => {
      if (
        activity.question &&
        activity.question.length > 0 &&
        parseInt(activity.fk_sub_id) === 1
      ) {
        activity.question.forEach((question) => {
         
            question.question_parts.forEach((quspart) => {
              //if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
                shape[quspart.english_question_part_id] = Yup.string().oneOf(['1', '2', '3'], 'Please select one option').required('Please select one option');
              //}
            });
          //}
        });
      } else if (
        activity.question &&
        activity.question.length > 0 &&
        parseInt(activity.fk_sub_id) === 2
      ) {
        activity.question.forEach((question) => {
          
            question.question_parts.forEach((quspart) => {
              // if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
                shape[quspart.part_id] = Yup.string().oneOf(['1', '2', '3'], 'Please select one option').required('Please select one option');
              //}
            });
          
        });
      }
      return shape;
    }, {})
  ),
  // feedback: Yup.object().shape(
  //   LessonActivitiesQuestions.reduce((shape, activity) => {
  //     if (
  //       activity.question &&
  //       activity.question.length > 0 &&
  //       parseInt(activity.fk_sub_id) === 1
  //     ) {
  //       activity.english_question.forEach((question) => {
  //         // if (
  //         //   parseInt(question.english_question_category_id) === 1 ||
  //         //   parseInt(question.english_question_category_id) === 8 ||
  //         //   parseInt(question.english_question_category_id) === 2 ||
  //         //   parseInt(question.english_question_category_id) === 12 ||
  //         //   parseInt(question.english_question_category_id) === 13 ||
  //         //   parseInt(question.english_question_category_id) === 10
  //         // ) {
  //           question.question_parts.forEach((quspart) => {
  //             //if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
  //               shape[quspart.english_question_part_id] = Yup.string().required('Feedback is required');
  //             //}
  //           });
  //        // }
  //       });
  //     }else if (
  //       activity.question &&
  //       activity.question.length > 0 &&
  //       parseInt(activity.fk_sub_id) === 2
  //     ) {
  //       activity.question.forEach((question) => {
         
  //           question.question_parts.forEach((quspart) => {
  //            // if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
  //               shape[quspart.part_id] = Yup.string().required('Feedback is required');
  //            // }
  //           });
         
  //       });
  //     }
  //     return shape;
  //   }, {})
  // ),
});

const initialValues = ({
  selectedOptions: LessonActivitiesQuestions.reduce((options, activity) => {
    if (
      activity.question &&
      activity.question.length > 0 &&
      parseInt(activity.fk_sub_id) === 1
    ) {
      activity.question.forEach((question) => {
        // if (
        //   parseInt(question.english_question_category_id) === 1 ||
        //   parseInt(question.english_question_category_id) === 8 ||
        //   parseInt(question.english_question_category_id) === 2 ||
        //   parseInt(question.english_question_category_id) === 12 ||
        //   parseInt(question.english_question_category_id) === 13 ||
        //   parseInt(question.english_question_category_id) === 10
        // ) {
          question.question_parts.forEach((quspart) => {
            //if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
              options[quspart.english_question_part_id] = '';
           // }
          });
        //}
      });
    }else if (
      activity.question &&
      activity.question.length > 0 &&
      parseInt(activity.fk_sub_id) === 2
    ) {
      activity.question.forEach((question) => {
        // if (
        //   parseInt(question.english_question_category_id) === 1 ||
        //   parseInt(question.english_question_category_id) === 8 ||
        //   parseInt(question.english_question_category_id) === 2 ||
        //   parseInt(question.english_question_category_id) === 12 ||
        //   parseInt(question.english_question_category_id) === 13 ||
        //   parseInt(question.english_question_category_id) === 10
        // ) {
          question.question_parts.forEach((quspart) => {
            //if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
              options[quspart.part_id] = '';
           // }
          });
        //}
      });
    }
    return options;
  }, {}),
  // feedback: LessonActivitiesQuestions.reduce((feedbacks, activity) => {
  //   if (
  //     activity.question &&
  //     activity.question.length > 0 &&
  //     parseInt(activity.fk_sub_id) === 1
  //   ) {
  //     activity.question.forEach((question) => {
  //       // if (
  //       //   parseInt(question.english_question_category_id) === 1 ||
  //       //   parseInt(question.english_question_category_id) === 8 ||
  //       //   parseInt(question.english_question_category_id) === 2 ||
  //       //   parseInt(question.english_question_category_id) === 12 ||
  //       //   parseInt(question.english_question_category_id) === 13 ||
  //       //   parseInt(question.english_question_category_id) === 10
  //       // ) {
  //         question.question_parts.forEach((quspart) => {
  //          // if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
  //             feedbacks[quspart.english_question_part_id] = '';
  //          // }
  //         });
  //      // }
  //     });
  //   } else if (
  //     activity.question &&
  //     activity.question.length > 0 &&
  //     parseInt(activity.fk_sub_id) === 2
  //   ) {
  //     activity.question.forEach((question) => {
  //       // if (
  //       //   parseInt(question.english_question_category_id) === 1 ||
  //       //   parseInt(question.english_question_category_id) === 8 ||
  //       //   parseInt(question.english_question_category_id) === 2 ||
  //       //   parseInt(question.english_question_category_id) === 12 ||
  //       //   parseInt(question.english_question_category_id) === 13 ||
  //       //   parseInt(question.english_question_category_id) === 10
  //       // ) {
  //         question.question_parts.forEach((quspart) => {
  //          // if (parseInt(quspart.english_question_part_automark) === 0 && quspart.StudentAnsAttempts.length > 0) {
  //             feedbacks[quspart.part_id] = '';
  //          // }
  //         });
  //      // }
  //     });
  //   }
  //   return feedbacks;
  // }, {}),
});

const validation = useFormik({
  enableReinitialize: true,
  initialValues,
  validationSchema,
  onSubmit: (values, { setSubmitting }) => {
    setSubmitting(true);
    //try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`;
      const response = axios.post(
        BASE_URL+"session/StudentQuestionsManualMark",
        {
          ...values,
          pk_activity_key: pk_activity_key,
          fk_sub_id: IsType,
          pk_student_key: IsStKey,
          question_no: ActivityData.LessonActivitiesQuestions[0].fk_question_id,
          fk_ses_key: ActivityData.fk_sest_key,
          fk_lesson_key: ActivityData.fk_lesson_key,
          topic_id : ActivityData.LessonActivitiesQuestions[0].topic_id
        }
      ).then(responseData => {
                //console.log(responseSessionEnd.data);
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                if (parseInt(responseData.data) === 1) {
       
                  // localStorage.setItem("authUser", JSON.stringify(response.data[0]['token']));
                  // props.router.navigate("/dashboard");
                  //console.log('success');
                  toast.success("Marks and Coins assigned", {
                    autoClose: 3000, // 3 seconds in this example
                    onClose: () => {window.history.back()},
                  })
                  
                  setSubmitting(false)
                } else {
                  toast.error("Coins not assigned to student, Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                  })
                  setSubmitting(false)
                }

    }).catch(error => {
      setSubmitting(false)
      if (
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
    })
  },
});


  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />
      {/* <Modal isOpen={modal_standard1} toggle={tog_standard1}>
        <ModalHeader toggle={tog_standard1}></ModalHeader>
        <ModalBody>
          <p className="text-center fw-bold text-dark mb-0 font-size-18">
            Once submitted, you will not be able to change your answers!
          </p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="btn-cst-danger-md"
            onClick={() => {
              tog_standard1()
            }}
          >
            CANCEL
          </Button>

          <Button
            className="btn-cst-success-md"
            disabled={isLinkSubmit}
            onClick={convertAndSendPdf}
          >
            CONFIRM
          </Button>
        </ModalFooter>
      </Modal> */}
      <Modal isOpen={modal_standard} toggle={tog_standard}>
        <ModalHeader toggle={tog_standard}>Flag this Question</ModalHeader>
        <ModalBody>
          <div className="flex-grow-1 mb-3 cs-rsm">
            <Label>Select a Reason</Label>
            <Select
              className="cs-style bg-mutant"
              value={optionGroup.find(
                option =>
                  option.value &&
                  selectedGroup &&
                  option.value.toString() == selectedGroup.toString()
              )}
              placeholder="Select"
              onChange={e => {
                handleSelectGroup(e.value)
              }}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>
          <div className="flex-grow-1 cs-rsm">
            <Label>Reason:</Label>
            <Input
              style={{ height: "200px" }}
              type="textarea"
              id="textarea"
              value={ResonTextData}
              onChange={e => {
                textareachange(e)
              }}
              maxLength="225"
              rows="3"
              placeholder="Write your reason here..."
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="btn btn-danger cs-btn-danger"
            disabled={isFlagReport}
            onClick={() => {
              submitFlagReport()
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-wrapper">
        <div className="cs-pg-header bg-dark-blue py-4">
          <Container className="custom-wt">
            <div className="d-flex align-items-center">
              <div className="mt-0 border-right me-4" style={{ width: "20%" }}>
                <div
                  className="text-white font-size-14 text-uppercase"
                  to={"#"}
                  onClick={() => history.back()}
                >
                  {" "}
                  <span>
                    <i className=" fas fa-chevron-left me-1"></i>
                  </span>{" "}
                  back
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between flex-grow-1">
                <h6 className="mb-0 font-size-20 text-white w-60 fw-regular">
                  {/* {ActivityData &&
                  ActivityData.activity_level_has_topic_node &&
                  ActivityData.activity_level_has_topic_node.length > 0
                    ? ActivityData.activity_level_has_topic_node[0]
                        .level_name != ""
                      ? ActivityData.activity_level_has_topic_node[0]
                          .level_name + "/"
                      : null
                    : null}
                  {ActivityData &&
                  ActivityData.activity_level_has_topic_node &&
                  ActivityData.activity_level_has_topic_node.length > 0
                    ? ActivityData.activity_level_has_topic_node[0]
                        .topic_name != ""
                      ? ActivityData.activity_level_has_topic_node[0]
                          .topic_name + "/"
                      : null
                    : null} */}
                    {ActivityData && ActivityData.level_name && ActivityData.level_name!='' ? ActivityData.level_name+' - ' : null}
                    {ActivityData && ActivityData.topic_name && ActivityData.topic_name!='' ? ActivityData.topic_name+' - ' : null}

                  {ActivityData &&
                  ActivityData.activity_node &&
                  ActivityData.activity_node.length > 0 &&
                  ActivityData.activity_node[0].name
                    ? ActivityData.activity_node[0].name
                    : ActivityData &&
                      ActivityData.activity_node &&
                      ActivityData.activity_node.length > 0 &&
                      ActivityData.activity_node[0].node_name
                    ? ActivityData.activity_node[0].node_name
                    : null}
                </h6>
                <p className="mb-0 text-uppercase luminous-wht">
                  {" "}
                  <span className="text-yellow me-1">
                    {ActivityData && ActivityData.subject_name
                      ? ActivityData.subject_name
                      : null}
                  </span>{" "}
                  Work Sheet{" "}
                </p>
                <div className="luminous-wht font-size-16">
                  <img width={24} className="" src={awicon} alt="" />{" "}
                  {ActivityData && ActivityData.coins_earned
                    ? ActivityData.coins_earned
                    : 0}
                  /
                  {ActivityData &&
                  ActivityData.LessonActivitiesQuestions &&
                  ActivityData.LessonActivitiesQuestions.length > 0
                    ? ActivityData.LessonActivitiesQuestions[0].question
                        .length * 2
                    : 0}{" "}
                  coins earned
                </div>
              </div>
            </div>
          </Container>
        </div>
        {ActivityData &&
        ActivityData.LessonActivitiesQuestions &&
        ActivityData.LessonActivitiesQuestions.length > 0
          ? ActivityData.LessonActivitiesQuestions.map(
              (questionActivity, activeindex) => {
                if (
                  ActivityData.activity_node &&
                  ActivityData.activity_node.length > 0 &&
                  (parseInt(questionActivity.fk_sub_id) === 1 ||
                    parseInt(questionActivity.fk_sub_id) === 2)
                ) {
                  return ActivityData.activity_node.map(
                    (InnerActivityData, index) => {
                      if (index === 0) {
                        return (
                          <form onSubmit={validation.handleSubmit}>
                          <div className="">
                            <div className="tool-bar-worksheet">
                              <div className="worksheet-width-hold d-flex align-items-center justify-content-end">
                                  <div className="cs-tools d-flex align-items-center">
                                  <button onClick={downloadPdf}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-printer-fill fx-printer"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                                          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                        </svg>
                                      </button>
                                  </div>
                              </div>
                            </div>
                            {/* <div className="tool-bar-worksheet">
                              <div className="worksheet-width-hold d-flex align-items-center justify-content-between">
                                {
                                  <>
                                    <div className="cs-tools d-flex align-items-center">
                                      <button
                                        className={drawMode ? "active" : ""}
                                        onClick={toggleDrawMode}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-brush-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04" />
                                        </svg>
                                      </button>

                                      <button
                                        className={textMode ? "active" : ""}
                                        onClick={toggleTextMode}
                                      >
                                       
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-fonts super-size"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479z" />
                                        </svg>
                                      </button>
                                      <div
                                        className={
                                          shapMode
                                            ? "fo-select active px-1"
                                            : "fo-select px-1"
                                        }
                                      >
                                        <button onClick={ClickShapeMode}>
                                          {selectedShape == "circle" ? (
                                            <div className="fs-circle"></div>
                                          ) : selectedShape == "rectangle" ? (
                                            <div className="fs-rectangle"></div>
                                          ) : selectedShape == "square" ? (
                                            <div className="fs-square"></div>
                                          ) : (
                                            "circle"
                                          )}
                                        </button>
                                        <select
                                          value={selectedShape}
                                         
                                          onChange={e =>
                                            toggleShapeMode(e.target.value)
                                          }
                                        >
                                          <option value="circle">Circle</option>
                                          <option value="rectangle">
                                            Rectangle
                                          </option>
                                          <option value="square">Square</option>
                                        </select>
                                      </div>
                                      <button onClick={zoomIn}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-zoom-in"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
                                          />
                                          <path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"
                                          />
                                        </svg>
                                      </button>
                                      <button onClick={zoomOut}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-zoom-out"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
                                          />
                                          <path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"
                                          />
                                        </svg>
                                      </button>
                                     
                                      <button onClick={removeSelectedObject}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-eraser-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
                                        </svg>
                                      </button>

                                      <div className="color-picker ps-1">
                                        <input
                                          type="color"
                                          value={currentColor}
                                          onChange={e =>
                                            changeColor(e.target.value)
                                          }
                                        />
                                      </div>
                                      <button onClick={downloadPdf}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-printer-fill fx-printer"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                                          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                        </svg>
                                      </button>
                                    </div>

                                    <div className="d-flex align-items-center mb-1">
                                     
                                    </div>
                                  </>
                                }
                              </div>
                            </div> */}
                            <div className="worksheet-manu-wrapper worksheet-width-hold d-flex mt-4">
                              <div className="assessement-section  me-3 flex-grow-1">
                                <div className="hw-list pb-100">
                                  <ul className="list-unstyled mb-0">
                                    <li className="justify-content-between border-thick">
                                      <div className="d-flex align-items-start gap-4 justify-content-end">
                                        <div className="mb-0 d-flex align-items-center gap-2">
                                          <p className="mb-0">
                                            #
                                            {InnerActivityData.pk_tutor_library_item_id
                                              ? InnerActivityData.pk_tutor_library_item_id
                                              : InnerActivityData.id
                                              ? InnerActivityData.id
                                              : null}
                                          </p>
                                          <span>
                                            <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                                          </span>
                                        </div>
                                      </div>

                                      {/* <div className="video-wrapper"> */}
                                      <div>
                                        <div>
                                          {/* <button onClick={toggleDrawMode}>{drawMode ? 'Deactivate Draw Mode' : 'Activate Draw Mode'}</button>

          <button onClick={toggleTextMode}>
            {textMode ? 'Deactivate Text Mode' : 'Activate Text Mode'}
          </button>

          <select
            value={selectedShape}
            //onClick={(e) => ClickShapeMode(e.target.value)}
            onChange={(e) => toggleShapeMode(e.target.value)}
            
          >
            <option value="circle">Circle</option>
            <option value="rectangle">Rectangle</option>
            <option value="square">Square</option>
          </select>
          <button onClick={zoomIn}>Zoom In</button>
          <button onClick={zoomOut}>Zoom Out</button>
          {/* <button onClick={undoAction}>Undo</button>
        <button onClick={redoAction}>Redo</button> */}
                                          {/*<button onClick={removeSelectedObject}>Remove Selected Object</button>
       
       <input
           type="color"
           value={currentColor}
           onChange={(e) => changeColor(e.target.value)}
         />
          <button onClick={downloadPdf}>Download PDF</button>
          <button onClick={convertAndSendPdf}>Submit</button> */}
                                        </div>
                                      </div>
                                      <div>
                                        <div id="Canvas_list"></div>
                                      </div>
                                      {/* </div> */}
                                    </li>
                                    {/* <div>
                    <span>Duration: {formatDuration(duration)}</span>
          <br />
          <span>Elapsed: {formatDuration(duration * played)}</span>
          <br />
          <span>Remaining: {remainingTime}</span>
                    </div> */}
                                  </ul>
                                </div>

                                {/* { questionActivity.StudentAnsAttempts.length > 0 ? <div className="navigator-wrapper position-relative mt-5">
                  <div className="nv-dg-smile">
                    <img width={400} src={dsmile} alt="" />
                  </div>
                  <div className="navigator-nxt">
                    {InnerActivityData.duration && InnerActivityData.duration!=''? 
                    <p className="opacity-75">
                      SUGGESTED TIME: {InnerActivityData.duration? InnerActivityData.duration : null}
                    </p>
                  : null}
                    <p className="font-size-16 d-flex align-items-center gap-2">
                      <img width={24} src={awicon} /> {ActivityData && ActivityData.coins_earned ? ActivityData.coins_earned : 0}/{ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 ? ActivityData.LessonActivitiesQuestions.length * 2 : 0} coins earned
                    </p>

                    {parseInt(IsHomework) === 1 ? 
                     
                      <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => history.back()}>
                       BACK TO HOMEWORK
                      </Button>
                      :
                      <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => history.back()}>
                       BACK TO LESSON PLAN
                    </Button>
                      
                    }
                      
                    
                    
                    
                  </div>
                </div>
                : null } */}
                              </div>
                              
                                <div className="manual-marking_section w-25 ms-2">
                                    <h6 className="text-center fw-400 mb-4 text-dark">Manual Marking</h6>
                                    {questionActivity.question &&
                                      questionActivity.question.length > 0 &&
                                      parseInt(questionActivity.fk_sub_id) === 1 && (
                                        questionActivity.question.map((InnerActivityDataE, Qindex) => {
                                          return InnerActivityDataE.question_parts && InnerActivityDataE.question_parts.map(
                                            (quspart, Pindex) => {
                                                return (<div className="mark-set" key={quspart.english_question_part_id}>
                                                <div className="que-indicator">
                                                  <h6 className="fw-500 mb-3 text-dark">Q. {Qindex + 1}.{Pindex + 1}</h6>
                                                </div>
                                                <div className="ans-checklists">
                                                  <ul className="ps-0 list-unstyled">
                                                    <li>
                                                      <div class="form-check">
                                                          <input
                                                            type="checkbox"
                                                            id={`checkbox${quspart.english_question_part_id}-1`}
                                                            name={`selectedOptions[${quspart.english_question_part_id}]`}
                                                            value="1"
                                                            onChange={(event) => {
                                                              const value = event.target.checked ? '1' : '';
                                                              validation.setFieldValue(`selectedOptions[${quspart.english_question_part_id}]`, value);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            checked={validation.values.selectedOptions[quspart.english_question_part_id] === "1"}
                                                          />
                                                          <label htmlFor={`checkbox${quspart.english_question_part_id}-1`}>Correct (2 coin)</label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                    <div class="form-check">
                                                          <input
                                                            type="checkbox"
                                                            id={`checkbox${quspart.english_question_part_id}-2`}
                                                            name={`selectedOptions[${quspart.english_question_part_id}]`}
                                                            value="2"
                                                            onChange={(event) => {
                                                              const value = event.target.checked ? '2' : '';
                                                              validation.setFieldValue(`selectedOptions[${quspart.english_question_part_id}]`, value);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            checked={validation.values.selectedOptions[quspart.english_question_part_id] === "2"}
                                                          />
                                                          <label htmlFor={`checkbox${quspart.english_question_part_id}-2`}>Partially correct (1 coin)</label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                    <div class="form-check">
                                                          <input
                                                            type="checkbox"
                                                            id={`checkbox${quspart.english_question_part_id}-3`}
                                                            name={`selectedOptions[${quspart.english_question_part_id}]`}
                                                            value="3"
                                                            onChange={(event) => {
                                                              const value = event.target.checked ? '3' : '';
                                                              validation.setFieldValue(`selectedOptions[${quspart.english_question_part_id}]`, value);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            checked={validation.values.selectedOptions[quspart.english_question_part_id] === "3"}
                                                          />
                                                          <label htmlFor={`checkbox${quspart.english_question_part_id}-3`}>Wrong (0 coins)</label>
                                                      </div>
                                                      {validation.touched.selectedOptions?.[quspart.english_question_part_id] && validation.errors.selectedOptions?.[quspart.english_question_part_id] && (
                                                        <p id={`error-selectedOptions-${quspart.english_question_part_id}`} style={{ color: 'red' }}>{validation.errors.selectedOptions[quspart.english_question_part_id]}</p>
                                                      )}
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>); // Render whatever JSX you need here
                                            })
                                        })
                                      )}
                                    {questionActivity.question &&
                                      questionActivity.question.length > 0 &&
                                      parseInt(questionActivity.fk_sub_id) === 2 && (
                                        questionActivity.question.map((InnerActivityDataM, Qindex) => {
                                          return InnerActivityDataM.question_parts && InnerActivityDataM.question_parts.map(
                                            (quspart, Pindex) => {
                                                return (<div className="mark-set" key={quspart.part_id}>
                                                <div className="que-indicator">
                                                  <h6 className="fw-500 mb-3 text-dark">Q. {Qindex + 1}.{Pindex + 1}</h6>
                                                </div>
                                                <div className="ans-checklists">
                                                  <ul className="ps-0 list-unstyled">
                                                    <li>
                                                      <div class="form-check">
                                                          <input
                                                            type="checkbox"
                                                            id={`checkbox${quspart.part_id}-1`}
                                                            name={`selectedOptions[${quspart.part_id}]`}
                                                            value="1"
                                                            onChange={(event) => {
                                                              const value = event.target.checked ? '1' : '';
                                                              validation.setFieldValue(`selectedOptions[${quspart.part_id}]`, value);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            checked={validation.values.selectedOptions[quspart.part_id] === "1"}
                                                          />
                                                          <label htmlFor={`checkbox${quspart.part_id}-1`}>Correct (2 coin)</label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                    <div class="form-check">
                                                          <input
                                                            type="checkbox"
                                                            id={`checkbox${quspart.part_id}-2`}
                                                            name={`selectedOptions[${quspart.part_id}]`}
                                                            value="2"
                                                            onChange={(event) => {
                                                              const value = event.target.checked ? '2' : '';
                                                              validation.setFieldValue(`selectedOptions[${quspart.part_id}]`, value);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            checked={validation.values.selectedOptions[quspart.part_id] === "2"}
                                                          />
                                                          <label htmlFor={`checkbox${quspart.part_id}-2`}>Partially correct (1 coin)</label>
                                                      </div>
                                                    </li>
                                                    <li>
                                                    <div class="form-check">
                                                          <input
                                                            type="checkbox"
                                                            id={`checkbox${quspart.part_id}-3`}
                                                            name={`selectedOptions[${quspart.part_id}]`}
                                                            value="3"
                                                            onChange={(event) => {
                                                              const value = event.target.checked ? '3' : '';
                                                              validation.setFieldValue(`selectedOptions[${quspart.part_id}]`, value);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            checked={validation.values.selectedOptions[quspart.part_id] === "3"}
                                                          />
                                                          <label htmlFor={`checkbox${quspart.part_id}-3`}>Wrong (0 coins)</label>
                                                      </div>
                                                      {validation.touched.selectedOptions?.[quspart.part_id] && validation.errors.selectedOptions?.[quspart.part_id] && (
                                                        <p id={`error-selectedOptions-${quspart.part_id}`} style={{ color: 'red' }}>{validation.errors.selectedOptions[quspart.part_id]}</p>
                                                      )}
                                                    </li>
                                                  </ul>
                                                </div>
                                                  
                                              </div>); // Render whatever JSX you need here
                                            })
                                        })
                                      )}

                                
                                    <div className="action-btns d-flex justify-content-start gap-4 mt-5">
                                        
                                        <Button className="btn btn-danger cs-btn-danger mt-1 w-20" type="submit" disabled={validation.isSubmitting}>
                                          SUBMIT
                                        </Button>
                                      
                                    </div>

                                </div>
                              
                            </div>
                          </div>
                          </form>
                        )
                      }
                    }
                  )
                }
              }
            )
          : null}
      </div>
    </React.Fragment>
  )
}

ManualLCMSWorksheet.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ManualLCMSWorksheet)
